import React, { Component } from 'react';
import debounce from 'lodash/debounce';

import { detectMobile } from 'utils/responsive';

const Responsive = WrappedComponent => {
  return class ResponsiveContainer extends Component {
    state = {
      isMobile: detectMobile()
    }

    resizeHandler = debounce(() => {
      this.setState({
        isMobile: detectMobile()
      });
    }, 300)

    componentDidMount() {
      window.addEventListener('resize', this.resizeHandler);
    }

    componentWillUnmount() {
      this.resizeHandler.cancel();
      window.removeEventListener('resize', this.resizeHandler);
    }

    render() {
      const { isMobile } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          isMobile={isMobile}
        />
      );
    }
  };
};


export default Responsive;
