import React from 'react';
import PropTypes from 'prop-types';

import IconCardsLuiza from 'icons/payment/icon-cards-luiza.svg';

import { toBRL } from 'utils/currency';

import './CardLuiza.scss';

const CardLuiza = ({
  numberOfInstallments,
  installmentAmount,
}) => (
  <div className="CardLuiza">
    <div className="CardLuiza__icon-cardluiza">
      <IconCardsLuiza className="IconCardsLuiza" />
    </div>
    <div className="CardLuiza__text-luiza">
      <p>
        (Em até {numberOfInstallments}x de { toBRL(installmentAmount) } sem juros no Cartão Luiza)
      </p>
    </div>
  </div>
);

CardLuiza.propTypes = {
  numberOfInstallments: PropTypes.number.isRequired,
  installmentAmount: PropTypes.string.isRequired,
};

export default CardLuiza;
