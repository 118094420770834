import verifyIfMiniapp from 'utils/verifyIfMiniapp';

export const removeFields = (payload, fields = []) => {
  if (!verifyIfMiniapp()) {
    fields.forEach((field) => {
      delete payload[field];
    });
  }

  return payload;
};
