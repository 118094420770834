import cookies from 'browser-cookies';

import {
  COOKIE_TYPE_CUSTOMER,
  COOKIE_TYPE_ZIPCODE,
  CUSTOMER_STORAGE_DATA,
  CUSTOMER_SESSION,
  WISHLIST_COOKIE,
  STWU
} from 'constants/cookies';

export const validateCustomer = (customerData) => {
  if (typeof customerData === 'string') return false;

  const isValidCustomer =
    customerData &&
    customerData.name && // customer must have name
    customerData.id && // customer must have id
    customerData.id !== 'None'; // backend could return the string 'None'

  return !!isValidCustomer;
};

export const getJsonCookie = (cookieName) => {
  let cookie = cookies.get(cookieName);
  if (cookie) {
    cookie = cookie.replace(/\+/g, ' ');
  } else {
    return false;
  }

  let jsonCookie = null;

  try {
    jsonCookie = JSON.parse(cookie);
  } catch (err) {
    return false;
  }

  return jsonCookie;
};

export const getCookieName = (type) =>
  window.MAGALU_CHANNEL?.channel_configs.cookies[type];

export const getChannelConfig = (config) => {
  return window.MAGALU_CHANNEL?.channel_configs?.[config];
};

export const getCustomer = () => {
  const disableSessionLocalStorage =
    getChannelConfig('disable_session_local_storage') || false;
  const enableCustomerCookie = getChannelConfig('enable_customer_cookie') ?? true;
  const uselocalStorage = !disableSessionLocalStorage && !enableCustomerCookie;

  return uselocalStorage
    ? storageGetCustomerData()
    : getJsonCookie(getCookieName(COOKIE_TYPE_CUSTOMER));
};

export const getZipcodeFromCookies = () => {
  const zipcode = cookies.get(getCookieName(COOKIE_TYPE_ZIPCODE));

  if (zipcode) {
    return zipcode;
  }

  const customerCookie = getJsonCookie(getCookieName(COOKIE_TYPE_CUSTOMER));
  return customerCookie?.zip_code || '';
};

export const storageGetCustomerData = () =>
  JSON.parse(localStorage.getItem(CUSTOMER_STORAGE_DATA)) || false;

export const logoutCustomer = () => {
  const cookieDomain = `${window.location.hostname.split('.').slice(1).join('.')}`;

  localStorage.removeItem(CUSTOMER_STORAGE_DATA);
  cookies.erase(getCookieName(COOKIE_TYPE_CUSTOMER), {
    domain: cookieDomain,
  });
  cookies.erase(CUSTOMER_SESSION, {
    domain: cookieDomain,
  });
  cookies.erase(WISHLIST_COOKIE, {
    domain: cookieDomain,
  });
  cookies.erase(STWU, {
    domain: cookieDomain,
  });
};

export const isLogged = () => {
  const customerData = getCustomer();

  if (customerData?.expiresAt) {
    const today = Date.now();
    if (today >= customerData.expiresAt) {
      logoutCustomer();

      return false;
    }

    const channelCustomerCookieName = getCookieName(COOKIE_TYPE_CUSTOMER);
    const channelCustomerCookie = getJsonCookie(channelCustomerCookieName);

    if (!channelCustomerCookie) {
      logoutCustomer();
      return false;
    }
  }

  return validateCustomer(customerData);
};

export const storageSaveCustomerData = (customerData) => {
  const actualSession = storageGetCustomerData() || {};

  const loggedAt = Date.now();

  const DAYS_TO_EXPIRE = 1;
  const expiresAt = new Date(loggedAt);
  expiresAt.setDate(expiresAt.getDate() + DAYS_TO_EXPIRE);

  const data = {
    ...actualSession,
    loggedAt,
    expiresAt: expiresAt.getTime(),
    ...customerData,
  };

  localStorage.setItem(CUSTOMER_STORAGE_DATA, JSON.stringify(data));
};

export const tmxGenerateSessionID = () => {
  if (window.crypto.randomUUID) {
    return window.crypto.randomUUID();
  }

  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      ((window.crypto || window.msCrypto).getRandomValues(new Uint8Array(1))[0] &
        (15 >> (c / 4)))
    ).toString(16)
  );
};

export const getCurrentStep = () => {
  const path = window.location.hash.replace('#/', '').split('?')[0];
  const currentPath = path || 'sacola';

  return currentPath;
};
