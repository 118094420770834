import cleanHtml from 'utils/cleanHtml';
import { formatShipmentData } from './formatShipmentData';
import { formatTags } from './formatTags';
import { GTM_UNDEFINED } from './constants';
import { sellerType } from './sellerType';
import { productName } from './productName';
import { getProductAvailability } from './getProductAvailability';

export const generateDimensionsData = (stateAfter, product, productId) => {
  const isAvailable = getProductAvailability(product);
  const deliveries = stateAfter?.basket?.basket?.packages;
  const findProduct = stateAfter.basket.basket?.products?.find(item => item.sku === product.sku);
  const reviewCount = product?.review_count || product?.reviewCount || 'null';
  const reviewScore = product?.review_score || product?.reviewScore || 'null';
  const showcaseId = product?.showcase?.id || product?.showcaseId;
  const showcaseType = product?.showcase?.type || product?.showcaseType;
  const showcaseTitle = product?.showcase?.title || product?.showcaseTitle;
  const isRecommendation = !!showcaseTitle;
  const dimension102 = isRecommendation ? `sacola:${productName(cleanHtml(showcaseTitle))}` : GTM_UNDEFINED;

  const dimensions = {
    dimension61: product.seller || product.storeId,
    dimension11: isAvailable,
    dimension112: deliveries ? formatShipmentData(deliveries, productId) : GTM_UNDEFINED,
    dimension62: findProduct?.provider || GTM_UNDEFINED,
    dimension7: GTM_UNDEFINED,
    dimension115: GTM_UNDEFINED,
    dimension86: formatTags(deliveries, productId),
    dimension24: `qtd-av:${reviewCount}|av:${reviewScore}`,
    dimension5: sellerType(product?.storeId || product?.seller),
    dimension102,
    dimension103: product?.link?.includes('rgr.bob') ? 'bob' : 'acme',
    dimension104: GTM_UNDEFINED,
    dimension108: isRecommendation && showcaseType || GTM_UNDEFINED,
    dimension106: isRecommendation ?
      `${product.pageId}-${product.placementId}-${showcaseId}` : GTM_UNDEFINED
  };

  if (isRecommendation) {
    dimensions.list = dimension102;
    dimensions.position = product?.position || 1;
  }

  return dimensions;
};
