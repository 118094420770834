import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import './ButtonEdit.scss';

const ButtonEdit = ({to, handleClick, className, label}) => {
  const dataGA = JSON.stringify({
    'category': 'Revisao do pedido',
    'action': 'Editar Sacola',
    'label': '',
  });

  return (
    <Link
      className={`ButtonEdit ${className}`}
      to={to}
      data-ga={dataGA}
      onClick={handleClick}
    >
      {label}
    </Link>
  );
};

ButtonEdit.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.objectOf(PropTypes.string),
    }),
  ]),
  handleClick: PropTypes.func,
};

ButtonEdit.defaultProps = {
  label: 'Alterar',
  to: {},
  handleClick: undefined,
  className: ''
};

export default ButtonEdit;
