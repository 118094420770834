import { productName, productsDataGA4 } from './helpers';
import cleanHtml from 'utils/cleanHtml';

import { getCustomer } from 'utils/session';

const basketPage = 'carrinho';

export function viewItemList(action, stateBefore, stateAfter) {
  const layer = {};
  const screenName = action.products[0]?.screenName || basketPage;
  layer.event = 'view_item_list';
  layer.eventCategory = `${
    screenName === basketPage ? 'carrinho' : screenName
  }:magalu:vitrine`;
  layer.eventAction = 'impression';
  layer.eventLabel = `${action.products[0]?.showcaseType || null}:${productName(
    cleanHtml(basketPage)
  )}:slot-null`;
  layer.eventNonInteraction = '1';
  layer.screenName = screenName;

  const customer = getCustomer();
  layer.idUsuario = customer?.id || null;
  layer.mundos = 'magalu';
  layer.item_list_name = `carrinho:${productName(
    cleanHtml(action.products[0]?.showcaseTitle)
  )}`;
  layer.items = productsDataGA4({
    products: action.products?.slice(action.first, action.last) || null,
    stateAfter,
    index: action.index,
  });

  return layer;
}

export function addToCartGA4(action, stateBefore, stateAfter) {
  const layer = {};
  const eventId = window.crypto.randomUUID();
  const product = action.product || null;
  const screenName = product?.screenName || basketPage;
  const eventName = 'add_to_cart';
  layer.event = eventName;
  layer.eventId = eventId;
  layer.eventCategory = `${screenName === basketPage ? 'carrinho' : screenName}`;
  layer.eventLabel = 'adicionar-a-sacola';
  layer.screenName = screenName;

  const customer = getCustomer();
  layer.idUsuario = customer?.id || null;
  layer.mundos = 'magalu';
  layer.items = productsDataGA4({
    products: [product],
    stateAfter,
    index: action.index + 1,
    eventName,
  });

  return layer;
}
