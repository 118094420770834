/**
 * Add a redux action to dataLayer read by gtm events
 *
 * @export
 * @param object action - redux action
 * @param array dataLayer - array to be read by gtm
 * @returns array - the datalayer
 */
export function addToDatalayer(action, dataLayer) {
  const layer = {
    event: 'action',
    ...action
  };

  dataLayer.push(layer);
  return dataLayer;
}
