export const RECEIVED = 'gift-card/RECEIVED';

export const initialState = {
  giftChecked: false,
  giftInfo: {
    from: '',
    to: '',
    message: '',
    cpfOrCnpj: '',
    stateInscription: ''
  }
};

export default function reducer(state = initialState, action = {}) {
  const { giftInfo } = action;

  switch (action.type) {
    case RECEIVED:
      return Object.assign({}, state, {
        giftChecked: true,
        giftInfo
      });
    default:
      return state;
  }
}

export function receivedGiftCard(giftInfo) {
  return {
    type: RECEIVED,
    giftInfo
  };
}
