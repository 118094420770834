import React from 'react';

import './StorePickupToken.scss';

const StorePickupToken = () => (
  <div className="StorePickupToken">
    <span className="ti ti-warning-amber" />
    <div className="StorePickupToken-warning-text">
      <b>Você receberá um código para retirar o pedido na loja</b>
      <p>
        Enviaremos o código no seu e-email e WhatsApp. Não deixe de levar um documento com
        foto.
      </p>
    </div>
  </div>
);

export default StorePickupToken;
