import React from 'react';
import PropTypes from 'prop-types';

import './NewsletterOption.scss';

export const NewsletterOption = ({
  success,
  handleClick,
  isMobile,
}) => {
  const sizes = isMobile
    ? {
      width: '360',
      height: '127',
    }
    : {
      width: '956',
      height: '112',
    };

  const data = success
    ? {
      className: 'NewsletterOption-success',
      alt: 'Eba! Agora é só aguardar as ofertas no seu e-mail',
      onClick: () => { },
      src: `${SETTINGS.staticPath}sucesso_optin_email_${sizes.width}x${sizes.height}.png`,
      ...sizes,
    }
    : {
      className: 'NewsletterOption-invite',
      alt: 'Clique aqui para receber promoções exclusivas no seu e-mail',
      onClick: handleClick,
      src: `${SETTINGS.staticPath}convite_optin_email_${sizes.width}x${sizes.height}.png`,
      ...sizes,
    };

  return (
    <div className={`NewsletterOption ${data.className}`}>
      <button className="NewsletterOption-button">
        <img
          className="NewsletterOption-img"
          src={data.src}
          width={data.width}
          height={data.height}
          alt={data.alt}
          onClick={data.onClick}
        />
      </button>
    </div>
  );
};

NewsletterOption.propTypes = {
  success: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default NewsletterOption;
