export const DATE_FORMAT = 'YYYY-MM-DD';
export const morningType = 'morning';
export const afternoonType = 'afternoon';
export const nightType = 'night';

export function getPeriodLabel(period) {
  switch (period) {
    case morningType:
      return 'manhã';
    case afternoonType:
      return 'tarde';
    case nightType:
      return 'noite';
    default:
      return '';
  }
}
