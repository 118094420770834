import React from 'react';
import PropTypes from 'prop-types';

import ModalCardNumberIcon from 'icons/payment/modal/icon-card-number.svg';
import ModalCardCcvIcon from 'icons/payment/modal/icon-card-ccv.svg';
import ModalCardLimitIcon from 'icons/payment/modal/icon-card-limit.svg';
import ModalUserIcon from 'icons/payment/modal/icon-user.svg';
import CardIcon from 'icons/payment/icon-credit-card.svg';
import BankSlipIcon from 'icons/payment/icon-bank-slip.svg';

import PaymentFailModalSubmitButton from 'components/Payment/PaymentFail/PaymentFailModalSubmitButton/PaymentFailModalSubmitButton';

import './PaymentFailModal.scss';

const PaymentFail = ({
  success,
  handleRetryClick,
  handleButtonClick,
  hasMarketplaceItems,
  enablePhoneSales,
}) => (
  <div className="PaymentFailModal">
    {success && (
      <div>
        <div className="PaymentFailModal-title">
            Pronto! Agora é só aguardar nosso contato.
        </div>
        <div className="PaymentFailModal-subtitle">
            Você receberá uma ligação no número registrado em seu cadastro.
        </div>
      </div>
    ) || (
      <div>
        <div className="PaymentFailModal-titleWrapper">
          <ModalUserIcon className="PaymentFailModal-title-icon" />
          <div className="PaymentFailModal-title">
              Não foi possível concluir seu pagamento.
          </div>
          <div className="PaymentFailModal-subtitle">
              Vamos fazer uma nova tentativa? Antes de continuar, verifique os pontos abaixo:
          </div>
        </div>

        <div className="PaymentFailModal-ReasonsWrapper">
          <div className="PaymentFailModal-ReasonItem">
            <ModalCardNumberIcon className="PaymentFailModal-ReasonIcon" />
            <p className="PaymentFailModal-ReasonText">
              O número do cartão e a validade estão corretos?
            </p>
          </div>
          <div className="PaymentFailModal-ReasonItem">
            <ModalCardCcvIcon className="PaymentFailModal-ReasonIcon" />
            <p className="PaymentFailModal-ReasonText">
              O código de segurança foi digitado corretamente?
            </p>
          </div>
          <div className="PaymentFailModal-ReasonItem--bigger">
            <ModalCardLimitIcon className="PaymentFailModal-ReasonIcon" />
            <p className="PaymentFailModal-ReasonText">
              O valor da compra não excedeu o limite do seu cartão?
            </p>
          </div>
        </div>

        <div className="PaymentFailModal-content">
            Você também pode finalizar sua compra selecionando uma nova forma de pagamento:
        </div>

        <div className="PaymentFailModal-RetryPaymentWrapper">
          <div
            className={`PaymentFailModal-RetryPaymentItem--NewCard${hasMarketplaceItems && 'Large' || ''}`}
            onClick={() => handleRetryClick({ target: { value: 'new_card', id: 'newCard-line' } })}
          >
            <CardIcon className="PaymentFailModal-RetryPaymentIcon" />
                  Novo Cartão de Crédito
          </div>
          <div
            className="PaymentFailModal-RetryPaymentItem--BankSlip"
            onClick={() => handleRetryClick({ target: { value: 'bank_slip', id: 'bankSlip-line' } })}
          >
            <BankSlipIcon className="PaymentFailModal-RetryPaymentIcon" />
                  Boleto Bancário
          </div>
        </div>

        {enablePhoneSales && (
          <div>
            <div className="PaymentFailModal-ReceiveCallText">
                  Se preferir, nossa Central de Atendimento pode entrar em contato com você!
            </div>
            <PaymentFailModalSubmitButton
              handleButtonClick={handleButtonClick}
            />
          </div>
        )}
      </div>
    )}
  </div>
);

PaymentFail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleRequestClose: PropTypes.func,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleRetryClick: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  hasMarketplaceItems: PropTypes.bool,
  enablePhoneSales: PropTypes.bool.isRequired,
};

export default PaymentFail;
