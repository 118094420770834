export function productIdPrimaryByCookie(cookieValue, id = '') {
  if (cookieValue) {
    const jsonCookie = JSON.parse(decodeURIComponent(cookieValue));

    if (Array.isArray(jsonCookie)) {
      const finalProductId = jsonCookie.reduce((acc, curr) => {
        if (curr[id] && Array.isArray(curr[id])) {
          return curr[id][0];
        }

        if (curr[id] && curr[id].length > 0) {
          return curr[id];
        }

        return acc;
      }, id);

      return finalProductId;
    }

    return id;
  }

  return id;
}
