import React from 'react';
import PropTypes from 'prop-types';

import * as maskers from 'utils/maskers';

import './DeliveryModeButtons.scss';

const DeliveryModeButtons = ({ enableEmail, enableSms, onClick, contacts }) => {
  const buttonContent = [];

  if (enableEmail) {
    buttonContent.push({
      type: 'email',
      textContent: 'E-mail em',
      userContact: contacts.email,
      icon: <span className="ti ti-mail DeliveryModeButtons-btn__svg" />
    });
  }

  if (enableSms) {
    buttonContent.push({
      type: 'sms',
      textContent: 'SMS no número',
      userContact: maskers.mobilePhoneApp(contacts.sms),
      icon: <span className="ti ti-chat-bubble-outline DeliveryModeButtons-btn__svg" />
    });
  }

  const classDeiveryButtons = 'DeliveryModeButtons-wrapper';
  const classSingleDeliveryMode = `${buttonContent.length < 2 ? ` ${classDeiveryButtons}-single-delivery-mode` : ''}`;

  return (
    <div className={`${classDeiveryButtons}${classSingleDeliveryMode}`}>
      {buttonContent.map(btnContent => {
        return (
          <button
            key={btnContent.type}
            onClick={() => onClick({
              to: btnContent.userContact,
              delivery_mode: btnContent.type
            })}
            className="DeliveryModeButtons-btn"
          >
            <div className="DeliveryModeButtons-btn-content">
              {btnContent.icon}
              <span>
                {btnContent.textContent}
                <span className="DeliveryModeButtons-btn-info">
                  {' '}
                  {btnContent.userContact}
                </span>
              </span>
            </div>
            <span className="ti ti-chevron-right DeliveryModeButtons-svg-hidden" />
          </button>
        );
      })}
    </div>
  );
};

DeliveryModeButtons.PropTypes = {
  enableEmail: PropTypes.bool.isRequired,
  enableSms: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  contacts: PropTypes.shape({
    email: PropTypes.string,
    sms: PropTypes.string
  }).isRequired
};

export default DeliveryModeButtons;
