import React, { Component } from 'react';

import LoadingIcon from 'icons/loading';

import './Loading.scss';

export class LoadingWithBackground extends Component {
  render() {
    return (
      <div className="LoadingBackground">
        <Loading />
      </div>
    );
  }
}

export default class Loading extends Component {
  render() {
    return (
      <LoadingIcon
        className="CenteredLoading"
        {...this.props}
      />
    );
  }
}
