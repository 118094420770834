import { GTM_UNDEFINED } from './constants';
import { productName } from './productName';

export const formatShipmentData = (deliveries, productId) => {
  let productDeliveries = [];
  
  const setProductModality = (delivery) => {
    if (delivery.modalities) {
      productDeliveries.push(...delivery.modalities);
    } else {
      productDeliveries.push(delivery.modality);
    }
  };

  const formatModalityText = (modality) =>
    `${productName(modality.name)}:${modality?.shipping_time?.max_value}:${modality.amount}`;

  for (const delivery of deliveries) {
    const deliveryMethod = delivery?.items?.find(item => item.sku === productId);
    
    if (deliveryMethod) setProductModality(delivery);
  }

  return productDeliveries.length ? productDeliveries.map(formatModalityText).join('|') : GTM_UNDEFINED;
};
