export const SHOW = 'loading/SHOW';
export const HIDE = 'loading/HIDE';

export const initialState = {
  ids: []
};

export default function reducer(state = initialState, action = {}) {
  const { id } = action;
  switch (action.type) {
    case SHOW:
      if (!state.ids.find(i => id === i)) {
        return Object.assign({}, state, {
          ids: [...state.ids, id]
        });
      }
      return state;
    case HIDE:
      return Object.assign({}, state, {
        ids: state.ids.filter(i => id !== i)
      });
    default:
      return state;
  }
}

export function showLoading(id) {
  return { type: SHOW, id };
}

export function hideLoading(id) {
  return { type: HIDE, id };
}

export function toggleLoading(id, show) {
  switch (show) {
    case true:
      return { type: SHOW, id };
    case false:
    default:
      return { type: HIDE, id };
  }
}
