export const SET_CAPTCHA_CALLBACK = 'captcha/SET_CAPTCHA_CALLBACK';

export const initialState = {
  captchaCallbackFunction: () => null,
};

export default function reducer(state = initialState, action = {}) {
  // This new state will always clear the error messages first.
  const newState = Object.assign({}, state, { errorMessage: '' });

  if (action.type === SET_CAPTCHA_CALLBACK) {
    return Object.assign({}, newState, {
      captchaCallbackFunction: action.callback,
    });
  }
  return state;
}

export function setCaptchaCallback(callback) {
  return { type: SET_CAPTCHA_CALLBACK, callback };
}
