import {NATIONAL_CURRENCY} from "constants/currency";

/**
 * Add dots on thousands
 * @param {string} integer The integer value as string
 *
 * @returns {string}
 */
export function addThousandDots(integer) {
  let integerWithDots = '';
  let i;

  for (i = integer.length; i > 3; i -= 3) {
    integerWithDots += '.' + integer.slice(i - 3, i);
  }
  integerWithDots = integer.slice(0, i) + integerWithDots;

  return integerWithDots;
}

/**
 * Return string to Brazilian
 * @param {float} value The value
 * @param {string} [prefix] The prefix
 *
 * @returns {string}
 */
export function toBRL(value, prefix = 'R$') {
  const floatValue = parseFloat(value);
  if (floatValue <= 0) {
    return '';
  }

  let [integer, decimal] = floatValue.toString().split('.');
  decimal = decimal || '00';
  decimal = `${decimal}0`.slice(0, 2);

  if (isNaN(integer) || integer === '0' && decimal === '00') {
    return '';
  }

  integer = addThousandDots(integer);

  return `${prefix} ${integer},${decimal}`;
}

export const toBRLwithZero = (value) =>
  (value === '0.00')
    ? 'R$ 0,00'
    : toBRL(value);

export const findInternationalProductCurrency = (packages) => {
  if (!packages) return null;

  const internationalProduct = packages
    .flatMap((pkg) => pkg.products)
    .find((product) =>
      Object.prototype.hasOwnProperty.call(product, 'currency') &&
      product.currency &&
      product.currency !== NATIONAL_CURRENCY
    );

  return internationalProduct ? internationalProduct.currency : null;
};
