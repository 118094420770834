import { checkoutUrl } from 'constants/api-urls';

import request from 'utils/request';
import getFingerprint from 'utils/fingerprint';

export const custumerOption = async (notificationsOption) => {
  const fingerprintHeaders = await getFingerprint();

  return request('PUT', `${checkoutUrl}/order/notifications/`)
    .send({ notification_option: notificationsOption })
    .set(fingerprintHeaders)
    .then(res => res, err => err);
};
