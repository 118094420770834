import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { copyToClipboard } from 'utils/copyToClipboard';

import Copy from 'icons/icon-copy';

const { string } = PropTypes;

class ConfirmOrderBoxCopySlip extends Component {
  constructor(props) {
    super(props);
    this.textarea = null;
    this.state = {
      showSuccessCopyMessage: false,
    };
  }

  render() {
    const { number } = this.props;
    const { showSuccessCopyMessage } = this.state;

    return (
      <div className="BankSlip-container" onClick={() => {
        copyToClipboard({
          fallbackMessage: 'Copie o numero do boleto',
          fallbackValue: number,
          textarea: this.textarea,
          updateState: () => this.setState({ showSuccessCopyMessage: true }),
        });
      }}>
        <span className="ConfirmOrderBox-bankSlip-title">Boleto bancário</span>
        <textarea
          /* eslint-disable react/no-unknown-property */
          editable={false}
          className="ConfirmOrderBox-bankSlip"
          value={number}
          ref={ (component) => { this.textarea = component; }}
        />
        <Copy className="ConfirmOrderBox-copyIcon" />
        { (showSuccessCopyMessage && <span className="ConfirmOrderBox-copyText">Número copiado com sucesso!</span>) }
      </div>
    );
  }
}

ConfirmOrderBoxCopySlip.propTypes = {
  number: string.isRequired
};

export default ConfirmOrderBoxCopySlip;
