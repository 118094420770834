export const OPTION_CLICK = 'navigation/OPTION_CLICK';

export const initialState = {
  route: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPTION_CLICK:
      const { route } = action;
      return Object.assign({}, state, {
        route,
      });
    default:
      return state;
  }
}

export function optionClick(route, origin) {
  return (dispatch) => {
    dispatch({ type: OPTION_CLICK, route, origin });
  };
}
