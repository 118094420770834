import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';

import PaymentFormButton from 'components/Payment/Forms/PaymentFormButton/PaymentFormButton';

export const PixForm = ({ price, handleSubmit }) => (
  <form className="PixForm" onSubmit={handleSubmit} method="post">
    <div className="PixForm-price">
      <span className="PixForm-price--highlighted">{toBRL(price)}</span>
    </div>
    <div className="PixForm-description">
      Copie o código Pix na próxima etapa e faça o pagamento na instituição financeira de
      sua escolha. O código tem validade de 13 horas.
    </div>
    <PaymentFormButton paymentType="Pix" />
  </form>
);

PixForm.propTypes = {
  price: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PixForm;
