import React from 'react';
import Airplane from 'icons/icon-airplane';

import './TagInternational.scss';

const TagInternational = () => {
  return (
    <div className="TagInternational-tag">
      <Airplane />
      <span className="TagInternational-tag-bold">Compra Internacional</span>
    </div>
  );
};

export default TagInternational;
