import * as CardApi from 'api/payment-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { unauthorizedLogout } from './unauthorized';

export const REQUEST = 'card-list/REQUEST';
export const RECEIVED = 'card-list/RECEIVED';
export const CLEAR = 'card-list/CLEAR';
export const FINISHED = 'card-list/FINISHED';

export const initialState = {
  isRequesting: false,
  cardList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, { isRequesting: true });
    case RECEIVED:
      const { cardList } = action;
      return Object.assign({}, state, {
        isRequesting: false,
        cardList
      });
    case CLEAR:
      return Object.assign({}, initialState);
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    default:
      return state;
  }
}

export function requestCardList() {
  return { type: REQUEST };
}

export function receivedCardList(cardList) {
  return { type: RECEIVED, cardList };
}

export function clearCardList() {
  return { type: CLEAR };
}

export function finish() {
  return { type: FINISHED };
}

export function fetchCardList({ hasMarketplaceItems }) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { cardList = {} } = getState();

    if (cardList.isRequesting) {
      return reject();
    }

    dispatch(requestCardList());

    return CardApi.getCardList({ hasMarketplaceItems })
      .then(res => {
        dispatch(receivedCardList(res));
        return resolve();
      }, err => {
        const { status } = err;
        if (status === 401) {
          dispatch(unauthorizedLogout(err));
          dispatch(fail(customerMessages.expired, status));
        }
        dispatch(finish());
        return reject(err);
      });
  });
}
