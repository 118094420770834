/* eslint-disable camelcase */
import { productName } from './productName';

const defaultStamp = 'Selo:sem-selo';
const DEFAULT_TAGS = {
  freeShipment: 'entrega-gratis',
  empty: 'sem-tag'
};

export const formatTags = (deliveries, productId) => {
  if (!deliveries) return `${defaultStamp}|Tag:${DEFAULT_TAGS.empty}`;

  const result = [defaultStamp];
  const hasDeliveries = deliveries.length;

  if (hasDeliveries) {
    let deliveryByProduct = null;
    let shippingTag = '';

    deliveries.forEach(delivery => {
      if (!deliveryByProduct) {
        const findDeliveryByProduct = delivery?.items?.find(({ sku }) => sku === productId);
        if (findDeliveryByProduct) {
          deliveryByProduct = delivery;
        }
      }
    });

    if (deliveryByProduct) {
      const deliveryList = deliveryByProduct?.modalities || [deliveryByProduct.modality];
      let hasDoubleTags = false;

      const valueTag = deliveryList.reduce((acc, delivery, index) => {
        let valueTemp = '';
        const { amount, shipping_time } = delivery;
        const descriptionTag = shipping_time?.description ? productName(shipping_time.description) : '';
        const freeTag = Number(amount) === 0 ? DEFAULT_TAGS.freeShipment : '';
        hasDoubleTags = descriptionTag && freeTag;

        if (hasDoubleTags) {
          valueTemp += `${descriptionTag};${freeTag}`;
        } else if(!descriptionTag && freeTag || descriptionTag && !freeTag) {
          valueTemp += `${descriptionTag}${freeTag}`;
        } else {
          valueTemp += DEFAULT_TAGS.empty;
        }

        return acc + `${index !== 0 ? ';': ''}${valueTemp}`;
      }, ''
      );

      shippingTag = `Tag${hasDoubleTags ? 's' : ''}:${valueTag}`;
    } else {
      shippingTag = `Tag:${DEFAULT_TAGS.empty}`;
    }

    result.push(shippingTag);
  }

  return result.join('|');
};
