import React from 'react';
import PropTypes from 'prop-types';

import BasketTableContainer from 'containers/BasketTableContainer';
import BasketPriceBoxContainer from 'containers/BasketPriceBoxContainer/BasketPriceBoxContainer';
import { BobbyCartDetailsContainer } from 'containers/BobbyContainer';

import Responsive from 'components/Responsive';
import { showcase as Showcase } from '../../constants/recommendation';

const BasketProducts = ({ modal, isMobile, recommendations }) => (
  <div className="BasketProducts">
    {modal}
    {!isMobile && (
      <BobbyCartDetailsContainer
        pageId={Showcase.sacola.page_id}
        placementId={
          recommendations.sdsacola?.sdsacolasemestoquetop
            ? Showcase.sacola.placement_sacolasemestoquetop
            : Showcase.sacola.placement_sacolatop
        }
      />
    )}
    <div className="BasketPage-title">
      Sacola
    </div>
    <BasketTableContainer />
    <BasketPriceBoxContainer />
    {!isMobile
      && (recommendations.sdsacola?.sdsacolasemestoquebottom || recommendations.sdsacola?.sdsacolabottom)
      && (
        <BobbyCartDetailsContainer
          pageId={Showcase.sacola.page_id}
          placementId={
            recommendations.sdsacola?.sdsacolasemestoquebottom
              ? Showcase.sacola.placement_sacolasemestoquebottom
              : Showcase.sacola.placement_sacolabottom
          }
        />
      )
    }
    {isMobile && (
      <BobbyCartDetailsContainer
        pageId={Showcase.sacola.page_id}
        placementId={
          recommendations.smsacola?.smsacolasemestoquebottom
            ? Showcase.sacola.placement_sacolasemestoquebottom
            : Showcase.sacola.placement_sacolabottom
        }
      />
    )}
  </div>
);

BasketProducts.propTypes = {
  modal: PropTypes.element.isRequired,
  isMobile: PropTypes.bool,
};

export {
  BasketProducts
};
export default Responsive(BasketProducts);
