/*
 * This file is related to the signup box on login screen. We just need to available
 * if the email is already registered.
 */
import * as CustomerApi from 'api/customer-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { metricsError } from './metrics';


export const REQUEST = 'signup-available/REQUEST';
export const RECEIVED = 'signup-available/RECEIVED';
export const FINISHED = 'signup-available/FINISHED';

export const initialState = {
  isRequesting: false,
  cpfOrCnpjAvailable: false,
  cpfOrCnpj: '',
  loginAvailable: false,
  login: {
    accessToken: '',
    email: ''
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      const { cpfOrCnpj = state.cpfOrCnpj } = action;
      return Object.assign({}, state, {
        isRequesting: true,
        login: {
          accessToken: state.login?.accessToken || action.login?.accessToken,
          email: state.login?.email || action.login?.email
        },
        cpfOrCnpj
      });
    case RECEIVED:
      const {
        loginAvailable = state.loginAvailable,
        cpfOrCnpjAvailable = state.cpfOrCnpjAvailable
      } = action;
      return Object.assign({}, state, {
        isRequesting: false,
        loginAvailable,
        cpfOrCnpjAvailable
      });
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    default:
      return state;
  }
}

export function requestLoginAvailable(accessToken, email) {
  return { type: REQUEST, login: { accessToken, email } };
}

export function receiveLoginAvailable(loginAvailable) {
  return { type: RECEIVED, loginAvailable };
}

export function requestCpfOrCnpjAvailable(cpfOrCnpj) {
  return { type: REQUEST, cpfOrCnpj };
}

export function receiveCpfOrCnpjAvailable(cpfOrCnpjAvailable) {
  return { type: RECEIVED, cpfOrCnpjAvailable };
}

export function finish() {
  return { type: FINISHED };
}

export function fetchLoginAvailable(captchaToken, accessToken, email) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { signupAvailable = {} } = getState();

      if (signupAvailable.isRequesting) {
        return reject();
      }

      dispatch(requestLoginAvailable(accessToken, email));

      if (!captchaToken) {
        dispatch(metricsError('postIsUserRegistered: no captcha'));
      }

      return CustomerApi.postIsUserRegistered(captchaToken, accessToken, email)
        .then(() => {
          dispatch(receiveLoginAvailable(false));
          return reject({ errored: false });
        })
        .catch(err => {
          const { status } = err;

          if (status === 403) {
            dispatch(finish());
            return reject(err);
          }

          if (Math.floor(status / 100) === 4) {
            dispatch(receiveLoginAvailable(true));
            return resolve();
          }

          dispatch(fail(customerMessages.failSignupAvailable, status));

          if (err && err.response && err.response.body) {
            dispatch(metricsError('quero criar uma conta', err.response.body.error_message));
          }

          dispatch(finish());
          return reject({ errored: true });
        });
    });
  };
}

export function fetchLoginAvailableFromSocial(captchaToken) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { socialLogin = {} } = getState();

    dispatch(fetchLoginAvailable(
      captchaToken,
      socialLogin?.profileInfo?.accessToken,
      socialLogin?.profileInfo?.email,
    ))
      .then(resolve, reject);
  });
}

export function fetchCpfOrCnpjAvailable(cpfOrCnpj, captchaToken) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { signupAvailable = {} } = getState();

    if (signupAvailable.isRequesting) {
      return reject();
    }

    dispatch(requestCpfOrCnpjAvailable(cpfOrCnpj));

    return CustomerApi.getDocumentAvailability(cpfOrCnpj, captchaToken)
      .then(() => {
        dispatch(receiveCpfOrCnpjAvailable(true));
        return resolve();
      })
      .catch(err => {
        const { status } = err;

        if (Math.floor(status / 100) === 4) {
          dispatch(receiveCpfOrCnpjAvailable(false));
          return reject({ status, isAvailable: false });
        }

        dispatch(fail(customerMessages.failFetchCpfOrCnpjAvailable, status));

        if (err && err.response && err.response.body) {
          dispatch(metricsError('validar CPF/CNPJ', err.response.body.error_message));
        }

        dispatch(finish());
        return reject(err);
      });
  });
}
