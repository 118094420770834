import React from 'react';
import PropTypes from 'prop-types';

import Logo from 'icons/logo-magalu';
import ColorStrip from 'icons/color-strip';
import ColorStripMobile from 'components/Header/ColorStripMobile';

import CheckoutSteps from 'components/Header/CheckoutSteps/CheckoutSteps';
import Responsive from 'components/Responsive';

import './CheckoutHeader.scss';
import Navigation from 'components/CheckoutNavigation/Navigation';

const CheckoutHeader = ({
  originUrl,
  isMobile,
  location,
}) => (
  <div>
    <div className="CheckoutHeader">
      <div className="CheckoutHeader-content">
        <div className="CheckoutHeader-logo">
          <a href={originUrl}>
            <Logo className="CheckoutHeader-logo-icon" />
          </a>
        </div>
        { !isMobile &&
      <CheckoutSteps location={location} />
        }
      </div>

      { isMobile &&
      <div className="CheckoutHeader-colorsStrip" >
        <ColorStripMobile
          className="CheckoutHeader-colorsStrip-image"
          width={window.innerWidth}
        />
      </div>
      }
      { !isMobile &&
      <div className="CheckoutHeader-colorsStrip" >
        <ColorStrip className="CheckoutHeader-colorsStrip-image" />
      </div>
      }
    </div>
    <Navigation />
  </div>
);

CheckoutHeader.propTypes = {
  originUrl: PropTypes.string,
  isMobile: PropTypes.bool,
};

export {
  CheckoutHeader
};
export default Responsive(CheckoutHeader);

