export const FAIL_OTP = 'fail/FAIL_OTP';
export const RESET_FAIL_OTP = 'fail/RESET_FAIL_OTP';

export const initialState = {
  failMessage: '',
  code: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FAIL_OTP:
      const { failMessage, code } = action;
      return Object.assign({}, state, {
        failMessage,
        code,
      });
    case RESET_FAIL_OTP:
      return initialState;
    default:
      return state;
  }
}

export function failOtp(failMessage = '', code = '') {
  return { type: FAIL_OTP, failMessage, code };
}

export function resetFailOtp() {
  return { type: RESET_FAIL_OTP };
}
