import cookies from 'browser-cookies';

import { getCityUf, GTM_TRUE, GTM_FALSE } from 'utils/data-layer/helpers';
import { removeFields } from './helpers/removeAppFields';

const pageSignup = 'signup';
const firstOtp = 'firstOtp';
const secondOtp = 'secondOtp';
const validate = 'validate';
const miniAppNameKey = 'mini_app_name';

const getDeliveryMode = (mode) => (mode === 'email' ? 'email' : 'telefone');

const deliveryModeWithDescription = (description) =>
  description === 'sms' ? 'telefone' : 'email';

export function signupOtpVirtualPageView({ device, city, state, screenName, pageType }) {
  return {
    device,
    screenName,
    pageType,
    event: 'virtualPageview',
    clientId: cookies.get('_ga'),
    cidadeuf: getCityUf(city, state),
    idUsuario: cookies.get('stwu'),
    idParceiro: cookies.get('MLPARCEIRO'),
    cepPreenchido: city && state ? GTM_TRUE : GTM_FALSE,
    hitTimestamp: Date.now().toString(),
  };
}

export function selectedModeSecurityValidationOtpClick({ description, page } = {}) {
  if (page === pageSignup) {
    return {
      event: 'clickEvent',
      eventCategory: 'login:cadastro-otp',
      eventAction: 'click',
      eventLabel: description,
      eventNonInteraction: '0',
      hitTimestamp: Date.now().toString(),
    };
  } else {
    const layer = {
      event: 'clickEvent',
      eventCategory: 'alteracao-dados-cadastrais-otp',
      eventAction: 'click',
      eventLabel: deliveryModeWithDescription(description),
      screenName: 'alteracao-dados-cadastrais-otp',
      [miniAppNameKey]: 'alteracao-dados-cadastrais',
    };

    return removeFields(layer, [miniAppNameKey]);
  }
}

export function otpResponse({ status, description, page, mode } = {}) {
  if (page === pageSignup) {
    return {
      event: 'clickEvent',
      eventCategory: 'login:cadastro-otp',
      eventAction: status,
      eventLabel: description,
      eventNonInteraction: '1',
      hitTimestamp: Date.now().toString(),
    };

  } else if(page === firstOtp){
    const layer = {
      event: 'clickEvent',
      eventCategory: `alteracao-dados-cadastrais-codigo-envio:${getDeliveryMode(
        mode
      )}`,
      eventAction: status,
      eventLabel: description,
      screenName: `alteracao-dados-cadastrais:${getDeliveryMode(
        mode
      )}-codigo`,
      [miniAppNameKey]: 'alteracao-dados-cadastrais',
    };

    return removeFields(layer, [miniAppNameKey]);

  } else if (page === secondOtp) {
    const layer = {
      event: 'clickEvent',
      eventCategory: `alteracao-dados-cadastrais:alterar:${getDeliveryMode(mode)}`,
      eventAction: status,
      eventLabel: description,
      screenName: `alteracao-dados-cadastrais:alterar:${getDeliveryMode(mode)}`,
      [miniAppNameKey]: 'alteracao-dados-cadastrais',
    };

    return removeFields(layer, [miniAppNameKey]);

  } else if (page === validate){
    const layer = {
      event: 'clickEvent',
      eventCategory: `alteracao-dados-cadastrais-codigo:${getDeliveryMode(
        mode
      )}`,
      eventAction: status,
      eventLabel: description,
      screenName: `alteracao-dados-cadastrais:${getDeliveryMode(
        mode
      )}-codigo`,
      [miniAppNameKey]: 'alteracao-dados-cadastrais',
    };

    return removeFields(layer, [miniAppNameKey]);
  }
}

export const changeDataScreenOtp = ({ mode }) => {
  const layer = {
    event: 'clickEvent',
    eventCategory: `alteracao-dados-cadastrais:${getDeliveryMode(mode)}-codigo`,
    eventAction: 'click',
    eventLabel: `alterar-${getDeliveryMode(mode)}`,
    screenName: `alteracao-dados-cadastrais:${getDeliveryMode(mode)}-codigo`,
    [miniAppNameKey]: 'alteracao-dados-cadastrais',
  };

  return removeFields(layer, [miniAppNameKey]);
};

export function selectOtherConfirmationOtpClick({ mode } = {}) {
  const layer = {
    event: 'clickEvent',
    eventCategory: `alteracao-dados-cadastrais:${getDeliveryMode(
      mode
    )}-codigo`,
    eventAction: 'click',
    eventLabel: 'usar-outro-meio-de-comunicacao',
    screenName: `alteracao-dados-cadastrais:${getDeliveryMode(mode)}-codigo`,
    [miniAppNameKey]: 'alteracao-dados-cadastrais',
  };

  return removeFields(layer, [miniAppNameKey]);
}

export function resendOtpClick({ description } = {}) {
  const layer = {
    event: 'clickEvent',
    eventCategory: `alteracao-dados-cadastrais:${deliveryModeWithDescription(
      description
    )}-codigo`,
    eventAction: 'click',
    eventLabel: 'reenviar-codigo',
    screenName: `alteracao-dados-cadastrais:${deliveryModeWithDescription(
      description
    )}-codigo`,
    [miniAppNameKey]: 'alteracao-dados-cadastrais',
  };

  return removeFields(layer, [miniAppNameKey]);
}
