import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  parseExternalContent,
  requestExternalAssets
} from 'utils/request';

import {
  showLoading,
  hideLoading,
} from 'reducers/loading';

import ExternalHTMLComponent from 'components/ExternalHTMLComponent';

export class ExternalLoader extends Component {
  state = {
    html: '',
    showFallback: false,
  };

  componentWillMount() {
    const {
      externalURL,
      externalScripts,
      externalStyles,
    } = this.props;

    this.props.showLoading(externalURL);

    requestExternalAssets(externalURL, externalScripts, externalStyles)
      .then(([ rawText ]) => {
        this.props.hideLoading(externalURL);
        parseExternalContent(rawText, 'body');
        this.setState({
          html: rawText,
          showFallback: false
        });
      })
      .catch(() => {
        this.props.hideLoading(externalURL);
        this.setState({ showFallback: true });
      });
  }

  render() {
    const { fallback: Fallback } = this.props;

    if (this.state.showFallback && Fallback) return <Fallback />;

    return <ExternalHTMLComponent externalHTML={this.state.html} />;
  }
}

ExternalLoader.propTypes = {
  externalURL: PropTypes.string,
  externalScripts: PropTypes.arrayOf(PropTypes.string),
  externalStyles: PropTypes.arrayOf(PropTypes.string),
  showLoading: PropTypes.func.isRequired,
  hideLoading: PropTypes.func.isRequired,
  fallback: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element,
  ])
};

const mapDispatchToProps = {
  showLoading,
  hideLoading,
};

export default connect(
  null,
  mapDispatchToProps
)(ExternalLoader);
