import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';

const CreditCardPayment = ({
  installmentText,
  amountPerInstallment,
}) => (
  <span>
    Cartão de crédito <span className="ConfirmOrderBox-payment_types-amount">
    ({installmentText.toLowerCase()} {toBRL(amountPerInstallment)})
    </span>
  </span>
);

CreditCardPayment.propTypes = {
  installmentText: PropTypes.string.isRequired,
  amountPerInstallment: PropTypes.string.isRequired
};

export default CreditCardPayment;
