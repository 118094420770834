import React from 'react';
import PropTypes from 'prop-types';

import './BasketItemWarranty.scss';


const BasketItemWarranty = ({ name }) => (
  <div className="BasketItemWarranty">
    <div className="BasketItemWarranty-title">
      + { name }
    </div>
  </div>
);

BasketItemWarranty.propTypes = {
  name: PropTypes.string.isRequired,
};

export default BasketItemWarranty;
