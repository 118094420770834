import { variationCategory } from './variationCategory';
import { categoryFromId } from './categoryFromId';
import { categoryFromList } from './categoryFromList';
import { GTM_UNDEFINED } from './constants';

export function productCategoryName(product) {
  if (product?.variation) {
    return variationCategory(product.variation);
  }

  if (product?.category_id || product?.categoryId) {
    return categoryFromId(product);
  }

  if (product?.categories?.[0]?.subcategories?.[0]?.id) {
    return categoryFromList(product);
  }

  return GTM_UNDEFINED;
}
