export const customerAreaChangeData = {
  email: {
    title: 'Alteração de endereço de e-mail',
    subtext: 'O endereço de e-mail atual é:',
    instructions: `Digite o novo e-mail que será 
    usado para acessar sua conta. 
    Enviaremos um código para verificação na próxima etapa.`,
    labelText: 'Novo endereço de e-mail',
    type: 'email',
    inputSize:'long',
    placeholder: 'Digite seu e-mail',
    buttonSpan: 'Alterar e-mail',
  },
  emailConfirm: {
    labelText: 'Confirme seu e-mail',
    type: 'email',
    inputSize:'long',
    placeholder: 'Digite seu e-mail',
  },
  telephone: {
    title: 'Alteração de telefone',
    subtext: 'O telefone atual é:',
    instructions: `Digite o novo telefone que será 
    usado para validar sua conta. 
    Enviaremos um código para verificação na próxima etapa.`,
    labelText: 'Novo telefone',
    type: 'tel',
    inputSize:'small',
    placeholder: 'Digite seu telefone',
    buttonSpan: 'Alterar telefone',
  },
  telephoneConfirm: {
    labelText: 'Confirme seu telefone',
    type: 'tel',
    inputSize:'small',
    placeholder: 'Digite seu telefone',
  }
};
