import React from 'react';

import Modal from 'components/Modal/Modal';

import './InternationalTaxICMSModal.scss';

const ModalContent = (props) => {
  return (
    <div className="InternationalTaxICMSModal">
      <div className="InternationalTaxICMSModal-header">
        <span className="font-lg-bold">{props?.title}</span>
      </div>

      <div className="InternationalTaxICMSModal-content">
        <p className="font-sm-regular">
          Imposto estadual sobre a circulação de produtos e serviços no Brasil,
          definido em 17% independente do valor da compra.
        </p>
      </div>

      <div className="InternationalTaxICMSModal-footer">
        <button
          className="InternationalTaxICMSModal-btn BtnLarge"
          onClick={props.toggle}>
          Continuar
        </button>
      </div>
    </div>
  );
};

const InternationalTaxICMSModal = ({ isOpen, toggle }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="ICMS"
      content={ModalContent}
      withoutFooter
      toggle={toggle}
      mobileIsDrawer
    />
  );
};

export default InternationalTaxICMSModal;
