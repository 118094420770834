import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default async function getFingerprint() {
  const fpPromise = FingerprintJS.load();
  const fp = await fpPromise;
  const result = await fp.get();
  const visitorId = result.visitorId;

  return {
    'x-fingerprint': visitorId
  };
}
