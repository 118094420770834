import forEach from 'lodash/forEach';
import superagent from 'superagent';
import superagentPromise from 'superagent-promise';

const requestAgent = superagentPromise(superagent, Promise);

export default function request(...args) {
  return requestAgent(...args).withCredentials();
}

export const ping = (link) => {
  superagent.get(link).end();
};

export function requestExternalAssets(externalUrl, scripts = [], styles = []) {
  const credentials = window.MAGALU_CHANNEL.channel_name !== 'magazineluiza';
  const requestHTML = externalUrl && requestExternalHTML(externalUrl, credentials);
  const requestJS = scripts.map(createAsyncScript);
  const requestStyle = styles.map(createAsyncLink);

  const ArrPromises = [requestHTML, ...requestStyle, ...requestJS];

  return Promise.all(ArrPromises);
}

export function requestExternalHTML(externalUrl, credentials = true) {
  return new Promise((resolve, reject) => {
    requestAgent
      .get(externalUrl)
      .withCredentials(credentials)
      .end()
      .then(
        res => {
          if (typeof window.XDomainRequest === 'undefined') {
            resolve(res.text);
          }
        }
      )
      .catch(reject);
  });
}

export function parseExternalContent(html, objectId) {
  const doc = document.createElement('div');
  // IE9 cant container below tags
  doc.innerHTML = html
    .replace(/<\/?(col|colgroup|frameset|html|style|table|tbody|tfoot|thead|title|tr)>/ig, 'div');
  const scriptElements = doc.getElementsByTagName('script');

  forEach(scriptElements, el => {
    const element = document.getElementsByTagName('script')[0];
    const gjs = element;
    let js;
    const elSrc = el.getAttribute('src');
    const elID = el.getAttribute('id');
    const id = elID || objectId;

    if (document.getElementById(id) || !elSrc) {
      return;
    }

    js = document.createElement('script');
    js.id = id;
    js.src = elSrc;
    gjs.parentNode.insertBefore(js, gjs);
  });
}

function createAsyncScript(url) {
  return new Promise((resolve, reject) => {
    const tag = createScript(url);
    appendToBody(tag);

    tag.onload = resolve;
    tag.onerror = reject;
  });
}

function createScript(url) {
  const tag = document.createElement('script');
  tag.src = url;
  return tag;
}

export function appendToBody(tag) {
  document.getElementsByTagName('body')[0].appendChild(tag);
}

export function htmlFromString(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild;
}

export const appendScriptsFromStringHTML = (stringHTML) => {
  const html = htmlFromString(stringHTML);
  const scriptTags = html.getElementsByTagName('script');
  for (let script of scriptTags) {
    appendToBody(createScript(script.src));
  }
};

/**
 * Generate list of scripts
 * @param {array} urls
 * @return {element} div element with array of scripts
 */
export const generateElementsScript = (urls = []) => {
  return urls.reduce((div, url) => {
    div.appendChild(createScript(url));
    return div;
  }, document.createElement('div'));
};

/**
 * Function to append scripts in the end of body
 * @param {array} urls
 */
export const importExternalScripts = (urls) => {
  if (!Array.isArray(urls)) {
    return;
  }

  appendToBody(generateElementsScript(urls));
};

function createAsyncLink(url) {
  return new Promise((resolve, reject) => {
    const tag = createLink(url);
    appendToBody(tag);

    tag.onload = resolve;
    tag.onerror = reject;
  });
}

export function createLink(url) {
  const link = document.createElement('link');

  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;

  return link;
}

export function appendStyle(url) {
  const head = document.head;
  const link = createLink(url);

  head.appendChild(link);
}
