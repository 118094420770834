import React from 'react';
import Policies from 'components/Policies/Policies';
import IconInfo from 'icons/icon-info.svg';
import Modal from 'components/ModalAdjustable';

function getViolatePolicyWarn(violatedRule) {
  if (violatedRule.available > 0) {
    return `Você ainda pode comprar ${violatedRule.available} unidades do produto`;
  }

  return 'Você não pode comprar unidades do produto';
}

class BasketPolicyViolationWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false
    };
  }

  handleOpenModal() {
    this.setState(prevState => ({
      openModal: !prevState.openModal
    }));
  }

  render() {
    const { product, violatedRule } = this.props;

    return (
      <div className="BasketItem-policyViolation">
        <button onClick={() => this.handleOpenModal()} className="BasketItem-policyViolation-button">
          <IconInfo />
        </button>
        <span>
          <span className="BasketItem-text-bold">Política violada:</span> {getViolatePolicyWarn(violatedRule)}
        </span>

        <Modal
          isOpen={this.state.openModal}
          onRequestClose={() => this.handleOpenModal()}
        >
          <Policies
            policy={violatedRule}
            product={product}
          />
        </Modal>
      </div>
    );
  }
}

export default BasketPolicyViolationWarning;



