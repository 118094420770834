import request from 'utils/request';
import { checkoutUrl } from 'constants/api-urls';
import * as PaymentModels from './models/payment-models';

export const getCardList = ({ hasMarketplaceItems }) => {
  return request('GET', `${checkoutUrl}/creditcard/list/`)
    .query({ has_marketplace_items: hasMarketplaceItems })
    .then(res => res.body)
    .then(json => json && json.data && json.data.cards.map(PaymentModels.card) || []);
};

export const getAllowedBins = () => {
  return request('GET', `${checkoutUrl}/creditcard/list/bins/`)
    .then(res => res.body)
    .then(json => json && json.data && PaymentModels.allowedBins(json.data));
};

export const postPlaceOrder = (data) => {
  return request('POST', `${checkoutUrl}/cart/placeorder/`)
    .send(data)
    .then(res => res.body);
};

export const getOrder = (orderId) => {
  return request('GET', `${checkoutUrl}/order/${orderId}/`)
    .then(res => res.body)
    .then(json => PaymentModels.order(json));
};

export const postCallMe = () => {
  return request('GET', `${checkoutUrl}/cart/callme/`)
    .then(res => res.body);
};
