import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import DeliveryAddress from 'containers/DeliveryAddress';

import * as routeNames from 'constants/route-names';

import * as addressListActions from 'reducers/address-list';
import * as addressActions from 'reducers/address';

import { redirectToLogin } from 'utils/redirect';
import { addressSelectContent } from 'utils/data-layer/helpers';

@connect(null, {
  ...addressListActions,
  ...addressActions,
})
export default class AddressPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    fetchAddressList: PropTypes.func.isRequired,
    putAddress: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  onFetchAddresses = (fetchProps) => {
    const { shipment, initializeAddressForm, router, handleFetchError, redirectToBasket } = fetchProps;
    const { location, fetchAddressList } = this.props;

    return fetchAddressList({ showAll: location.query.showAll === 'true' })
      .then((res = []) => {
        if (!res.length) {
          return initializeAddressForm();
        }

        if (shipment.zipcode) {
          const foundAddress = res.find(
            (address) => address.zipcode === shipment.zipcode
          );

          if (!foundAddress && !isEmpty(res) && !res[0].redirect) {
            return shipment.zipcode;
          }
        }
      })
      .catch((err) => {
        if (err) {
          if (err.status === 400) {
            return redirectToBasket(router);
          }

          if (err.status === 401) {
            return redirectToLogin(router, routeNames.address, location);
          }

          if (err.status === 404) {
            return initializeAddressForm();
          }

          if (err.status === 406) {
            return router.push({
              pathname: routeNames.editRegistration,
              query: { next: routeNames.address },
            });
          }
          
          handleFetchError();
        }
      });
  };

  onAddressSubmit = (submitProps) => {
    const {
      patchAddress,
      postSignup,
      customerInfo,
      editAddressUuid,
      values,
      handleSubmitError,
      handleCustomRedirectToDelivery
    } = submitProps;

    const { putAddress } = this.props;

    const address = Object.assign({}, values, { addressUuid: editAddressUuid });

    return new Promise((resolve, reject) => {
      return putAddress(address, true)
        .then(({ addressUuid }) => addressUuid)
        .then((addressUuid) => patchAddress(addressUuid, true))
        .then(() => {
          addressSelectContent({
            contentId: 'sucesso',
            contentType: 'formulario-enderecos',
          });

          if (!values.setCustomerAddress) {
            return;
          }

          const dataCustomer = Object.assign({}, customerInfo, {
            address: values.address,
            city: values.city,
            complement: values.complement,
            neighbourhood: values.neighbourhood,
            number: values.number || 'S/N',
            reference: values.reference,
            state: values.state,
            zipcode: values.zipcode,
          });

          postSignup('pf', dataCustomer);
        })
        .then(handleCustomRedirectToDelivery)
        .then(resolve)
        .catch((error) => {
          addressSelectContent({
            contentId: `erro: Não foi possível salvar o endereço | status:${error.status}`,
            contentType: 'formulario-enderecos',
          });
          
          handleSubmitError();

          return reject(error);
        });
    });
  }

  render() {
    return (
      <DeliveryAddress
        location={this.props.location}
        redirectToLoginNext={routeNames.address}
        onFetchAddresses={this.onFetchAddresses}
        onAddressSubmit={this.onAddressSubmit}
        putDeliveryAddress={this.props.putAddress}
      />
    );
  }
}
