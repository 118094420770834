import React from 'react';
import PropTypes from 'prop-types';

import Password from 'icons/icon-visibility.svg';
import PasswordOff from 'icons/icon-visibility-off.svg';

import './ButtonShowPassword.scss';

export default function ButtonShowPassword(props) {
  return(
    <button
      className={props.buttonClassName}
      type="button"
      aria-label={props.field ? 'Ocultar senha' : 'Ver senha'}
      onClick={props.handleClick}
    >
      <span className={`${props.buttonClassName}__icon`}>
        {props.field ? <PasswordOff /> : <Password />}
      </span>
    </button>
  );
}

ButtonShowPassword.propTypes = {
  buttonClassName: PropTypes.string,
  field: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

ButtonShowPassword.defaultProps = {
  buttonClassName: 'ButtonShowPassword',
};
