const getLayer = () => ({
  event: 'clickEvent',
  eventCategory: 'identificacao:criar-conta',
  eventNonInteraction: '0',
  hitTimestamp: Date.now().toString(),
});

export function signupResponse({ status, description } = {}) {
  return {
    ...getLayer(),
    eventAction: status,
    eventLabel: description,
  };
}

export function signupCreateMpay({ checked } = {}) {
  return {
    ...getLayer(),
    eventAction: checked ? 'aplicar' : 'limpar',
    eventLabel: 'abrir-conta-mpay',
  };
}

export function signupKnowMoreTerms({ description } = {}) {
  return {
    ...getLayer(),
    eventAction: 'click',
    eventLabel: description,
  };
}
