import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseX from 'icons/icon-close.svg';
import Responsive from 'components/Responsive';
import { handleLockBackgroundScroll } from 'utils/maskers';
import CheckoutHeader from 'components/Header/CheckoutHeader/CheckoutHeader';

import './MobileModal.scss';

const { bool, node, func, string } = PropTypes;

class MobileModal extends Component {
  static propTypes = {
    children: node.isRequired,
    isMobile: bool.isRequired,
    handleClose: func,
    styleClass: string,
    title: string,
    titleClass: string,
    hasHeader: bool
  }

  static defaultProps = {
    styleClass: '',
    title: '',
    titleClass: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      currentOffset: window.pageYOffset
    };
  }
  componentDidMount() {
    if (this.props.isMobile) {
      window.scroll(0, 0);
      document.body.classList.add('no-scroll');
      document.getElementsByTagName('html')[0].classList.add('flow-controll-modal');
      handleLockBackgroundScroll('inherit');
    }
  }
  componentWillUnmount() {
    if (this.props.isMobile) {
      window.scroll(0, this.state.currentOffset);
      document.body.classList.remove('no-scroll');
      document.getElementsByTagName('html')[0].classList.remove('flow-controll-modal');
    }
  }

  handleClose = (e) => {
    this.props.handleClose(e);
    handleLockBackgroundScroll('inherit');
  }

  render() {
    const {
      isMobile,
      children,
      title,
      handleClose,
      titleClass,
      styleClass,
      hasHeader,
    } = this.props;

    if (!isMobile) {
      return (
        children
      );
    }
    return (
      <div className={`ModalContainer ${styleClass}`}>
        {hasHeader && <CheckoutHeader isMobile />}
        {handleClose && <CloseX className="ModalContainer-CloseIcon" onClick={this.handleClose} />}
        { title && <div className={`DeliveryModal-header ${titleClass}`}> {title} </div> }
        { this.props.children }
      </div>
    );
  }
}

export default Responsive(MobileModal);
