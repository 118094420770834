import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { toBRL } from 'utils/currency';

import FormGroup from 'components/Form/FormGroup/FormGroup';
import Dropdown from 'components/Form/Dropdown/Dropdown';
import InputCvvForm from 'components/Form/InputCvvForm';
import PaymentFormButton from 'components/Payment/Forms/PaymentFormButton/PaymentFormButton';

export const SavedCardForm = ({
  expirationDate,
  CVC,
  installments: installmentsField,
  installmentsList,
  handleSubmit,
  flag,
}) => {
  const expirationMoment = moment(expirationDate.value, 'MM/YYYY');
  const isCardExpired = expirationMoment.endOf('month') < moment();

  return (
    <form className="SavedCardForm" onSubmit={handleSubmit} method="post">
      <FormGroup
        labelText="Validade"
        field={Object.assign({}, expirationDate, {
          disabled: true,
          error: (isCardExpired && 'Este cartão está expirado.') || '',
        })}
        forceShowSuccess={!isCardExpired}
        forceShowError={isCardExpired}
        inputSize="extraSmall"
      />
      <InputCvvForm CVC={CVC} flag={flag} />
      <Dropdown
        labelText="Parcelamento"
        inputSize="long"
        field={Object.assign({}, installmentsField, { disabled: isCardExpired })}>
        <option value="-1">Selecione</option>
        {installmentsList.map(
          ({ numberOfInstallments, installmentAmount, interestRate, description }, i) =>
            flag.value === 'magalu_card' && interestRate > 0 ? (
              <option key={i} value={i}>
                {numberOfInstallments}x de {toBRL(installmentAmount)}*
              </option>
            ) : (
              <option key={i} value={i}>
                {description} {toBRL(installmentAmount)}
              </option>
            )
        )}
      </Dropdown>
      <PaymentFormButton
        flag={flag && flag.value}
        paymentType="Cartão Salvo"
        disabled={isCardExpired}
      />
    </form>
  );
};

SavedCardForm.propTypes = {
  expirationDate: PropTypes.object.isRequired,
  CVC: PropTypes.object.isRequired,
  installments: PropTypes.object.isRequired,
  installmentsList: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  flag: PropTypes.object,
};

export default SavedCardForm;
