import React from 'react';
import PropTypes from 'prop-types';

import { toBRLwithZero } from 'utils/currency';


const OrderReviewDiscount = ({ discount, title, type }) => (
  <div className="OrderReviewTotals-discount">
    <span className="OrderReviewTotals-left">{ title }</span>
    <span className={`OrderReviewTotals-right OrderReviewTotals--${type}`}>
      <span className="OrderReviewTotals-discount__minus">-</span>
      { toBRLwithZero(discount) }
    </span>
  </div>
);

OrderReviewDiscount.propTypes = {
  discount: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default OrderReviewDiscount;
