export const zipcode = (value) => {
  return value && value.replace(/-+/g, '');
};

export const onlyNumbers = (value) => {
  return value && value.replace(/\D+/g, '');
};

export const onlyCharactersAndSpace = (value, allowNumbers = false) => {
  const accentsMap = {
    A: 'Á|À|Ã|Â|Ä',
    a: 'á|à|ã|â|ä',
    E: 'É|È|Ê|Ë',
    e: 'é|è|ê|ë',
    I: 'Í|Ì|Î|Ï',
    i: 'í|ì|î|ï',
    O: 'Ó|Ò|Ô|Õ|Ö',
    o: 'ó|ò|ô|õ|ö',
    U: 'Ú|Ù|Û|Ü',
    u: 'ú|ù|û|ü',
    C: 'Ç',
    c: 'ç',
    N: 'Ñ',
    n: 'ñ',
  };

  const chars = Object.keys(accentsMap);
  const reducer = (acc, [char]) => acc.replace(new RegExp(accentsMap[char], 'g'), char);
  const removeAccents = (text) => chars.reduce(reducer, text);
  const regex = allowNumbers ? /[^a-z 0-9]+/gi : /[^a-z ]+/gi;

  return value && removeAccents(value).replace(regex, '');
};

export const cpfOrCnpj = (value) => {
  return value && value.replace(/[.\-\/]/g, '');
};

export const removeSpecialChars = (value = '', includes = '') => {
  const regexp = new RegExp(`[^a-z0-9${includes}]`, 'gi');
  return value.replace(regexp, '');
};
