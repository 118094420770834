import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CheckoutFooter from 'components/Footer/CheckoutFooter/CheckoutFooter';
import ExternalLoader from 'components/ExternalLoader';
import './ChannelFooterContainer.scss';

const mapStateToProps = ({ channel }) => ({
  footerURL: channel.configs.site_footer_url,
  maxProductQuantity: channel.configs.maximum_quantity_per_sku,
});

@connect(mapStateToProps, undefined)
export default class ChannelFooterContainer extends Component {
  static propTypes = {
    footerURL: PropTypes.string,
  };

  render() {
    const { footerURL } = this.props;

    if (window.MAGALU_CHANNEL.channel_name === 'magazineluiza') {
      return <iframe src={footerURL} className="footer-iframe-reset" />;
    }

    return (
      <ExternalLoader
        externalURL={footerURL}
        fallback={() => <CheckoutFooter />}
      />
    );
  }
}
