import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';
import withModal from 'hocs/withModal/withModal';
import InternationalTaxICMSModal from 'components/InternationalTaxICMSModal/InternationalTaxICMSModal';
import InternationalImportTaxModal from 'components/InternationalImportTaxModal/InternationalImportTaxModal';

class OrderReviewInternationalTax extends Component {
  static propTypes = {
    importValue: PropTypes.string,
    icms: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isInternationalTaxICMSModalOpen: false,
      isInternationalImportTaxModalOpen: false,
    };
  }

  handleInternationalTaxICMSModalToggle = () => {
    this.setState({ isInternationalTaxICMSModalOpen: !this.state.isInternationalTaxICMSModalOpen });
  }

  handleInternationalImportTaxModalToggle = () => {
    this.setState({ isInternationalImportTaxModalOpen: !this.state.isInternationalImportTaxModalOpen });
  }

  render() {
    const {importValue, icms} = this.props;

    const { isInternationalTaxICMSModalOpen, isInternationalImportTaxModalOpen } = this.state;

    if (!importValue || !icms) {
      return null;
    }

    return (
      <div className="OrderReviewTotals-international-tax">
        <InternationalTaxICMSModal isOpen={isInternationalTaxICMSModalOpen} toggle={this.handleInternationalTaxICMSModalToggle} />
        <InternationalImportTaxModal isOpen={isInternationalImportTaxModalOpen} toggle={this.handleInternationalImportTaxModalToggle} />
        {importValue && (
          <div className="OrderReviewTotals-international-tax-item">
            <span className="OrderReviewTotals-left">
              <span
                className="OrderReviewTotals-international-tax-text ti ti-help-outline cursor-pointer"
                onClick={this.handleInternationalImportTaxModalToggle}
              />{' '}
            Imposto de Importação{' '}
            </span>
            <span className="OrderReviewTotals-right">{toBRL(importValue)}</span>
          </div>
        )}

        {icms && (
          <div className="OrderReviewTotals-international-tax-item OrderReviewTotals-international-tax-icms">
            <span className="OrderReviewTotals-left">
              <span
                className="OrderReviewTotals-international-tax-text ti ti-help-outline cursor-pointer"
                onClick={this.handleInternationalTaxICMSModalToggle}
              />{' '}
              ICMS{' '}
            </span>
            <span className="OrderReviewTotals-right">{toBRL(icms)}</span>
          </div>
        )}
      </div>
    );
  }
}

export default withModal(OrderReviewInternationalTax);
