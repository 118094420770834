export const variationCategory = (
  variation = {
    categories: [
      {
        id: '',
        subcategories: [{ id: '' }]
      }
    ]
  }
) => `${variation.categories[0].id}/${variation.categories[0].subcategories[0].id}`.toLowerCase();
