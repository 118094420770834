import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';

const OrderReviewShipment = ({ shipment }) => (
  <div className="OrderReviewTotals-shipment">
    <span className="OrderReviewTotals-left">Frete</span>
    <span className={
      toBRL(shipment) === '' ?
        'OrderReviewTotals-right--free' :
        'OrderReviewTotals-right'
    }
    >
      {toBRL(shipment) || 'Grátis'}
    </span>
  </div>
);

OrderReviewShipment.propTypes = {
  shipment: PropTypes.string.isRequired,
};

export default OrderReviewShipment;
