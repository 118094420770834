import React from 'react';
import PropTypes from 'prop-types';

import CardCvvIcon from 'icons/payment/icon-credit-card-cvv.svg';
import SmartphoneIcon from 'icons/icon-smartphone.svg';

import {
  commonCard as commonCvvMessage,
  virtualDebitElo as virtualDebitEloCvvMessage,
} from 'constants/customer-messages';

const InputMessageCVV = ({
  flag
}) => {
  let icon = <CardCvvIcon className="CardFlag-cvv-card" />;
  let message = commonCvvMessage;

  if (flag === 'virtual_debit_elo') {
    icon = <SmartphoneIcon className="CardFlag-smartphone" />;
    message = virtualDebitEloCvvMessage;
  }

  return (
    <div className="PaymentInfoLabel">
      {icon}
      {message}
    </div>
  );
};

InputMessageCVV.propTypes = {
  flag: PropTypes.string.isRequired
};

export default InputMessageCVV;
