import React, { Component } from 'react';

import * as routeNames from 'constants/route-names';

import { isLogged } from 'utils/session';
import { redirectToLogin } from 'utils/redirect';

export default class DeliveryPage extends Component {
  componentWillMount() {
    const { location } = this.props;
    const { router } = this.context;

    if (!isLogged()) {
      return redirectToLogin(router, routeNames.delivery, location);
    }

    const nationalDeliveryPage = `${process.env.REACT_APP_NATIONAL_BASE_URL || ''}#/entrega`;
    const pathToRedirect = new RegExp('(.{0,})\/#\/?entrega', 'i');
    const urlToRedirect = window.location.href.replace(pathToRedirect, nationalDeliveryPage);

    return window.location.assign(urlToRedirect);
  }

  render() {
    return null;
  }
}
