import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import * as orderActions from 'reducers/order';
import * as routeNames from 'constants/route-names';
import * as maskers from 'utils/maskers';
import Logo from 'icons/logo-no-slogan.svg';
import { toBRL } from 'utils/currency';
import { redirectToLogin } from 'utils/redirect';
import { isLogged } from 'utils/session';
import * as paymentTypes from 'constants/payment-types';

import './ReceiptPage.scss';

moment.locale('pt-br');

const { bool, array, number, string, node, object, func } = PropTypes;

class ReceiptContent extends Component {
  static propTypes = {
    title: node,
    children: node
  };

  render() {
    const { title, children } = this.props;
    return (
      <div className="ReceiptContent">
        <div className="ReceiptContent-titleContainer">
          <div className="ReceiptContent-title">
            {title}
          </div>
        </div>
        <div className="ReceiptContent-details">
          {children}
        </div>
      </div>
    );
  }
}

class ReceiptBasketItem extends Component {
  static propTypes = {
    id: string.isRequired,
    title: string.isRequired,
    reference: string,
    quantity: number.isRequired,
    gifts: array.isRequired,
    warranty: object.isRequired,
    subtotal: string.isRequired,
    variations: array,
    services: array.isRequired
  };
  render() {
    const {
      id,
      title,
      reference = '',
      quantity,
      gifts,
      warranty,
      variations,
      services,
      subtotal
    } = this.props;
    return (
      <div className="ReceiptBasketItem">
        <div className="ReceiptBasketItem-description">
          {maskers.fullProductDescription({
            quantity,
            title,
            reference,
            warranty,
            gifts,
            variations
          })}
          ({id})
        </div>
        <div className="ReceiptBasketItem-price">
          {toBRL(subtotal)}
        </div>
        {services.map((service, i) => (
          <span key={i}>
            <div className="ReceiptBasketItem-description">
              {maskers.fullServiceDescription(quantity, service.name)}
            </div>
            <div className="ReceiptBasketItem-price">
              {toBRL(service.subtotal)}
            </div>
          </span>
        ))}
      </div>
    );
  }
}

export class ReceiptBasketPackage extends Component {
  static propTypes = {
    store: string.isRequired,
    time: number.isRequired,
    products: array.isRequired,
    deliveryType: string.isRequired,
    scheduledParameters: object.isRequired,
    index: number.isRequired,
    packagesCount: number.isRequired,
    shippingPrice: string.isRequired,
    description: string,
  };

  render() {
    const {
      index,
      store,
      time,
      products,
      deliveryType,
      scheduledParameters,
      packagesCount,
      shippingPrice,
      description,
    } = this.props;

    return (
      <div className="ReceiptBasketPackage">
        <div className="ReceiptBasketPackage-description">
          <b>{`Entrega ${maskers.addLeadingZero(index)} de ${maskers.addLeadingZero(packagesCount)}`}</b> por {store}
        </div>
        <div className="ReceiptBasketPackage-items">
          {products.map((product, j) => <ReceiptBasketItem {...product} key={`product-${index}-${j}`} />)}
        </div>
        <div className="ReceiptBasketPackage-shippingType">
          <div className="ReceiptBasketPackage-shippingType-description">
            {
              description ||
              maskers.reviewDeliveryLabel({
                deliveryType,
                time,
                scheduledParameters
              })
            }
          </div>
          <div className="ReceiptBasketPackage-shippingType-price">
            {toBRL(shippingPrice) || 'Frete grátis'}
          </div>
        </div>
      </div>
    );
  }
}

class ReceiptBasketAddress extends Component {
  static propTypes = {
    number: string.isRequired,
    address: string.isRequired,
    state: string.isRequired,
    reference: string,
    complement: string,
    city: string.isRequired,
    receiver: string.isRequired,
    receiverDocument: string,
    isStore: bool,
    startIndex: number.isRequired,
    endIndex: number.isRequired
  };

  render() {
    const {
      number: addressNumber,
      address,
      state,
      reference,
      complement = '',
      city,
      receiver,
      receiverDocument = '',
      isStore = false,
      startIndex,
      endIndex
    } = this.props;

    return (
      <div className="ReceiptBasketAddress">
        <div className="ReceiptBasketAddress-description">
          {maskers.reviewAddressDescription({
            startIndex,
            endIndex,
            isStore,
            address,
            addressNumber,
            complement,
            city,
            state,
            reference,
          })}
        </div>
        <div className="ReceiptBasketAddress-receiver">
          {isStore && 'Quem irá retirar' || 'Destinatário'}: {receiver}
          {isStore && receiverDocument && ` - RG ou CNH: ${receiverDocument}` || ''}
        </div>
      </div>
    );
  }
}

class ReceiptBasketTable extends Component {
  static propTypes = {
    shippingTypes: object.isRequired,
    packagesCount: number.isRequired,
    totalAmount: string.isRequired,
    shippingAmount: string.isRequired
  };

  render() {
    const {
      shippingTypes: { deliveries, stores },
      packagesCount,
      totalAmount
    } = this.props;
    let packageIndex = 0;
    let packageStartIndex = 1;
    return (
      <div className="ReceiptBasketTable">
        <div className="ReceiptBasketTable-title">
          <div className="ReceiptBasketTable-title-product">Sacola</div>
          <div className="ReceiptBasketTable-title-price">Preço</div>
        </div>
        <div className="ReceiptBasketTable-items">
          {deliveries.packages.map(package_ => {
            packageIndex += 1;
            return (
              <ReceiptBasketPackage
                {...package_}
                key={`package-${packageIndex}`}
                index={packageIndex}
                packagesCount={packagesCount}
              />
            );
          })}
          {deliveries.packages.length && <ReceiptBasketAddress
            startIndex={packageStartIndex}
            endIndex={packageStartIndex + deliveries.packages.length - 1}
            {...deliveries.address}
          /> || null}
          {stores.map((store, index) => {
            const currentStore = Object.values(store)[0];
            packageStartIndex += packageIndex;

            return (
              <span className={`ReceiptBasketTable-store${packageIndex > 0 && '--inMiddle' || ''}`} key={`store-${index}`}>
                {currentStore.packages.map(storePackage => {
                  packageIndex += 1;
                  return (
                    <ReceiptBasketPackage
                      key={`package-${packageIndex}`}
                      {...storePackage}
                      index={packageIndex}
                      packagesCount={packagesCount}
                    />
                  );
                })}
                <ReceiptBasketAddress
                  isStore
                  startIndex={packageStartIndex}
                  endIndex={packageStartIndex + currentStore.packages.length - 1}
                  {...currentStore.address}
                />
              </span>
            );
          })}
        </div>
        <div className="ReceiptBasketTable-total">
          <div className="ReceiptBasketTable-total-title">Total</div>
          <div className="ReceiptBasketTable-total-price">{toBRL(totalAmount)}</div>
        </div>
      </div>
    );
  }
}

@connect(({ order, unauthorized }) => ({ ...order, ...unauthorized }), { ...orderActions })
export default class ReceiptPage extends Component {
  static propTypes = {
    order: object.isRequired,
    fetchOrder: func.isRequired,
    location: object.isRequired,
    err: object
  };

  static contextTypes = {
    router: object.isRequired
  };

  componentWillMount() {
    const {
      order,
      location,
      fetchOrder
    } = this.props;
    const { router } = this.context;
    const { query: { orderId } } = location;

    if (!orderId) {
      return router.replace(routeNames.root);
    }

    if (!isLogged()) {
      return redirectToLogin(router, routeNames.receipt, location);
    }

    if (isEmpty(order) || order.orderId !== orderId) {
      return fetchOrder(orderId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location, err } = nextProps;
    const { router } = this.context;

    if (err) {
      return redirectToLogin(router, routeNames.receipt, location);
    }
  }

  render() {
    const { order } = this.props;
    const {
      id,
      createdAt,
      customerName,
      customerEmail,
      customerAddress,
      payments,
      bankSlip
    } = order;
    const today = moment();
    return id && (
      <div className="ReceiptPage">
        <div className="ReceiptHeader">
          <Logo className="ReceiptHeader-logo" />
          <div className="ReceiptHeader-timestamp">Página impressa em {today.format('DD/MM/YYYY')} às {today.format('HH:mm:ss')}</div>
        </div>
        <div className="ReceiptTitle">
          <div className="ReceiptTitle-title">
            Sua compra foi realizada com sucesso.
          </div>
          <div className="ReceiptTitle-subtitle">
            Agradecemos por escolher o magazineluiza.com.br. Este é o comprovante de recebimento da sua compra.
          </div>
        </div>
        <ReceiptContent title="Detalhes do Pedido">
          <div>Número: {id}</div>
          <div>Data: {createdAt.format('DD/MM/YYYY')} às {createdAt.format('HH:mm:ss')}</div>
        </ReceiptContent>
        <ReceiptContent title="Informações Cadastrais">
          <div>Nome: {customerName}</div>
          <div>
            {maskers.fullAddress(
              customerAddress.address,
              customerAddress.neighbourhood,
              customerAddress.city,
              customerAddress.state
            )}
          </div>
          <div>CEP: {maskers.zipcode(customerAddress.zipcode)}</div>
          <div>E-mail: {customerEmail}</div>
        </ReceiptContent>
        <ReceiptContent title="Forma de pagamento">
          {
            payments.map(payment => {
              const amountPerInstallment = payments.length > 1 ? `(${toBRL(payment.amountPerInstallment)})` : '';
              return (
                <div>
                  {
                    payment.type === paymentTypes.bankSlip && `Boleto bancário ${amountPerInstallment} - Vencimento: ${bankSlip.expiration}` ||
                    payment.type === paymentTypes.valeCompra && `Vale Compra ${amountPerInstallment}` ||
                    payment.type === paymentTypes.pix && `Pix ${amountPerInstallment}` ||
                    payment.type === paymentTypes.virtualDebitElo && `Cartão de débito virtual Caixa ${amountPerInstallment}` ||
                    `Cartão de crédito (${payment.installmentText.toLowerCase()} ${toBRL(payment.amountPerInstallment)})`
                  }
                  <br/>
                </div>
              );
            })
          }
        </ReceiptContent>
        <ReceiptBasketTable {...order} />
      </div>
    ) || null;
  }
}
