import React from 'react';
import PropTypes from 'prop-types';

import {
  sameDayAvailability,
  boldExtendedDelivery,
  normalExtendedDelivery,
} from 'constants/customer-messages';

import {
  reviewDeliveryLabel as reviewDeliveryLabelMasker,
  addLeadingZero as addLeadingZeroMasker,
} from 'utils/maskers';

import OrderReviewItem from 'components/OrderReview/OrderReviewItem/OrderReviewItem';

import './OrderReviewPackage.scss';
import isInternationalPrice from 'utils/isInternationalPrice';
import TagInternational from 'components/TagInternational/TagInternational';

const OrderReviewPackage = ({
  index,
  store,
  time,
  products,
  scheduledParameters,
  packagesCount,
  zipcodeRestriction,
  zipcode,
  deliveryType,
  promotionalDate,
  firstPartySeller,
  shippingTimeDescription,
}) => {
  const isSameDayFirstParty = time === 0 && store === firstPartySeller;

  function messageControl(isSameDay) {
    if (isSameDay && promotionalDate) {
      return (
        <span>
          <span className="OrderReviewPackage-samedayMessage">
            {' '}
            {boldExtendedDelivery}{' '}
          </span>{' '}
          {normalExtendedDelivery}
        </span>
      );
    } else if (isSameDay) {
      return (
        <span>
          {reviewDeliveryLabelMasker({
            deliveryType,
            time,
            scheduledParameters,
          })}
          <p className="OrderReviewPackage-samedayMessage">{sameDayAvailability}</p>
        </span>
      );
    }
    return reviewDeliveryLabelMasker({ deliveryType, time, scheduledParameters });
  }
  const deliveryIndex = addLeadingZeroMasker(index);
  const totalPackages = addLeadingZeroMasker(packagesCount);

  const [productReference] = products || [];

  return (
    <div className="OrderReviewPackage">
      <b>{`Entrega ${deliveryIndex} de ${totalPackages}`}</b>
      <span>
        {' '}
        por {store} -{' '}
        {shippingTimeDescription
          ? shippingTimeDescription
          : messageControl(isSameDayFirstParty)}{' '}
      </span>

      {isInternationalPrice(productReference) && (
        <div className="OrderReviewPackage-international">
          <TagInternational />
        </div>
      )}

      {products.map((product, j) => (
        <OrderReviewItem
          {...product}
          key={`product-${index}-${j}`}
          zipcodeRestriction={zipcodeRestriction}
          zipcode={zipcode}
          productCount={products.length}
        />
      ))}
    </div>
  );
};

OrderReviewPackage.propTypes = {
  store: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  deliveryType: PropTypes.string.isRequired,
  scheduledParameters: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  packagesCount: PropTypes.number.isRequired,
  zipcodeRestriction: PropTypes.bool,
  zipcode: PropTypes.string,
  promotionalDate: PropTypes.bool,
  firstPartySeller: PropTypes.string.isRequired,
};

export default OrderReviewPackage;
