const modality_ = (payload = {}) => {
  const {
    id,
    name,
    amount,
    shipping_time: { min_value: minValue },
  } = payload;

  return {
    ...payload,
    id: id.toString(),
    description: name,
    cost: amount,
    price: amount,
    time: minValue,
    parameters: {
      modality_id: id,
    },
  };
};

const delivery_ = (payload = {}) => {
  const { items, modalities, id: deliveryId } = payload;

  const {
    id: modalityId,
    name,
    amount,
    shipping_time: { min_value: minValue } = {},
  } = modalities.find((modality) => modality.type === 'conventional') || {};

  return {
    price: amount,
    cost: amount,
    products: items,
    deliveryTime: minValue,
    store: '?', // this will be used on /cart endpoint
    deliveryTypeId: modalityId.toString(),
    deliveryTypeDescription: name,
    deliveryTypes: modalities.map(modality_),
    deliveryId,
  };
};

export const shipment = (payload = {}) => {
  const {
    address_detail: addressDetail = {},
    out_of_stock_products: outOfStockProducts,
    deliveries = [],
    unavailable_deliveries: unavailableDeliveries,
    conventional_amount: conventionalAmount = '',
  } = payload;

  const address_ = {
    ...addressDetail,
    address: addressDetail.street_address,
  };
  const deliveries_ = deliveries.map(delivery_);
  const unavailableDeliveries_ = unavailableDeliveries;
  const conventionalAmount_ = conventionalAmount.toString();

  return {
    address: address_.address || '',
    state: address_.state,
    city: address_.city,
    neighbourhood: address_.area || '',
    zipcode: address_.zip_code,
    cost: conventionalAmount_,
    outOfStockProducts,
    notAvailableProducts: unavailableDeliveries_,
    packages: deliveries_,
  };
};

export const shipmentPackages = (packages = []) => packages.map(delivery_);
