import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  root as routeBasket,
  login as routeLogin,
  signup as routeSignup,
  address as routeAddress,
  delivery as routeDelivery,
  payment as routePayment,
} from 'constants/route-names';

import Cart from 'icons/icon-cart';
import Identify from 'icons/icon-identify';
import Payment from 'icons/icon-payment';
import Ship from 'icons/icon-ship';
import { isLogged, getChannelConfig } from 'utils/session';

import './CheckoutSteps.scss';

const { object } = PropTypes;

export default class CheckoutSteps extends Component {
  static contextTypes = {
    router: object.isRequired
  };

  getActiveClasses(expectedPathname) {
    const { router } = this.context;
    const active = '--active';
    let icon = 'CheckoutSteps-item-icon';
    let title = 'CheckoutSteps-item-title';
    const urlPath = !router && window.location.href.split('#/')[1];

    // did that because root path is ''
    const isEqualPathname = (router && (
      expectedPathname && expectedPathname.find(path => router.isActive(path)) ||
      expectedPathname === '' && router.isActive(expectedPathname, true)
    )) || expectedPathname.includes(urlPath) || undefined;

    if (isEqualPathname) {
      icon = `${icon}${active}`;
      title = `${title}${active}`;
    }

    return { icon, title };
  }
  goTo(path) {
    const { router } = this.context;

    if (router) {
      router.push(path);
    } else if (path !== 'entrega') {
      window.location.assign(`/#/${path}`);
    }
    this.forceUpdate();
  }
  render() {
    const cartClassNames = this.getActiveClasses(routeBasket);

    const loginClassNames = this.getActiveClasses([
      routeLogin,
      routeSignup,
      'recuperar-senha'
    ]);

    const deliveryClassNames = this.getActiveClasses([
      routeAddress,
      routeDelivery,
    ]);

    const paymentClassNames = this.getActiveClasses([
      routePayment,
    ]);

    if(getChannelConfig('enable_checkout_shortcuts')) return null;

    return (
      <ul className="CheckoutSteps" >
        <li className="CheckoutSteps-item">
          <button className="CheckoutSteps-item-button"
            onClick={() => {
              this.goTo(routeBasket);
            }}
          >
            <Cart className={cartClassNames.icon} />
            <span className={cartClassNames.title}>
                Sacola
            </span>
          </button>
        </li>
        <li className="CheckoutSteps-item">
          <button className="CheckoutSteps-item-button"
            onClick={() => {
              const pageLogin = isLogged()
                ? routeDelivery
                : routeLogin;
              return this.goTo(pageLogin);

            }}
          >
            <Identify className={loginClassNames.icon} />
            <span className={loginClassNames.title}>
                Identificação
            </span>
          </button>
        </li>
        <li className="CheckoutSteps-item">
          <button className="CheckoutSteps-item-button"
            onClick={() => {
              const pageAdress = isLogged()
                ? routeDelivery
                : routeLogin;
              return this.goTo(pageAdress);
            }}
          >
            <Ship className={deliveryClassNames.icon} />
            <span className={deliveryClassNames.title}>
                Entrega
            </span>
          </button>
        </li>
        <li className="CheckoutSteps-item">
          <button className="CheckoutSteps-item-button"
            onClick={() => {
              const pagePayment = isLogged()
                ? routePayment
                : routeLogin;
              return this.goTo(pagePayment);
            }}
          >
            <Payment className={paymentClassNames.icon} />
            <span className={paymentClassNames.title}>
                Pagamento
            </span>
          </button>
        </li>
      </ul>
    );
  }
}
