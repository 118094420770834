export const recommendation = (recommendations) => {
  return {
    id: recommendations.id,
    placementId: recommendations.placement.id,
    showcases: recommendations.placement.showcases.map(showcase => ({
      ...showcase,
      mobileSubject: showcase.title,
      subject: showcase.title,
      products: showcase.items.map(item => ({
        ...item,
        showcaseId: showcase.id,
        showcaseType: showcase.type,
        showcaseTitle: showcase.title,
        placementId: recommendations.placement.id,
        pageId: recommendations.id,
        storeId: item.seller,
        rawPictureUrl: item.medias.length ? item.medias[0] : '',
        prices: {
          from: item.list_price,
          to: item.price,
          cash: item.best_price?.amount || item.price,
        },
        priceDescription: item.best_price?.payment_method_description || 'à vista',
        installment: {
          quantity: item.best_installment_plan?.installment_quantity,
          price: item.best_installment_plan?.installment_amount,
          description: item.best_installment_plan?.payment_method_description,
        },
        stewieProductId: item.sku,
        variationId: item.sku,
        link: item.base_url,
        reviewCount: item.review_count,
        reviewScore: item.review_score,
        stockStatus: null,
        carouselName: null,
      })),
    })),
  };
};
