import * as CustomerApi from 'api/customer-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { unauthorizedLogout } from './unauthorized';

export const REQUEST = 'customer/REQUEST';
export const RECEIVED = 'customer/RECEIVED';
export const CLEAR = 'customer/CLEAR';
export const FINISHED = 'customer/FINISHED';

export const initialState = {
  isRequesting: false,
  customerInfo: {},
};

export default function reducer(state = initialState, action = {}) {
  const { customerInfo } = action;
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, { isRequesting: true });
    case RECEIVED:
      return Object.assign({}, state, {
        isRequesting: false,
        customerInfo,
      });
    case CLEAR:
      return Object.assign({}, initialState);
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    default:
      return state;
  }
}

export function requestCustomer() {
  return { type: REQUEST };
}

export function receivedCustomer(customerInfo) {
  return { type: RECEIVED, customerInfo };
}

export function clear() {
  return { type: CLEAR };
}

export function finish() {
  return { type: FINISHED };
}

export function fetchCustomer() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { customer: customerState = {} } = getState();

      if (customerState.isRequesting) {
        return reject('Already fetching customer.');
      }

      dispatch(requestCustomer());

      return CustomerApi.getCustomer().then(
        (res) => {
          dispatch(receivedCustomer(res));
          return resolve(res);
        },
        (err) => {
          const { status } = err;
          if (status === 401) {
            dispatch(unauthorizedLogout(err));
            dispatch(fail(customerMessages.expired, err.status));
          } else {
            dispatch(fail(customerMessages.failFetchCustomer, err.status));
          }
          dispatch(finish());
          return reject(err);
        }
      );
    });
}

export function setEmailNewsletter(value) {
  return () =>
    new Promise((resolve, reject) => {
      return CustomerApi.putEmailNewsletter(value).then(resolve, (err) => {
        const { status } = err;
        return reject({ status });
      });
    });
}
