import * as BobbySlots from 'constants/bobby';
import * as BobbyApi from 'api/bobby-api';


export const REQUEST_BOBBY = 'bobby/REQUEST_BOBBY';
export const RECEIVED_CART_DETAILS = 'bobby/RECEIVED-CART-DETAILS';
export const RECEIVED_SIMILAR = 'bobby/RECEIVED-SIMILAR';
export const REMOVE_CART_DETAILS_PRODUCT = 'bobby/REMOVE-CART-DETAILS-PRODUCT';
export const RECEIVED_TRENDS = 'bobby/RECEIVED_TRENDS';
export const FINISHED = 'bobby/FINISHED';
export const ADD_TO_CART = 'bobby/ADD_TO_CART';
export const ADD_TO_CART_GA4 = 'bobby/ADD_TO_CART_GA4';
export const PRODUCT_LIST_VIEW = 'bobby/PRODUCT-IMPRESSION';
export const VIEW_ITEM_LIST = 'bobby/VIEW_ITEM_LIST';
export const PRODUCT_CLICK = 'bobby/PRODUCT-CLICK';
export const SELECT_ITEM = 'bobby/SELECT-ITEM';

export const initialState = {
  isRequesting: [],
  cartDetails: [],
  trendsPurchases: {},
  trendsViews: {},
  trendsCarts: {}
};

export const CART_DETAILS_REQUESTING = 'cartDetails';
export const TRENDS_REQUESTING = 'trends';
export const SIMILAR_REQUESTING = 'similar';

export default function reducer(state = initialState, action = {}) {
  const {
    cartDetails,
    trends,
    removeStewieProductId,
    finishId
  } = action;
  switch (action.type) {
    case REQUEST_BOBBY:
      return Object.assign({}, state, {
        isRequesting: [...state.isRequesting, action.requesting]
      });
    case RECEIVED_CART_DETAILS:
      return Object.assign({}, state, {
        isRequesting: state.isRequesting.filter(x => x !== CART_DETAILS_REQUESTING),
        cartDetails
      });
    case RECEIVED_SIMILAR:
      return Object.assign({}, state, {
        isRequesting: state.isRequesting.filter(x => x !== SIMILAR_REQUESTING),
        cartDetails
      });
    case REMOVE_CART_DETAILS_PRODUCT:
      return Object.assign({}, state, {
        cartDetails: state.cartDetails.filter(x => x.stewieProductId !== removeStewieProductId)
      });
    case RECEIVED_TRENDS:
      return Object.assign({}, state, {
        isRequesting: state.isRequesting.filter(x => x !== TRENDS_REQUESTING),
        trendsPurchases: trends.purchases || {},
        trendsViews: trends.views || {},
        trendsCarts: trends.carts || {}
      });
    case FINISHED:
      return Object.assign({}, state, {
        isRequesting: state.isRequesting.filter(x => x !== finishId)
      });
    case ADD_TO_CART:
    case ADD_TO_CART_GA4:
    case PRODUCT_LIST_VIEW:
    case PRODUCT_CLICK:
    default:
      return state;
  }
}

export function requestBobby(requesting) {
  return { type: REQUEST_BOBBY, requesting };
}

export function receivedCartDetails(cartDetails) {
  return { type: RECEIVED_CART_DETAILS, cartDetails };
}

export function receivedSimilar(cartDetails) {
  return { type: RECEIVED_SIMILAR, cartDetails };
}

export function removeBobbyProduct(removeStewieProductId) {
  return { type: REMOVE_CART_DETAILS_PRODUCT, removeStewieProductId };
}

export function productClick(product, index) {
  return { type: PRODUCT_CLICK, product, index };
}

export function selectItem(product, index) {
  return { type: SELECT_ITEM, product, index };
}

export function receivedTrends(trends) {
  return { type: RECEIVED_TRENDS, trends };
}

export function finish(finishId) {
  return { type: FINISHED, finishId };
}

export function addToCart(sku, product) {
  return { type: ADD_TO_CART, sku, product };
}

export function addToCartGA4(sku, product, index) {
  return { type: ADD_TO_CART_GA4, sku, product, index };
}

export function productListView(products, first, last) {
  return { type: PRODUCT_LIST_VIEW, products, first, last };
}

export function viewItemList(products, first, last) {
  return { type: VIEW_ITEM_LIST, products, first, last };
}

export function fetchBobby(requesting, apiMethod, dispatcher, slot, params) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { bobby = {}, channel } = getState();

    if (!channel.configs.show_recommendation) {
      return reject('Invalid channel for bobby return');
    }

    if (bobby.isRequesting.indexOf(requesting) > -1) {
      return reject('Already requesting cart details.');
    }

    dispatch(requestBobby(requesting));

    return apiMethod(slot, params)
      .then(res => {
        dispatch(dispatcher(res));
        return resolve();
      })
      .catch((err) => {
        dispatch(finish(requesting));
        return reject(err);
      });
  });
}

export const fetchCartDetails = (productIds) => fetchBobby(
  CART_DETAILS_REQUESTING,
  BobbyApi.getCartDetails,
  receivedCartDetails,
  BobbySlots.basket,
  productIds
);

export const fetchTrends = () => fetchBobby(
  TRENDS_REQUESTING,
  BobbyApi.getTrends,
  receivedTrends,
  BobbySlots.confirmOrder
);

export const fetchSimilar = (productId) => fetchBobby(
  SIMILAR_REQUESTING,
  BobbyApi.getSimilar,
  receivedSimilar,
  BobbySlots.basket,
  productId
);
