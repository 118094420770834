import { productName, generateProductDataGA4, productsData } from 'utils/data-layer/helpers';
import cleanHtml from 'utils/cleanHtml';

import { editCartEcommerceDataGA4 } from 'utils/data-layer/addToCartEcommerceData';
import { getCustomer } from 'utils/session';

export function addToCart(action, stateBefore, stateAfter) {
  const layer = {};

  layer.event = 'addToCart';
  layer.eventCategory = 'sacola:produto';
  layer.eventAction = 'add-to-cart';
  layer.eventLabel = 'adicionar-ao-carrinho';
  layer.screenName = 'carrinho';
  layer.currency = 'BRL';
  layer.value = action.product.prices.cash || action.product.price;
  layer.ecommerce = {
    add: {
      products: [editCartEcommerceDataGA4(action.product, 1)],
    },
  };

  return layer;
}

export function productListView(action, stateBefore, stateAfter) {

  const { ecommerceProducts } = productsData(
    action.products.slice(action.first, action.last),
    stateAfter
  );
  const layer = {};
  const basketPage = 'carrinho';
  const screenName = action.products[0]?.screenName || basketPage;

  layer.event = 'productListView';
  layer.eventCategory = `${screenName === basketPage ? 'sacola' : screenName}:vitrine`;
  layer.eventAction = 'impression';
  layer.eventLabel = 'product-list';
  layer.eventNonInteraction = '1';
  layer.screenName = screenName;

  layer.ecommerce = {};
  layer.ecommerce.products = [];

  layer.ecommerce.products = ecommerceProducts;

  return layer;
}

export function productClick(action, stateBefore, stateAfter) {
  const { ecommerceProducts } = productsData([action.product], stateAfter);
  const basketPage = 'carrinho';
  const screenName = action.product?.screenName || basketPage;
  const layer = {};
  const showcaseTitle = action.product?.showcase?.title || action.product?.showcaseTitle || '';

  layer.event = 'productListClick';
  layer.eventCategory = `${screenName === basketPage ? 'sacola' : screenName}:vitrine`;
  layer.eventAction = 'product-click';
  layer.eventLabel = 'product-list';
  layer.eventNonInteraction = '0';
  layer.screenName = screenName;
  layer.hitTimestamp = `${Date.now()}`;

  layer.ecommerce = {};
  layer.ecommerce.click = {};
  layer.ecommerce.click.actionField = {
    list: `sacola:${productName(cleanHtml(showcaseTitle))}`,
  };
  layer.ecommerce.click.products = [];

  const ecommerceData = ecommerceProducts;

  ecommerceData.position = action.index;
  layer.ecommerce.click.products = ecommerceData;

  // redirect after information sent
  layer.eventCallback = () => (document.location = action.product.link);
  // force redirect if browser blocked analytics
  layer.eventTimeout = 500;
  // force redirect if browser blocked gtm
  setTimeout(layer.eventCallback, 1000);

  return layer;
}

export function selectItem(action, stateBefore, stateAfter) {
  const product = action.product;
  const basketPage = 'carrinho';
  const screenName = product?.screenName || basketPage;
  const layer = {};
  const showcaseTitle =
    product?.showcase?.title || product?.showcaseTitle || '';

  layer.event = 'select_item';
  layer.screenName = screenName;
  layer.eventCategory = `${screenName === basketPage ? 'carrinho' : screenName}:magalu:vitrine`;
  layer.eventAction = 'click';
  layer.eventLabel = `${product?.showcaseType}:${productName(cleanHtml(showcaseTitle)) || null}:slot-null:${action?.index || null}:${product?.sku || null}`;

  const customer = getCustomer();
  layer.idUsuario = customer?.id || null;
  layer.mundos = 'magalu';
  layer.item_list_name = `carrinho:${productName(cleanHtml(showcaseTitle)) || null}`;
  layer.items = [generateProductDataGA4({product, stateAfter, index: action.index})];

  return layer;
}

