import { logoutCustomer } from 'utils/session';

export const UNAUTHORIZED = 'unauthorized/UNAUTHORIZED';
export const RESET = 'unauthorized/RESET';

export const initialState = {
  err: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UNAUTHORIZED:
      const { err } = action;
      return Object.assign({}, state, { err });
    case RESET:
      return Object.assign({}, state, { err: null });
    default:
      return state;
  }
}

export function unauthorized(err) {
  return { type: UNAUTHORIZED, err };
}

export function reset() {
  return { type: RESET };
}

export function unauthorizedLogout(err) {
  logoutCustomer();

  return unauthorized(err);
}
