import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, initialize, change } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

import * as customerMessages from 'constants/customer-messages';

import * as cleaners from 'utils/value-cleaners';
import * as validators from 'utils/validators';
import { getChannelConfig } from 'utils/session';

import * as addressActions from 'reducers/address';
import * as signupActions from 'reducers/signup';

import { validateCaptcha } from 'components/Form/Captcha/Captcha';
import FormGroup from 'components/Form/FormGroup/FormGroup';
import { SignupFormPF } from 'components/Signup/SignupFormPF/SignupFormPF';
import AddressForm from 'components/Address/AddressForm/AddressForm';
import SignupNotificationsPF from 'components/Signup/SignupNotificationsPF/SignupNotificationsPF';
import ButtonLink from 'components/Form/ButtonLink/ButtonLink';

import {
  errorsAddress,
  errorsFormPF,
  onSubmitFail,
  commonFieldsPF,
} from 'containers/SignupFormContainer';
import {
  handleZipcodeSubmit,
  handleZipcodeChange,
} from 'containers/AddressFormContainer';

const editFormPFName = 'editPF';

const EDIT_CUSTOMER_DATA_LINK = getChannelConfig('enable_customer_edit_data_link') ?? false;

const errorsPasswordEdit = ({ oldPassword, password }) => {
  const errors = {};

  if (!oldPassword) {
    errors.oldPassword = customerMessages.oldPasswordRequired;
  }
  if (!password) {
    errors.password = customerMessages.invalidPassword;
  }
  if (password && !validators.password(password)) {
    errors.password = customerMessages.wrongPasswordLength;
  }

  return errors;
};

const getAddress = async (fetchAddress, customerInfo) => {
  try {
    return await fetchAddress(customerInfo.zipcode);
  } catch {
    return customerInfo;
  }
};

const mapStateToProps = ({ customer, address, form, channel }) => ({
  customerInfo: customer.customerInfo,
  channelName: channel.name,
  address,
  form,
});

const mapDispatchToActions = {
  initializeForm: initialize,
  fetchAddress: addressActions.fetchAddress,
  resetAddress: addressActions.resetAddress,
  postSignup: signupActions.postSignup,
};

@reduxForm({
  form: editFormPFName,
  fields: ['email', 'oldPassword', ...commonFieldsPF],
  onSubmitFail,
})
@connect(mapStateToProps, {
  ...mapDispatchToActions,
  changeField: change,
})
export class EditRegistrationFormPFContainer extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    channelName: PropTypes.string.isRequired,
    customerInfo: PropTypes.object.isRequired,
    initializeForm: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    resetAddress: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    postSignup: PropTypes.func.isRequired,
    changeField: PropTypes.func.isRequired,
    asyncValidating: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
      .isRequired,
    redirectOnSuccess: PropTypes.func.isRequired,
    redirectToChangeCustomerAreaPage: PropTypes.func.isRequired
  };

  state = {
    showOldPassword: false,
    showPassword: false,
    notifications: {
      selectAll: false,
      pushPromotional: false,
      smsPromotional: false,
      emailPromotional: false,
      mailingPromotional: false,
      whatsappPromotional: false,
      whatsappTransactional: false,
      whatsappTelemarketingPromotional: false,
      telemarketingPromotional: false,
    },
  };

  constructor(props) {
    super(props);
    this.onNotificationsHandlerPF = this.onNotificationsHandlerPF.bind(this);
    this.state = {
      showOldPassword: false,
      showPassword: false,
      notifications: props.customerInfo.notifications,
    };
  }

  componentWillMount() {
    const { customerInfo, fetchAddress, initializeForm, fields } = this.props;
    let initialValues = {};

    if (customerInfo.birthDate) {
      initialValues = {
        birthDate: customerInfo.birthDate.format('DDMMYYYY'),
      };
    }

    if (customerInfo.zipcode) {
      return getAddress(fetchAddress, customerInfo).then((res) => {
        const { address, neighbourhood, city, state } = res;

        initializeForm(
          editFormPFName,
          Object.assign({}, customerInfo, initialValues, {
            address,
            neighbourhood,
            city,
            state,
          }),
          Object.keys(fields)
        );
      });
    }

    return initializeForm(
      editFormPFName,
      Object.assign({}, customerInfo, initialValues),
      Object.keys(fields)
    );
  }

  onNotificationsHandlerPF(type, value) {
    if (type === 'selectAll') {
      this.setState({
        ...this.state,
        notifications: {
          selectAll: value,
          pushPromotional: value,
          smsPromotional: value,
          emailPromotional: value,
          mailingPromotional: value,
          whatsappPromotional: value,
          whatsappTransactional: value,
          whatsappTelemarketingPromotional: value,
          telemarketingPromotional: value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        notifications: {
          ...this.state.notifications,
          [type]: value,
        },
      });
    }
  }

  render() {
    const {
      customerInfo,
      channelName,
      address: { address, neighbourhood, city, state },
      fields,
      fetchAddress,
      resetAddress,
      handleSubmit,
      postSignup,
      changeField,
      asyncValidating,
      redirectOnSuccess,
      redirectToChangeCustomerAreaPage,
    } = this.props;
    const showCustomerEditLink = !!(EDIT_CUSTOMER_DATA_LINK && redirectToChangeCustomerAreaPage);
    const finalFields = Object.assign({}, fields, {
      email: Object.assign({}, fields.email, { disabled: true }),
      cpf: Object.assign({}, fields.cpf, { disabled: true }),
      address: Object.assign({}, fields.address, { disabled: !!address }),
      neighbourhood: Object.assign({}, fields.neighbourhood, {
        disabled: !!neighbourhood,
      }),
      city: Object.assign({}, fields.city, { disabled: !!city }),
      state: Object.assign({}, fields.state, { disabled: !!state }),
      telephone: Object.assign({}, fields.telephone, { disabled: true }),
    });

    return (
      <form
        method="post"
        className="EditRegistrationFormPFContainer"
        onSubmit={handleSubmit((values) => {
          return new Promise((resolve, reject) => {
            const cleanValues = Object.assign({}, values, {
              telephone: cleaners.onlyNumbers(values.telephone),
              cpf: cleaners.cpfOrCnpj(values.cpf),
              zipcode: cleaners.zipcode(values.zipcode),
              channelName,
              notifications: this.state.notifications,
            });
            let errors = Object.assign(
              {},
              errorsFormPF(cleanValues),
              errorsAddress(cleanValues)
            );

            if (values.oldPassword || values.password) {
              errors = Object.assign(
                {},
                errors,
                errorsPasswordEdit(cleanValues)
              );
            }

            if (!isEmpty(errors)) {
              return reject(errors);
            }

            return validateCaptcha('signup').then((captchaToken) => {
              return postSignup(
                'pf',
                Object.assign({}, cleanValues, {
                  customerUuid: customerInfo.customerUuid,
                }),
                captchaToken
              ).then(redirectOnSuccess);
            });
          });
        })}
      >
        <FormGroup
          containerClassName="FormGroup FormGroup--withIcon"
          labelText="E-mail"
          inputSize="long"
          field={finalFields.email}
        >
          {(showCustomerEditLink && (
            <ButtonLink
              handleClick={redirectToChangeCustomerAreaPage}
            />
          )) ||
            null}
        </FormGroup>
        <SignupFormPF
          {...finalFields}
          asyncValidating={asyncValidating}
          showOldPassword={this.state.showOldPassword}
          showPassword={this.state.showPassword}
          handleShowPasswordClick={(statePassword) => {
            this.setState({ [statePassword]: !this.state[statePassword] });
          }}
        />
        <AddressForm
          {...finalFields}
          handleZipcodeSubmit={handleZipcodeSubmit(
            fields.zipcode.value,
            editFormPFName,
            fetchAddress,
            changeField
          )}
          canChangeZipcode
          handleZipcodeChange={handleZipcodeChange(resetAddress, fields)}
          redirectToChangeCustomerAreaPage={redirectToChangeCustomerAreaPage}
          showCustomerEditLink={showCustomerEditLink}
        />
        <SignupNotificationsPF
          {...finalFields}
          notifications={this.state.notifications}
          onNotificationsHandlerPF={this.onNotificationsHandlerPF}
        />
        <button className="continueButton">Continuar</button>
      </form>
    );
  }
}
