import React from 'react';
import PropTypes from 'prop-types';

import IconDelete from 'icons/icon-delete.svg';
import Responsive from 'components/Responsive';


const BasketItemServiceQuantity = ({
  handleItemRemove,
  quantity,
  isMobile,
}) => {
  const dataGA = JSON.stringify({
    'category': 'Carrinho',
    'action': 'Serviço',
    'label': 'Remover',
  });

  return (
    <div className="BasketItemProduct-quantity">
      {isMobile &&
      <span
        className="BasketItemProduct-quantity-title"
      >
        Quantidade:
      </span>
      }
      <select
        className="BasketItemProduct-quantity-dropdown"
        value={quantity}
        disabled
      >
        <option value={quantity} key={quantity}>{quantity}</option>
      </select>
      <button
        className="BasketItemProduct-quantity-remove"
        onClick={handleItemRemove}
        data-ga={dataGA}
      >
        <IconDelete className="BasketItem-delete-icon" />
        <span className="BasketItem-delete-label">Excluir</span>
      </button>
    </div>
  );
};

BasketItemServiceQuantity.propTypes = {
  handleItemRemove: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
};

export {
  BasketItemServiceQuantity
};
export default Responsive(BasketItemServiceQuantity);
