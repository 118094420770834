import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MagazineModal from 'components/MagazineModal/MagazineModal';
import * as failActions from 'reducers/fail';

const { func, string } = PropTypes;

@connect(({ fail }) => ({ ...fail }), failActions)
export default class extends Component {
  static propTypes = {
    failMessage: string.isRequired,
    reset: func.isRequired
  };

  render() {
    const { failMessage, reset } = this.props;

    const message = (subject) => (
      <span dangerouslySetInnerHTML={{ __html: subject }}></span>
    );
    return (
      <MagazineModal
        isOpen={!! failMessage}
        onRequestClose={function onRequestClose() {
          reset();
        }}
      >
        <div>{message(failMessage)}</div>
      </MagazineModal>
    );
  }
}
