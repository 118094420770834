import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { checkoutUrl } from 'constants/api-urls';

const { string } = PropTypes;

export default class ConfirmOrderBoxPrintSlip extends Component {
  static propTypes = {
    orderId: string.isRequired,
    barCode: string
  };

  render() {
    const { orderId, barCode = '' } = this.props;
    const printUrl = `${checkoutUrl}/boleto-bancario/${orderId}/`;

    return (
      <div className="ConfirmOrderBox-printSlip">
        <p className="ConfirmOrderBox-printSlip-slipInstruction">Utilize este código de barras e pague o boleto pelo celular ;)</p>

        <div className="ConfirmOrderBox-leftContainer">
          {barCode && <span className="ConfirmOrderBox-barCode" dangerouslySetInnerHTML={{ __html: barCode }} /> || null}
        </div>
        <div className="ConfirmOrderBox-printSlip-rightContainer">
          <a href={printUrl} className="ConfirmOrderBox-printSlip-button" target="_blank" data-ga='{"category": "Pedido Concluído", "action": "Imprimir Boleto", "label": ""}'>Imprimir boleto</a>
        </div>
      </div>
    );
  }
}
