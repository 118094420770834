import React from 'react';
import Proptypes from 'prop-types';
import SuccessIcon from 'icons/icon-success-circle.svg';

import './SuccessNotificationModal.scss';

const SuccessNotificationModal = ({ onClick }) => {
  return (
    <div className="SuccessNotificationModal-wrapper">
      <SuccessIcon />
      <span>Sua conta está cadastrada</span>
      <span>Agora você poderá aproveitar todos os benefícios de ser um cliente do Magalu.</span>

      <button onClick={() => onClick()}>Entrar</button>
    </div>
  );
};

SuccessNotificationModal.Proptypes = {
  onClick: Proptypes.func.isRequired
};

export default SuccessNotificationModal;
