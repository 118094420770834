import kebabCase from 'lodash/kebabCase';
import { GTM_UNDEFINED } from './constants';

export function getCityUf(city, state) {
  if (!city || !state) {
    return GTM_UNDEFINED;
  }

  return `${kebabCase(city)}:${state.toLowerCase()}`;
}
