import React from 'react';
import PropTypes from 'prop-types';

import {
  bobbyCompactImgWidth,
  bobbyCompactImgHeight,
} from 'constants/bobby';

import IconCart from 'icons/icon-cart';

import { resizeProductPicture } from 'utils/pictures';
import { toBRL } from 'utils/currency';

import BobbyProductTitle from 'components/Bobby/BobbyProductTitle';

import './BobbyCompactProduct.scss';

const BobbyCompactProduct = ({
  stewieProductId,
  storeId,
  prices,
  priceDescription,
  title,
  reference,
  link,
  rawPictureUrl,
  handleAddClick,
  carouselName,
  product,
  handleLinkClick,
  variationId,
  index,
}) => {
  const productId = stewieProductId || variationId;

  return (
    <div
      className="BobbyCompactProduct" data-ga_bob={JSON.stringify({ productId, title, carouselName })}
    >
      <a
        href={link} className="BobbyCompactProduct-productRow"
        onClick={handleLinkClick}
      >
        <div className="BobbyCompactProduct-productRow-left">
          <div className="BobbyCompactProduct-image">
            <img src={
              resizeProductPicture(
                rawPictureUrl,
                bobbyCompactImgWidth,
                bobbyCompactImgHeight
              )
            }
            />
          </div>
        </div>

        <div className="BobbyCompactProduct-productRow-right">
          <div className="BobbyCompactProduct-title">
            <div className="BobbyCompactProduct-title-link">
              <BobbyProductTitle
                title={title}
                reference={reference}
              />
            </div>
          </div>
          <div className="BobbyCompactProduct-price">
            <div className="BobbyCompactProduct-price-link">
              {toBRL(prices.cash || prices.to || prices.from)} {prices.cash && priceDescription || ''}
            </div>
          </div>
        </div>
      </a>
      <div className="BobbyCompactProduct-buttonRow">
        <button
          className="BobbyCompactProduct-button"
          onClick={handleAddClick(storeId, productId, link, product, index)}
        >
          <IconCart
            className="BobbyCompactProduct-button-icon"
          /> Adicionar
        </button>
      </div>
    </div>
  );
};

BobbyCompactProduct.propTypes = {
  stewieProductId: PropTypes.string,
  storeId: PropTypes.string.isRequired,
  prices: PropTypes.object.isRequired,
  priceDescription: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  rawPictureUrl: PropTypes.string.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  carouselName: PropTypes.string.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
  variationId: PropTypes.string.isRequired,
};

export default BobbyCompactProduct;
