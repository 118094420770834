import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

import * as routeNames from 'constants/route-names';
import * as customerMessages from 'constants/customer-messages';

import * as validators from 'utils/validators';

import * as signupActions from 'reducers/signup-available';
import * as loginActions from 'reducers/login';

import SignupBox from 'components/Login/SignupBox/SignupBox';
import { validateCaptcha } from 'components/Form/Captcha/Captcha';


const { shape, object, func } = PropTypes;

const validate = ({ login }) => {
  const errors = {};

  if (login && !validators.email(login)) {
    errors.login = customerMessages.blankSignupEmail;
  }

  return errors;
};

const validateValues = ({ login }) => {
  const errors = {};

  if (!validators.email(login)) {
    errors.login = customerMessages.blankSignupEmail;
  }

  return errors;
};

const submitWithRouter = (
  router,
  fetchLoginAvailable,
  query,
) => {
  return values => {
    const errors = validateValues(values);

    if (!values.login || !isEmpty(errors)) {
      return Promise.reject(errors);
    }

    return validateCaptcha('pre_signup_email')
      .then((captchaToken) => {
        return new Promise((resolve, reject) => {
          const { login } = values;
          return fetchLoginAvailable(captchaToken, undefined, login)
            .then(() => {
              const signupRoute = router.isActive(routeNames.channelLogin) && routeNames.channelSignup || routeNames.signup;
              resolve();
              router.push({
                pathname: signupRoute,
                query
              });
            })
            .catch(({ status }) => {
              const errorMessage =
                status === 403 ?
                  customerMessages.blockEmail :
                  customerMessages.emailAlreadyRegistered;
              reject({ login: errorMessage });
            });
        });
      });
  };
};

const mapStateToProps = ({
  signupAvailable,
}) => ({
  ...signupAvailable,
});

const mapDispatchToProps = {
  ...loginActions,
  ...signupActions,
};

@reduxForm({
  form: 'signupBox',
  fields: ['login'],
  validate
})
@connect(mapStateToProps, mapDispatchToProps)
export default class SignupBoxContainer extends Component {
  static propTypes = {
    fields: shape({
      login: object.isRequired
    }).isRequired,
    handleSubmit: func.isRequired,
    fetchLoginAvailable: func.isRequired,
    location: object.isRequired,
  };

  static contextTypes = {
    router: object.isRequired
  };

  render() {
    const {
      fields: { login },
      handleSubmit,
      fetchLoginAvailable,
      location: { query },
    } = this.props;
    const { router } = this.context;

    return (
      <SignupBox
        loginField={login}
        handleSubmit={handleSubmit(submitWithRouter(
          router,
          fetchLoginAvailable,
          query,
        ))}
      />
    );
  }
}
