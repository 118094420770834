import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';

import PaymentFormButton from 'components/Payment/Forms/PaymentFormButton/PaymentFormButton';

export const ValeCompraForm = ({
  totals,
  credits,
  hasMarketplaceItems,
  useCredit,
  handleSubmit
}) => {
  const moneyClass = parseFloat(credits.totalRemainingAmount) > 0
    ? 'ValeTrocaCreditForm-money'
    : '';

  return (
    <form className="ValeTrocaCreditForm" onSubmit={handleSubmit} method="post" >
      <div className="ValeTrocaCreditForm-messages">
        {useCredit &&
          <div className="ValeCompraForm-remaining-credit">
            <p>
              Crédito para próximas compras: <span
                className={`ValeCompraForm-remaining-credit-next ${moneyClass}`}
              >
                {toBRL(totals.remainingCreditAmount) || 'R$ 0,00'}
              </span>
            </p>
          </div>
        }
        {hasMarketplaceItems && (
          <div className={`ValeCompraForm-info-third-party-items${!useCredit ? '-warning' : ''}`}>
            Válido apenas para produtos vendidos e entregues pelo Magazine Luiza
          </div>
        )}
        {(useCredit && parseFloat(totals.remainingAmount) > 0) &&
          <div>
            <div className="ValeCompraForm-info">
              <p>
                Agora escolha abaixo mais uma forma de pagamento para pagar o valor restante do pedido:
              </p>
            </div>
            <div className="ValeCompraForm-remaining-amount">
              <p>
                {toBRL(totals.remainingAmount)}
                {(totals.remainingAmount !== totals.totalCheckoutAmount) && (
                  <span> ou <br />{toBRL(totals.totalCheckoutAmount)} à vista</span>
                )}
              </p>
            </div>
          </div>
        }
      </div>
      {parseFloat(totals.remainingAmount) === 0 && <PaymentFormButton paymentType="Vale Compra" />}
    </form>
  );
};

ValeCompraForm.propTypes = {
  totals: PropTypes.shape({
    creditAmountInUse: PropTypes.string.isRequired,
    remainingCreditAmount: PropTypes.string,
    remainingAmount: PropTypes.string,
    totalAmount: PropTypes.string,
    checkoutAmount: PropTypes.string,
    totalCheckoutAmount: PropTypes.string,
  }),
  credits: PropTypes.shape({
    totalAmount: PropTypes.string,
    totalAvailableAmount: PropTypes.string,
    totalRemainingAmount: PropTypes.string
  }),
  hasMarketplaceItems: PropTypes.bool.isRequired,
  useCredit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default ValeCompraForm;
