import slugify from '../utils/slugify.js';

export const productUrl = (product, configs) => {
  const data = {
    '{base_url}': configs.origin_url,
    '{showcase_name}': configs.full_showcase_name,
    '{sku_product}': product.id,
    '{product_slug_clube}': slugify(product.productUrl.split('/')[1]),
    '{product_slug}': product.productUrl,
    '{category_id}': product.categoryId.toLowerCase(),
  };

  return configs.product_url_pattern
    .replace(/(\{\w+\})/g, (match, key) => data[key]);
};

export const similarProductUrl = (
  similarUrlPattern,
  originUrl,
  fullShowcaseName,
  categoryId,
  subcategoryUrl,
) => {
  const data = {
    '{base_url}': originUrl,
    '{showcase_name}': fullShowcaseName,
    '{category_id}': categoryId.toLowerCase(),
    '{subcategory_path}': subcategoryUrl,
  };
  return similarUrlPattern
    .replace(/(\{\w+\})/g, (match, key) => data[key]);
};
