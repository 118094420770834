export const withError = (className, error = false) => error
  ? ` ${className}--withError`
  : '';

export const withSize = (className, size = '') => size
  ? ` ${className}--${size}`
  : '';

export const inputClassName = (className, error = false) =>
  `${className}${withError(className, error)}`;

export const inputGroupClassName = (className, size = '') =>
  `${className}${withSize(className, size)}`;

export const floatingLabelClassName = (name = '', field = {}) => (field.dirty || field.value)
  ? `${name} active--input`
  : `${name}`;

export function errorClassName(
  message = '',
  className = '',
) {
  const maxInputSize = 70; // Max size without breaking desk layout

  return (message.length > maxInputSize)
    ? `${className} ${className}--break`
    : className;
}

export function classNameWithModifier(classname = '', modifier = '') {
  const classNameModifier = modifier ? ` ${classname}--${modifier}` : '';
  return `${classname}${classNameModifier}`;
}
