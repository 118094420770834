import { pickupInStore, defaultDelivery } from 'constants/delivery-choices';

const isSameDay = (deliveryType, time, checkOnDefault = false) =>
  deliveryType === (checkOnDefault ? defaultDelivery : pickupInStore) && parseInt(time, 10) === 0;

export const hasSameDayPickup = (packages = []) => {
  return packages.some(({ deliveryTypes }) =>
    deliveryTypes.some(({ type, time }) => isSameDay(type, time))
  );
};

export const hasSameDayDelivery = (packages = []) => {
  return packages.some((deliveryPackage) => {
    if (deliveryPackage?.deliveryType) {
      const { deliveryType, time } = deliveryPackage;

      return isSameDay(deliveryType, time, true);
    }

    return deliveryPackage.deliveryTypes.some(({ type, time }) => isSameDay(type, time, true));
  }
  );
};

export const storesHaveSameDayPickup = (stores = []) =>
  stores.some(storesObject =>
    Object.keys(storesObject).some(storeKey =>
      storesObject[storeKey].packages.some(({ deliveryType, time }) =>
        isSameDay(deliveryType, time)
      )
    )
  );
