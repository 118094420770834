import React from 'react';
import Modal from 'components/ModalAdjustable';
import SelectEmailOrSms from './SelectEmailOrSms/SelectEmailOrSms';
import OtpCode from './OtpCode/OtpCode';
import MobileModal from 'components/MobileModal/MobileModal';
import PropTypes from 'prop-types';
import Loader from 'components/SimpleLoader/SimpleLoader';

import { getFirstOtpId, getSecondOtpId } from 'utils/manageOtpIds';
import { detectMobile } from 'utils/responsive';

import './ValidateAccountOtp.scss';

const DEFAULT_OPTIONS = {
  email: {
    delivery_mode: 'email',
    description: 'e‑mail',
    extraDescription: 'Caso não o encontre, verifique a caixa de spam.',
  },
  sms: {
    delivery_mode: 'sms',
    description: 'número',
  },
};

class ValidateAccountOtp extends React.Component {
  constructor(props) {
    super(props);
    const firstOtpId = getFirstOtpId();
    const secondOtpId = getSecondOtpId();
    const hasAllOtpId = !!firstOtpId && !!secondOtpId;
    const hasSelectedEmailOrSms = hasAllOtpId
      ? DEFAULT_OPTIONS[props?.query?.opcao]
      : undefined;

    this.state = {
      hasSelectedEmailOrSms,
    };
    this.baseState = this.state;
  }

  handlePostOtpGenerateCode = (data, retry) => this.props.postOtpGenerate(data, retry);

  handleSelectChannel(contactOptions, retry) {
    if (contactOptions.delivery_mode === 'sms') {
      const formatNumberPhone = contactOptions.to.replace(/(^(\+)|\D)/g, '');
      contactOptions.to = formatNumberPhone;
    }

    this.setState(
      {
        hasSelectedEmailOrSms: DEFAULT_OPTIONS[contactOptions.delivery_mode],
      },
      this.handlePostOtpGenerateCode(contactOptions, retry)
    );
  }

  handleSubmitFormOnComplete = (providedCode) => {
    return this.props.onComplete(providedCode);
  };

  render() {
    const {
      enableEmail,
      enableSms,
      error,
      isOpen,
      loading,
      hasOtpId,
      contacts,
      expiresAt,
      onRenderOtpCode,
      onRenderSelectEmailOrSms,
      handleCloseOtpModal,
      isModal,
      otpCodeOnly,
      updateCustomTo,
    } = this.props;
    const { hasSelectedEmailOrSms } = this.state;
    const bothMethodsAvailable = enableEmail && enableSms;

    const content = () => {
      return (
        <div>
          {hasSelectedEmailOrSms && hasOtpId ? (
            <OtpCode
              error={error}
              contacts={contacts}
              expiresAt={expiresAt}
              channel={hasSelectedEmailOrSms}
              onRender={onRenderOtpCode}
              onCloseOtpModal={() => {
                this.setState({ hasSelectedEmailOrSms: undefined });
                handleCloseOtpModal();
              }}
              onComplete={this.handleSubmitFormOnComplete.bind(this)}
              onSelectDeliveryMode={this.handleSelectChannel.bind(this)}
              updateCustomTo={updateCustomTo}
              bothMethodsAvailable={bothMethodsAvailable}
              isModal={isModal}
              otpCodeOnly={otpCodeOnly}
            />
          ) : (
            <SelectEmailOrSms
              enableEmail={enableEmail}
              enableSms={enableSms}
              isModal={isModal}
              error={error}
              contacts={contacts}
              onRender={onRenderSelectEmailOrSms}
              onCloseOtpModal={handleCloseOtpModal}
              onSelectDeliveryMode={this.handleSelectChannel.bind(this)}
            />
          )}
        </div>
      );
    };

    if (loading) {
      return <Loader text="Validando informações" />;
    }

    if (isModal) {
      return (
        <div>
          {detectMobile() ? (
            isOpen && <MobileModal>{content()}</MobileModal>
          ) : (
            <Modal isOpen={isOpen}>{content()}</Modal>
          )}
        </div>
      );
    }

    return (
      <div className={`ValidateAccountOtp${isModal ? '-modal' : ''}`}>{content()}</div>
    );
  }
}

ValidateAccountOtp.PropTypes = {
  enableEmail: PropTypes.bool,
  enableSms: PropTypes.bool,
  contacts: PropTypes.shape({
    email: PropTypes.string,
    sms: PropTypes.string,
  }).isRequired,
  hasOtpId: PropTypes.bool,
  error: PropTypes.object,
  expiresAt: PropTypes.number.isRequired,
  onRenderOtpCode: PropTypes.func,
  onRenderSelectEmailOrSms: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  postOtpGenerate: PropTypes.func.isRequired,
  updateCustomTo: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  handleCloseOtpModal: PropTypes.func,
  isModal: PropTypes.bool,
  query: PropTypes.shape({
    opcao: PropTypes.oneOf(['email', 'sms']),
  }),
  otpCodeOnly: PropTypes.bool,
};

ValidateAccountOtp.defaultProps = {
  enableEmail: false,
  enableSms: false,
  isModal: true,
  query: {},
  otpCodeOnly: false,
  updateCustomTo: null,
  onRenderOtpCode: null,
  onRenderSelectEmailOrSms: null,
};

export default ValidateAccountOtp;
