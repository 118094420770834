import React, { Component } from 'react';

import MagazineModal from 'components/MagazineModal/MagazineModal';


export default class BasketAvailabilityWarning extends Component {
  state = {
    isOpen: false
  }

  handleClick = (event) => {
    event.preventDefault();
    this.setState({ isOpen: true });
  }

  handleCloseClick = () => {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <div className="BasketItem-availabilityWarning">
        <MagazineModal
          isOpen={false}
          onRequestClose={() => {}}
        >
          <div className="BasketItem-availabilityWarning-title">
            Produto disponível apenas para algumas regiões do Brasil
          </div>

          <p className="BasketItem-availabilityWarning-description">Nossos produtos estão armazenados em diversos Centros de Distribuição e alguns itens não estão disponíveis para entregas em todas as regiões do Brasil. Para consultar a disponibilidade deste produto, informe o CEP de entrega.</p>
        </MagazineModal>
        Produto disponível apenas para algumas regiões. Digite o CEP e consulte.
        <button
          className="BasketItem-availabilityWarning-link"
          onClick={this.handleClick}
        >
        Saiba mais
        </button>
      </div>
    );
  }
}
