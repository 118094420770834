import React from 'react';
import PropTypes from 'prop-types';
import WarningIcon from 'icons/icon-warning-amber.svg';
import { otpErrorPopUpTitleSubTitle } from 'constants/otp-error-popup';

import './OtpErrorNotification.scss';

const OtpErrorNotification = ({ onResetAction, onResetError, isMobile, errorOtp }) => {
  const showButton = errorOtp === 'error500';
  return (
    <div>
      <div className="OtpOverlay"/>
      <div className="OtpErrorNotification-wrapper">
        <div className="OtpClose">
          <span className="ti ti-close" onClick={() => onResetError()} />
        </div>
        <div className="OtpErrorNotification-description">
          <span className="OtpErrorNotification-title"> 
            {
              (isMobile && <WarningIcon className="warning-icon-amber"/>)
            }
            {otpErrorPopUpTitleSubTitle[errorOtp].title}
            
          </span>
          <p>{otpErrorPopUpTitleSubTitle[errorOtp].subtext}</p>
        </div>
        { showButton ?
          (
            <button
              className="OtpErrorNotification-btn"
              onClick={() => onResetAction()}
            >
                Tentar novamente
            </button>
          ) :
          (null)
        }
        
      </div>
    </div>
  );
};

OtpErrorNotification.PropTypes = {
  handleResetAction: PropTypes.func.isRequired,
  onResetError: PropTypes.func.isRequired,
};

export default OtpErrorNotification;
