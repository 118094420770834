import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import {
  root as rootRoute,
  delivery as deliveryRoute,
} from 'constants/route-names';

import ButtonEdit from 'components/Form/ButtonEdit/ButtonEdit';
import OrderReviewTotals from 'components/OrderReview/OrderReviewTotals/OrderReviewTotals';
import OrderReviewPackage from 'components/OrderReview/OrderReviewPackage/OrderReviewPackage';
import OrderReviewAddress from 'components/OrderReview/OrderReviewAddress/OrderReviewAddress';

import './OrderReview.scss';

const OrderReview = ({
  totals,
  discounts,
  deliveries,
  stores,
  packagesCount,
  hasGiftCard,
  promocodeComponent,
  firstPartySeller,
  productCount,
  isMobile,
}) => {
  let packageIndex = 0;
  let packageStartIndex = 1;

  return (
    <div className="OrderReview">
      <div className="OrderReview-titleContainer">
        <span className="OrderReview-title mobile-only">
          Revisão
        </span>
        <span className="OrderReview-title desktop-only">
          Revisão do pedido
        </span>
        <ButtonEdit
          label="Editar Sacola"
          to={{
            pathname: `${rootRoute}`,
          }}
        />
      </div>
      <div className="OrderReview-container">
        <div className="OrderReview-leftContainer">
          <p className="OrderReview-title mobile-only">Entrega</p>
          {deliveries.packages.map((package_) => {
            packageIndex += 1;

            return (
              <OrderReviewPackage
                key={`package-${packageIndex}`}
                {...package_}
                index={packageIndex}
                packagesCount={packagesCount}
                zipcode={deliveries.address.zipcode}
                firstPartySeller={firstPartySeller}
              />
            );
          })}

          {deliveries.packages.length && <OrderReviewAddress
            isMobile={isMobile}
            startIndex={packageStartIndex}
            endIndex={packageStartIndex + deliveries.packages.length - 1}
            {...deliveries.address}
          /> || null}

          {stores.map((store, index) => {
            const currentStore = Object.values(store)[0];
            packageStartIndex += packageIndex;

            return (
              <div
                key={`store-${index}`}
              >
                {currentStore.packages.map((storePackage) => {
                  packageIndex += 1;
                  return (
                    <OrderReviewPackage
                      key={`package-${packageIndex}`}
                      {...storePackage}
                      index={packageIndex}
                      packagesCount={packagesCount}
                      firstPartySeller={firstPartySeller}
                    />
                  );
                })}
                <OrderReviewAddress
                  isStore
                  isMobile={isMobile}
                  startIndex={packageStartIndex}
                  endIndex={packageStartIndex + currentStore.packages.length - 1}
                  {...currentStore.address}
                />
              </div>
            );
          })}
          {hasGiftCard && <div className="OrderReview-giftCard">
            Embalar para presente <Link to={`/${deliveryRoute}`}>
              Editar cartão
            </Link>
          </div>}
        </div>
        <div className="OrderReview-rightContainer">
          <OrderReviewTotals {...totals} discounts={discounts} productCount={productCount} />
          {promocodeComponent}
        </div>
      </div>
    </div>
  );
};

OrderReview.propTypes = {
  totals: PropTypes.object.isRequired,
  discounts: PropTypes.object.isRequired,
  deliveries: PropTypes.object.isRequired,
  stores: PropTypes.array.isRequired,
  packagesCount: PropTypes.number.isRequired,
  hasGiftCard: PropTypes.bool.isRequired,
  promocodeComponent: PropTypes.element,
  firstPartySeller: PropTypes.string.isRequired,
  productCount: PropTypes.number,
  isMobile: PropTypes.bool,
};

export default OrderReview;
