import cookies from 'browser-cookies';
import { generateDimensionsData } from './generateDimensionsData';
import { productCategoryName } from './productCategoryName';
import { productName } from './productName';
import { productIdPrimaryByCookie } from './productIdPrimaryByCookie';
import { serviceLayer } from './serviceLayer';
import { GTM_UNDEFINED } from './constants';

export const productPrice = (product) => {
  const price = product.unit_price || product.prices.cash;
  const quantity = product.quantity || 1;
  const discount = product.payment_discount_amount || 0;
  const total = (price - (discount / quantity)).toFixed(2);

  return Number(total);
};

export const generateProductData = (product, stateAfter) => {
  const {
    name,
    title,
    brand,
    sku,
    id,
    quantity,
  } = product;

  const products = [];
  const productId = productIdPrimaryByCookie(cookies.get('ga_cookie_add'), product.id || product.sku);

  const productData = {
    id: productId,
    name: productName(name || title),
    brand: brand || GTM_UNDEFINED,
    category: productCategoryName(product),
    variant: sku || id,
    price: productPrice(product),
    quantity: quantity || 1,
    ...generateDimensionsData(stateAfter, product, productId)
  };

  products.push(productData);

  if (product?.services) {
    product.services.map(service => {
      products.push(
        serviceLayer(service, quantity)
      );
    });
  }

  return products;
};

export function productsData(products = [], stateAfter) {
  const productsArray = products.map(
    (product) => generateProductData(product, stateAfter)
  );
  const total = productsArray.reduce((accList, currList) =>
    accList + currList.reduce(
      (accItem, currItem) => accItem + Number(currItem.price || 0) * currItem.quantity , 0
    ), 0
  );

  return { ecommerceProducts: [].concat(...productsArray), total: total.toFixed(2)};
}
