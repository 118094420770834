export const SET_PROFILING_IS_COMPLETE = 'tmx/SET_PROFILING_IS_COMPLETE';

export const initialState = {
  profilingIsComplete: false,
};

export default function reducer(state = initialState, action = {}) {
  if (action.type === SET_PROFILING_IS_COMPLETE) {
    return Object.assign({}, state, {
      profilingIsComplete: action.profilingIsComplete,
    });
  }

  return state;
}

export function setProfilingIsComplete(profilingIsComplete) {
  return { type: SET_PROFILING_IS_COMPLETE, profilingIsComplete };
}

export function toggleProfilingIsComplete(profilingIsComplete) {
  return (dispatch) => {
    dispatch(setProfilingIsComplete(profilingIsComplete));
  };
}
