import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroup from 'components/Form/FormGroup/FormGroup';
import InputError from 'components/Form/InputError/InputError';
import { correiosUrl } from 'constants/external-urls';
import { inputClassName } from 'utils/classnames';
import { moveCursorToLastChar } from 'utils/moveCursorToLastChar';
import ButtonLink from 'components/Form/ButtonLink/ButtonLink';

import './AddressForm.scss';
import { preventFocusEventCall } from 'utils/focus-event';

const { oneOfType, array, func, string, object, bool } = PropTypes;

export default class AddressForm extends Component {
  static propTypes = {
    children: oneOfType([object, array]),
    zipcode: object.isRequired,
    address: object.isRequired,
    telephone: object,
    homePhone: object,
    comercialPhone: object,
    mobilePhone: object,
    receiver: object,
    number: object.isRequired,
    complement: object.isRequired,
    neighbourhood: object.isRequired,
    city: object.isRequired,
    state: object.isRequired,
    reference: object.isRequired,
    handleZipcodeSubmit: func.isRequired,
    handleZipcodeChange: func.isRequired,
    title: string,
    closedLabelText: string,
    cancelComponent: object,
    canChangeZipcode: bool,
    multipleTelephones: bool,
    redirectToChangeCustomerAreaPage: func,
    showCustomerEditLink: bool,
  };

  handleKeyDown = (event) => {
    const { handleZipcodeSubmit } = this.props;
    const enterKeyCode = 13;

    if (event.keyCode === enterKeyCode) {
      event.preventDefault();
      handleZipcodeSubmit();
    }
  };

  handleZipcodeOnBlur = (e) => {
    const { zipcode, handleZipcodeSubmit } = this.props;
    preventFocusEventCall(e, 'findCep', handleZipcodeSubmit, zipcode.onBlur);
  };

  render() {
    const {
      children,
      zipcode,
      address,
      telephone,
      homePhone,
      comercialPhone,
      mobilePhone,
      receiver,
      number,
      complement,
      neighbourhood,
      city,
      state,
      reference,
      handleZipcodeSubmit,
      title = 'Endereço de cobrança',
      closedLabelText = 'CEP',
      cancelComponent,
      handleZipcodeChange,
      canChangeZipcode = false,
      multipleTelephones = false,
      redirectToChangeCustomerAreaPage,
      showCustomerEditLink
    } = this.props;
    const shouldShowSuccess = (field) =>
      !!field.value && !field.error && !field.active;
    const okDataGa =
      '{"category": "Endereco", "action": "CEP", "label": "OK CEP"}';
    const noKnowDataGa =
      '{"category": "Endereco", "action": "CEP", "label": "Não sabe CEP"}';
    const changeDataGA =
      '{"category": "Novo Endereco", "action": "Alterar CEP", "label": ""}';

    return (
      <div className="AddressForm">
        {title && <div className="form-title">{title}</div>}
        {(!state.value && (
          <div className="FormGroup AddressForm-form-group">
            <label
              className="AddressForm-form-label--large"
              htmlFor={zipcode.name}
            >
              {closedLabelText}
            </label>

            <div className="inputWithinGroup">
              <input
                className={inputClassName(
                  'inputWithin',
                  zipcode.touched && !!zipcode.error
                )}
                onBlur={this.handleZipcodeOnBlur}
                onKeyUp={moveCursorToLastChar}
                onKeyDown={this.handleKeyDown}
                placeholder="00000-000"
                type="tel"
                name={zipcode.name}
                value={zipcode.value}
                onChange={zipcode.onChange}
                onFocus={zipcode.onFocus}
              />
              <button
                className="buttonWithin"
                onClick={handleZipcodeSubmit}
                data-ga={okDataGa}
              >
                OK
              </button>

              <div className="FormGroup-inputGroup">
                <a
                  className="AddressForm-form-zipcode-link--href"
                  id="findCep"
                  href={correiosUrl}
                  target="_blank"
                  data-ga={noKnowDataGa}
                >
                  Não sei o CEP
                </a>
                <InputError
                  className="FormGroup-errorMessage"
                  field={zipcode}
                />
              </div>

              <div className="AddressForm-cancel">{cancelComponent}</div>
            </div>
          </div>
        ))
          ||
          (
            <div className="AddressForm-form-group">
              <FormGroup
                labelText="CEP"
                containerClassName="FormGroup FormGroup--withIcon"
                inputSize="extraSmall"
                field={Object.assign({}, zipcode, { disabled: true })}
                onKeyUp={moveCursorToLastChar}
              >
                {(canChangeZipcode && (
                  <ButtonLink
                    handleClick={handleZipcodeChange}
                    data-ga={changeDataGA}
                  />
                )) ||
                  null}
              </FormGroup>
              {receiver && (
                <FormGroup labelText="Nome do Destinatário" field={receiver} />
              )}
              <FormGroup
                labelText="Endereço"
                field={address}
                forceShowSuccess={shouldShowSuccess(address)}
              />
              <FormGroup
                inputSize="small"
                labelText="Número"
                field={number}
                type="tel"
              />
              <FormGroup
                inputSize="long"
                labelText="Complemento"
                field={complement}
              />
              <FormGroup
                inputSize="long"
                labelText="Bairro"
                field={neighbourhood}
                forceShowSuccess={shouldShowSuccess(neighbourhood)}
              />
              <FormGroup
                inputSize="long"
                labelText="Cidade"
                field={city}
                forceShowSuccess={shouldShowSuccess(city)}
              />
              <FormGroup
                inputSize="extraSmall"
                labelText="Estado"
                field={state}
                forceShowSuccess={shouldShowSuccess(state)}
              />
              <FormGroup
                labelText="Ponto de referência"
                placeholder={'Ex.: Próximo ao Museu do Ipiranga'}
                field={reference}
              />
              {(multipleTelephones && (
                <div>
                  <FormGroup
                    inputSize="small"
                    labelText="Telefone residencial"
                    placeholder="(DD) XXXXX-XXXX"
                    field={homePhone}
                  />
                  <FormGroup
                    inputSize="small"
                    labelText="Telefone comercial"
                    placeholder="(DD) XXXXX-XXXX"
                    field={comercialPhone}
                  />
                  <FormGroup
                    inputSize="small"
                    labelText="Telefone celular"
                    placeholder="(DD) X-XXXX-XXXX"
                    field={mobilePhone}
                  />
                </div>
              ))
                ||
                (
                  <FormGroup
                    containerClassName={'FormGroup' + (redirectToChangeCustomerAreaPage ? ' FormGroup--withIcon' : '')}
                    inputSize="small"
                    labelText="Telefone celular"
                    placeholder="(DD) X-XXXX-XXXX"
                    field={telephone}
                    type="tel"
                  >
                    {(showCustomerEditLink && (
                      <ButtonLink
                        handleClick={redirectToChangeCustomerAreaPage}
                      />
                    )) ||
                      null}
                  </FormGroup>
                )}
              {children}
            </div>
          )}
      </div>
    );
  }
}
