import React from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';

const Modal = (props) => {
  if (!props.isOpen) return null;

  return (
    <div
      className={`ModalDefault-wrapper ${
        props.mobileIsDrawer ? 'ModalDefault-drawer' : 'ModalDefault-no-drawer'
      }`}>
      <div className="ModalDefault-overlay cursor-pointer" onClick={props.toggle} />
      <div className="ModalDefault-content">
        {!props.withoutHeader && (
          <div className="ModalDefault-header">
            <span className="ti ti-arrow-back cursor-pointer" onClick={props.toggle} />

            <span className="font-lg-bold">{props.title}</span>

            <span className="ti ti-close icon-close cursor-pointer" onClick={props.toggle} />
          </div>
        )}
        <div className="ModalDefault-body">{props?.content({ ...props })}</div>
        {!props.withoutFooter && (
          <div className="ModalDefault-footer">{props?.footer({ ...props })}</div>
        )}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  withoutFooter: false,
  withoutHeader: false,
  mobileIsDrawer: true,
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  header: PropTypes.func,
  content: PropTypes.func,
  footer: PropTypes.func,
  toggle: PropTypes.func,
  withoutFooter: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  mobileIsDrawer: PropTypes.bool,
};

export default Modal;
