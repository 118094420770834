import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CheckoutHeader from 'components/Header/CheckoutHeader/CheckoutHeader';
import ExternalLoader from 'components/ExternalLoader';
import Navigation from 'components/CheckoutNavigation/Navigation';

const { array, object, string } = PropTypes;

const mapStateToProps = ({ channel }) => ({
  channelName: channel.name,
  isRequesting: channel.isRequesting,
  originUrl: channel.configs.origin_url,
  checkoutHeaderUrl: channel.configs.checkout_header_url,
});

@connect(mapStateToProps, undefined)
export default class CheckoutChannelHeaderContainer extends Component {
  static propTypes = {
    channelName: string.isRequired,
    isRequesting: array.isRequired,
    location: object.isRequired,
    originUrl: string,
    checkoutHeaderUrl: string,
  };

  render() {
    return (
      <div className={`CheckoutHeader-${this.props.channelName}`}>
        { !!this.props.checkoutHeaderUrl
          ? <div>
            <ExternalLoader
              externalURL={this.props.checkoutHeaderUrl}
            />
            <Navigation />
          </div>
          : <CheckoutHeader
            originUrl={this.props.originUrl}
            location={this.props.location}
          />
        }
      </div>
    );
  }
}
