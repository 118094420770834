import { connect } from 'react-redux';

import { promocode as isValidPromocode } from '../../utils/validators';

import {
  showPromocodeForm,
  fetchPromocode,
  cancelPromocodeForm,
  changePromocodeInput,
  removePromocode,
  clearErrorInput,
} from '../reducers/promocode';

import Promocode from 'components/Payment/Promocode/Promocode';


const mapStateToProps = (state) => ({
  isFormVisible: state.promocode.isFormVisible,
  isLoading: state.promocode.isLoading,
  hasError: state.promocode.hasError,
  errorMessage: state.promocode.errorMessage,
  promocodeInput: state.promocode.promocodeInput,
  promocode: state.promocode.promocode,
  hasPromocodeDiscount: state.promocode.hasPromocodeDiscount,
});

const mapDispatchToProps = {
  showPromocodeForm,
  cancelPromocodeForm,
  fetchPromocode,
  changePromocodeInput,
  removePromocode,
  clearErrorInput,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  onShowClick: () => {
    dispatchProps.showPromocodeForm();
  },
  onCancelClick: (e) => {
    e.preventDefault();
    dispatchProps.cancelPromocodeForm();
  },
  handleChangePromocodeInput: (e) => {
    if (e.target.value.length === 0 || isValidPromocode(e.target.value)) {
      dispatchProps.clearErrorInput();
      dispatchProps.changePromocodeInput(e.target.value);
    }
  },
  onSubmitPromocode: (e) => {
    e.preventDefault();
    ownProps.initChangePromocode();
    if (stateProps.promocodeInput.length > 0) {
      dispatchProps.fetchPromocode(stateProps.promocodeInput)
        .then(() => ownProps.onChangePromocode())
        .catch(() => {});
    }
  },
  onRemoveClick: (e) => {
    e.preventDefault();
    ownProps.initChangePromocode();
    dispatchProps.removePromocode()
      .then(() => ownProps.onChangePromocode());
  },
});

const PromocodeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Promocode);

export default PromocodeContainer;
