export const ERROR = 'metrics/ERROR';

function pageName(hash = window.location.hash) {
  const page = hash
    .replace(/\?(.*)/, '') // remove query
    .replace('#/', '') // remove initial "#/"
    .replace(/\/$/, ''); // remove trailing "/"

  switch (page) {
    case 'cliente/login':
      return 'identificacao';
    case '':
      return 'sacola';
    default:
      return page;
  }
}

/**
 * Builds the error action to send to dataLayer
 *
 * Accepts two error formats:
 *
 * { res: { body: { error_message: "server error" } } }
 * and
 * { res: { body: { error_message: { error_message: "server error" } } } }
 *
 * @uses pageName
 * @export
 * @param {string} [message=''] The user action
 * @param {string} [error='']   The server error
 * @returns reducer action for error metrics
 */
export function metricsError(message = '', error = '') {
  let errorMessage = !!error.error_message
    ? error.error_message
    : error;

  const label = !!errorMessage
    ? `${message}: ${typeof errorMessage === 'object' ? JSON.stringify(errorMessage) : errorMessage}`
    : message;

  return {
    type: ERROR,
    category: 'error',
    action: pageName(),
    label,
  };
}
