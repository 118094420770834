import * as CustomerApi from 'api/customer-api';
import * as CustomerMessages from 'constants/customer-messages';
import { failOtp, resetFailOtp } from './fail-otp';

export const REQUESTING = 'otp/REQUEST';
export const RECEIVED = 'otp/RECEIVED';
export const RECEIVED_CUSTOM_TO = 'otp/RECEIVED_CUSTOM_TO';
export const FINISHED = 'otp/FINISHED';
export const RESET = 'otp/RESET';
export const TRACK_ERROR = 'otp/TRACK_ERROR';
export const TRACK_CLICK = 'otp/TRACK_CLICK';
export const TRACK_PAGE_VIEW = 'otp/TRACK_PAGE_VIEW';
export const TRACK_SUBMIT = 'otp/TRACK_SUBMIT';
export const TRACK_SUCCESS = 'otp/TRACK_SUCCESS';
export const ERROR = 'otp/ERROR';
export const TRACK_CHANGE_DATA_SCREEN_OTP = 'otp/TRACK_CHANGE_DATA_SCREEN_OTP';
export const TRACK_RESEND_CLICK = 'otp/TRACK_RESEND_CLICK';

export const initialState = {
  customTo: { email: null, phone: null },
  expiresAt: null,
  smsExpiresAt: null,
  emailExpiresAt: null,
  otpId: null,
  deliveryMode: null,
  isRequesting: false,
  isError: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUESTING:
      return Object.assign({}, state, {
        isRequesting: true,
      });
    case RECEIVED:
      const {
        deliveryMode,
        otpInfo: { expires_at: expiresAt, id },
      } = action;
      const expiresAtKey = deliveryMode === 'email' ? 'emailExpiresAt' : 'smsExpiresAt';
      return Object.assign({}, state, {
        deliveryMode,
        otpId: id,
        expiresAt,
        [expiresAtKey]: expiresAt
      });
    case RECEIVED_CUSTOM_TO:
      const { customTo } = action;
      return Object.assign({}, state, {
        customTo,
      });
    case FINISHED:
      return Object.assign({}, state, {
        isRequesting: false,
      });
    case RESET:
      return Object.assign({}, state, {
        otpId: null,
        isError: false,
      });
    case ERROR:
      const { isError } = action;
      return Object.assign({}, state, {
        isError,
      });
    default:
      return state;
  }
}

export function requestOtpCode() {
  return { type: REQUESTING };
}

export function receivedOtpCode(otpInfo, deliveryMode) {
  return { type: RECEIVED, otpInfo, deliveryMode };
}

export function receivedCustomTo(customTo) {
  return { type: RECEIVED_CUSTOM_TO, customTo };
}

export function finishedOtpCode() {
  return { type: FINISHED };
}

export function resetOtpCode(mode) {
  return { type: RESET, mode };
}

export function setError(isError) {
  return { type: ERROR, isError };
}

export function currentOtpCode(otpInfo, deliveryMode) {
  return (dispatch) => dispatch(receivedOtpCode(otpInfo, deliveryMode));
}

export function updateCustomTo(to) {
  return (dispatch) => dispatch(receivedCustomTo(to));
}

export function resetOtp(mode) {
  return (dispatch) => dispatch(resetOtpCode(mode));
}

export function trackClick(description, page) {
  return { type: TRACK_CLICK, description, page };
}

export function trackPageView(pageInfo) {
  return { type: TRACK_PAGE_VIEW, pageInfo };
}

export function trackError(errorMessage, page, mode) {
  return { type: TRACK_ERROR, errorMessage, page, mode };
}

export function trackSubmit() {
  return { type: TRACK_SUBMIT };
}

export function trackSuccess(description, page, mode) {
  return { type: TRACK_SUCCESS, description, page, mode };
}

export function trackChangeDataScreenOTP(mode) {
  return {
    type: TRACK_CHANGE_DATA_SCREEN_OTP,
    mode,
  };
}

export function trackResendClick(description, page) {
  return { type: TRACK_RESEND_CLICK, description, page };
}

export function otpTrackPageView(pageInfo) {
  return (dispatch) => dispatch(trackPageView(pageInfo));
}

export function otpTrackClick(description, page) {
  return (dispatch) => dispatch(trackClick(description, page));
}

export function otpTrackResendClick(description, page) {
  return (dispatch) => dispatch(trackResendClick(description, page));
}

export function postOtpCode(formData, page) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const mode = formData.delivery_mode;
      dispatch(setError(false));
      dispatch(requestOtpCode());
      CustomerApi.postGenerateOtpCode(formData)
        .then(
          (otpInfo) => {
            dispatch(receivedOtpCode(otpInfo, formData.delivery_mode));
            dispatch(trackSuccess('sucesso', page, mode));
            dispatch(resetFailOtp());
            return resolve(otpInfo);
          },
          (err) => {
            dispatch(setError(true));
            if (err.status > 499) {
              dispatch(failOtp(CustomerMessages.otpGeneratorFail500, err.status));
              dispatch(
                trackError(`erro:${CustomerMessages.otpGeneratorFail500}`, page, mode)
              );
            } else {
              dispatch(failOtp(CustomerMessages.otpGeneratorFail400, err.status));
              dispatch(
                trackError(`erro:${CustomerMessages.otpGeneratorFail400}`, page, mode)
              );
              return reject(err);
            }
          }
        )
        .finally(() => dispatch(finishedOtpCode()));
    });
  };
}
