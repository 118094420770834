import React, { Component } from 'react';

const withModal = (WrappedComponent) => {
  return class extends Component {
    state = {
      isOpen: false,
    };

    handleToggle = () => {
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen,
      }));
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          isOpen={this.state.isOpen}
          handleToggle={this.handleToggle}
        />
      );
    }
  };
};

export default withModal;
