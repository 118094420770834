import React from 'react';
import PropTypes from 'prop-types';

import Success from 'icons/icon-success';

const DropdownSuccess = ({ field }) => {
  return (!field.error && !!field.value)
    ? <Success className="Success" />
    : null;
};

DropdownSuccess.propTypes = {
  field: PropTypes.object.isRequired,
};

export default DropdownSuccess;
