

export function tax({ stateAfter, screenName }) {
  const { basket: { totals: { importValue, icms } } } = stateAfter.basket;
  const isTaxCalculated = importValue && icms;

  if(!isTaxCalculated) return;
  
  return {
    event: 'tax',
    screenName,
    imposto: `${importValue || 'nao-calculado'}|icms:${icms || 'nao-calculado'}`,
  };
}
