export function socialLoginResponse({ status, description } = {}) {
  const layer = {};

  layer.event = 'clickEvent';
  layer.eventCategory = 'identificacao:social-login';
  layer.eventAction = status;
  layer.eventLabel = description;
  layer.eventNonInteraction = '0';
  layer.hitTimestamp = new Date().getTime().toString();

  return layer;
}
