import React from 'react';

import { paymentContractUrl } from 'constants/external-urls';

const PaymentContract = () => (
  <div>
    <a
      href={paymentContractUrl}
      target="_blank"
      className="PaymentContract-link"
      data-ga='{"category": "Pagamento", "action": "Contrato de Compra e Venda", "label": ""}'
    >
      Clique aqui para ler o termo de compra e venda
    </a>
    <p>
      O valor total da compra, mesmo dividido em parcelas de pequeno valor, não poderá exceder o limite do seu cartão de crédito. Para maiores informações, consulte a administradora do seu cartão.
    </p>
  </div>
);

export default PaymentContract;
