import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DialogBottomSheet from 'components/DialogBottomSheet';
import { LOGIN_ERROR_CODES } from 'constants/login';

export const CONTENT_MAP = {
  [LOGIN_ERROR_CODES.INACTIVE_LOGIN]: {
    title: 'Sua conta está inativa',
    description: <p className="pb-md md:text-center">Por favor, entre em contato com nossa Central de Atendimento pelo <b>0800 310 0002</b>. De segunda à sexta, das 8h às 20h e aos sábados, das 9h às 15h.</p>,
  },
  [LOGIN_ERROR_CODES.INVALID_REGISTER]: {
    title: 'Problema no acesso da conta',
    description: <p className="pb-md md:text-center">Por favor, entre em contato com nossa Central de Atendimento pelo <b>0800 310 0002</b>. De segunda à sexta, das 8h às 20h e aos sábados, das 9h às 15h.</p>,
  },
  [LOGIN_ERROR_CODES.DEFAULT]: {
    title: 'Ocorreu um problema ao realizar seu login',
    description: <p className="pb-md md:text-center">Por favor, aguarde alguns minutos e tente novamente.</p>,
  }
};

class OtherErrorsModal extends Component {
  static propTypes = {
    type:PropTypes.string,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  }

  render () {
    const { type, open, onClose} = this.props;
    const content = CONTENT_MAP[type];

    const title = content && (
      <div className="flex items-center mr-auto pr-2xlg">
        <i className="ti ti-error-outline text-danger-default mr-sm"/>
        <span>{content?.title}</span>
      </div>
    );

    return (
      <DialogBottomSheet open={open && !!content} onClose={onClose} 
        title={title}>
        {content?.description}
      </DialogBottomSheet>
    );

  }
}

export default OtherErrorsModal;