import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CheckboxGroup from 'components/Form/CheckboxGroup/CheckboxGroup';

import Responsive from 'components/Responsive';

import IconWhatsApp from 'icons/notifications/icon-whatsapp.svg';
import IconEmail from 'icons/notifications/icon-email.svg';
import IconBell from 'icons/notifications/icon-bell.svg';
import IconSms from 'icons/notifications/icon-sms.svg';
import IconStore from 'icons/notifications/icon-store.svg';
import IconMailing from 'icons/notifications/icon-mailing.svg';

import './SignupNotificationsPF.scss';

export class SignupNotificationsPF extends Component {
  static propTypes = {
    selectAll: PropTypes.object.isRequired,
    pushPromotional: PropTypes.object.isRequired,
    smsPromotional: PropTypes.object.isRequired,
    emailPromotional: PropTypes.object.isRequired,
    mailingPromotional: PropTypes.object.isRequired,
    whatsappPromotional: PropTypes.object.isRequired,
    whatsappTransactional: PropTypes.object.isRequired,
    whatsappTelemarketingPromotional: PropTypes.object.isRequired,
    telemarketingPromotional: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="SignupNotificationsPF">
        <div className="SignupNotificationsPF-title">
          <b>Notificações</b> (opcional)
          <br />
        </div>
        <span className="SignupNotificationsPF-description">
          Escolha quais os canais e tipo de mensagens do Grupo Magazine Luiza você quer receber.
          O contato com <b>você é muito importante pra gente fazer a diferença no seu dia a dia.</b>
        </span>
        <CheckboxGroup
          labelText="Selecionar todas as opções"
          field={{
            name: 'selectAll',
            value: this.props.notifications.selectAll,
            onChange: (event) => this.props.onNotificationsHandlerPF('selectAll', event.target.checked)
          }}
        />
        <div className="SignupNotificationsPF-checkboxGroup">
          <IconWhatsApp className="SignupNotificationsPF-icon"/>
          <span className="SignupNotificationsPF-iconDescription">Lu do Magalu no WhatsApp</span>
        </div>
        <CheckboxGroup
          labelText="Gostaria de <b>receber mensagens com cupons e novidades</b>"
          field={{
            name: 'whatsappPromotional',
            value: this.props.notifications.whatsappPromotional,
            onChange: (event) => this.props.onNotificationsHandlerPF('whatsappPromotional', event.target.checked)
          }}
        />
        <CheckboxGroup
          labelText="Quero <b>acompanhar o status do meu pedido</b>"
          field={{
            name: 'whatsappTransactional',
            value: this.props.notifications.whatsappTransactional,
            onChange: (event) => this.props.onNotificationsHandlerPF('whatsappTransactional', event.target.checked)
          }}
        />
        <div className="SignupNotificationsPF-checkboxGroup">
          <IconEmail className="SignupNotificationsPF-icon"/>
          <span className="SignupNotificationsPF-iconDescription">E-mail</span>
        </div>
        <CheckboxGroup
          labelText="Aceito receber e-mails com <b>dicas da Lu e ofertas exclusivas</b>"
          field={{
            name: 'emailPromotional',
            value: this.props.notifications.emailPromotional,
            onChange: (event) => this.props.onNotificationsHandlerPF('emailPromotional', event.target.checked)
          }}
        />
        <div className="SignupNotificationsPF-checkboxGroup">
          <IconBell className="SignupNotificationsPF-icon"/>
          <span className="SignupNotificationsPF-iconDescription">Notificações do aplicativo</span>
        </div>
        <CheckboxGroup
          labelText="Quero receber avisos de <b>liquidações e ofertas imperdíveis no SuperApp<b/>"
          field={{
            name: 'pushPromotional',
            value: this.props.notifications.pushPromotional,
            onChange: (event) => this.props.onNotificationsHandlerPF('pushPromotional', event.target.checked)
          }}
        />
        <div className="SignupNotificationsPF-checkboxGroup">
          <IconSms className="SignupNotificationsPF-icon"/>
          <span className="SignupNotificationsPF-iconDescription">SMS</span>
        </div>
        <CheckboxGroup
          labelText="Receber informações de <b>promoções e lançamentos direto no meu celular</b>"
          field={{
            name: 'smsPromotional',
            value: this.props.notifications.smsPromotional,
            onChange: (event) => this.props.onNotificationsHandlerPF('smsPromotional', event.target.checked)
          }}
        />
        <div className="SignupNotificationsPF-checkboxGroup">
          <IconStore className="SignupNotificationsPF-icon"/>
          <span className="SignupNotificationsPF-iconDescription">Vendedores</span>
        </div>
        <CheckboxGroup
          labelText="Receber <b>dicas e ajuda do time de vendas no WhatsApp</b>"
          field={{
            name: 'whatsappTelemarketingPromotional',
            value: this.props.notifications.whatsappTelemarketingPromotional,
            onChange: (event) => this.props.onNotificationsHandlerPF('whatsappTelemarketingPromotional', event.target.checked)
          }}
        />
        <CheckboxGroup
          labelText="Receber <b>ligações do time de vendas</b>"
          field={{
            name: 'telemarketingPromotional',
            value: this.props.notifications.telemarketingPromotional,
            onChange: (event) => this.props.onNotificationsHandlerPF('telemarketingPromotional', event.target.checked)
          }}
        />
        <div className="SignupNotificationsPF-checkboxGroup">
          <IconMailing className="SignupNotificationsPF-icon"/>
          <span className="SignupNotificationsPF-iconDescription">Correspondências de promoções</span>
        </div>
        <CheckboxGroup
          labelText="Topo receber <b>cartas, brindes e convites</b>"
          field={{
            name: 'mailingPromotional',
            value: this.props.notifications.mailingPromotional,
            onChange: (event) => this.props.onNotificationsHandlerPF('mailingPromotional', event.target.checked)
          }}
        />
      </div>
    );
  }
}

export default Responsive(SignupNotificationsPF);
