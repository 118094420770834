import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_CARDS, defaultCvvLength } from 'utils/card';
import FormGroup from 'components/Form/FormGroup/FormGroup';
import InputMessageCvv from './InputMessageCvv';

const InputCvvForm = ({
  CVC,
  flag
}) => {
  return (
    <FormGroup
      labelText="CVV"
      inputSize="extraSmall"
      field={CVC}
      maxLength={flag.value && DEFAULT_CARDS[flag.value].cvcLength || defaultCvvLength}
      type="tel"
      autocomplete="off"
      containerClassName="FormGroup-cvv"
      className="FormGroup-input FormGroup-cvv-input"
    >
      <InputMessageCvv
        flag={flag.value}
      />
    </FormGroup>
  );
};

InputCvvForm.propTypes = {
  CVC: PropTypes.object.isRequired,
  flag: PropTypes.object.isRequired
};

export default InputCvvForm;
