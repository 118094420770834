import { getCustomer } from 'utils/session';
import getBasketTaxes from './helpers/getBasketTaxes';

export const addPaymentInfoEventGA4 = (action,stateAfter) => {
  const customer = getCustomer();
  const {order} = action;
  const price = stateAfter?.basket?.basket?.totals;
  const { to } = stateAfter?.basket?.basket?.totals;
  const coupon = stateAfter.promocode.promocodeInput || null;
  const nominal = price ? (parseFloat(price?.to) - parseFloat(price?.cash)).toFixed(2) : 'null';
  const percentage = ((nominal) / (price?.to) * 100).toFixed(2);
  const { dimension9 } = getBasketTaxes(stateAfter?.basket?.basket?.totals);

  return {
    event: 'add_payment_info',
    currency: 'BRL', 
    value: nominal ?`${ (parseFloat(to) - parseFloat(nominal)).toFixed(2)}` : to,
    coupon,
    idUsuario: customer?.id,
    mundos: 'magalu',
    basketId: stateAfter?.basket?.basket?.id || null,
    dimension9,
    dimension77: nominal ? `nominal:${nominal}|percentual:${percentage}` : null,
    payment_type: order?.payment?.type ? `${order?.payment?.type}:${order?.payment?.type}` : null,
  };
};