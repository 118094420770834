import { checkoutUrl } from 'constants/api-urls';
import request from 'utils/request';

export const putPromocode = (value) => {
  const promocodeURL = `${checkoutUrl}/cart/promocode/`;
  const promocode = { 'promocode': value };

  return request('PUT', promocodeURL)
    .send(promocode)
    .then(
      res => res.body,
      err => err.response.body
    );
};

export const deletePromocode = () => {
  const promocodeURL = `${checkoutUrl}/cart/promocode/`;

  return request('DELETE', promocodeURL)
    .then(res => res.body);
};
