import slugify from './slugify';

export function slugifyForPictureUrl(pictureUrl, title, reference) {
  return `${slugify(title)}-${slugify(reference)}-${pictureUrl}`;
}

export function buildPictureUrl(baseUrl, pictureUrl, title, reference, width, height) {
  // We have this function because the service does not slugify the url for now
  const slug = slugifyForPictureUrl(pictureUrl, title, reference);
  return `//${baseUrl}/${width}x${height}/${slug}`;
}

export function resizeProductPicture(imageUrl, width, height) {
  return imageUrl.replace('{w}', width).replace('{h}', height);
}
