import React from 'react';
import PropTypes from 'prop-types';

import Success from 'icons/icon-success';

const InputSuccess = ({
  field,
  asyncValidating = false,
  forceShow = false,
}) => {
  return (
    asyncValidating !== field.name
    && field.touched
    && !field.active
    && !field.error
    && field.value
    || forceShow
  ) && <Success className="Success" />;
};

InputSuccess.propTypes = {
  field: PropTypes.object.isRequired,
  asyncValidating: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  forceShow: PropTypes.bool,
};

export default InputSuccess;
