import React, { Component } from 'react';
import NavigationOption from '../NavigationOption';
import { getChannelConfig, getCurrentStep } from 'utils/session';
import {
  basket as routeBasket,
  login as routeLogin,
  signup as routeSignup,
  editRegistration as routeEditRegistration,
  delivery as routeDelivery,
  address as routeAddress,
  payment as routePayment,
} from 'constants/route-names';
import {
  INITIAL_STATUS,
  BASKET_STATUS,
  DELIVERY_STATUS,
  LOGIN_STATUS,
  PAYMENT_STATUS,
} from 'constants/checkout-shortcuts-status';

import './styles.scss';

export const allowedRoutes = () => {
  const validRoutes = [
    routeBasket,
    routeLogin,
    routeSignup,
    routeEditRegistration,
    routeDelivery,
    routeAddress,
    routePayment,
  ];
  const path = window.location.hash.replace('#/','').split('?')[0];
  const currentPath = path || routeBasket;

  return validRoutes.some(route => route.includes(currentPath));
};

export default class Navigation extends Component {
  state = {
    status: INITIAL_STATUS,
  };

  setCurrentStatus = () => {
    const currentPath = getCurrentStep();

    if (currentPath.includes(routeBasket)) {
      return BASKET_STATUS;
    }

    if (currentPath.includes(routePayment)) {
      return PAYMENT_STATUS;
    }

    if (currentPath.includes(routeDelivery) || currentPath.includes(routeAddress)) {
      return DELIVERY_STATUS;
    }

    if (
      currentPath.includes(routeLogin) ||
      currentPath.includes(routeSignup) ||
      currentPath.includes(routeEditRegistration)
    ) {
      return LOGIN_STATUS;
    }

    return INITIAL_STATUS;
  };

  componentDidMount() {
    this.setState({ status: this.setCurrentStatus() });
  }

  componentDidUpdate(_, prevState) {
    if (prevState === this.state) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ status: this.setCurrentStatus() });
    }
  }

  render() {
    const { status } = this.state;

    if(allowedRoutes() && getChannelConfig('enable_checkout_shortcuts')){
      return (
        <div className="Navigation-container">
          <NavigationOption routeName={routeBasket} status={status} />
          <NavigationOption routeName={routeLogin} status={status} />
          <NavigationOption routeName={routeDelivery} status={status} />
          <NavigationOption routeName={routePayment} status={status} />
        </div>
      );
    } else {
      return null;
    }
  }
}
