import { stewieUrlSource, stwuCookieName } from 'constants/stewie-constants';
import { loadJsAsync } from './load';
import { isLogged } from './session';
import cookies from 'browser-cookies';

export default () => {
  const stewieStwuCookie = cookies.get(stwuCookieName);

  if(!isLogged() && !stewieStwuCookie) {
    const stewieApiChannel = window.MAGALU_CHANNEL?.channel_configs?.stewie_api_channel;
    
    loadJsAsync(stewieUrlSource, null, 'stewie');
    setTimeout(() => {
      if(stewieApiChannel && window.stewie?.api) {
        window.stewie.api.channel(stewieApiChannel);
        window.stewie.api.ping();
        window.stewie.api.dispatch();
      }
    }, 5000);
  }
};

