import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FacebookLogo from 'icons/icon-facebook-logo';
import { FACEBOOK } from 'constants/social-login';
import { loadJsAsync } from 'utils/load';

export default class FacebookLoginButton extends Component {
  // This component was based on https://github.com/keppelen/react-facebook-login/blob/f699c38750aa8d360210653918ce21c5b7e83fee/src/facebook.js
  static propTypes = {
    callback: PropTypes.func.isRequired,
    appId: PropTypes.string.isRequired,
    scope: PropTypes.string,
    onClick: PropTypes.func
  };

  componentDidMount() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: this.props.appId,
        version: 'v7.0'
      });
    };

    loadJsAsync('https://connect.facebook.net/en_US/sdk.js', null, 'facebook-jssdk');
  }

  responseApi = (authResponse) => {
    window.FB.api('/me', 'GET', {'fields':'id,name,email'}, (me) => {
      this.props.callback(
        Object.assign({}, me, { accessToken: authResponse.accessToken })
      );
    });
  };

  checkLoginState = (response) => {
    if (response.authResponse) {
      this.responseApi(response.authResponse);
    } else {
      this.props.callback(null);
    }
  };

  handleClick = () => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(FACEBOOK);
    }

    try {
      window.FB.login(
        (response) => {
          this.checkLoginState(response);
        },
        { scope: this.props.scope },
      );
    } catch (error) {
      this.props.callback(null);
    }
  };

  render() {
    return (
      <button
        className="SocialLogin-Facebook"
        onClick={this.handleClick}
        aria-label="Fazer login com Facebook"
      >
        <span>
          <FacebookLogo className="SocialLoginBox-button--FB" />
          <span
            className="SocialLoginBox-button--ButtonNameFB"
          > Facebook </span>
        </span>
      </button>
    );
  }
}
