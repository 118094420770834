import { Component } from 'react';
import PropTypes from 'prop-types';

import {
  isLogged,
  logoutCustomer,
} from 'utils/session';
import { redirectToNextUrl } from 'utils/redirect';

import { root } from 'constants/route-names';

const { object } = PropTypes;

export default class Logout extends Component {
  static contextTypes = {
    router: object.isRequired
  };

  componentWillMount() {
    const {
      location: { query: { origin, next } }
    } = this.props;
    const { router } = this.context;
    const nextUrl = next || root;

    if (isLogged()) {
      logoutCustomer();
    }

    return redirectToNextUrl(router.replace, { next: nextUrl, origin });
  }

  render() {
    return false;
  }
}
