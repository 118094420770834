import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import EmptyBasket from 'components/Basket/EmptyBasket/EmptyBasket';
import BobbyCarousel from 'components/Bobby/BobbyCarousel';
import { BobbyCartDetailsContainer } from 'containers/BobbyContainer';
import { showcase as Showcase } from '../../constants/recommendation';

const BasketEmpty = ({
  modal,
  products,
  trendsPurchases,
  trendsViews,
  trendsCarts,
  purchaseMoreLink,
  recommendations,
}) => {
  const bobbyClassName = !products.length ? 'BobbyCarousel--larger' : '';

  const title = (subject) => <span dangerouslySetInnerHTML={{ __html: subject }}></span>;

  const bobbyCarousel = (trends) => {
    return !isEmpty(trends?.products) ? (
      <BobbyCarousel
        className={bobbyClassName}
        titleComponent={title(trends.subject)}
        products={trends.products}
        carouselName={trends.subject}
      />
    ) : null;
  };

  return (
    <div>
      {modal}
      <div className="BasketPage-title">Sacola</div>
      <EmptyBasket purchaseMoreLink={purchaseMoreLink} />
      {!recommendations.isEmpty() && (
        <BobbyCartDetailsContainer
          pageId={Showcase.sacola.page_id}
          placementId={Showcase.sacola.placement_sacolavaziabottom}
        />
      )}
      {bobbyCarousel(trendsViews)}
      {bobbyCarousel(trendsPurchases)}
      {bobbyCarousel(trendsCarts)}
    </div>
  );
};

BasketEmpty.propTypes = {
  modal: PropTypes.element.isRequired,
  products: PropTypes.array.isRequired,
  trendsPurchases: PropTypes.shape({
    products: PropTypes.array,
    subject: PropTypes.string,
  }),
  trendsViews: PropTypes.shape({
    products: PropTypes.array,
    subject: PropTypes.string,
  }),
  trendsCarts: PropTypes.shape({
    products: PropTypes.array,
    subject: PropTypes.string,
  }),
  purchaseMoreLink: PropTypes.string.isRequired,
};

export default BasketEmpty;
