import request from 'utils/request';

import { checkoutUrl } from 'constants/api-urls';

import {
  basket as basketModel,
  paymentReview as paymentReviewModel,
} from 'api/models/basket-models';
import { installment as installmentPaymentModel } from 'api/models/payment-models';


export const getBasket = (zipcode) => {
  const req = request('GET', `${checkoutUrl}/cart/`);
  if (zipcode) {
    req.query({ zip_code: zipcode });
  }

  return req.then(res => res.body)
    .then(json => {
      return {
        basketModel: basketModel(json),
        json,
      };
    });
};

export const updateItemQuantity = (storeId, itemId, quantity) => {
  return request('PUT', `${checkoutUrl}/cart/item/${itemId}/seller/${storeId}/`)
    .send({ quantity });
};

export const addProduct = (storeId, itemId) => {
  return request('PUT', `${checkoutUrl}/cart/item/${itemId}/seller/${storeId}/`)
    .send({ quantity: 1 });
};

export const deleteWarranty = (storeId, itemId) => {
  return request('DELETE', `${checkoutUrl}/cart/item/${itemId}/seller/${storeId}/warranty/`);
};

export const deleteServices = (services) => {
  return request('DELETE', `${checkoutUrl}/cart/service/delete/`)
    .send(services);
};

export const deleteItem = (items) => {
  const data = items.map(item => {
    return { item_sku: item.id, seller: item.storeId, price: item.price};
  });

  return request('DELETE', `${checkoutUrl}/cart/item/delete/`)
    .send(data);
};

export const getInstallments = paymentId => {
  return request('GET', `${checkoutUrl}/cart/installmentplan/${paymentId}/`)
    .then(res => res.body)
    .then(json => json.objects.map(installmentPaymentModel));
};

export const getInstallmentsSimulate = (paymentId, shippingAmount) => {
  return request('GET', `${checkoutUrl}/cart/installmentplan/${paymentId}/simulate/?shipping_amount=${shippingAmount}`)
    .then(res => res.body)
    .then(json => json.objects.map(installmentPaymentModel, basketModel));
};

export const getShippingPackages = () => {
  return request('GET', `${checkoutUrl}/cart/packages/`)
    .then(res => res.body)
    .then(json => paymentReviewModel(json));
};

export const putCredit = (useCredit) => {
  return request('PUT', `${checkoutUrl}/cart/credit/`)
    .send({ use_credit: useCredit })
    .then((res) => paymentReviewModel(res.body));
};
