const getBasketTaxes = (taxes = {}) => {
  const icmsValue = parseFloat(taxes.icms) || 0;
  const importValueAmount = parseFloat(taxes.importValue) || 0;
  
  const icms = icmsValue.toFixed(2);
  const importValue = importValueAmount.toFixed(2);

  const hasTax = icmsValue > 0 || importValueAmount > 0;
  const tax = hasTax ? (icmsValue + importValueAmount).toFixed(2) : null;
  const dimension9 = hasTax ? `imposto: ${importValue}|icms:${icms}` : null;

  return {
    tax,
    dimension9
  };
};

export default getBasketTaxes;