import React from 'react';
import { bool, func, shape } from 'prop-types';

import Responsive from 'components/Responsive';
import CheckboxGroup from 'components/Form/CheckboxGroup/CheckboxGroup';

import IconInfo from 'icons/icon-info.svg';

import { magalupayAbboutUrl, magalupayTermsUrl } from 'constants/external-urls';

import './SignupOptInMagaluPay.scss';

const SignupOptInMagaluPay = ({ opt, onOptClick, onOptKnowMoreClick, onOptTermsClick }) => {
  return (
    <div className="SignupOptInMagaluPay">
      <div className="SignupOptInMagaluPay-title">
        <b>Que tal aproveitar e abrir sua conta MagaluPay?</b> (opcional)
        <br />
      </div>
      <span className="SignupOptInMagaluPay-description">
      Uma conta digital para todos, sem taxas escondidas e totalmente
      grátis. Você conta com descontos especiais e cashback exclusivo em
      ofertas no SuperApp Magalu.
      </span>

      <a
        href={magalupayAbboutUrl}
        target="_blank"
        onClick={onOptKnowMoreClick}
        className="SignupOptInMagaluPay-info"
      >
        <IconInfo className="SignupOptInMagaluPay-icon" />
        Saiba mais sobre o MagaluPay
      </a>

      <CheckboxGroup
        labelText="Quero abrir minha conta MagaluPay"
        field={{
          name: 'magalupay',
          value: opt.magalupay,
          onChange: ({ target: { name, checked } }) => onOptClick(name, checked)
        }}
      />
      <div className="SignupOptInMagaluPay-terms">
        <small>
            Abertura de conta sujeita a aprovação. Ao tocar em{' '}
          <b>Abrir minha conta</b> você concorda com os{' '}
          <a
            href={magalupayTermsUrl}
            target="_blank"
            onClick={onOptTermsClick}
            className="SignupOptInMagaluPay-terms-link"
          >
          termos de uso
          </a>{' '}
        MagaluPay.
        </small>
      </div>

    </div>
  );
};

SignupOptInMagaluPay.propTypes = {
  opt: shape({
    magalupay: bool,
  }).isRequired,
  onOptClick: func.isRequired,
  onOptKnowMoreClick: func.isRequired,
  onOptTermsClick: func.isRequired,
};

export default Responsive(SignupOptInMagaluPay);
