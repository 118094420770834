import { GTM_FALSE, GTM_TRUE, GTM_UNDEFINED } from './helpers';

export function addToCartLayer(product) {
  const layer = {};

  layer.eventNonInteraction = '0';

  layer.hitTimestamp = `${Date.now()}`;
  layer.produtoDisponivel = (product.isSoldOut || product.notAvailableForDelivery) ? GTM_FALSE : GTM_TRUE;
  layer.tipoDeCliente = GTM_UNDEFINED;
  layer.sellerEntrega = GTM_UNDEFINED;
  layer.valorFrete = GTM_UNDEFINED;
  layer.sku = product.id;

  return layer;
}
