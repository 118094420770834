import React from 'react';

function Airplane() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.33341 13.6666L5.66675 13.6666L9.00008 7.99992L12.6667 7.99992C13.2201 7.99992 13.6667 7.55325 13.6667 6.99992C13.6667 6.44659 13.2201 5.99992 12.6667 5.99992L9.00008 5.99992L5.66675 0.333252L4.33341 0.333252L6.00008 5.99992L2.33341 5.99992L1.33341 4.33325L0.333414 4.33325L1.00008 6.99992L0.333414 9.66658L1.33341 9.66659L2.33341 7.99992L6.00008 7.99992L4.33341 13.6666Z"
        fill="#0086FF"
      />
    </svg>
  );
}

export default Airplane;
