import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroup from 'components/Form/FormGroup/FormGroup';
import { customerAreaChangeData } from 'constants/customer-area-changedata';
import { SECOND_OTP_KEY, resetOtpId } from 'utils/manageOtpIds';
import './ChangeDataBox.scss';

export default class ChangeDataBox extends Component {
  static propTypes = {
    dataMode: PropTypes.string,
    dataModeConfirm: PropTypes.string,
    customerData: PropTypes.string,
    dataJumpLine: PropTypes.bool,
    handleSubmit: PropTypes.func,
    field: PropTypes.object,
    handleChangeField: PropTypes.func || PropTypes.bool,
    handleChangeFieldConfirm: PropTypes.func || PropTypes.bool,
  };

  componentDidMount() {
    resetOtpId([SECOND_OTP_KEY]);
  }

  render() {
    const { dataMode, dataModeConfirm, dataJumpLine=false, customerData, handleSubmit, field, fieldConfirm, handleChangeField=false, handleChangeFieldConfirm = false } = this.props;
    const showConfirm = !!fieldConfirm && !!customerAreaChangeData[dataModeConfirm];

    return (
      <div className="ChangeDataBox">
        <div>
          <div className="ChangeDataBox-title">{customerAreaChangeData[dataMode].title}</div>
          <div className="ChangeDataBox-subtext">
            <div className="ChangeDataBox-subtext" name={dataMode}>
              {customerAreaChangeData[dataMode].subtext} {dataJumpLine ? <br /> : ''}
              <div className="ChangeDataBox-data">{customerData}</div>
            </div>
            <div className="ChangeDataBox-subtext-instructions">
              {customerAreaChangeData[dataMode].instructions}
            </div>
          </div>
          <form
            className="ChangeDataBox-form"
            onSubmit={handleSubmit}
            method="post"
          >
            <FormGroup
              labelText={customerAreaChangeData[dataMode].labelText}
              field={field}
              handleChange={handleChangeField}
              type={customerAreaChangeData[dataMode].type}
              inputSize={customerAreaChangeData[dataMode].inputSize}
              placeholder={customerAreaChangeData[dataMode].placeholder}
              hideSuccess
              newDesignInput
            />
            {showConfirm && ( <FormGroup
              labelText={customerAreaChangeData[dataModeConfirm].labelText}
              field={fieldConfirm}
              handleChange={handleChangeFieldConfirm}
              type={customerAreaChangeData[dataModeConfirm].type}
              inputSize={customerAreaChangeData[dataModeConfirm].inputSize}
              placeholder={customerAreaChangeData[dataModeConfirm].placeholder}
              hideSuccess
              newDesignInput
            />)}
            
            <button className="ChangeDataBox-form-continue">
              <span className="ChangeDataBox-span-change">
                {customerAreaChangeData[dataMode].buttonSpan}
              </span>
            </button>
          </form>
        </div>
      </div>
    );
  }
}
