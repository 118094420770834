import React from 'react';
import PropTypes from 'prop-types';

import * as cardFlags from 'constants/card-flags';

import CardFlag from 'components/Payment/CardFlag/CardFlag';

import './cardFlagsBox.scss';

export const CardFlagsBox = ({
  clickedFlag = '',
  handleFlagClick,
  disabledFlags = []
}) => (
  <div className="FormGroup">
    <label className="FormGroup-label">Selecione a bandeira</label>
    <ul className="CardFlagsBox">
      {Object.keys(cardFlags)
        .filter(flag => flag !== 'unknown')
        .filter(flag => disabledFlags.indexOf(flag) === -1)
        .map(flagName => (
          <li
            key={flagName}
            className={
              `CardFlagContainer${clickedFlag &&
              (clickedFlag === flagName
                ? ''
                : '--notChosen')
              || ''
              }`}
            data-flag={cardFlags[flagName]}
            onClick={handleFlagClick}
          >
            <CardFlag
              flag={cardFlags[flagName]}
            />
          </li>
        ))}
    </ul>
  </div>
);

CardFlagsBox.propTypes = {
  clickedFlag: PropTypes.string,
  handleFlagClick: PropTypes.func.isRequired,
  disabledFlags: PropTypes.array,
};

export default CardFlagsBox;
