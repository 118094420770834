import { productsDataGA4 } from './helpers';

const DEFAULT_VIEW_CART = 'view_cart';

export const DEFAULT_PAGE_TYPE = {
  basket: {
    step: 1,
    category: 'sacola',
    screenName: 'carrinho',
    eventLabel: 'view_cart',
  },
  login: {
    step: 2,
    category: 'login',
    screenName: 'login',
    eventLabel: 'step_login',
  },
  delivery: {
    step: 3,
    category: 'entrega',
    screenName: 'endereços',
    eventLabel: 'add_shipping_info',
  },
  review: {
    step: 4,
    category: 'revisao-pedido',
    screenName: 'revisão de pedidos',
    eventLabel: 'review',
  }
};

export const DEFAULT_PAGE_TYPE_CATEGORY =
  Object.keys(DEFAULT_PAGE_TYPE)
    .map(categoryName => (
      { key: categoryName, value: DEFAULT_PAGE_TYPE[categoryName].category }
    ))
    .reduce((obj, item) => Object.assign(obj, { [item.key]: item.key }), {});

export function viewCart({ pageType, stateAfter }) {
  
  const { products, totals: basketTotal, id: cartId, customerId, packages } = stateAfter.basket.basket;
  const { screenName } = DEFAULT_PAGE_TYPE[pageType];

  return {
    event: DEFAULT_VIEW_CART,
    screenName,
    idUsuario: customerId ?? null,
    mundos: 'magalu',
    currency: 'BRL',
    value: parseFloat(basketTotal.itemsCatalogAmount),
    items: productsDataGA4({products, stateAfter, eventName:'view_cart'}),
  };
}
