import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Dialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    footerContent: PropTypes.node,
  };

  handleClose = (e) => {
    const { onClose } = this.props;
    e?.preventDefault?.();
    onClose?.();
  };

  handleEsc = (event) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if (open) {
      document.addEventListener('keydown', this.handleEsc);
    }

    if (prevProps.open &&!open) {
      return document.removeEventListener('keydown', this.handleEsc);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEsc);
  }

  render() {
    const { title, children, open, footerContent, onClose } = this.props;
    const showHeader = !!title || !!onClose;

    return (
      <div className={`bottom-sheet ${open ? 'visible' : ''}`}>
        <div
          className={`bottom-sheet-overlay ${!!onClose ? 'cursor-pointer' : ''}`}
          onClick={this.handleClose}
        />
        <div className="bottom-sheet-container md:max-w-[360px] left-1/2 top-1/2 !-translate-x-1/2 !-translate-y-1/2 rounded-2xlg !h-fit">
          {showHeader && (
            <header className="bottom-sheet-header !justify-center font-md-bold">
              {title}
              {!!onClose && (
                <button
                  onClick={this.handleClose}
                  className="bottom-sheet-close !absolute right-md"
                />
              )}
            </header>
          )}
          <section className="bottom-sheet-content h-fit py-0">{children}</section>
          {footerContent && (
            <footer className="bottom-sheet-footer border-t-none">{footerContent}</footer>
          )}
        </div>
      </div>
    );
  }
}

Dialog.defaultProps = {
  footerContent: null,
};

export default Dialog;
