import React from 'react';
import PropTypes from 'prop-types';

import { getChannelConfig } from 'utils/session';

import {
  zipcode as zipcodeMasker,
  fullProductDescription as fullProductDescriptionMasker,
} from 'utils/maskers';

import './OrderReviewItem.scss';

const OrderReviewItem = ({
  title,
  reference = '',
  quantity,
  gifts,
  warranty,
  variations,
  zipcodeRestriction,
  zipcode,
  productCount,
  services,
}) => {
  const ENABLE_WARNING_ADDRESS_AND_ATTRIBUTES_ON_REVIEW = getChannelConfig('enable_warning_address_and_attributes_on_review');
  const plural = `${productCount > 1 && 's' || ''}`;
  return (
    <div className="OrderReviewItem">
      {zipcodeRestriction &&
        <span className="OrderReviewItem-zipCodeRestriction">
          Entrega restrita para o CEP {zipcodeMasker(zipcode)}.
          Você deverá retirar este{plural} produto{plural} em uma agência dos
          Correios. Após realizar o pedido, enviaremos instruções por e-mail.
        </span>
      }
      <div className="OrderReviewItem-description">
        {fullProductDescriptionMasker({
          quantity,
          title,
          reference,
          warranty,
          gifts,
          variations: !ENABLE_WARNING_ADDRESS_AND_ATTRIBUTES_ON_REVIEW ? variations : [],
          services
        })}
      </div>
      {ENABLE_WARNING_ADDRESS_AND_ATTRIBUTES_ON_REVIEW && (
        variations?.map((variation, i) =>
          (<span key={i} className="OrderReviewVariation">{variation.value}</span>)
        )
      )}
    </div>
  );
};

OrderReviewItem.propTypes = {
  title: PropTypes.string.isRequired,
  reference: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  gifts: PropTypes.array.isRequired,
  warranty: PropTypes.object.isRequired,
  variations: PropTypes.array,
  zipcodeRestriction: PropTypes.bool,
  zipcode: PropTypes.string,
  productCount: PropTypes.number.isRequired,
  services: PropTypes.array.isRequired
};

export default OrderReviewItem;
