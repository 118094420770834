import get from 'lodash/get';

import { showcase as Showcase } from 'constants/recommendation';
import * as RecommendationApi from 'api/recommendation-api';

export const REQUEST_RECOMMENDATION = 'recommendation/REQUEST_RECOMMENDATION';
export const FINISHED = 'recommendation/FINISHED';
export const RECEIVED_SHOWCASES_RECOMMENDATION = 'recommendation/RECEIVED_SHOWCASES_RECOMMENDATION';

export const initialState = {
  isRequesting: [],
  recommendations: {
    isEmpty() {
      return Object.entries(this).filter(o => typeof o[1] !== 'function').length === 0;
    },
    getShowcases(pageId, placementId) {
      return get(this, `${pageId}.${placementId}`, []);
    }
  }
};

export default function reducer(state = initialState, action = {}) {
  const { showcasesRecommendation, finishId, requesting } = action;

  switch (action.type) {
    case REQUEST_RECOMMENDATION:
      return {
        ...state,
        isRequesting: [...state.isRequesting, action.requesting],
      };
    case RECEIVED_SHOWCASES_RECOMMENDATION: {
      return {
        ...state,
        isRequesting: state.isRequesting.filter(x => x !== requesting),
        recommendations: {
          ...state.recommendations,
          [showcasesRecommendation.id]: {
            ...state.recommendations[showcasesRecommendation.id],
            [showcasesRecommendation.placementId]: showcasesRecommendation.showcases || [],
          },
        },
      };
    }
    case FINISHED:
      return {
        ...state,
        isRequesting: state.isRequesting.filter(x => x !== finishId),
      };
    default:
      return state;
  }
}

export function requestRecommendation(requesting) {
  return { type: REQUEST_RECOMMENDATION, requesting };
}

export function receivedShowcasesRecommendation({ showcasesRecommendation, requesting }) {
  return {
    type: RECEIVED_SHOWCASES_RECOMMENDATION,
    showcasesRecommendation,
    requesting,
  };
}

export function finish(finishId) {
  return { type: FINISHED, finishId };
}

export function fetchRecommendation(requesting, apiMethod, dispatcher, { params, items }) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { recommendation = {}, channel } = getState();

    if (!channel.configs.show_recommendation) {
      return reject(`Channel ${channel.name} does not support recommendation`);
    }

    if (recommendation.isRequesting.indexOf(requesting) > -1) {
      return reject('Already requesting showcase recommendation.');
    }

    dispatch(requestRecommendation(requesting));

    return apiMethod({ params, items })
      .then(res => {
        dispatch(dispatcher({ showcasesRecommendation: res, requesting }));
        return resolve();
      })
      .catch((err) => {
        dispatch(finish(requesting));
        return reject(err);
      });
  });
}

export const fetchShowcasesRecommendation = ({
  params = {
    pageId: Showcase.sacola.page_id,
    placementId: Showcase.sacola.placement_sacolavaziabottom,
  },
  items = [],
} = {}) =>
  fetchRecommendation(
    params.placementId,
    RecommendationApi.getShowcasesRecommendation,
    receivedShowcasesRecommendation,
    { params, items }
  );
