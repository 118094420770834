import React from 'react';
import PropTypes from 'prop-types';

import './BasketTable.scss';

const BasketHeader = () => (
  <div className="BasketTable-header" />
);

const BasketTable = ({ isProductsNotAvailable, children }) => (
  <div className="BasketTable">
    {!isProductsNotAvailable && <BasketHeader />}

    <div className="BasketTable-items">
      {children}
    </div>
  </div>
);

BasketTable.propTypes = {
  isProductsNotAvailable: PropTypes.bool,
  children: PropTypes.array,
};

BasketTable.defaultProps = {
  isProductsNotAvailable: false,
};

export default BasketTable;
