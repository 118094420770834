import React, { Component } from 'react';
import { custumerOption } from '../api/whatsapp-api';
import WhatsAppOption from 'components/ConfirmOrder/WhatsApp/WhatsAppOption/WhatsAppOption';
import WhatsAppFeedBack from 'components/ConfirmOrder/WhatsApp/WhatsAppFeedBack/WhatsAppFeedBack';

class WhatsAppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenFeedBack: 'none',
      hiddenOptionIn: 'block'
    };
  }

  handlerFeedBack = () => {
    this.setState({
      hiddenFeedBack: 'block',
      hiddenOptionIn: 'none'
    });
    custumerOption(true);
  };

  render() {
    return (
      <div className="WhatsAppOption__container-modules">
        <WhatsAppOption
          handlerFeedBack={this.handlerFeedBack}
          handlerShowFeedBack={this.state.hiddenOptionIn}
        />
        <WhatsAppFeedBack
          handlerShowFeedBack={this.state.hiddenFeedBack}
        />
      </div>
    );
  }
}

export default WhatsAppContainer;
