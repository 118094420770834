import React from 'react';
import PropTypes from 'prop-types';

import CardLuizaContainer from 'containers/CardLuizaContainer';

import OrderReviewSubtotal from '../OrderReviewSubtotal';
import OrderReviewShipment from '../OrderReviewShipment';
import OrderReviewDiscount from '../OrderReviewDiscount';
import OrderReviewTotal from '../OrderReviewTotal';
import OrderReviewInternationalTax from '../OrderReviewInternationalTax';

import './OrderReviewTotals.scss';

const OrderReviewTotals = ({
  products,
  shipment,
  discounts,
  to,
  cash = '',
  amountDescription,
  checkoutAmountDescription,
  productCount,
  servicesQuantity,
  servicesAmount,
  tax,
}) => (
  <div className="OrderReviewTotals">
    <OrderReviewSubtotal
      products={products}
      productCount={productCount}
      servicesQuantity={servicesQuantity}
      servicesAmount={servicesAmount}
    />

    <OrderReviewShipment shipment={shipment} />

    <OrderReviewInternationalTax {...tax} />

    {!!discounts.promocode && (
      <OrderReviewDiscount
        discount={discounts.promocode}
        type="promocode"
        title="Cupom"
      />
    )}

    {!!discounts.loyalty && (
      <OrderReviewDiscount
        discount={discounts.loyalty}
        type="loyalty"
        title="Clube da Lu"
      />
    )}

    <OrderReviewTotal
      to={to}
      cash={cash}
      amountDescription={amountDescription}
      checkoutAmountDescription={checkoutAmountDescription}
    />

    <CardLuizaContainer shipment={shipment} />
  </div>
);

OrderReviewTotals.propTypes = {
  products: PropTypes.string.isRequired,
  shipment: PropTypes.string.isRequired,
  discounts: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  cash: PropTypes.string,
  amountDescription: PropTypes.string,
  checkoutAmountDescription: PropTypes.string,
  servicesQuantity: PropTypes.number,
  servicesAmount: PropTypes.string,
  productCount: PropTypes.number,
  tax: PropTypes.shape({
    importValue: PropTypes.string,
    icms: PropTypes.string,
  })
};

export default OrderReviewTotals;
