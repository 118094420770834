import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import LoadingIcon from 'icons/loading';
import './MaxAttemptsModal.scss';

export const REDIRECT_TIMEOUT = 5000;

class MaxAttemptsModal extends Component {
  static propTypes = {
    onRedirect: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  }

  timeoutRef = null;

  componentDidUpdate(prevProps) {
    const {onRedirect, open} = this.props;

    if(!prevProps.open && open) {
      this.timeoutRef = setTimeout(onRedirect, REDIRECT_TIMEOUT);
    }
  }

  componentWillUnmount() {
    if(this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }

  render() {
    const { open } = this.props;

    return (
      <Dialog open={open}>
        <div className="MaxAttemptsContent">
          <LoadingIcon className="animate-spin" />
          <p className="MaxAttemptsLabel">
            Vamos ajudar a recuperar sua senha. Aguarde enquanto carregamos a página de
            Recuperação de Senha.
          </p>
        </div>
      </Dialog>
    );
  }
}

export default MaxAttemptsModal;
