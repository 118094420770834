export const mastercard = 'mastercard';
export const visa = 'visa';
export const amex = 'amex';
export const diners = 'diners';
export const hipercard = 'hipercard';
export const elo = 'elo';
export const aura = 'aura';
export const magazine = 'luizacard';
export const magazineGold = 'luizacard_gold';
export const virtualDebitEloCardFlag = 'virtual_debit_elo';
export const magaluCard = 'magalu_card';
export const unknown = 'unknown';
