export default function slugify(text) {
  // From https://gist.github.com/mathewbyrne/1280286
  return text && text.toString().normalize('NFD').toLowerCase().trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-$/, '') // Trim - from end of text
    .replace(/&/g, '-e-') // Replace - & with 'e'
    .replace(/-$/, '') || ''; // Replace last floating dash if exists
}
