import React from 'react';
import PropTypes from 'prop-types';

import Edit from 'icons/icon-edit';

import './ButtonLink.scss';

const ButtonLink = (props) => (
  <button
    className={props.buttonClassName}
    data-ga={props.dataGA}
    onClick={props.handleClick}
  >
    <span className={`${props.buttonClassName}__label`}>
      {props.label}
    </span>
    <span className={`${props.buttonClassName}__icon`}>
      <Edit />
    </span>
  </button>
);

ButtonLink.propTypes = {
  buttonClassName: PropTypes.string,
  dataGA: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string,
};

ButtonLink.defaultProps = {
  label: 'Alterar',
  buttonClassName: 'ButtonLink',
};

export default ButtonLink;
