import cloneDeep from 'lodash/cloneDeep';

import {
  promocodeError,
  promocodeMaxQuantity,
  promocodeExpired,
} from '../constants/customer-messages';

import { promocode as isValidPromocode } from 'utils/validators';

import { paymentReview } from '../api/models/basket-models';
import {
  putPromocode,
  deletePromocode,
} from '../api/promocode-api';

import { receivedPackages } from './basket';
import { metricsError } from './metrics';


//
// STATE
//

//                 | init  | onclick | sending | received | error
// isFormVisible   | false | true    | true    | false    | true
// isRequesting    | false | false   | true    | false    | false
// isLoading       | false | false   | true    | false    | false
// hasError        | false | false   | false   | false    | true
// promocodeInput  | ''    | ''      | string  | string   | ''
// errorMessage    | null  | null    | null    | null     | string

export const initialState = {
  promocode: '',
  promocodeInput: '',
  isFormVisible: false,
  isRequesting: false,
  isLoading: false,
  hasError: false,
  errorMessage: null,
  hasPromocodeDiscount: false,
};

//
// ACTIONS
//

export const SHOW_FORM = 'promocode/SHOW_FORM';
export const HIDE_FORM = 'promocode/HIDE_FORM';
export const PROMOCODE_INPUT = 'promocode/PROMOCODE_INPUT';
export const SET_PROMOCODE = 'promocode/SET_PROMOCODE';
export const CLEAR_ERROR = 'promocode/CLEAR_ERROR';
export const REQUEST = 'promocode/REQUEST';
export const SHOW_LOADING = 'promocode/SHOW_LOADING';
export const HIDE_LOADING = 'promocode/HIDE_LOADING';
export const RECEIVED = 'promocode/RECEIVED';
export const REQUEST_END = 'promocode/REQUEST_END';
export const PROMOCODE_ERROR = 'promocode/PROMOCODE_ERROR';
export const RESET_FORM = 'promocode/RESET_FORM';

//
// ACTION CREATORS
//

export function showForm() {
  return {
    type: SHOW_FORM,
  };
}

export function hideForm() {
  return {
    type: HIDE_FORM,
  };
}

export function setPromocodeInput(value) {
  return {
    type: PROMOCODE_INPUT,
    value,
  };
}

export function setPromocode(promocode, hasPromocodeDiscount) {
  return {
    type: SET_PROMOCODE,
    promocode,
    hasPromocodeDiscount,
  };
}

export function showError(errorMessage) {
  return {
    type: PROMOCODE_ERROR,
    errorMessage
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export function showLoading() {
  return {
    type: SHOW_LOADING
  };
}

export function hideLoading() {
  return {
    type: HIDE_LOADING
  };
}

export function requestEnd() {
  return {
    type: REQUEST_END
  };
}

export function received() {
  return {
    type: RECEIVED,
  };
}

export function request() {
  return {
    type: REQUEST,
  };
}

//
// REDUCER
//

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_FORM:
      return Object.assign({}, cloneDeep(state), {
        isFormVisible: true,
      });
    case HIDE_FORM:
      return Object.assign({}, cloneDeep(state), {
        isFormVisible: false,
      });
    case PROMOCODE_INPUT:
      return Object.assign({}, cloneDeep(state), {
        promocodeInput: action.value,
      });
    case SET_PROMOCODE:
      return Object.assign({}, cloneDeep(state), {
        promocode: action.promocode,
        hasPromocodeDiscount: action.hasPromocodeDiscount,
      });
    case PROMOCODE_ERROR:
      return Object.assign({}, cloneDeep(state), {
        hasError: true,
        errorMessage: action.errorMessage,
      });
    case CLEAR_ERROR:
      return Object.assign({}, cloneDeep(state), {
        hasError: false,
        errorMessage: null,
      });
    case SHOW_LOADING:
      return Object.assign({}, cloneDeep(state), {
        isLoading: true,
      });
    case HIDE_LOADING:
      return Object.assign({}, cloneDeep(state), {
        isLoading: false,
      });
    case REQUEST:
      return Object.assign({}, cloneDeep(state), {
        isRequesting: true,
      });
    case REQUEST_END:
      return Object.assign({}, cloneDeep(state), {
        isRequesting: false,
      });
    default:
      return state;
  }
}

//
// DISPATCHERS
//

const resetForm = (dispatch) => {
  dispatch(hideForm());
  dispatch(setPromocodeInput(''));
  dispatch(setPromocode(null, false));
  dispatch(clearError());
};

const startRequest = (dispatch) => {
  dispatch(clearError());
  dispatch(showLoading());
  dispatch(request());
};

export function showPromocodeForm() {
  return (dispatch) => {
    dispatch(showForm());
  };
}

export function clearErrorInput() {
  return (dispatch) => {
    dispatch(clearError());
  };
}

export function cancelPromocodeForm() {
  return (dispatch) => {
    resetForm(dispatch);
  };
}

export function changePromocodeInput(value) {
  return (dispatch) => {
    dispatch(setPromocodeInput(value));
  };
}

function promocodeErrorMessage(errorCode) {
  let message = promocodeError;

  if (errorCode === 'promocode_max_quantity_in_use') {
    message = promocodeMaxQuantity;
  }
  if (errorCode === 'promocode_not_found') {
    message = promocodeError;
  }
  if (errorCode === 'promocode_expired') {
    message = promocodeExpired;
  }

  return message;
}

export function fetchPromocode(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    if (!isValidPromocode(value)) {
      dispatch(showError(promocodeError));
      return reject();
    }

    startRequest(dispatch);

    return putPromocode(value)
      .then((response) => {
        if (!!response.error_code) {
          dispatch(showError(promocodeErrorMessage(response.error_code)));
          dispatch(requestEnd());
          dispatch(metricsError('cupom', response.error_code));
          return reject(response.error_code);
        }

        dispatch(requestEnd());
        dispatch(received());
        dispatch(receivedPackages(paymentReview(response)));
        dispatch(setPromocode(
          response.promocode,
          response.totals.discount_amount > 0
        ));
      })
      .catch((err) => {
        dispatch(requestEnd());
        dispatch(showError(promocodeError));
        reject(err);
      })
      .then(() => {
        dispatch(hideLoading());
        resolve();
      });
  });
}

export function removePromocode() {
  return (dispatch) => new Promise((resolve, reject) => {
    startRequest(dispatch);

    return deletePromocode()
      .then((response) => {
        dispatch(requestEnd());

        if (response.hasError) {
          dispatch(showError(promocodeError));
          return;
        }
        resetForm(dispatch);
        dispatch(receivedPackages(paymentReview(response)));
      })
      .catch((err) => {
        dispatch(requestEnd());
        dispatch(showError(promocodeError));
        reject(err);
      })
      .then(() => {
        dispatch(hideLoading());
        resolve();
      });
  });
}
