import { serviceCategoryName } from './serviceCategoryName';

export function serviceLayer(service, quantity) {
  const serviceData = {};

  const duration = service.duration || service.duration_value;

  serviceData.id = service.treeId;
  serviceData.name = `${service.category}${duration}`;
  serviceData.category = 'servicos/' + serviceCategoryName(service.category);
  serviceData.variant = service.treeId;
  serviceData.price = Number(service.unitPrice);
  serviceData.quantity = quantity;

  return serviceData;
}
