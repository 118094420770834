import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SacolaIcon from 'icons/navigation/sacola.svg';
import IdentificacaoIcon from 'icons/navigation/identificacao.svg';
import EntregaIcon from 'icons/navigation/entrega.svg';
import PagamentoIcon from 'icons/navigation/pagamento.svg';
import {
  basket as routeBasket,
  login as routeLogin,
  delivery as routeDelivery,
  payment as routePayment,
} from 'constants/route-names';
import './styles.scss';

export default class Icon extends Component {
  static propTypes = {
    routeName: PropTypes.string.isRequired,
    status: PropTypes.object.isRequired,
  };

  render() {
    const { routeName, status } = this.props;

    switch(routeName) {
      case routeBasket:
        return <SacolaIcon className="NavigationIcon" fill={status[routeBasket]}/>;
      case routeLogin:
        return <IdentificacaoIcon className="NavigationIcon" fill={status[routeLogin]}/>;
      case routeDelivery:
        return <EntregaIcon className="NavigationIcon" fill={status[routeDelivery]}/>;
      case routePayment:
        return <PagamentoIcon className="NavigationIcon" fill={status[routePayment]}/>;
      default:
        return null;
    }
  }
}
