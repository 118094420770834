import {
  getProductAvailability,
  getProductBadges,
  getProductScore,
  getProductShowcaseAlgorithm,
  getProductShowcaseTitle,
  GTM_FALSE,
  GTM_TRUE,
  GTM_UNDEFINED,
  productCategoryName,
  productIdPrimary,
  productName,
  sellerType,
} from './helpers';

export function addToCartEcommerceData(product, quantity = 1) {
  const ecommerceData = {};
  const idVariant = product.stewieProductId || product.id;

  ecommerceData.id = productIdPrimary(idVariant);
  ecommerceData.name = product.title;
  ecommerceData.brand = product.brand;
  ecommerceData.category = productCategoryName(product);
  ecommerceData.variant = idVariant;
  ecommerceData.price = product.prices.cash / quantity;
  ecommerceData.quantity = quantity;
  ecommerceData.dimension9 = GTM_UNDEFINED;
  ecommerceData.dimension12 =
    product.isSoldOut || product.notAvailableForDelivery ? GTM_FALSE : GTM_TRUE;
  ecommerceData.dimension13 = GTM_UNDEFINED;
  ecommerceData.dimension14 = GTM_UNDEFINED;
  ecommerceData.dimension15 = `${product.prices.to / quantity}`;
  ecommerceData.dimension16 = sellerType(product.storeId);
  ecommerceData.dimension17 = product.storeId;
  ecommerceData.dimension18 = GTM_UNDEFINED;
  ecommerceData.dimension19 = GTM_UNDEFINED;
  ecommerceData.dimension18 = GTM_UNDEFINED;
  ecommerceData.dimension19 = GTM_UNDEFINED;
  ecommerceData.dimension20 = GTM_UNDEFINED;
  ecommerceData.dimension21 = GTM_UNDEFINED;
  ecommerceData.dimension22 = GTM_UNDEFINED;
  ecommerceData.dimension23 = GTM_UNDEFINED;
  ecommerceData.dimension24 = GTM_UNDEFINED;
  ecommerceData.dimension44 = GTM_UNDEFINED;
  ecommerceData.dimension45 = GTM_UNDEFINED;
  ecommerceData.dimension46 = GTM_UNDEFINED;
  ecommerceData.dimension57 = GTM_UNDEFINED;
  ecommerceData.dimension58 = GTM_UNDEFINED;
  ecommerceData.dimension62 = GTM_UNDEFINED;
  ecommerceData.dimension68 = GTM_UNDEFINED;
  ecommerceData.dimension70 = GTM_UNDEFINED;
  ecommerceData.dimension72 = GTM_UNDEFINED;
  ecommerceData.dimension76 = `${product.prices?.cash}` || GTM_UNDEFINED;
  ecommerceData.dimension77 = GTM_UNDEFINED;
  ecommerceData.dimension78 = GTM_UNDEFINED;
  ecommerceData.dimension79 = GTM_UNDEFINED;
  ecommerceData.dimension85 = GTM_UNDEFINED;
  ecommerceData.dimension86 = GTM_UNDEFINED;
  ecommerceData.dimension87 = GTM_UNDEFINED;
  ecommerceData.dimension90 = GTM_UNDEFINED;
  ecommerceData.dimension91 = GTM_UNDEFINED;
  ecommerceData.dimension92 = GTM_UNDEFINED;

  return ecommerceData;
}

export const editCartEcommerceDataGA4 = (product, quantity = 1) => {
  const ecommerceData = {};
  const idVariant = product.stewieProductId || product.id || product.sku;

  ecommerceData.id = productIdPrimary(idVariant);
  ecommerceData.name = productName(product.title);
  ecommerceData.brand = product.brand;
  ecommerceData.category = productCategoryName(product);
  ecommerceData.variant = idVariant;
  ecommerceData.price = product.prices.cash / quantity;
  ecommerceData.quantity = quantity;
  ecommerceData.currency = 'BRL';

  ecommerceData.dimension11 = getProductAvailability(product);
  ecommerceData.dimension24 = getProductScore(product);
  ecommerceData.dimension61 = product?.storeId || GTM_UNDEFINED;
  ecommerceData.dimension62 = product?.provider || GTM_UNDEFINED;
  ecommerceData.dimension71 = GTM_UNDEFINED;
  ecommerceData.dimension86 = getProductBadges(product?.badges);
  ecommerceData.dimension102 = getProductShowcaseTitle(product);
  ecommerceData.dimension103 = product?.link?.includes('rgr.bob') ? 'bob' : 'acme';
  ecommerceData.dimension106 = getProductShowcaseAlgorithm(product);
  ecommerceData.dimension108 = product.showcaseType || GTM_UNDEFINED;
  ecommerceData.dimension112 = GTM_UNDEFINED;
  ecommerceData.dimension115 = GTM_UNDEFINED;

  return ecommerceData;
};
