import React from 'react';
import PropTypes from 'prop-types';

import Stars from 'components/Bobby/Stars.js';

const BobbyStars = ({
  rating
}) => {
  const ratingPercent = rating * 20;
  const yellow = '#FFCC03';
  const gray = '#E5E5E5';
  return (
    <div className="BobbyProduct-stars">
      {rating > 0 &&
        <Stars
          ratingPercent={ratingPercent}
          yellow={yellow}
          gray={gray}
        /> || null
      }
    </div>
  );
};

BobbyStars.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default BobbyStars;
