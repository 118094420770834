import { isLogged } from 'utils/session';
import { redirectToLogin } from 'utils/redirect';
import miniappSetSession from 'utils/miniappSetSession';
import verifyIfMiniapp from 'utils/verifyIfMiniapp';
import { isSuccess } from '@magalu/webzord';
import requestWebzord from './requestWebzord';

export default async function miniappValidateSession(router, pathname, location) {
  const shouldCallLogin = !isLogged();

  if (verifyIfMiniapp()) {
    if (shouldCallLogin) {
      await miniappSetSession();
    } else {
      return new Promise((resolve, reject) => {
        const args = {
          serviceName: 'customer.getCustomerInfo',
          callbackId: 'getCustomerInfos',
          callback: async (result) => {
            if (isSuccess(result)) {
              const data = result.body;
              const customerStorage = JSON.parse(
                localStorage.getItem('nationalCustomerData')
              );

              if (data.customer_id !== customerStorage.customer_id) {
                localStorage.removeItem('nationalCustomerData');
                await miniappSetSession();
              }

              resolve(result);
            } else {
              console.error('MagaluService getCustomerInfos failed', result);
              reject(result);
            }
          },
        };

        requestWebzord(args);
      });
    }
  } else {
    if (shouldCallLogin) {
      return redirectToLogin(router, pathname, location);
    }
  }
}
