import { getCustomer } from 'utils/session';
import { productsDataGA4 } from './helpers';

import { getProductFromState } from 'utils/data-layer/helpers';

function productPriceFormatted (price) {
  return parseFloat(price).toFixed(2);
}
export function removeFromCartGA4(action, stateBefore, stateAfter) {
  const eventName = 'remove_from_cart';
  const result = [];
  action.items.forEach((item) => {
    const customer = getCustomer();
    const layer = {};
    const product = getProductFromState(stateBefore, item.id);
    
    const price = product.prices;
    const quantityItem = product.quantity;
    
    const productPrice = price.to / quantityItem;
    const productPriceCash = price.cash / quantityItem;

    const basketPage = 'carrinho';
    const screenName = product?.screenName || basketPage;
    layer.event = 'remove_from_cart';
    layer.eventLabel = 'excluir-item';
    layer.idUsuario = customer?.id || null;
    layer.screenName = screenName;
    layer.mundos = 'magalu';
    layer.currency = 'BRL';
    layer.value = productPriceFormatted(price.to);
    
    product.prices.to = productPrice;
    product.prices.cash = productPriceCash;

    layer.items = productsDataGA4({products: [product], stateAfter, index: action.index + 1, eventName});
    result.push(layer);
  });
  return result;

}

export function changeItemQtyGA4(action, stateBefore, stateAfter) {
  const layer = {};

  const quantityModified = Math.abs(action.product.quantity - action.quantity);
  if (action.product.quantity < action.quantity) {
    const basketPage = 'carrinho';
    const product = action.product || null;
    const screenName = product?.screenName || basketPage;
    const eventName = 'add_to_cart';

    layer.event = eventName;
    layer.eventCategory = `${screenName === basketPage ? 'carrinho' : screenName}`;
    layer.eventLabel = 'adicionar-a-sacola';
    layer.screenName = screenName;
    
    const customer = getCustomer();
    layer.idUsuario = customer?.id || null;
    layer.mundos = 'magalu';

    const productPrice = action.product.prices.to / action.product.quantity;
    const productPriceCash = action.product.prices.cash / action.product.quantity;

    product.quantity = quantityModified;
    product.prices.to = productPrice;
    product.prices.cash = productPriceCash;

    layer.items = productsDataGA4({products: [product], stateAfter, index: action.index + 1, eventName});

  } else {
    const { id } = action.product;
    const product = getProductFromState(stateBefore, id);
    const eventName = 'remove_from_cart';
    
    const productPrice = action.product.prices.to / action.product.quantity;
    const productPriceCash = action.product.prices.cash / action.product.quantity;
    const totalPriceProduct = productPrice * quantityModified;

    const customer = getCustomer();
    const basketPage = 'carrinho';
    const screenName = product?.screenName || basketPage;
    layer.event = 'remove_from_cart';
    layer.eventLabel = 'diminuir-quantidade';
    layer.screenName = screenName;
    layer.idUsuario = customer?.id || null;
    layer.mundos = 'magalu';
    layer.currency = 'BRL';
    layer.value = productPriceFormatted(totalPriceProduct);
    product.quantity = quantityModified;
    product.prices.to = productPrice;
    product.prices.cash = productPriceCash;

    layer.items = productsDataGA4({products: [product], stateAfter, index: action.index + 1, eventName});
  }

  return layer;
}

export function eventActionBasketGA4(event) {
  const layer = {
    event: 'clickEvent',
    eventCategory: 'carrinho',
    eventAction: 'click',
    eventLabel: event.eventName
  };
  return layer;
}