import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CardFlag from 'components/Payment/CardFlag/CardFlag';
import InputRadioButton from 'components/Form/InputRadioButton/InputRadioButton';

export default class SavedCardLine extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    flag: PropTypes.string.isRequired,
    handleTypeChangeClick: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    enabled: PropTypes.bool,
    children: PropTypes.object
  }

  render() {
    const {
      id,
      cardNumber,
      fullName,
      checked = false,
      flag,
      children,
      handleTypeChangeClick,
      enabled = false
    } = this.props;
    const inputId = `payment-savedCard-${id}`;
    return (
      <li className={`SavedCardLine clearfix ${enabled === false ? 'PaymentBox-line-disabled' : 'PaymentBox-line'}`}>
        <div>
          <label className="PaymentBox-line-label" htmlFor={inputId}>
            <InputRadioButton
              checkedValue={checked}
            />
            <input
              type="radio"
              id={inputId}
              name="payment-type"
              value={id}
              checked={checked}
              disabled={!enabled}
              onChange={handleTypeChangeClick}
              className="InputRadioButton"
            />

            <CardFlag className={`PaymentBox-icon--flag${flag === 'visa' ? 'Visa' : ''}`} flag={flag} />

            <span>{cardNumber}</span>

            <span className="PaymentBox-line-info-label">{fullName}</span>
          </label>
        </div>
        {children}
      </li>
    );
  }
}
