import React from 'react';

import EmptyBasketAsset from 'icons/empty-basket.svg';

import './EmptyBasket.scss';

const EmptyBasket = ({
  purchaseMoreLink
}) => (
  <div className="EmptyBasket clearfix">
    <div className="EmptyBasket-box">
      <EmptyBasketAsset className="EmptyBasket-asset" />
      <div className="EmptyBasket-title">
        Sua sacola está vazia
      </div>
      <div className="EmptyBasket-text">
        Vá para a <a href={purchaseMoreLink}>página inicial</a> ou procure no site os produtos que vão te deixar feliz.<br />
        Quando encontrá-los, clique no botão adicionar à sacola ;)
      </div>
    </div>
  </div>
);

export default EmptyBasket;
