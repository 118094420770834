import { change } from 'redux-form';

import * as BasketApi from 'api/basket-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { unauthorizedLogout } from './unauthorized';

export const REQUEST = 'installments/REQUEST';
export const RECEIVED = 'installments/RECEIVED';
export const RESET = 'installments/RESET';
export const FINISHED = 'installments/FINISHED';

export const initialState = {
  installmentsRequesting: [],
  installmentsPerType: {}
};

export default function reducer(state = initialState, action = {}) {
  const { installments, paymentId } = action;
  const requesting = state.installmentsRequesting;
  const index = requesting.indexOf(paymentId);

  switch (action.type) {
    case REQUEST:
      if (!requesting.find(id => paymentId === id)) {
        return Object.assign({}, state, {
          installmentsRequesting: [
            ...state.installmentsRequesting,
            paymentId
          ],
          installmentsPerType: {
            ...state.installmentsPerType,
            [paymentId]: []
          }
        });
      }
      return state;
    case RECEIVED:
      return Object.assign({}, state, {
        installmentsRequesting: [
          ...requesting.slice(0, index),
          ...requesting.slice(index + 1)
        ],
        installmentsPerType: {
          ...state.installmentsPerType,
          [paymentId]: installments
        }
      });
    case RESET:
      return Object.assign({}, initialState);
    case FINISHED:
      return Object.assign({}, state, {
        installmentsRequesting: [
          ...requesting.slice(0, index),
          ...requesting.slice(index + 1)
        ],
        installmentsPerType: state.installmentsPerType
      });
    default:
      return state;
  }
}

export function requestInstallments(paymentId) {
  return { type: REQUEST, paymentId };
}

export function receivedInstallments(paymentId, installments) {
  return { type: RECEIVED, paymentId, installments };
}

export function reset() {
  return { type: RESET };
}

export function finish(paymentId) {
  return { type: FINISHED, paymentId };
}

export function resetInstallmentsSelect(form) {
  return (dispatch, getState) => dispatch(change(form, 'installments', '-1'));
}

export function fetchInstallments(paymentId) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { installments = {} } = getState();

    if (installments.installmentsRequesting.indexOf(paymentId) > -1) {
      return reject(`Installment ${paymentId} already fetching.`);
    }

    dispatch(requestInstallments(paymentId));

    return BasketApi.getInstallments(paymentId)
      .then(res => {
        dispatch(receivedInstallments(paymentId, res));
        return resolve();
      }, err => {
        const { status } = err;
        if (status === 401) {
          dispatch(unauthorizedLogout(err));
          dispatch(fail(customerMessages.expired, status));
        } else {
          dispatch(fail(customerMessages.failFetchInstallments, status));
        }
        dispatch(finish(paymentId));
        return reject(err);
      });
  });
}

export function fetchInstallmentsSimulate(paymentId, shippingAmount) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { installments = {} } = getState();

    if (installments.installmentsRequesting.indexOf(paymentId) > -1) {
      return reject(`Installment ${paymentId} ${shippingAmount} already fetching.`);
    }

    dispatch(requestInstallments(paymentId));

    return BasketApi.getInstallmentsSimulate(paymentId, shippingAmount)
      .then(res => {
        dispatch(receivedInstallments(paymentId, res));
        return resolve();
      }).catch(() => dispatch(finish(paymentId)));
  });
}

export function fetchInstallmentsVirtualDebitElo(paymentId) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { installments = {} } = getState();

    if (installments.installmentsRequesting.indexOf(paymentId) > -1) {
      return reject(`Installment ${paymentId} ${shippingAmount} already fetching.`);
    }

    dispatch(requestInstallments(paymentId));

    return BasketApi.getInstallments(paymentId)
      .then(res => {
        dispatch(receivedInstallments(paymentId, res));
        return resolve();
      }).catch(() => dispatch(finish(paymentId)));
  });
}
