import React from 'react';
import PropTypes from 'prop-types';

import BasketItemDelivery from 'components/Basket/Item/BasketItemDelivery/BasketItemDelivery';

import './BasketTableByPackage.scss';
import LogoMagalu from 'icons/icon-logo-magalu-package.svg';
import isInternationalPrice from 'utils/isInternationalPrice';

const BasketHeader = ({ headerKey, numberOfProviders, groupedProducts, index }) => {
  const deliveryByMagalu = 'magazineluiza';
  const { provider, providerDescription } = groupedProducts[headerKey][0];

  return (
    <div key={index} className="BasketTable-header-package">
      <div className="BasketTable-header-package-title">
        Entrega {index + 1} de {numberOfProviders} por{' '}
        {provider !== deliveryByMagalu ? (
          providerDescription
        ) : (
          <LogoMagalu className="BasketTable-header-package-logo" />
        )}
      </div>
    </div>
  );
};

const BasketTableByPackage = ({ groupedProducts, children }) => {
  const numberOfProviders = Object.keys(groupedProducts).length;

  return (
    <div>
      {Object.keys(groupedProducts).map((key, index) => (
        <div key={`${key}${index}`}>
          {groupedProducts[key].shipping_time}
          <BasketHeader
            headerKey={key}
            numberOfProviders={numberOfProviders}
            groupedProducts={groupedProducts}
            index={index}
          />
          <div className="BasketTable-items-package">{children[index]}</div>
          <div className="Basket-shipment-box">
            <span className="Basket-shipment-title">Frete para esta entrega</span>
            {groupedProducts[key][0].deliveryType && (
              <BasketItemDelivery
                isInternational={isInternationalPrice(groupedProducts[key][0])}
                shippingTime={groupedProducts[key][0].deliveryType}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

BasketTableByPackage.propTypes = {
  children: PropTypes.array,
  groupedProducts: PropTypes.object,
};

export default BasketTableByPackage;
