import cookies from 'browser-cookies';
import { detectMobile } from 'utils/responsive';
import { getCustomer, isLogged} from 'utils/session';
import {
  getChannelStoreName,
  getChannelStoreId,
  getCityUf,
  GTM_FALSE ,
  GTM_TRUE,
  GTM_UNDEFINED,
  lowerDash,
  productsData
} from './helpers';

const stepNames = [
  'carrinho',
  'login',
  'entrega',
  'revisão pedido',
  'pedido concluido'
];

export const pageviewLayer = (stateAfter, step) => {
  const layer = {};
  const customer = getCustomer();
  const order = stateAfter?.order?.order || {};
  const { address = {} } = stateAfter.basket?.shippingPackages?.shippingTypes?.deliveries || {};
  const { ecommerceProducts } = productsData(order.items, stateAfter);

  layer.event = 'virtualPageview';

  layer.nomeDaLoja = getChannelStoreName(stateAfter?.channel);
  layer.idDaLoja = getChannelStoreId(stateAfter?.channel);
  layer.hitTimestamp = `${Date.now()}`;

  layer.cidadeuf = getCityUf(address?.city || '', address?.state || '').toLowerCase();
  layer.loginAtivo = isLogged() ? 'logado' : 'nao-logado';
  layer.idUsuario = customer?.id || GTM_UNDEFINED;
  layer.screenName = stepNames[step - 1];
  layer.pageType = lowerDash(stepNames[step - 1]);
  layer.usuarioClube = isLogged() && cookies.get('MLPARCEIRO_clube') ? GTM_TRUE : GTM_FALSE;
  layer.barraLocalizacao = GTM_UNDEFINED;
  layer.cepPreenchido = address?.city && address?.state ? GTM_TRUE : GTM_FALSE;
  layer.device = detectMobile() ? 'site-mobile' : 'site-desktop';
  layer.idParceiro = (window.MAGALU_CHANNEL.channel_name === 'clube' ?
    cookies.get('MLPARCEIRO_clube') :
    cookies.get('MLPARCEIRO')) ||
    GTM_UNDEFINED;

  if (step === '5') {
    layer.ecommerce = {};
    layer.ecommerce.purchase = {};
    layer.ecommerce.purchase.actionField = {};
    layer.ecommerce.purchase.actionField.id = order.id;
    layer.ecommerce.purchase.actionField.currency = 'BRL';
    layer.ecommerce.purchase.actionField.affiliation = 'site_magalu';
    layer.ecommerce.purchase.actionField.revenue = `${order.totalAmount}`;
    layer.ecommerce.purchase.actionField.shipping = `${order.shippingAmount}`;
    layer.ecommerce.purchase.actionField.coupon = stateAfter?.promocode?.promocode || '';
    layer.ecommerce.purchase.products = ecommerceProducts;
  }

  return layer;
};
