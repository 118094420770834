export const preventFocusEventCall = (
  event,
  preventElementId,
  onIsValid,
  callback
) => {
  const clickedElement = event.relatedTarget?.id;
  const targetToPreventEvent = preventElementId;

  if (clickedElement !== targetToPreventEvent) {
    onIsValid();
  }
  callback?.(event);
};
