import React from 'react';

import Modal from 'components/Modal/Modal';
import { addToDatalayer } from 'utils/gtm';

import './PaymentWithoutInternationalTax.scss';

const ModalContent = (props) => {
  const handleClick = () => {
    const layer = {
      event: 'clickEvent',
      eventCategory: 'revisao:compra-internacional',
      eventAction: 'click',
      eventLabel: 'tentar-novamente-mais-tarde',
      eventNonInteraction: '0',
      hitTimestamp: `${new Date().getTime()}`,
    };

    addToDatalayer(layer, window.dataLayer || []);
    return props.toggle();
  };

  return (
    <div className="PaymentWithoutInternationalTax">
      <div className="PaymentWithoutInternationalTax-header">
        <span className="font-lg-bold">{props?.title}</span>
      </div>

      <div className="PaymentWithoutInternationalTax-content">
        <p className="font-sm-regular">
          Não foi possível calcular os impostos de importação. <br /> Aguarde e tente novamente
          mais tarde.
        </p>
      </div>

      <div className="PaymentWithoutInternationalTax-footer">
        <button
          className="PaymentWithoutInternationalTax-btn BtnLarge"
          onClick={handleClick}>
          Tentar novamente mais tarde
        </button>
      </div>
    </div>
  );
};

const PaymentWithoutInternationalTax = ({ isOpen, toggle }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Compra internacional"
      content={ModalContent}
      withoutFooter
      withoutHeader
      toggle={toggle}
    />
  );
};

export default PaymentWithoutInternationalTax;
