import React from 'react';
import PropTypes from 'prop-types';

import { errorClassName } from 'utils/classnames';

export const InputError = ({
  asyncValidating = false,
  field,
  errorMessage,
  ...otherProps
}) => {
  const message = field.error || errorMessage;

  return (
    asyncValidating !== field.name &&
    field.touched &&
    !field.active &&
    field.error ||
    errorMessage
  )
    ? <p
      {...otherProps}
      className={errorClassName(
        message,
        otherProps.className
      )}
    >
      {message}
    </p>
    : null;
};

InputError.propTypes = {
  field: PropTypes.object.isRequired,
  asyncValidating: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  errorMessage: PropTypes.string, // If we need to introduce another kind of error.
  id: PropTypes.string,
};

export default InputError;
