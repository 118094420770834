import React from 'react';

import { privacyUrl } from 'constants/external-urls';

const LoginFooterInfo = () => (
  <p>
    Seus dados pessoais serão respeitados de acordo com a nossa <a href={privacyUrl} target="_blank" rel="noopener">política de privacidade</a>. *Nada será publicado em sua timeline. Serviço válido somente para pessoas físicas.<br />
    Em caso de dúvidas, acesse nossa central de atendimento.
  </p>
);

export default LoginFooterInfo;
