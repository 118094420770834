import { Component } from 'react';

import { notFoundUrl } from 'constants/external-urls';

export default class NotFound extends Component {
  componentWillMount() {
    window.location = notFoundUrl;
  }

  render() {
    return false;
  }
}
