/* eslint-disable camelcase */
const deliveryTime = ({ unit, max_value}) => {
  if(unit === 'hours' && max_value <= 2){
    return 'hours';
  }

  if(unit === 'days' && max_value === 0){
    return 'today';
  }

  if(unit === 'days' && max_value === 1){
    return 'day';
  }

  if(unit === 'working_days' && max_value === 1){
    return 'days';
  }

  return 'default';
};

export default deliveryTime;
