// Frete por pacote | Componente será migrado de por produto para por pacote
import React from 'react';
import PropTypes from 'prop-types';
import ConventionalDefault from 'icons/icon-conventional';
import ConventionalHours from 'icons/shipping/today.svg';
import ConventionalInternational from 'icons/shipping/airplane.svg';
import ConventionalDay from 'icons/shipping/day_24c.svg';
import ConventionalToday from 'icons/shipping/delivery_2h.svg';
import ConventionalDays from 'icons/shipping/delivery_24h.svg';
import deliveryTime from 'utils/deliveryTime';
import { toBRL } from 'utils/currency';

import './BasketItemDelivery.scss';

const classNameDelivery = 'BasketItemProduct-delivery-iconConventional';

const ConventionalIcon = {
  international: <ConventionalInternational className={`${classNameDelivery}-international`} />,
  hours: <ConventionalHours className={`${classNameDelivery}-hours`} />,
  today: <ConventionalToday className={`${classNameDelivery}`} />,
  day: <ConventionalDay className={`${classNameDelivery}`} />,
  days: <ConventionalDays className={`${classNameDelivery}`} />,
  default: <ConventionalDefault className={`${classNameDelivery}`} />,
};

const BasketItemDelivery = ({ shippingTime = {}, isInternational }) => {
  const conventionalIconName = isInternational
    ? 'international'
    : deliveryTime(shippingTime.shipping_time);

  return (
    <span className="BasketItemProduct-shipping">
      {ConventionalIcon[conventionalIconName]}
      {`${shippingTime?.shipping_time?.description} `}
      <span className="BasketItemDelivery-price">
        {shippingTime?.cost && shippingTime?.cost === '0.00' ? (
          <span className="BasketItemDelivery-price--free">Grátis</span>
        ) : (
          toBRL(shippingTime?.cost)
        )}
      </span>
    </span>
  );
};

BasketItemDelivery.propTypes = {
  shippingTime: PropTypes.object,
};

export default BasketItemDelivery;
