import * as CardApi from 'api/payment-api';
import * as customerMessages from 'constants/customer-messages';
import { fail } from './fail';
import { unauthorizedLogout } from './unauthorized';

export const REQUEST = 'allowed-bins/REQUEST';
export const RECEIVED = 'allowed-bins/RECEIVED';
export const CLEAR = 'allowed-bins/CLEAR';
export const FINISHED = 'allowed-bins/FINISHED';

export const initialState = {
  isRequesting: false,
  allowedBins: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, { isRequesting: true });
    case RECEIVED:
      const { allowedBins } = action;
      return Object.assign({}, state, {
        isRequesting: false,
        allowedBins: allowedBins.allowed_bins
      });
    case CLEAR:
      return Object.assign({}, initialState);
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    default:
      return state;
  }
}

export function requestAllowedBins() {
  return { type: REQUEST };
}

export function receivedAllowedBins(allowedBins) {
  return { type: RECEIVED, allowedBins };
}

export function clearAllowedBins() {
  return { type: CLEAR };
}

export function finish() {
  return { type: FINISHED };
}

export function fetchAllowedBins() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { allowedBins = {} } = getState();

    if (allowedBins.isRequesting) {
      return reject();
    }

    dispatch(requestAllowedBins());

    return CardApi.getAllowedBins()
      .then(res => {
        dispatch(receivedAllowedBins(res));
        return resolve();
      }, err => {
        const { status } = err;
        if (status === 401) {
          dispatch(unauthorizedLogout(err));
          dispatch(fail(customerMessages.expired, status));
        }
        dispatch(finish());
        return reject(err);
      });
  });
}
