import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RightArrow from 'icons/bobby/icon-arrow-right.svg';

class NextArrow extends Component {
  render() {
    const disabledClass = /slick-disabled/.test(this.props.className)
      ? '--disabled' : '';

    return (
      <RightArrow
        {...this.props}
        className={`${this.props.className} BobbyArrow-right${disabledClass}`}
      />
    );
  }
}

NextArrow.propTypes = {
  className: PropTypes.string
};

export default NextArrow;
