import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Responsive from 'components/Responsive';

import CloseX from 'icons/icon-close.svg';

const MagazineModal = ({
  children,
  textAlign = 'center',
  isOpen = false,
  onRequestClose,
  marginTop = '25vh',
  isMobile,
  ...otherProps
}) => {
  const sidePadding = 61;
  const contentWidth = 956 - 2 * sidePadding;
  Modal.setAppElement('body');

  let style = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0)'
    },
    content: {
      position: 'relative',
      top: null,
      left: null,
      right: null,
      bottom: null,
      border: 0,
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: 4,
      outline: 'none',
      padding: 0,
      paddingTop: 50,
      paddingBottom: 50,
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop,
      width: contentWidth,
      textAlign,
      boxShadow: '0px 18px 40px 10px rgba(0, 0, 0, 0.12)'
    }
  };

  if (isMobile) {
    style.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)';

    style.content.boxShadow = '0px 0px 6px 4px rgba(0, 0, 0, 0.12)';
    style.content.width = 'auto';
    style.content.paddingLeft = '10px';
    style.content.paddingRight = '10px';
    style.content.marginTop = '56px';
    style.content.paddingTop = '14px';
  }

  return (
    <Modal
      {...otherProps}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={style}
    >
      <div className="Modal-content">
        {children}
      </div>
      <CloseX
        className="CloseIcon"
        onClick={(e) => { // eslint-disable-line react/jsx-no-bind
          e.preventDefault();
          if (onRequestClose) {
            onRequestClose();
          }
        }}
      />
    </Modal>
  );
};

MagazineModal.protoTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  textAlign: PropTypes.string,
  marginTop: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default Responsive(MagazineModal);
