import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';

import { address as routeAddress, login as routeLogin } from 'constants/route-names';

import {
  orderOutOfStockProducts,
  orderItemsNotAllowedForCompaniesProducts,
  basketSoldOutProducts,
} from 'utils/product';
import { isLogged } from 'utils/session';

import { deleteServiceData } from 'api/models/basket-models';

import * as basketActions from 'reducers/basket';

import BasketPrices from 'components/Basket/BasketPriceBox/BasketPriceBox';
import BasketContinue from 'components/Basket/BasketContinue/BasketContinue';
import BasketContinueFloatingContainer from 'components/Basket/BasketContinue/BasketContinueFloatingContainer';
import Responsive from 'components/Responsive';

import BasketShipmentContainer from 'containers/BasketShipmentContainer';
import CardLuizaContainer from 'containers/CardLuizaContainer';

import './BasketPriceBoxContainer.scss';

const { func, object, array, string, shape, number, bool } = PropTypes;
const mapStateToProps = ({ basket, shipment, order }) => ({
  ...basket,
  ...shipment,
  ...order,
});

@connect(mapStateToProps, basketActions)
class BasketPriceBoxContainer extends Component {
  static propTypes = {
    basket: shape({
      totals: shape({
        to: string.isRequired,
        cash: string,
        amountDescription: string,
        checkoutAmountDescription: string,
        amountWithoutDiscount: string,
        totalDelivery: string,
      }).isRequired,
      productCount: number.isRequired,
      hasServices: bool.isRequired,
      products: array,
    }).isRequired,
    shipment: shape({
      state: string,
      zipcode: string,
      packages: array.isRequired,
      outOfStockProducts: array,
      notAvailableProducts: array,
    }).isRequired,
    itemsOutOfStock: array,
    itemsNotAllowedForCompanies: array,
    orderViolatedPolicies: array,
    deleteItem: func.isRequired,
    deleteServices: func.isRequired,
    triggerClickEventGA4: func.isRequired,
    isMobile: bool,
  };

  static contextTypes = {
    router: object.isRequired,
  };

  unavailableProducts = () => {
    const {
      basket: { products = [] },
      itemsOutOfStock = [],
      itemsNotAllowedForCompanies = [],
    } = this.props;

    return uniqBy(
      [
        ...basketSoldOutProducts(products),
        ...orderOutOfStockProducts(products, itemsOutOfStock),
        ...orderItemsNotAllowedForCompaniesProducts(
          products,
          itemsNotAllowedForCompanies
        ),
      ],
      'sku'
    );
  };

  deleteBasketProducts = () => {
    const { deleteItem } = this.props;

    const shipmentItemsToDelete = this.unavailableProducts();

    const itemsToDelete = shipmentItemsToDelete.map((product) => {
      return {
        id: product.bundle_sku || product.id || product.sku,
        storeId: product.storeId || product.seller,
      };
    });

    return new Promise((resolve, reject) => {
      if (!itemsToDelete.length) {
        return resolve();
      }

      return deleteItem(itemsToDelete).then(resolve).catch(reject);
    });
  };

  deleteBasketServices = () => {
    const {
      basket: { customerPerson, products },
      deleteServices,
    } = this.props;

    const itemsToDelete = products.map((product) => {
      return {
        id: product.id,
        storeId: product.storeId,
        services: product.services.map((service) => service.id),
      };
    });

    return new Promise((resolve, reject) => {
      if (!itemsToDelete.length) {
        return resolve();
      }

      if (customerPerson !== false) {
        return resolve();
      }

      return deleteServices(deleteServiceData(itemsToDelete)).then(resolve).catch(reject);
    });
  };

  render() {
    const {
      basket: { totals, productCount, customerPerson, hasServices, products },
      shipment: { state, zipcode },
      orderViolatedPolicies = [],
      isMobile,
      triggerClickEventGA4
    } = this.props;

    const { router } = this.context;

    const areAllProductsUnavailable =
      (zipcode && products.length === this.unavailableProducts().length) ||
      !!orderViolatedPolicies.length;

    const pjWithService = customerPerson === false && hasServices;

    const handleClick = () => {
      this.deleteBasketProducts()
        .then(this.deleteBasketServices)
        .then(() => {
          const nextPage = isLogged() ? routeAddress : routeLogin;
          return router.push(nextPage);
        });
    };

    return (
      <div className="BasketPriceBox">
        <div className="BasketPriceBox-prices">
          <div className="BasketPriceBox-shipment">
            <BasketShipmentContainer />
          </div>

          <div className="BasketPriceBox-prices--values">
            <BasketPrices
              {...totals}
              zipcode={zipcode}
              itemsCount={productCount}
              finalTotal={!!state}
              unavailable={areAllProductsUnavailable}
              products={products}
            />

            <CardLuizaContainer shipment={totals.shipment} />

            {isMobile && (
              <div className="BasketPriceBox-unavaible">
                {(this.unavailableProducts().length > 0 && (
                  <p className="BasketPriceBox-unavailableWarning">
                    Ao clicar em "continuar", os produtos {pjWithService && 'e serviços'}{' '}
                    não disponíveis serão removidos da sua sacola de compras
                  </p>
                )) ||
                  (pjWithService && (
                    <p className="BasketPriceBox-unavailableWarning">
                      Ao clicar em "continuar", os serviços não disponíveis serão
                      removidos da sua sacola de compras
                    </p>
                  ))}
              </div>
            )}

            <BasketContinue
              disabled={areAllProductsUnavailable}
              handleClick={handleClick}
              handleAddClick={(eventName) => triggerClickEventGA4(eventName)}
            />

            {totals && (
              <BasketContinueFloatingContainer
                zipcode={zipcode}
                totals={totals}
                productCount={productCount}
                hasFreight={!!state}
                disabled={areAllProductsUnavailable}
                handleClick={handleClick}
                products={products}
              />
            )}
          </div>
        </div>

        {!isMobile && (
          <div className="BasketPriceBox-unavaible">
            {(this.unavailableProducts().length > 0 && (
              <p className="BasketPriceBox-unavailableWarning">
                Ao clicar em "continuar", os produtos {pjWithService && 'e serviços'} não
                disponíveis serão removidos da sua sacola de compras
              </p>
            )) ||
              (pjWithService && (
                <p className="BasketPriceBox-unavailableWarning">
                  Ao clicar em "continuar", os serviços não disponíveis serão removidos da
                  sua sacola de compras
                </p>
              ))}
          </div>
        )}
      </div>
    );
  }
}

export default Responsive(BasketPriceBoxContainer);
