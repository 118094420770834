import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchInstallmentsSimulate } from 'reducers/installments';

import CardLuiza from 'components/CardLuiza/CardLuiza';

const mapStateToProps = state => ({
  installments: state.installments,
  basket: state.basket
});

const mapDispatchToProps = {
  fetchInstallmentsSimulate
};
export class CardLuizaContainer extends Component {
  componentWillMount() {
    this.props.fetchInstallmentsSimulate('luizacard', this.props.shipment);
  }

  componentWillReceiveProps(nextProps) {
    if (this.shouldUpdateInstallments(nextProps, this.props)) {
      this.props.fetchInstallmentsSimulate('luizacard', nextProps.shipment);
    }
  }

  getInstallments() {
    let maxNoInterest = null;

    if (this.fetchedLuizaInstallments()) {
      const card = this.props.installments.installmentsPerType.luizacard;

      maxNoInterest = this.maxNoInterest(card);
    }

    return maxNoInterest;
  }

  totalsHasChanged(nextProps, props) {
    return (nextProps.basket.basket.totals.cash !== props.basket.basket.totals.cash)
    || (nextProps.basket.totals.totalAmount !== props.basket.totals.totalAmount);
  }

  shouldUpdateInstallments(nextProps, props) {
    return this.totalsHasChanged(nextProps, props);
  }

  fetchedLuizaInstallments() {
    return (
      !!this.props.installments &&
      !!this.props.installments.installmentsPerType &&
      !!this.props.installments.installmentsPerType.luizacard
    );
  }

  maxNoInterest(luizaCard) {
    return luizaCard.reduce((acc, cur) =>
      cur.description.includes('sem juros')
        ? cur
        : acc
    , {});
  }

  render() {
    const maxNoInterest = this.getInstallments();

    return !!maxNoInterest && !!maxNoInterest.numberOfInstallments ? (
      <CardLuiza
        numberOfInstallments={maxNoInterest.numberOfInstallments}
        installmentAmount={maxNoInterest.installmentAmount}
      />
    ) : null;
  }
}

CardLuizaContainer.propTypes = {
  fetchInstallmentsSimulate: PropTypes.func.isRequired,
  installments: PropTypes.object,
  basket: PropTypes.object,
  shipment: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardLuizaContainer);
