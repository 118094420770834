export const FIRST_OTP_KEY = 'temp_id';
export const SECOND_OTP_KEY = 'temp_id_c';

export const setFirstOtpId = (id) => window.sessionStorage.setItem(FIRST_OTP_KEY, id);

export const getFirstOtpId = () => window.sessionStorage.getItem(FIRST_OTP_KEY);

export const setSecondOtpId = (id) => window.sessionStorage.setItem(SECOND_OTP_KEY, id);

export const getSecondOtpId = () => window.sessionStorage.getItem(SECOND_OTP_KEY);

export const resetOtpId = (keys = [FIRST_OTP_KEY, SECOND_OTP_KEY]) =>
  keys.forEach(key => window.sessionStorage.removeItem(key));

