import React from 'react';
import PropTypes from 'prop-types';

import './PaymentInfoLabel.scss';

const PaymentInfoLabel = ({
  field,
  label,
}) => {
  return (field.active || (!field.visited && field.pristine))
    ? (
      <div
        className="PaymentInfoLabel"
      >
        {label}
      </div>
    )
    : null;
};

PaymentInfoLabel.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default PaymentInfoLabel;
