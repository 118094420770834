import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from 'store';

import { setCaptchaCallback } from 'reducers/captcha';
import { metricsError } from 'reducers/metrics';

import './Captcha.scss';

export const validateCaptcha = (field, action) => {
  const state = store.getState();
  const configs = state.channel.configs;

  const useCaptcha = configs.use_captcha ?? true;
  const useCaptchaOnField = configs[`use_captcha_${field}`] ?? true;
  const shouldUseCaptcha = useCaptcha && useCaptchaOnField;

  return new Promise((resolve, reject) => {
    if (!shouldUseCaptcha) {
      store.dispatch(metricsError('Captcha off'));
      return resolve();
    }

    store.dispatch(
      setCaptchaCallback((token) => {
        if (useCaptcha && !token) {
          return reject();
        }
        return resolve(token);
      })
    );

    try {
      window.grecaptcha.enterprise.reset();
      window.grecaptcha.enterprise.execute({
        action: action || field,
      });
    } catch (error) {
      store.dispatch(metricsError('Captcha error', error.message));
    }
  });
};

export class Captcha extends Component {
  componentDidMount() {
    const callbackName = 'onloadCaptchaCallback';
    window.onloadCaptchaCallback = this.renderCaptcha;
    this.appendScript(callbackName);
  }

  captchaURL(callbackName) {
    const recaptchaURL = 'https://www.google.com/recaptcha/enterprise.js';
    const hl = 'hl=pt-BR';
    const onload = `&onload=${callbackName}&render=explicit`;
    return `${recaptchaURL}?${hl}${onload}`;
  }

  appendScript(callbackName) {
    const js = document.createElement('script');
    js.src = this.captchaURL(callbackName);
    js.type = 'text/javascript';
    js.async = true;
    document.head.appendChild(js);
  }

  renderCaptcha = () => {
    const data = {
      sitekey: SETTINGS.ReCaptcha.sitekey,
    };

    data.size = 'invisible';
    data.callback = (token) => {
      if (!token) {
        store.dispatch(metricsError('Captcha no token', token));
      }

      try {
        this.props.captchaCallbackFunction(token);
      } catch (error) {
        store.dispatch(metricsError('Captcha callback error', error.message));
      }
    };

    try {
      window.grecaptcha.enterprise.render('g-recaptcha', data);
    } catch (error) {
      store.dispatch(metricsError('Captcha render error', error.message));
    }
  };

  render() {
    return (
      <div className="Captcha">
        <div id="g-recaptcha"></div>
      </div>
    );
  }
}

const mapStateToProps = ({ captcha }) => ({
  captchaCallbackFunction: captcha.captchaCallbackFunction,
});

export default connect(mapStateToProps)(Captcha);
