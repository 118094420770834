import React from 'react';
import PropTypes from 'prop-types';

import './BasketSoldBy.scss';

const BasketSoldBy = ({ store, unavailable }) => {
  const classNameBasketSoldBy = !unavailable ?
    'BasketSoldBy' :
    'BasketSoldBy--unavailable';

  const classNameBasketSoldByStore = !unavailable ?
    'BasketSoldBy-store' :
    'BasketSoldBy-store--unavailable';

  return (
    <div className={classNameBasketSoldBy}>
    Vendido por{' '}
      <span className={classNameBasketSoldByStore}>{store}</span>
    </div>
  );
};

BasketSoldBy.propTypes = {
  store: PropTypes.string.isRequired,
  unavailable: PropTypes.bool.isRequired,
};

export default BasketSoldBy;
