import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  floatingLabelClassName,
  inputClassName,
  inputGroupClassName,
} from 'utils/classnames';
import { randomString } from 'utils/math';

import Loading from 'components/shared/Loading/Loading';
import InputError from 'components/Form/InputError/InputError';
import InputSuccess from 'components/Form/InputSuccess/InputSuccess';
import Responsive from 'components/Responsive';

import './FormGroup.scss';

export class FormGroup extends Component {
  rand = randomString();

  render() {
    const {
      children,
      labelText = '',
      inputSize,
      activeLabel = '',
      field = {},
      hideError = false,
      forceShowError = false,
      hideSuccess = false,
      forceShowSuccess = false,
      placeholder = '',
      asyncValidating = false,
      isTextArea = false,
      errorClassName = 'FormGroup-errorMessage',
      labelClassName = 'FormGroup-label',
      containerClassName = 'FormGroup',
      modifiedcontainerClassName = 'FormGroup-inputGroup',
      className = !isTextArea && 'FormGroup-input' || 'FormGroup-textarea',
      maxLength,
      handleChange,
      handleBlur,
      handleFocus,
      handleKeyUp,
      autocomplete,
      isMobile,
      type,
      icon,
      newDesignInput,
    } = this.props;

    const modifiedLabelClassName = floatingLabelClassName(
      labelClassName,
      field
    );

    const inputId = field.name ? `input-${field.name}-${this.rand}` : undefined;

    const showError = !field.active && field.touched && !!field.error;

    const ariaId = showError
      ? `aria-${field.name}-${this.rand}`
      : undefined;

    const inputClass = inputClassName(className, showError);
    const inputGroupClass = inputGroupClassName(modifiedcontainerClassName, inputSize);
    const hasIcon = !!icon;

    const InputLabel = () => (
      <label
        className={`${modifiedLabelClassName} ${activeLabel}${hasIcon ? 'FormGroup-label-icon' : ''}`.concat(`${newDesignInput && field.error && field.touched && !field.active ? errorClassName : ''}`)}
        htmlFor={inputId}
      >
        {labelText}
      </label>
    );

    const clearInput = () => {
      field.onChange('');
    };

    const disabled = field.disabled ? 'true' : undefined;

    return (
      <div className={containerClassName}>
        {(!isMobile || newDesignInput) && <InputLabel />}
        <div className={inputGroupClass} >
          {hasIcon && icon}

          {!isTextArea && (
            <input
              id={inputId}
              className={`${inputClass}${hasIcon ? ' FormGroup-input-icon' : ''}`}
              maxLength={maxLength}
              placeholder={placeholder}
              onKeyUp={handleKeyUp}
              autoComplete={autocomplete}
              aria-describedby={ariaId}
              type={field.type || type}
              name={field.name}
              value={field.value}
              disabled={disabled}
              onChange={handleChange ? handleChange : field.onChange}
              onBlur={handleBlur ? handleBlur : field.onBlur}
              onFocus={handleFocus ? handleFocus : field.onFocus}
            ></input>
          ) || (
            <textarea
              id={inputId}
              className={inputClass}
              maxLength={maxLength}
              placeholder={placeholder}
              onKeyUp={handleKeyUp}
              autoComplete={autocomplete}
              aria-describedby={ariaId}
              type={field.type || type}
              name={field.name}
              value={field.value}
              disabled={disabled}
              onChange={handleChange ? handleChange : field.onChange}
              onBlur={handleBlur ? handleBlur : field.onBlur}
              onFocus={handleFocus ? handleFocus : field.onFocus}
            />
          )}
          {newDesignInput && !hideError && field.error && field.touched && !field.active && (
            <div className="FormGroup-error">
              <span className="ti ti-error-outline"/>
              <InputError
                className={errorClassName}
                field={field}
                asyncValidating={asyncValidating}
                errorMessage={forceShowError && field.error || ''}
                id={ariaId}
              />
            </div>
          )}
          {!hideError && !newDesignInput && (
            <InputError
              className={errorClassName}
              field={field}
              asyncValidating={asyncValidating}
              errorMessage={forceShowError && field.error || ''}
              id={ariaId}
            />
          )}
          {newDesignInput && field.value && (
            <span 
              className="ti ti-highlight-off" 
              onClick={clearInput}
            />
          )}
        </div>
        
        {(isMobile && !newDesignInput) && <InputLabel />}

        {children && (
          <div
            className="FormGroup-children"
          >
            {children}
          </div>
        )}

        {!hideSuccess && (
          <div
            className="FormGroup-feedback"
          >
            <InputSuccess
              asyncValidating={asyncValidating}
              field={field}
              forceShow={forceShowSuccess}
            />
            {asyncValidating === field.name &&
              <Loading className="Loading--small" />
            }
          </div>
        )}
      </div>
    );
  }
}

FormGroup.propTypes = {
  children: PropTypes.node,
  labelText: PropTypes.string,
  inputSize: PropTypes.string,
  autocomplete: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    active: PropTypes.bool,
    touched: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    disabled: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  hideError: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  placeholder: PropTypes.string,
  asyncValidating: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  hideSuccess: PropTypes.bool,
  forceShowSuccess: PropTypes.bool,
  forceShowError: PropTypes.bool,
  isTextArea: PropTypes.bool,
  maxLength: PropTypes.number,
  transformOnChange: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  handleKeyUp: PropTypes.func,
  name: PropTypes.string,
  activeLabel: PropTypes.string,
  isMobile: PropTypes.bool,
  size: PropTypes.string,
  icon: PropTypes.node,
};

export default Responsive(FormGroup);
