import React from 'react';

import Modal from 'components/Modal/Modal';

import './InternationalImportTaxModal.scss';

const ModalContent = (props) => {
  return (
    <div className="InternationalImportTaxModal">
      <div className="InternationalImportTaxModal-header">
        <span className="font-lg-bold">{props?.title}</span>
      </div>

      <div className="InternationalImportTaxModal-content">
        <p className="font-sm-regular">
          Tributo federal aplicado a produtos importados
        </p>
      </div>

      <div className="InternationalImportTaxModal-footer">
        <button
          className="InternationalImportTaxModal-btn BtnLarge"
          onClick={props.toggle}>
          Continuar
        </button>
      </div>
    </div>
  );
};

const InternationalImportTaxModal = ({ isOpen, toggle }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Imposto de importação"
      content={ModalContent}
      withoutFooter
      toggle={toggle}
      mobileIsDrawer
    />
  );
};

export default InternationalImportTaxModal;
