import React from 'react';
import PropTypes from 'prop-types';

import { promocodeWithoutDiscount } from 'constants/customer-messages';

import { LoadingWithBackground } from 'components/shared/Loading/Loading';

import './Promocode.scss';

const Promocode = ({
  isFormVisible,
  isLoading,
  hasError,
  onShowClick,
  onCancelClick,
  onSubmitPromocode,
  handleChangePromocodeInput,
  promocodeInput,
  promocode,
  onRemoveClick,
  hasPromocodeDiscount,
  errorMessage,
}) => (
  <div className="Promocode">
    { isLoading && <LoadingWithBackground /> }

    { !!promocode && (
      <div className="Promocode-promocode">
        <p className="Promocode-promocode__text">
          <span>Cupom de desconto: </span>
          <span className="Promocode-promocode__value">{ promocode }</span>
        </p>
        <button
          className="Promocode__link-button Promocode--remove"
          onClick={ onRemoveClick }
          aria-label="Remover cupom de desconto"
          data-ga={`{"category": "Promocode", "action": "Remover", "label": "${promocode}"}`}
        >
          Remover
        </button>

        { !hasPromocodeDiscount && (
          <p className="Promocode__no-discount-message">
            { promocodeWithoutDiscount }
          </p>
        )}
      </div>
    ) || (
      <form
        className={
          hasError
            ? 'Promocode__form Promocode__form--error'
            : 'Promocode__form'
        }
        onSubmit={ onSubmitPromocode }
        method="post"
      >
        <label
          className={
            isFormVisible
              ? 'Promocode__label Promocode__label--disabled'
              : 'Promocode__label'
          }
          onClick={ onShowClick }
          htmlFor="PromocodeInput"
          data-ga={'{"category": "Promocode", "action": "Abrir"}'}
        >
          Inserir cupom de desconto
        </label>
        { isFormVisible && (
          <div className="FormGroup">
            <div className="FormGroup-inputGroup">
              <input
                name="promocode"
                id="PromocodeInput"
                className="inputWithin"
                value={ promocodeInput }
                onChange={ handleChangePromocodeInput }
                maxLength="20"
              />
              <button
                type="submit"
                className="buttonWithin"
                onClick={ onSubmitPromocode }
                aria-label="Inserir cupom de desconto"
                data-ga={`{"category": "Promocode", "action": "Adicionar", "label": "${promocodeInput}"}`}
              >
                OK
              </button>
              <button
                className="Promocode__link-button"
                onClick={ onCancelClick }
                aria-label="Cancelar Cupom"
                data-ga={`{"category": "Promocode", "action": "Cancelar", "label": "${promocodeInput}"}`}
              >
                Cancelar
              </button>
              { hasError && (
                <p className="Promocode__error-message">
                  { errorMessage }
                </p>
              )}
            </div>
          </div>
        )}
      </form>
    )
    }
  </div>
);

Promocode.propTypes = {
  isLoading: PropTypes.bool,
  isFormVisible: PropTypes.bool.isRequired,
  onShowClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  handleChangePromocodeInput: PropTypes.func.isRequired,
  onSubmitPromocode: PropTypes.func.isRequired,
  promocodeInput: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onRemoveClick: PropTypes.func.isRequired,
};

export default Promocode;
