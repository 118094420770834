import React from 'react';
import PropTypes from 'prop-types';

import Responsive from 'components/Responsive';

import PhoneCodeIcon from 'icons/phone-code.svg';
import IconError from 'icons/icon-error-red.svg';

import DeliveryModeButtons from '../DeliveryModeButtons/DeliveryModeButtons';
import './SelectEmailOrSms.scss';

class SelectEmailOrSms extends React.Component {
  state = {
    renderTimeout: null,
  };

  componentDidMount() {
    const { onRender } = this.props;

    if (onRender) {
      const renderTimeout = setTimeout(() => {
        if (this.state.renderTimeout) {
          onRender();
        }
      }, 1000);

      this.setState({ renderTimeout });
    }
  }

  componentWillUnmount() {
    const { onRender } = this.props;

    if (onRender) {
      clearTimeout(this.state.renderTimeout);
    }
  }

  render() {
    const {
      enableEmail,
      enableSms,
      isModal,
      error,
      contacts,
      onCloseOtpModal,
      onSelectDeliveryMode,
    } = this.props;

    return (
      <div className={`SelectEmailOrSms-wrapper${isModal ? '-modal' : ''}`}>
        <PhoneCodeIcon />
        <div className="SelectEmailOrSms-info">
          <span className="SelectEmailOrSms-text-info">
            Selecione um contato para validação de segurança.
          </span>
        </div>

        <DeliveryModeButtons
          enableEmail={enableEmail}
          enableSms={enableSms}
          contacts={contacts}
          onClick={onSelectDeliveryMode}
        />

        {isModal && (
          <button
            className="SelectEmailOrSms-outlined-btn"
            onClick={() => onCloseOtpModal()}>
            Editar meus dados
          </button>
        )}
        {(error.statusCode === 422 || error.statusCode > 409) && isModal && (
          <div className="SelectEmailOrSms-wrapper-error">
            <IconError width="24" height="24" />
            <span>{error.message}</span>
          </div>
        )}
      </div>
    );
  }
}

SelectEmailOrSms.propTypes = {
  enableEmail: PropTypes.bool.isRequired,
  enableSms: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  onRender: PropTypes.func,
  onSelectDeliveryMode: PropTypes.func.isRequired,
  onCloseOtpModal: PropTypes.func,
  contacts: PropTypes.shape({
    email: PropTypes.string,
    numberPhone: PropTypes.string,
  }).isRequired,
};

export default Responsive(SelectEmailOrSms);
