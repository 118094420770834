import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { maxItemsBasketError } from 'constants/errors';

import * as basketActions from 'reducers/basket';
import * as bobbyActions from 'reducers/bobby';
import * as failActions from 'reducers/fail';
import * as metricsActions from 'reducers/metrics';
import * as recommendationActions from 'reducers/recommendation';
import * as customerMessages from 'constants/customer-messages';

import { pingBobby } from 'api/bobby-api';

import BobbyCarouselCompact from '../components/Bobby/BobbyCarouselCompact';

const { object, array, func, string } = PropTypes;

const cartScreenName = 'carrinho';

@connect(
  ({
    bobby,
    basket,
    recommendation,
  }) => ({
    ...bobby,
    ...basket,
    ...recommendation,
  }),
  {
    ...basketActions,
    ...bobbyActions,
    ...failActions,
    ...metricsActions,
    ...recommendationActions,
  }
)
export class BobbyCartDetailsContainer extends Component {
  static propTypes = {
    basket: object.isRequired,
    cartDetails: array,
    addProduct: func.isRequired,
    removeBobbyProduct: func.isRequired,
    fetchBasket: func.isRequired,
    fail: func.isRequired,
    productListView: func.isRequired,
    metricsError: func.isRequired,
    addToCart: func.isRequired,
    addToCartGA4: func.isRequired,
    pageId: string.isRequired,
    placementId: string.isRequired,
  };

  render() {
    const {
      cartDetails = [],
      addProduct,
      removeBobbyProduct,
      fetchBasket,
      fail,
      basket,
      productListView,
      metricsError,
      addToCart,
      addToCartGA4,
      productClick,
      selectItem,
      recommendations,
      pageId,
      placementId,
      viewItemList
    } = this.props;
    const productIds = basket.products.map(({ id }) => id);
    const filteredProducts = cartDetails.filter(item => {
      return !productIds.includes(item.stewieProductId);
    });

    if (!recommendations.isEmpty()) {
      return (
        <div>
          {recommendations.getShowcases(pageId, placementId).map((showcase) => {
            return !isEmpty(showcase.products) ? (
              <BobbyCarouselCompact
                key={showcase.id}
                showcaseTitle={showcase.title}
                products={showcase.products}
                onProductImpression={(products, first, last) => {
                  productListView(products.map((product, index) => ({...product, showcase, pageId, placementId, position: ++index, screnName: cartScreenName})), first, last);
                  viewItemList(products.map((product, index) => ({...product, showcase, pageId, placementId, position: ++index, screnName: cartScreenName})), first, last);
                }}
                handleLinkClick={(event, product, index) => {
                  event.preventDefault();
                  productClick({...product, showcase, pageId, placementId, screenName: 'carrinho'}, index + 1);
                  selectItem( {...product, showcase, pageId, placementId, screenName: 'carrinho' }, index + 1);
                }}
                handleAddClick={function handleAddClick(
                  storeId,
                  itemId,
                  link,
                  product,
                  index
                ) {
                  return () => {
                    pingBobby(link);
                    addToCart(itemId, product);
                    addToCartGA4(itemId, product, index);
                    addProduct(storeId, itemId)
                      .then(() => {
                        removeBobbyProduct(itemId);
                        return fetchBasket();
                      })
                      .catch((err = {}) => {
                        const json = err.response ? err.response.body : {};
                        if (
                          json &&
                          json.error_message &&
                          json.error_message.error_message ===
                            maxItemsBasketError
                        ) {
                          fail(customerMessages.maxItemsBasket);
                        }
                        metricsError('recomendacao', json.error_message);
                      });
                  };
                }}
              />
            ) : null;
          })}
        </div>
      );
    }

    return !isEmpty(filteredProducts) ? (
      <BobbyCarouselCompact
        products={filteredProducts}
        onProductImpression={
          (products, first, last) => {
            productListView(products.map((product) => ({...product, screnName: cartScreenName})), first, last);
            viewItemList(products.map((product) => ({...product, screnName: cartScreenName})), first, last);
          }}
        handleLinkClick={(event, product, index) => {
          event.preventDefault();
          productClick({...product, screenName: 'carrinho'}, index + 1);
          selectItem({ ...product, screenName: 'carrinho' }, index + 1);
        }}
        handleAddClick={function handleAddClick(storeId, itemId, link, product) {
          return () => {
            pingBobby(link);
            addToCart(itemId, product);
            addToCartGA4(itemId, product);
            addProduct(storeId, itemId)
              .then(() => {
                removeBobbyProduct(itemId);
                return fetchBasket();
              })
              .catch((err = {}) => {
                const json = err.response
                  ? err.response.body
                  : {};
                if (
                  json
                  && json.error_message
                  && json.error_message.error_message === maxItemsBasketError
                ) {
                  fail(customerMessages.maxItemsBasket);
                }
                metricsError('recomendacao', json.error_message);
              });
          };
        }}
      />
    ) : null;
  }
}
