import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MastercardIcon from 'icons/payment/icon-mastercard.svg';
import VisaIcon from 'icons/payment/icon-visa.svg';
import AmexIcon from 'icons/payment/icon-american-express.svg';
import DinersIcon from 'icons/payment/icon-dinersclub.svg';
import HipercardIcon from 'icons/payment/icon-hipercard.svg';
import EloIcon from 'icons/payment/icon-elo.svg';
import AuraIcon from 'icons/payment/icon-aura.svg';
import MLCardSilverIcon from 'icons/payment/icon-cartao-luiza-silver';
import MLCardGoldIcon from 'icons/payment/icon-cartao-luiza-gold';
import MLCardIcon from 'icons/payment/icon-cartao-magalu.svg';
import CardIcon from 'icons/payment/icon-credit-card.svg';
import * as cardFlags from 'constants/card-flags';

import './CardFlag.scss';

const { string } = PropTypes;

export default class CardFlag extends Component {
  static propTypes = {
    flag: string.isRequired,
    className: string
  };

  flags = {
    [cardFlags.mastercard]: MastercardIcon,
    [cardFlags.visa]: VisaIcon,
    [cardFlags.amex]: AmexIcon,
    [cardFlags.diners]: DinersIcon,
    [cardFlags.hipercard]: HipercardIcon,
    [cardFlags.elo]: EloIcon,
    [cardFlags.aura]: AuraIcon,
    [cardFlags.magazine]: MLCardSilverIcon,
    [cardFlags.magazineGold]: MLCardGoldIcon,
    [cardFlags.magaluCard]: MLCardIcon,
    [cardFlags.unknown]: CardIcon
  };

  render() {
    const { flag, className = '' } = this.props;
    const Icon = this.flags[flag] || this.flags[cardFlags.unknown];

    return <Icon className={className || 'CardFlag'} />;
  }
}
