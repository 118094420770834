import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';

import { pixDescription, cardDescription } from 'constants/price-descriptions';

const OrderReviewTotal = ({ to, cash, amountDescription, checkoutAmountDescription }) => (
  <div className="OrderReviewTotals-total">
    <span className="OrderReviewTotals-left">Total</span>
    <span className="OrderReviewTotals-right">
      <div className="OrderReviewTotal">
        <span className="OrderReviewTotal__to">
          {toBRL(to) } {amountDescription || cardDescription}
          <br/> ou {''}
        </span>
        <span className="OrderReviewTotal__cash">
          {toBRL(cash)} {checkoutAmountDescription || pixDescription}
        </span>
      </div>
    </span>
  </div>
);

OrderReviewTotal.propTypes = {
  to: PropTypes.string.isRequired,
  cash: PropTypes.string.isRequired,
  amountDescription: PropTypes.string,
  checkoutAmountDescription: PropTypes.string,
};

export default OrderReviewTotal;
