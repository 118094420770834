import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalAdjustable from 'components/ModalAdjustable';
import MobileModal from 'components/MobileModal/MobileModal';
import PaymentFail from 'components/Payment/PaymentFail/PaymentFail';
import Responsive from 'components/Responsive';


export class PaymentModalFail extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleRequestClose: PropTypes.func,
    success: PropTypes.bool,
    errorMessage: PropTypes.string,
    handleRetryClick: PropTypes.func.isRequired,
    handleButtonClick: PropTypes.func.isRequired,
    hasMarketplaceItems: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    enablePhoneSales: PropTypes.bool.isRequired,
  };

  render() {
    const {
      isOpen,
      success,
      handleRetryClick,
      handleRequestClose = f => f,
      handleButtonClick,
      hasMarketplaceItems,
      isMobile,
      enablePhoneSales,
    } = this.props;

    return (
      <div>
        { isOpen && (
          <div>
            { isMobile
              ?
              <MobileModal
                handleClose={handleRequestClose}
              >
                <PaymentFail
                  success={success}
                  handleRetryClick={handleRetryClick}
                  handleButtonClick={handleButtonClick}
                  hasMarketplaceItems={hasMarketplaceItems}
                  enablePhoneSales={enablePhoneSales}
                />
              </MobileModal>
              :
              <ModalAdjustable
                isOpen={isOpen}
                onRequestClose={handleRequestClose}
                heightValue="400px"
              >
                <PaymentFail
                  success={success}
                  handleRetryClick={handleRetryClick}
                  handleButtonClick={handleButtonClick}
                  hasMarketplaceItems={hasMarketplaceItems}
                  enablePhoneSales={enablePhoneSales}
                />
              </ModalAdjustable>
            }
          </div>
        )}
      </div>
    );
  }
}

export const PaymentFailModal = Responsive(PaymentModalFail);
