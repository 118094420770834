import { detectMobile } from 'utils/responsive';

const buildPrefixAndPlacement = (place) => {
  const prefix = detectMobile() ? 'sm' : 'sd';
  const placement = detectMobile() ? place.replace('top', 'bottom') : place;

  return [prefix, placement];
};

const isMobile = (place) => {
  const [prefix, placement] = buildPrefixAndPlacement(place);

  return `${prefix}${placement}`;
};

export const showcase = {
  checkout: {
    page_id: 'checkout',
    placement_ctop: 'ctop',
  },
  sacola: {
    page_id: isMobile('sacola'),
    placement_sacolatop: isMobile('sacolatop'), //                           Sacola com estoque
    placement_sacolabottom: isMobile('sacolabottom'), //                     Sacola com estoque
    placement_sacolasemestoquetop: isMobile('sacolasemestoquetop'), //       Sacola sem estoque
    placement_sacolasemestoquebottom: isMobile('sacolasemestoquebottom'), // Sacola sem estoque
    placement_sacolavaziabottom: isMobile('sacolavaziabottom'), //           Sacola vazia
  },
  conclusao: {
    page_id: isMobile('conclusao'), //                                       Conclusao / ConfirmOrder
    placement_conclusaobottom: isMobile('conclusaobottom'),
  }
};
