import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  addLeadingZero as addLeadingZeroMasker,
  reviewDeliveryLabel as reviewDeliveryLabelMasker,
} from 'utils/maskers';

import './ShowDeliveryTime.scss';

export default class ShowDeliveryTime extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    packagesCount: PropTypes.number.isRequired,
    store: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired,
    deliveryType: PropTypes.string,
    scheduledParameters: PropTypes.object,
    description: PropTypes.string,
  };

  render() {
    const {
      index,
      packagesCount,
      store,
      time,
      deliveryType,
      scheduledParameters,
      description,
    } = this.props;

    const deliveryCountInfo = (packagesCount > 1)
      ? `${addLeadingZeroMasker(index)} de ${addLeadingZeroMasker(packagesCount)} `
      : ' ';

    const titleClassName = (packagesCount > 1)
      ? ' ShowDeliveryTime-title--multiple_deliveries'
      : '';

    return (
      <p>
        <span
          className={`ShowDeliveryTime-title${titleClassName}`}
        >
          Entrega {deliveryCountInfo}
        </span>
          por {store} - {
          description ||
          reviewDeliveryLabelMasker({ deliveryType, time, scheduledParameters })
        }
      </p>
    );
  }
}
