import React, { Component } from 'react';
import PropTypes from 'prop-types';


export default class PaymentFailModalSubmitButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    handleButtonClick: PropTypes.func.isRequired
  };

  render() {
    const {
      disabled = false,
      handleButtonClick
    } = this.props;

    return (
      <button
        className="PaymentFailModal-continue"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          handleButtonClick();
        }}
      >
        Receber uma ligação
      </button>
    );
  }
}
