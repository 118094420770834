import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { googleAccountsUrl } from 'constants/external-urls';
import { loadJsAsync } from 'utils/load';

export class GoogleOneTap extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired
  };

  componentWillMount() {
    loadJsAsync( googleAccountsUrl, this.initializeOneTap );
  }

  componentWillUnmount() {
    try {
      window.google.accounts.id.cancel();
    } catch (error) {
      return;
    }
  }

  initializeOneTap = () => {
    const callback = this.props.handleOneTapCredential;

    try {
      window.google.accounts.id.initialize({
        client_id: this.props.googleClientID,
        callback,
      });

      window.google.accounts.id.prompt();
    } catch (error) {
      return error;
    }
  }

  render() {
    return <div id="GoogleOneTap" />;
  }
}

GoogleOneTap.propTypes = {
  googleClientID: PropTypes.string.isRequired,
  handleOneTapCredential: PropTypes.func.isRequired,
};

export default GoogleOneTap;
