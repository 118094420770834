import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import InputRadioButton from 'components/Form/InputRadioButton/InputRadioButton';
import CheckboxGroup from 'components/Form/CheckboxGroup/CheckboxGroup';

import './PaymentBox.scss';

export default class PaymentBoxLine extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    children: PropTypes.object,
    icon: PropTypes.object.isRequired,
    labelText: PropTypes.string.isRequired,
    description: PropTypes.string,
    descriptionClassName: PropTypes.string,
    handleTypeChangeClick: PropTypes.func.isRequired,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    labelTextSpecialMessage: PropTypes.string
  };

  specialMessagePaymentBox = (labelTextSpecialMessage) => {
    return (
      <span className="PaymentBox__special-message">
        {`${labelTextSpecialMessage}`}
      </span>
    );
  }

  render() {
    const {
      id,
      value,
      checked,
      children,
      icon,
      labelText,
      description,
      labelTextSpecialMessage = null,
      descriptionClassName,
      handleTypeChangeClick,
      type = 'radio',
      disabled = false
    } = this.props;

    return (
      <li className={`${disabled ? 'PaymentBox-line-disabled' : 'PaymentBox-line'}`}>
        <label className="PaymentBox-line-label" htmlFor={id}>
          { type === 'radio' ?
            <span>
              <InputRadioButton
                checkedValue={checked}
              />
              <input
                type={type}
                id={id}
                name="payment-type"
                value={value}
                checked={checked}
                onChange={handleTypeChangeClick}
                disabled={disabled}
                className="InputRadioButton"
              />
            </span>
            :
            <CheckboxGroup
              id={id}
              field={
                {
                  value: checked,
                  onChange: handleTypeChangeClick,
                  name: 'payment-type',
                  disabled,
                }
              }
            />
          }
          {icon}{labelText}

          {labelTextSpecialMessage !== null
            ? this.specialMessagePaymentBox(labelTextSpecialMessage)
            : ''
          }

          {description &&
            <div className={`PaymentBox-line-info-label ${descriptionClassName}`}>
              {description}
            </div>
          }
        </label>
        {children}
      </li>
    );
  }
}
