import React from 'react';
import { formatPoliciesByProduct } from 'utils/formatPoliciesByProduct';
import BasketPolicyViolationWarning from '../Warnings/BasketPolicyViolationWarning';

const BasketPolicyViolation = ({policies, product}) => {
  const productPolicies = formatPoliciesByProduct(policies, product.id);
  return (
    <div>
      {productPolicies.map(violationRule => (
        <BasketPolicyViolationWarning
          key={`${violationRule.rule}-${violationRule.sku}`}
          product={product}
          violatedRule={violationRule}
        />
      ))}
    </div>
  );
};

export default BasketPolicyViolation;
