import React from 'react';
import PropTypes from 'prop-types';

import {
  itemNotAllowedForCompanies,
  checkSimilar
} from 'constants/customer-messages';

const BasketItemNotAllowedForCompaniesWarning = ({
  subcategoryUrl,
  id,
}) => {
  const dataGA = JSON.stringify({
    'category': 'Produto Indisponível para Empresas',
    'action': id,
    'label': 'Confira Similares',
  });

  return (
    <div className="BasketItem-unavailableWarning">
      {itemNotAllowedForCompanies} <a
        className="BasketItem-unavailableWarning-link"
        href={`${subcategoryUrl}`}
        data-ga={dataGA}
      >
        {checkSimilar}
      </a>
    </div>
  );
};

BasketItemNotAllowedForCompaniesWarning.propTypes = {
  subcategoryUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default BasketItemNotAllowedForCompaniesWarning;
