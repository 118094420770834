import { checkoutUrl } from 'constants/api-urls';

import request, { ping } from 'utils/request';

import {
  trends as trendsModel,
  stwProductList,
} from 'api/models/bobby-models';


export const pingBobby = (link) => ping(link);

export const getCartDetails = (slot, productIds) => {
  return request('GET', `${checkoutUrl}/stewie/cart/`)
    .query({
      product_id: productIds,
      slot
    })
    .then(res => res.body)
    .then(json => stwProductList(json))
    .catch(() => []);
};

export const getTrends = (slot) => {
  return request('GET', `${checkoutUrl}/stewie/trends/`)
    .query({ slot })
    .then(res => res.body)
    .then(json => trendsModel(json));
};

export const getSimilar = (slot, productId) => {
  return request('GET', `${checkoutUrl}/stewie/similar/${productId}/`)
    .query({
      slot
    })
    .then(res => res.body)
    .then(json => stwProductList(json))
    .catch(() => []);
};
