import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeftArrow from 'icons/bobby/icon-arrow-left.svg';

class PrevArrow extends Component {
  render() {
    const disabledClass = /slick-disabled/.test(this.props.className)
      ? '--disabled' : '';

    return (
      <LeftArrow
        {...this.props}
        className={`${this.props.className} BobbyArrow-left${disabledClass}`}
      />
    );
  }
}

PrevArrow.propTypes = {
  className: PropTypes.string
};

export default PrevArrow;
