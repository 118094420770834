import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Raffle.scss';

class Raffle extends Component {

  componentDidMount() {
    const raffleEl = document.getElementById('raffle');
    const script = document.createElement('script');
    if (raffleEl && raffleEl.dataset.src) {
      script.src = raffleEl.dataset.src;
      document.getElementsByTagName('body')[0].appendChild(script);
    }
  }

  render() {
    return (
      <div className="Raffle"
        dangerouslySetInnerHTML={{ __html: this.props.content }}
      />
    );
  }
}

Raffle.propTypes = {
  content: PropTypes.string,
};

export default Raffle;
