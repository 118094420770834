import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as routeNames from 'constants/route-names';

import { isLogged } from 'utils/session';
import { redirectToNextUrl } from 'utils/redirect';

import * as loadingActions from 'reducers/loading';
import * as basketActions from 'reducers/basket';
import * as loginActions from 'reducers/login';

import Responsive from 'components/Responsive';
import { GenericFooter } from 'components/Footer/GenericFooter/GenericFooter';
import LoginFooterInfo from 'components/Login/LoginFooterInfo';

import LoginBoxContainer from 'containers/Login/LoginBoxContainer';
import SignupBoxContainer from 'containers/Login/SignupBoxContainer';
import SocialLoginBoxContainer from 'containers/SocialLoginBoxContainer';

import './LoginPage.scss';

const { object, bool, func } = PropTypes;

const signupAvailableLoadingID = 'signupAvailableLoading';

const mapStateToProps = ({
  signupAvailable,
  channel,
}) => ({
  signupAvailableRequesting: signupAvailable.isRequesting,
  useSocialLogin: channel.configs.use_social_login ?? true,
});

const mapDispatchToActions = {
  ...loadingActions,
  fetchBasketForGTM: basketActions.fetchBasketForGTM,
  loginPageview: loginActions.loginPageview,
  loginCheckoutProgress: loginActions.loginCheckoutProgress,
};

@connect(mapStateToProps, mapDispatchToActions)
class LoginPageComponent extends Component {
  static propTypes = {
    location: object.isRequired,
    signupAvailableRequesting: bool.isRequired,
    toggleLoading: func.isRequired,
    isMobile: bool,
    fetchBasketForGTM: func.isRequired,
    loginPageview: func.isRequired,
    loginCheckoutProgress: func.isRequired,
  };

  static contextTypes = {
    router: object.isRequired
  };

  componentWillMount() {
    const {
      location: { query: { origin, next, ...nextQuery } },
      fetchBasketForGTM,
      loginPageview,
      loginCheckoutProgress,
    } = this.props;
    const { router } = this.context;
    const nextUrl = next || routeNames.address;

    if (isLogged()) {
      return redirectToNextUrl(router.replace, { next: nextUrl, origin, nextQuery });
    }

    fetchBasketForGTM()
      .then(loginPageview)
      .then(loginCheckoutProgress)
      .catch(() => { })
      .finally(loginPageview);
  }

  componentWillUnmount() {
    const {
      toggleLoading
    } = this.props;

    toggleLoading(signupAvailableLoadingID, false);
  }

  componentWillReceiveProps(nextProps) {
    const {
      signupAvailableRequesting,
      toggleLoading
    } = nextProps;

    toggleLoading(signupAvailableLoadingID, signupAvailableRequesting);
  }

  render() {
    const {
      location,
      isMobile,
      useSocialLogin,
    } = this.props;

    return (
      <div className="LoginPage">
        <div className="LoginPage-title">Identificação</div>
        <div className="LoginPage-mainContainer">
          {(isMobile && useSocialLogin) &&
            <SocialLoginBoxContainer
              location={location}
            />
          }
          <div className="LoginPage-leftContainer">
            <SignupBoxContainer
              location={location}
            />
          </div>
          <div className="LoginPage-rightContainer">
            <LoginBoxContainer
              location={location}
            />
          </div>
        </div>
        <div className="LoginPage-socialContainer">
          {(!isMobile && useSocialLogin) &&
            <SocialLoginBoxContainer
              location={location}
            />
          }
          {useSocialLogin &&
            <GenericFooter>
              <LoginFooterInfo />
            </GenericFooter>
          }
        </div>
      </div>
    );
  }
}

const LoginPage = Responsive(LoginPageComponent);
export default LoginPage;
