import React from 'react';
import PropTypes from 'prop-types';

import Success from 'icons/icon-success';

import './CheckboxGroup.scss';

export default function CheckboxGroup(props) {
  const CheckboxGroupIdClass = props.id || `CheckboxGroup-${props.field.name}`;
  const CheckboxGroupClass = props.field.value
    ? 'CheckboxGroup--state-checked'
    : 'CheckboxGroup--state-unchecked';
  const CheckboxGroupIconClass = props.field.value
    ? 'checked--icon'
    : 'unchecked--icon';

  return (
    <div className="CheckboxGroup">
      <label htmlFor={CheckboxGroupIdClass}>
        <input
          className="CheckboxGroup-input"
          type="checkbox"
          id={CheckboxGroupIdClass}
          checked={props.field.value}
          value={props.field.name}
          name={props.field.name}
          onChange={props.field.onChange}
          onBlur={props.field.onBlur}
          onFocus={props.field.onFocus}
          disabled={props.field.disabled}
        />
        <span className={CheckboxGroupClass}>
          <Success className={CheckboxGroupIconClass} />
        </span>
        {props.labelText &&
          <span className="CheckboxGroup-labelText" dangerouslySetInnerHTML={{ __html: props.labelText }}/>
        }
        {props.children &&
          <span className="CheckboxGroup-labelText">
            {props.children}
          </span>
        }
      </label>
    </div>
  );
}

CheckboxGroup.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  labelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

CheckboxGroup.defaultProps = {
  field: {
    value: undefined,
  },
  labelText: undefined,
};
