import { loadJsAsync } from 'utils/load';
import { postSmartLock } from 'api/customer-api';


export function initSmartlock(
  smartLockEnabled,
  smartLockClientId,
  receiveProfile,
  metricsError,
  showLoading,
  hideLoading,
) {
  return new Promise((resolve, reject) => {
    if (smartLockEnabled) {
      loadJsAsync('https://smartlock.google.com/client');
      window.onGoogleYoloLoad = () => {
        const retrievePromise = googleyolo.hint(
          yoloConfigs(smartLockClientId)
        );
        retrievePromise.then((credential) => {
          showLoading('submitLoginSmartlock');
          if (credential.password) {
            return resolve(credential);
          }

          postSmartLock(credential.idToken)
            .then(customerSL => {
              if (customerSL.error_message) {
                if (credential.id) {
                  receiveProfile({
                    email: credential.id
                  });
                }
                return reject(customerSL.error_message);
              }

              metricsError('login smartlock', customerSL.error_message);

              hideLoading('submitLoginSmartlock');
              return resolve(credential);
            }).catch(error => {
              hideLoading('submitLoginSmartlock');
              return reject(error);
            });
        }, (error) => {
          hideLoading('submitLoginSmartlock');
          reject(error);
        });
      };
    }
  });
}

export const yoloConfigs = (smartLockClientId) => (
  {
    supportedAuthMethods: [
      'https://accounts.google.com',
      'googleyolo://id-and-password'
    ],
    supportedIdTokenProviders: [
      {
        uri: 'https://accounts.google.com',
        clientId: smartLockClientId
      }
    ]
  }
);
