import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input/Input';
import { correiosUrl } from 'constants/external-urls';
import { addressSelectContent } from 'utils/data-layer/helpers';

const { oneOfType, array, func, string, object, bool } = PropTypes;

export default class NewAddressForm extends Component {
  static propTypes = {
    children: oneOfType([object, array]),
    zipcode: object.isRequired,
    address: object.isRequired,
    telephone: object,
    homePhone: object,
    comercialPhone: object,
    mobilePhone: object,
    receiver: object,
    number: object.isRequired,
    complement: object.isRequired,
    neighbourhood: object.isRequired,
    city: object.isRequired,
    state: object.isRequired,
    reference: object.isRequired,
    handleZipcodeSubmit: func.isRequired,
    title: string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showWarning: false,
      zipCodeSuccefully: props.formName === 'editAddress',
      zipCodeAddress: props.zipcode.value,
    };
    
    this.handleZipcodeOnBlur = this.handleZipcodeOnBlur.bind(this);
    this.handleDontKnow = this.handleDontKnow.bind(this);
    this.handleChangeDefaultAddress = this.handleChangeDefaultAddress.bind(this);
    this.handleChangeCustomerAddress = this.handleChangeCustomerAddress.bind(this);
  }

  handleZipcodeOnBlur(event) {
    event.preventDefault();
    const { zipCodeAddress } = this.state;
    const { handleZipcodeSubmit, address, neighbourhood, city, state, reference, number, complement, zipcode, noNumber } = this.props;

    if (zipCodeAddress && zipcode?.value === zipCodeAddress) {
      return;
    }
    
    this.setState({ showWarning: false, zipCodeSuccefully: false });

    this.setState({ zipCodeAddress: zipcode.value });

    const fieldsToUpdate = [
      address,
      neighbourhood,
      city,
      state,
      reference,
      number,
      complement,
    ];
    fieldsToUpdate.forEach((field) => field.onChange(''));
    noNumber.onChange(false);

    if (zipcode.error && zipcode.error !== 'CEP não encontrado!') {
      return;
    }

    handleZipcodeSubmit(event).then((res) => {
      this.setState({ zipCodeSuccefully: true });
      if (res.address) {
        return;
      }

      this.setState({ showWarning: true });
    });
  }

  handleDontKnow() {
    addressSelectContent({
      contentId: 'nao-sei-meu-cep',
      contentType: 'lista-de-enderecos',
    });
    
    window.open(correiosUrl, '_blank', 'noopener noreferrer');
  }

  handleDontKnowKeyDown(event) {
    if (event.key === 'Enter') {
      this.handleDontKnow();
    }
  }
  
  handleChangeDefaultAddress(event) {
    const checked = event.target.checked ? 'marcado' : 'desmarcado';

    addressSelectContent({
      contentId: `tornar-padrao-${checked}`,
      contentType: 'lista-de-enderecos',
    });

    this.props.isDefault?.onChange(event);
  }

  handleChangeCustomerAddress(event) {
    const checked = event.target.checked ? 'marcado' : 'desmarcado';

    addressSelectContent({
      contentId: `adicionar-endereco-ao-cadastro-${checked}`,
      contentType: 'lista-de-enderecos',
    });

    this.props.setCustomerAddress?.onChange(event);
  }

  getDisabledField = (field, editable) => {
    const { zipcode } = this.props;
    const { zipCodeSuccefully, showWarning } = this.state;    

    const zipcodeWithoutFieldInformation = showWarning && field.noInitialValue;
  
    const disabledFields = (!zipCodeSuccefully || (zipcode.error || !zipcode.value));
    return disabledFields || (!editable && !zipcodeWithoutFieldInformation && field.value);
  }

  render() {
    const {
      children,
      receiver,
      number,
      zipcode,
      complement,
      telephone,
      address,
      neighbourhood,
      city,
      state,
      reference,
      noNumber,
      isDefault,
      isDefaultAddress,
      setCustomerAddress,
      hasCustomerAddress,
      title,
    } = this.props;

    const { showWarning, zipCodeSuccefully } = this.state;

    const noKnowDataGa =
      '{"category": "Endereco", "action": "CEP", "label": "Não sabe CEP"}';

    return (
      <div className="flex flex-col max-w-[560px]">
        <div className="Form-title flex flex-col">
          <p className="font-md-bold text-on-surface-4">{title}</p>
          <div className="border-t-thin my-md border-surface-container-mid" />
        </div>
        <div className="flex flex-col" style={{ gap: '8px' }}>
          <p className="font-md-bold text-on-surface-4">Quem irá receber o seu pedido</p>
          <Input
            label="Nome Completo"
            {...receiver}
          />
          <Input
            label="Telefone"
            type="tel"
            placeholder="(DD) X-XXXX-XXXX"
            {...telephone}
          />
          <p className="font-md-bold text-on-surface-4">Complete as informações do CEP</p>
          <div className="flex items-center gap-xlg">
            <Input
              label="CEP"
              type="tel"
              className="flex-[2]"
              {...zipcode}
              onBlur={this.handleZipcodeOnBlur}
            />
            <a
              role="button"
              tabIndex={0}
              className="cursor-pointer btn btn-invisible flex-[3] pt-[18px]"
              onClick={this.handleDontKnow}
              onKeyDown={this.handleDontKnowKeyDown}
              data-ga={noKnowDataGa}
            >
              Não sei o CEP
            </a>
          </div>
          {showWarning && (
            <div
              className="bg-yellow-00 rounded-md box-border border-solid border-thin px-sm py-xsm 
                bg-warning-lighter-inverted border-on-warning-lighter-inverted w-full mb-sm"
            >
              <span className="w-full font-2xsm-regular" data-testid="cep-warning">
                O CEP não retornou todos os campos de endereço. Preencha atentamente!
              </span>
            </div>
          )}
          <Input
            label="Endereço"
            {...address}
            disabled={this.getDisabledField(address) || address.disabled}
          />
          <div className="flex items-center gap-xlg">
            <Input
              label="Número"
              type="tel"
              placeholder="Ex.: 01"
              className="flex-[2]"
              {...number}
              disabled={this.getDisabledField(number, true) || noNumber.checked || number.disabled}
              value={noNumber.checked ? '' : number.value}
            />
            <div className="pt-[18px] flex-[3]">
              <label className="checkbox-group w-full">
                <input
                  id="noNumber"
                  type="checkbox"
                  className="checkbox"
                  {...noNumber}
                  disabled={this.getDisabledField(noNumber, true) || noNumber.disabled}
                />
                <span className="ripple" />
                <i className="mark" />
                <span className="title w-full">Sem número</span>
              </label>
            </div>
          </div>
          <Input
            label="Complemento"
            placeholder="Apartamento, casa, lote, etc..."
            {...complement}
            disabled={this.getDisabledField(complement, true) || complement.disabled}
          />
          <Input
            label="Bairro"
            {...neighbourhood}
            disabled={this.getDisabledField(neighbourhood) || neighbourhood.disabled}
          />
          <Input
            label="Cidade"
            {...city}
            disabled={this.getDisabledField(city) || city.disabled}
          />
          <Input
            label="Estado"
            {...state}
            disabled={this.getDisabledField(state) || state.disabled}
          />
          <Input
            label="Ponto de Referência"
            placeholder="Ex.: Próximo ao Museu do Ipiranga"
            {...reference}
            disabled={this.getDisabledField(reference, true) || reference.disabled}
          />
          {!hasCustomerAddress &&
            <div className="flex justify-between gap-lg">
              <label htmlFor="setCustomerAddress" className="font-sm-regular text-wrap">Adicionar este endereço ao meu cadastro</label>
              <div className="switch-group">
                <input className="switch" type="checkbox" {...setCustomerAddress} onChange={this.handleChangeCustomerAddress} id="setCustomerAddress" />
                <label className="switch-toggle" htmlFor="setCustomerAddress"></label>
              </div>
            </div>
          }
          {!isDefaultAddress &&
            <div className="flex justify-between gap-lg">
              <label htmlFor="isDefault" className="font-sm-regular text-wrap">Definir como endereço padrão para próximas entregas</label>
              <div className="switch-group">
                <input className="switch" type="checkbox" {...isDefault} onChange={this.handleChangeDefaultAddress} id="isDefault" />
                <label className="switch-toggle" htmlFor="isDefault"></label>
              </div>
            </div>
          }
        </div>
        <div>
          {children}
        </div>
      </div>
    );
  }
}
