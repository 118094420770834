import React, { Component } from 'react';
import PropTypes from 'prop-types';
export class ExternalHTMLComponent extends Component {
  static propTypes = {
    externalHTML: PropTypes.string.isRequired,
  };

  componentWillReceiveProps(props) {
    if (props.externalHTML !== this.props.externalHTML){
      const slotHtml = document.createRange().createContextualFragment(props.externalHTML);
      this.divRef.innerHTML = '';
      this.divRef.appendChild(slotHtml);
    }
  }

  render() {
    return <div ref={(div) => { this.divRef = div; }}/>;
  }
}

export default ExternalHTMLComponent;
