import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { syncHistory } from 'redux-simple-router';

import gtmMiddleware from './gtm-middleware';
import dataLayerMiddleware from './data-layer-middleware';
import reducers from 'reducers';

export default function configureStore(history, initialState) {
  const middleware = [
    thunk,
    gtmMiddleware,
    dataLayerMiddleware,
  ];

  if(history) {
    middleware.push(syncHistory(history));
  }

  const finalCreateStore = compose(
    applyMiddleware(...middleware),
    process.env.NODE_ENV !== 'production' && window.devToolsExtension ? window.devToolsExtension() : f => f
  )(createStore);
  const store = finalCreateStore(reducers, initialState);

  // Just for HMR
  if (module.hot) {
    module.hot.accept('reducers', () => {
      store.replaceReducer(require('reducers'));
    });
  }

  return store;
}
