import { removeSpecialChars } from './value-cleaners';

const removeCardDataSpecialChars = data => {
  const specialCharPerKey = {
    card_holder_name: ' ',
    card_exp_date: '/',
  };

  return Object.entries(data).reduce((parsedData, [dataKey, dataValue]) => ({
    ...parsedData,
    [dataKey]: removeSpecialChars(dataValue, specialCharPerKey[dataKey] || '').trim()
  }), {});
};

export function encrypt(rawCreditCardData) {
  const TokenExPublicKey = SETTINGS.TokenEx.key;

  const data = JSON.stringify(removeCardDataSpecialChars(rawCreditCardData));

  // eslint-disable-next-line new-cap
  const encryptedCreditCard = window.TxEncrypt(
    TokenExPublicKey,
    data,
  );

  return encryptedCreditCard;
}
