import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';

import LoadingContainer from '../LoadingContainer';
import FailModalContainer from '../FailModalContainer';
import Responsive from 'components/Responsive';

import * as routeNames from 'constants/route-names';
import { getChannelConfigs } from 'reducers/channel';

import './App.scss';

const mapDispatchToActions = {
  getChannelConfigs
};

const { bool, func, element } = PropTypes;

@connect(undefined, mapDispatchToActions)
class App extends Component {
  static propTypes = {
    getChannelConfigs: func.isRequired,
    header: element,
    main: element.isRequired,
    footer: element,
    isMobile: bool
  };

  componentWillMount() {
    this.props.getChannelConfigs(this.props.isMobile);
  }

  classNameCustomTheme = () => {
    const routes = [routeNames.channelCustomerArea];
    const isCustomAreaRoutes = routes.some(route => window.location.hash.includes(route));
    return isCustomAreaRoutes ? ' CustomerArea-theme' : '';
  }

  render() {
    const { header, main, footer } = this.props;

    return (
      <div>
        {header}
        <div className={`App clearfix${this.classNameCustomTheme()}`}>
          {main}
        </div>
        {footer}
        <FailModalContainer />
        <LoadingContainer />
      </div>
    );
  }
}

export default hot(Responsive(App));
