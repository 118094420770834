import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, initialize, change } from 'redux-form';
import { isEmpty } from 'lodash';

import * as customerMessages from 'constants/customer-messages';
import * as paymentTypes from 'constants/payment-types';

import * as validators from 'utils/validators';
import { onlyNumbers } from 'utils/value-cleaners';
import { encrypt } from 'utils/encrypt';
import { addLeadingZero } from 'utils/maskers';

import {
  encryptedVirtualDebitEloData,
  virtualDebitEloData,
} from 'api/models/payment-models';

import * as orderActions from 'reducers/order';

import VirtualDebitEloForm from 'components/Payment/Forms/VirtualDebitEloForm/VirtualDebitEloForm';

const errorsVirtualDebitEloForm = ({
  expirationMonth,
  expirationYear,
  number,
  CVC,
  fullName,
}) => {
  const errors = {};

  if (!validators.expirationDate(expirationMonth, expirationYear)) {
    errors.expirationYear = customerMessages.invalidExpirationDate;
  }

  if (!validators.isValidCardNumber(onlyNumbers(number))) {
    if (!validators.validateCardNumber(onlyNumbers(number))) {
      errors.number = customerMessages.invalidLuhnCardNumber;
    }
  }

  if (!errors.number && !validators.isValidVirtualDebitEloCardNumber(onlyNumbers(number))) {
    errors.number = customerMessages.invalidVirtualDebitValidCreditNumber;
  }

  if (!validators.cvc(CVC)) {
    errors.CVC = customerMessages.invalidCVC;
  }

  if (!fullName) {
    errors.fullName = customerMessages.invalidFullName;
  }

  return errors;
};

@reduxForm({
  form: 'virtualDebitElo',
  fields: [
    'number',
    'fullName',
    'expirationMonth',
    'expirationYear',
    'CVC',
    'saveCard',
    'campaignCode',
    'itemsSku',
  ],
  validate: errorsVirtualDebitEloForm
})
@connect(({
  form,
  order,
  basket
}) => ({
  form,
  ...order,
  ...basket,
}), {
  ...orderActions,
  initializeForm: initialize,
  changeField: change
})
export default class VirtualDebitEloFormContainer extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    postPlaceOrder: PropTypes.func.isRequired,
    initializeForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    shippingPackages: PropTypes.object.isRequired,
    redirectToAddress: PropTypes.func.isRequired,
    redirectToBasket: PropTypes.func.isRequired,
    changeField: PropTypes.func.isRequired,
    handlePlaceOrderError: PropTypes.func.isRequired,
    campaignCode: PropTypes.string,
    itemsSku: PropTypes.array,
    allowedBins: PropTypes.array,
    enableEncryptedCard: PropTypes.bool,
    items: PropTypes.array,
    tmxSessionID: PropTypes.string
  };

  componentWillMount() {
    this.props.initializeForm(
      'virtualDebitElo',
      {
        saveCard: true,
        installments: '-1',
        campaignCode: this.props.campaignCode,
        itemsSku: this.props.itemsSku
      },
      Object.keys(this.props.fields),
    );
  }

  render() {
    const {
      postPlaceOrder,
      handleSubmit,
      shippingPackages: { hasMarketplaceItems },
      redirectToAddress,
      redirectToBasket,
      handlePlaceOrderError,
      enableEncryptedCard,
      price,
      items,
      tmxSessionID,
    } = this.props;

    return (
      <VirtualDebitEloForm
        {...this.props.fields}
        price={price}
        handleSubmit={handleSubmit(values => new Promise((resolve, reject) => {
          const {
            number,
            fullName,
            CVC,
            expirationMonth,
            expirationYear,
            saveCard
          } = values;
          const errors = errorsVirtualDebitEloForm(values);

          if (!isEmpty(errors)) {
            return reject(errors);
          }

          const cardData = !!enableEncryptedCard
            ? encryptedVirtualDebitEloData({
              saveCard,
              hasMarketplaceItems,
              encryptedCreditCard: encrypt(
                {
                  'card_holder_name': fullName,
                  'card_number': number.replace(/ /g, ''),
                  'card_exp_date': `${addLeadingZero(expirationMonth)}/${expirationYear}`,
                  'card_security_code': CVC
                }
              ),
            })
            : virtualDebitEloData({
              fullName,
              number,
              expirationMonth,
              expirationYear,
              CVC,
              saveCard,
              hasMarketplaceItems,
            });

          return postPlaceOrder(
            {...cardData, items, customer_risk_analysis_id: tmxSessionID},
            paymentTypes.virtualDebitElo,
            redirectToAddress,
            redirectToBasket,
          )
            .catch((err) => {
              handlePlaceOrderError(err);
            });
        }))}
      />
    );
  }
}
