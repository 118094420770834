import React from 'react';
import PropTypes from 'prop-types';

import './GenericFooter.scss';

export function GenericFooter(props) {
  const footerClass = props.pageFull
    ? 'GenericFooter GenericFooter--full'
    : 'GenericFooter';

  return (
    <div className={footerClass}>
      {props.children}
    </div>
  );
}

GenericFooter.propTypes = {
  children: PropTypes.node,
  pageFull: PropTypes.bool,
};
