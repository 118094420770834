import {
  successBothDataChange,
  successEmailChange,
  successTelephoneChange,
  successDefaultDataChange
} from 'constants/customer-messages';

export const getSuccessDataChangeText = (pendingEmailUpdate, pendingTelephoneUpdate) => {

  if (pendingEmailUpdate && pendingTelephoneUpdate) {
    return successBothDataChange;
  }

  if (pendingEmailUpdate) {
    return successEmailChange;
  }

  if (pendingTelephoneUpdate) {
    return successTelephoneChange;
  }

  return successDefaultDataChange;
};
