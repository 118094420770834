export const itemVariantParser = (attributes) => {
  if (!attributes || !Array.isArray(attributes) || attributes.length === 0) {
    return null;
  }

  const values = attributes
    .map(item => {
      const type = item.key || '';
      const valor = item.value || '';

      return [type, valor].filter(Boolean).join(':');
    })
    .filter(Boolean)
    .join('|');

  return values
    .replace(/[_\s]/g, '-')
    .replace(/,(?:-\s*)/g, ',')
    .toLowerCase()
    .replace('"','');
};
