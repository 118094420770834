import { productsData } from './helpers/productData';

const DEFAULT_CHECKOUT_PROGRESS = 'checkout_progress';

export const DEFAULT_PAGE_TYPE = {
  basket: {
    step: 1,
    category: 'sacola',
    screenName: 'carrinho',
    eventLabel: 'view_cart',
  },
  login: {
    step: 2,
    category: 'login',
    screenName: 'login',
    eventLabel: 'step_login',
  },
  delivery: {
    step: 3,
    category: 'entrega',
    screenName: 'endereços',
    eventLabel: 'add_shipping_info',
  },
  review: {
    step: 4,
    category: 'revisao-pedido',
    screenName: 'revisão de pedidos',
    eventLabel: 'review',
  }
};

export const DEFAULT_PAGE_TYPE_CATEGORY =
  Object.keys(DEFAULT_PAGE_TYPE)
    .map(categoryName => (
      { key: categoryName, value: DEFAULT_PAGE_TYPE[categoryName].category }
    ))
    .reduce((obj, item) => Object.assign(obj, { [item.key]: item.key }), {});

export function checkoutProgress({ pageType, stateAfter }) {
  const { products, totals: basketTotal, id: cartId } = stateAfter.basket.basket;
  const { category, step, screenName, eventLabel } = DEFAULT_PAGE_TYPE[pageType];
  const { ecommerceProducts } = productsData(products, stateAfter);

  const ecommerce = {
    checkout: {
      actionField: {
        step
      },
      products: ecommerceProducts
    }
  };

  return {
    event: DEFAULT_CHECKOUT_PROGRESS,
    eventCategory: category,
    eventActtion: 'page-load',
    eventLabel,
    screenName,
    currency: 'BRL',
    value: basketTotal.to,
    basketId: cartId,
    afilliation: 'site_magalu',
    ecommerce,
  };
}


