export const REQUEST = 'channel/REQUEST';
export const FINISHED = 'channel/FINISHED';
export const SET_CHANNEL_NAME = 'channel/SET_CHANNEL_NAME';
export const SET_CHANNEL_CONFIGS = 'channel/SET_CHANNEL_CONFIGS';

export const initialState = {
  name: '',
  configs: {},
  isRequesting: [],
  gtm_account: '',
  optimize_account: '',
  ua_account: '',
  origin_url: '',
  first_party_seller: 'magazineluiza',
};

export const CONFIGS_REQUESTING = 'configs';

export default function reducer(state = initialState, action = {}) {
  const {
    channelName,
    channelConfigs,
    requestId
  } = action;

  switch (action.type) {
    case SET_CHANNEL_CONFIGS:
      return Object.assign({}, state, { configs: channelConfigs });
    case SET_CHANNEL_NAME:
      return Object.assign({}, state, { name: channelName });
    case REQUEST:
      return Object.assign({}, state, {
        isRequesting: [...state.isRequesting, requestId]
      });
    case FINISHED:
      return Object.assign({}, state, {
        isRequesting: state.isRequesting.filter(x => x !== requestId)
      });
    default:
      return state;
  }
}

export function finish(requestId) {
  return { type: FINISHED, requestId };
}

export function request(requestId) {
  return { type: REQUEST, requestId };
}

export function setChannelConfigs(channelConfigs) {
  return {
    type: SET_CHANNEL_CONFIGS,
    channelConfigs
  };
}

export function setChannelName(channelName) {
  return {
    type: SET_CHANNEL_NAME,
    channelName
  };
}

function removeConfigFromHTML() {
  const configsEl = document.getElementById('magalu_channel_configs');
  configsEl.parentElement.removeChild(configsEl);
}

export function getChannelConfigs() {
  return (dispatch) => {
    dispatch(setChannelName(MAGALU_CHANNEL.channel_name));
    dispatch(setChannelConfigs(MAGALU_CHANNEL.channel_configs));
    removeConfigFromHTML();
  };
}

