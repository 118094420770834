import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { correiosUrl } from 'constants/external-urls';
import { preventFocusEventCall } from 'utils/focus-event';

import './ZipcodeForm.scss';

export default class ZipcodeForm extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
    }),
    onSubmit: PropTypes.func.isRequired,
    handleAddClick: PropTypes.func.isRequired
  };

  inputRef = null;

  componentDidMount() {
    const { focus } = this.props;

    if (focus) {
      this.inputRef.focus();
      this.inputRef.setSelectionRange(
        this.inputRef.value.length,
        this.inputRef.value.length
      );
    }
  }

  handleZipcodeBlur(e) {
    const { onSubmit, field } = this.props;
    preventFocusEventCall(e, 'findCep', onSubmit, field.onBlur);
  }

  render() {
    const { field, onSubmit, handleAddClick } = this.props;

    return (
      <form className="ZipcodeForm" onSubmit={onSubmit} method="post">
        <input
          id="ZipcodeForm-input"
          type="tel"
          className="ZipcodeForm-input"
          placeholder="00000-000"
          ref={(element) => (this.inputRef = element)}
          maxLength={9}
          name={field.name}
          value={field.value}
          onBlur={this.handleZipcodeBlur.bind(this)}
          onChange={field.onChange}
          onFocus={field.onFocus}
        />
        <button
          className="buttonWithin"
          onClick={onSubmit}
          data-ga='{"category": "Carrinho", "action": "Calcular frete", "label": "OK"}'
        >
          <span className="buttonWithin-text">OK</span>
        </button>
        <button onClick={handleAddClick}>
          <a
            className="ZipcodeForm-link"
            id="findCep"
            href={correiosUrl}
            target="_blank"
            data-ga='{"category": "Endereco", "action": "CEP", "label": "Nao sabe CEP"}'
          >
          Não sei o CEP
          </a>
        </button>
      </form>
    );
  }
}
