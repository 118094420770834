import { checkoutProgress, DEFAULT_PAGE_TYPE_CATEGORY } from './checkoutProgress';
import { pageviewLayer } from 'utils/data-layer/pageviewLayer';

export const paymentPageview = (action, stateBefore, stateAfter) =>
  pageviewLayer(stateAfter, '4');

export const paymentCheckoutProgress = (action, stateBefore, stateAfter) =>
  checkoutProgress({
    pageType: DEFAULT_PAGE_TYPE_CATEGORY.review,
    stateAfter,
  });

export function paymentShippingCalcImpression(action, stateBefore) {
  const secondProduct =
    stateBefore?.basket?.shippingPackages?.shippingTypes?.deliveries?.packages?.[0]
      ?.products?.[1]?.sku || false;
  return {
    event: 'shipping',
    basketId: stateBefore.basket.basket.id,
    cep: stateBefore.basket.shippingPackages.shippingTypes.deliveries.address.zip_code,
    screenName: 'carrinho',
    content_type: 'revisao:frete',
    content_id: null,
    deliveriesId:
      stateBefore.basket.shippingPackages.shippingTypes.deliveries.packages[0].deliveryId,
    code: 'OK:null',
    item_id: `${
      stateBefore?.basket?.shippingPackages?.shippingTypes?.deliveries?.packages?.[0]
        ?.products?.[0]?.sku || null
    }${secondProduct ? `|${secondProduct}` : ''}:null:${
      stateBefore?.basket?.shippingPackages?.shippingTypes?.deliveries?.packages?.[0]?.deliveryType || null
    }:${stateBefore?.basket?.shippingPackages?.shippingTypes?.deliveries?.packages?.[1]?.deliveryType || null}`,
  };
}
