export const FAIL = 'fail/FAIL';
export const RESET = 'fail/RESET';

export const initialState = {
  failMessage: '',
  code: '',
  details: ''
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FAIL:
      const { failMessage, code, details } = action;
      return Object.assign({}, state, {
        failMessage,
        code,
        details
      });
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export function fail(failMessage = '', code = '', details = '') {
  return { type: FAIL, failMessage, code, details };
}

export function reset() {
  return { type: RESET };
}
