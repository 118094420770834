import cloneDeep from 'lodash/cloneDeep';

import * as customerMessages from 'constants/customer-messages';
import * as ShipmentApi from 'api/shipment-api';
import { getZipcodeFromCookies } from 'utils/session';
import { fail } from './fail';

export const REQUEST = 'shipment/REQUEST';
export const RECEIVED = 'shipment/RECEIVED';
export const RECEIVED_CLICK = 'shipment/RECEIVED_CLICK';
export const RECEIVED_SHIPPING_CALC_ERROR_IN_BASKET = 'shipment/RECEIVED_SHIPPING_CALC_ERROR_IN_BASKET';
export const RECEIVED_PACKAGES = 'shipment/RECEIVED_PACKAGES';
export const REFRESH_ZIPCODE_FROM_COOKIES =
  'shipment/REFRESH-ZIPCODE-FROM-COOKIES';
export const RESET = 'shipment/RESET';
export const FINISHED = 'shipment/FINISHED';
export const TRACK = 'shipment/TRACK';

export const initialState = {
  isRequesting: false,
  shipment: {
    address: '',
    state: '',
    city: '',
    neighbourhood: '',
    zipcode: '',
    cost: '0.0',
    outOfStockProducts: [],
    notAvailableProducts: [],
    packages: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, cloneDeep(state), { isRequesting: true });
    case RECEIVED:
      const { shipment } = action;
      return Object.assign({}, cloneDeep(state), {
        shipment,
        isRequesting: false,
      });
    case RECEIVED_CLICK:
      return Object.assign({}, cloneDeep(state), {
        shipment: action.shipment,
        isRequesting: false,
      });
    case RECEIVED_SHIPPING_CALC_ERROR_IN_BASKET:
      const { error } = action;
      return Object.assign({}, cloneDeep(state), {
        error
      });
    case RECEIVED_PACKAGES:
      const { packages } = action;
      return Object.assign({}, cloneDeep(state), {
        shipment: Object.assign({}, state.shipment, {
          packages,
        }),
      });
    case REFRESH_ZIPCODE_FROM_COOKIES:
      const { zipcode } = action;
      if (zipcode === state.shipment.zipcode) {
        return state;
      }
      return Object.assign({}, Object.assign({}, initialState), {
        shipment: Object.assign({}, initialState.shipment, {
          zipcode,
        }),
      });
    case RESET:
      return Object.assign({}, cloneDeep(initialState));
    case FINISHED:
      return Object.assign({}, cloneDeep(state), { isRequesting: false });
    default:
      return state;
  }
}

export function requestShipment() {
  return {
    type: REQUEST,
  };
}

export function receivedShipment(shipment = {}) {
  return {
    type: RECEIVED,
    shipment,
  };
}

export function receivedShipmentClick(shipment = {}) {
  return {
    type: RECEIVED_CLICK,
    shipment,
  };
}

export function receivedShippingCalcError(error, status, zipcode) {
  return {
    type: RECEIVED_SHIPPING_CALC_ERROR_IN_BASKET,
    error,
    zipcode
  };
}

export function receivedShipmentPackages(packages = []) {
  return {
    type: RECEIVED_PACKAGES,
    packages,
  };
}

export function trackShipment(shipment = { packages: {} }) {
  const deliveries = shipment?.packages?.map((delivery) => {
    return delivery?.deliveryTypes?.map((deliveryType) => {
      return {
        price: deliveryType.price,
        deliveryTime: deliveryType.time,
        type: deliveryType.type,
      };
    });
  });

  return {
    type: TRACK,
    delivery: deliveries,
  };
}

export function refreshZipcodeFromCookies() {
  return {
    type: REFRESH_ZIPCODE_FROM_COOKIES,
    zipcode: getZipcodeFromCookies(),
  };
}

export function resetShipment() {
  return { type: RESET };
}

export function finish() {
  return {
    type: FINISHED,
  };
}

export function fetchShipment(zipcode) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { shipment = {} } = getState();

      if (shipment.isRequesting) {
        return reject('Already fetching shipment.');
      }

      dispatch(requestShipment());

      return ShipmentApi.getShipment(zipcode)
        .then(
          (json) => {
            dispatch(receivedShipmentClick(json));
            dispatch(trackShipment(json));

            return json;
          },
          (err) => {

            if (!!err?.status && err?.status === 404) {
              dispatch(fail(customerMessages.failShipmentNotFound, err?.status));
              dispatch(receivedShippingCalcError(customerMessages.failShipmentNotFound, err?.status, zipcode));
            } else {
              dispatch(fail(customerMessages.failFetchShipment, err?.status));
              dispatch(receivedShippingCalcError(customerMessages.failFetchShipment, err?.status, zipcode));
            }
            dispatch(finish());
            return reject(err);
          }
        )
        .then(() => {
          return resolve();
        });
    });
}
