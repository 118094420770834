import React from 'react';
import PropTypes from 'prop-types';

import IconRadioButtonOff from 'icons/radio-button-off';
import IconRadioButtonOn from 'icons/radio-button-on';

import './InputRadioButton.scss';

const { bool } = PropTypes;

const InputRadioButton = (props) => {
  const activeRadio = props.checkedValue ? '--on' : '--off';
  return (
    <div className={`InputRadioButton${activeRadio}`}>
      <IconRadioButtonOff className="radio-button-off" />
      <IconRadioButtonOn className="radio-button-on" />
    </div>
  );
};

InputRadioButton.propTypes = {
  checkedValue: bool.isRequired,
};

export default InputRadioButton;
