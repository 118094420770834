import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';

const OrderReviewSubtotal = ({
  products,
  productCount,
  servicesQuantity,
  servicesAmount,
}) => (
  <div className="OrderReviewTotals-subTotal">
    <span className="OrderReviewTotals-products">
      <span className="OrderReviewTotals-left">Produtos ({productCount - servicesQuantity})</span>
      <span className="OrderReviewTotals-right">{toBRL(products)}</span>
    </span>
    {servicesQuantity >= 1 && (
      <span className="OrderReviewTotals-services">
        <span className="OrderReviewTotals-left--servicesQuantity">Serviços ({servicesQuantity})</span>
        <span className="OrderReviewTotals-right--servicesAmount">{toBRL(servicesAmount)}</span>
      </span>
    )}
  </div>
);

OrderReviewSubtotal.propTypes = {
  products: PropTypes.string.isRequired,
  productCount: PropTypes.number,
  servicesQuantity: PropTypes.number,
  servicesAmount: PropTypes.string,
};

export default OrderReviewSubtotal;
