import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { GOOGLE } from 'constants/social-login';
import { googleAccountsUrl } from 'constants/external-urls';
import { loadJsAsync } from 'utils/load';

export default class GoogleLoginButton extends Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    googleClientId: PropTypes.string.isRequired,
    isSignup: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onError: PropTypes.func,
  };

  componentDidMount() {
    loadJsAsync(googleAccountsUrl, this.onLoadCallback);
  }

  setLoginAuthGoogle = ({ credential }) => {
    if (this.props.onClick) {
      this.props.onClick(GOOGLE);
    }

    this.props.callback({ accessToken: credential });
  }

  onLoadCallback = () => {
    const { isSignup } = this.props;

    window.google.accounts.id.initialize({
      client_id: window.MAGALU_CHANNEL?.channel_configs?.google_client_id,
      callback: (data) => this.setLoginAuthGoogle(data)
    });
    window.google.accounts.id.renderButton(
      document.getElementById('google-button'),
      {
        theme: 'outline',
        size: 'medium',
        text: `${isSignup ? 'continue_with' : 'signin'}`
      }
    );
    window.google.accounts.id.prompt();
  }

  render() {
    return <div id="google-button" />;
  }
}
