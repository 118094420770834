import { getCustomer } from 'utils/session';
import { GTM_UNDEFINED, productsData, productsDataGA4 } from './helpers';
import getBasketTaxes from './helpers/getBasketTaxes';

export const errorApplicationGA4 = ({ error, screenName }) => {
  return {
    event: 'error_application',
    application_name: 'p52',
    code_error: error.error_code || error.status || 400,
    details: error.error_detail || error.message || error.error_message || null,
    screenName,
  };
};
