import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import miniappValidateSession from 'utils/miniappValidateSession';
import { SCREEN_NAME_CHANGE } from 'utils/data-layer/customerArea';

import * as customerActions from 'reducers/customer';
import * as customerAreaActions from 'reducers/customer-area';
import * as loadingActions from 'reducers/loading';

import Captcha from 'components/Form/Captcha/Captcha';
import ChangeEmailCellphone from 'components/ChangeEmailCellphone/ChangeEmailCellphone';

import './CustomerAreaPageChangeData.scss';

const mapStateToProps = ({ signup, customer, address, channel, signupAvailable }) => ({
  customerInfo: customer.customerInfo,
  customerRequesting: customer.isRequesting,
  addressRequesting: address.isRequesting,
  signupRequesting: signup.isRequesting,
  useCaptcha: channel.configs.use_captcha,
});

const mapDispatchToActions = {
  fetchCustomer: customerActions.fetchCustomer,
  toggleLoading: loadingActions.toggleLoading,
  showLoading: loadingActions.showLoading,
  hideLoading: loadingActions.hideLoading,
  trackVirtualPageviewApp: customerAreaActions.trackVirtualPageviewApp,
};

const customerLoadingID = 'customerChangeDataLoadingID';

@connect(mapStateToProps, mapDispatchToActions)
export default class CustomerAreaPageChangeData extends Component {
  static propTypes = {
    customerInfo: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    showLoading: PropTypes.func.isRequired,
    hideLoading: PropTypes.func.isRequired,
    trackVirtualPageviewApp: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    changeEmail: false,
  };

  componentWillMount() {
    const { location, fetchCustomer, trackVirtualPageviewApp, customerInfo } = this.props;
    const { router } = this.context;
    const { pathname } = location;
    const changeEmail = pathname.includes('email') && !this.state.changeEmail;

    miniappValidateSession(router, pathname, location).then(async () => {
      const customer = isEmpty(customerInfo) ? await fetchCustomer() : customerInfo;
      const mode = changeEmail ? 'email' : 'sms';
      trackVirtualPageviewApp(customer, SCREEN_NAME_CHANGE, mode);

      return customer;
    });

    this.setState({ changeEmail });
  }

  componentWillReceiveProps(nextProps) {
    const { customerRequesting, toggleLoading } = nextProps;

    toggleLoading(customerLoadingID, customerRequesting);
  }

  render() {
    const { changeEmail } = this.state;
    const { customerInfo, customerRequesting, handleSubmit, useCaptcha, location } =
      this.props;

    if (!customerRequesting) {
      return (
        <section className="CustomerAreaChangeData">
          {useCaptcha ? <Captcha /> : null}
          <ChangeEmailCellphone
            handleSubmit={handleSubmit}
            changeEmail={changeEmail}
            customerInfo={customerInfo}
            location={location}
          />
        </section>
      );
    }

    return null;
  }
}
