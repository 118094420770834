import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CheckoutFooter from 'components/Footer/CheckoutFooter/CheckoutFooter';
import ExternalLoader from 'components/ExternalLoader';


const mapStateToProps = ({ channel }) => ({
  channelName: channel.name,
  checkoutFooterUrl: channel.configs.checkout_footer_url,
  maxProductQuantity: channel.configs.maximum_quantity_per_sku,
});

@connect(mapStateToProps, undefined)
export default class CheckoutChannelFooterContainer extends Component {
  static propTypes = {
    channelName: PropTypes.string.isRequired,
    checkoutFooterUrl: PropTypes.string,
    maxProductQuantity: PropTypes.number.isRequired,
  };

  render() {
    return (
      <div className={`Checkout-footer-${this.props.channelName}`}>
        { !!this.props.checkoutFooterUrl
          ? <ExternalLoader
            externalURL={this.props.checkoutFooterUrl}
          />
          : <CheckoutFooter />
        }
      </div>
    );
  }
}
