import { isSuccess } from '@magalu/webzord';
import * as CustomerApi from 'api/customer-api';
import requestWebzord from './requestWebzord';
import { storageSaveCustomerData } from 'utils/session';

export default async function miniappSetSession() {
  return new Promise((resolve, reject) => {
    const args = {
      serviceName: 'request.miniapps',
      callbackId: 'requestUserCredentials',
      serviceArgs: {
        method: 'POST',
        path: 'webx-national/customer/app/',
        data: '',
      },
      callback: async (result) => {
        if (isSuccess(result)) {
          const res = result.body.response;
          const data = JSON.parse(res);

          await CustomerApi.getMiniappToken(data.session_id);
          storageSaveCustomerData(data);
          resolve(result);
        } else {
          console.error('MagaluService requestUserCredentials failed', result);
          reject(result);
        }
      },
    };

    requestWebzord(args);
  });
}
