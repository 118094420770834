import React from 'react';
import PropTypes from 'prop-types';

const PaymentFormButton = ({
  paymentType,
  disabled,
  flag
}) => {
  const gaObj = flag || paymentType;
  const dataGA = gaObj;

  return (
    <button
      className="continueButton"
      data-ga={`{"category": "Pagamento", "action": "Continuar", "label": "${dataGA}"}`}
      disabled={disabled}
    >Concluir pedido</button>
  );
};

PaymentFormButton.protTypes = {
  paymentType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  flag: PropTypes.string
};

export default PaymentFormButton;
