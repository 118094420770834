import React from 'react';
import PropTypes from 'prop-types';

import IconSac from 'icons/footer/icon-sac';
import IconTeleSales from 'icons/footer/icon-site-telesales';
import IconSafe from 'icons/footer/icon-safe';
import LogoInternetSegura from 'icons/footer/logo-internet-segura';
import { EbitDiamante as IconEbitDiamante } from 'icons/footer/icon-ebit-diamante';
import * as externalUrls from 'constants/external-urls';

import './CheckoutFooter.scss';
import { maxItemsPerSkuNotification } from '../../../constants/customer-messages';

const CheckoutFooter = () => (
  <div className="CheckoutFooter">
    <div className="CheckoutFooter-content">
      <ul className="CheckoutFooter-top">
        <li className="CheckoutFooter-top-item CheckoutFooter-top-item--sac mobile-hidden">
          <IconSac className="CheckoutFooter-icon" />
          {' '}
          <a
            className="CheckoutFooter-icon-description"
            href={externalUrls.sac}
            target="_blank"
            rel="noopener"
          >
              Atendimento
          </a>
        </li>
        <li className="CheckoutFooter-top-item CheckoutFooter-top-item--tel-chat">
          <IconTeleSales className="CheckoutFooter-icon" /> Compre pelo <a
            className="CheckoutFooter-icon-description"
            href="#" onClick={function handleChatClick(e) {
              e.preventDefault();
              window.open(
                externalUrls.onlineChat,
                'ChatOnline',
                'width=480, height=640, resizable=no, toolbar=0, location=0, directories=0, status=no, menubar=0'
              );
            }}
          >chat online</a>
        </li>
        <li className="CheckoutFooter-top-item CheckoutFooter-top-item--certificate mobile-hidden">
          <IconSafe className="CheckoutFooter-icon" /> <a className="CheckoutFooter-icon-description" href={externalUrls.security} target="_blank" rel="noopener">Certificados e segurança</a>
        </li>
        <li className="CheckoutFooter-top-item CheckoutFooter-top-item--logo-internetSegura mobile-hidden">
          <a href={externalUrls.internetSegura} target="_blank" rel="noopener"><LogoInternetSegura className="CheckoutFooter-logo-internetSegura" /></a>
        </li>
        <li className="CheckoutFooter-top-item CheckoutFooter-top-item--logo-ebit mobile-hidden">
          <a href={externalUrls.ebit} target="_blank" rel="noopener"><IconEbitDiamante className="CheckoutFooter-logo-ebit" /></a>
        </li>
      </ul>
    </div>
    <div className="CheckoutFooter-section">
        Preços e condições de pagamento exclusivos para compras via internet, podendo variar nas lojas físicas.<br/>
        Ofertas válidas na compra de até {maxItemsPerSkuNotification} peças de cada produto por cliente, até o término dos nossos estoques para internet.<br/>
        Caso os produtos apresentem divergências de valores, o preço válido é o da Sacola de compras.<br/>
        Vendas sujeitas a análise e confirmação de dados.<br/>
        * Juros e Custo de parcelamento: todos os encargos do parcelamento, como juros e outras tarifas estão incluídos neste valor.{' '}
      <a href={externalUrls.paymentContractUrl} target="_blank">Saiba mais</a>.
    </div>
    <div className="CheckoutFooter-section">
        Rodovia dos Bandeirantes KM 68,760 - Rio Abaixo - CEP: 13213-902 - Louveira/SP - CNPJ: 47960950/0449-27<br/>
        Magazine Luiza – Todos os direitos reservados
    </div>
  </div>
);

CheckoutFooter.propTypes = {
  maxProductQuantity: PropTypes.number,
};


export default CheckoutFooter;
