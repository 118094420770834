import slugify from 'utils/slugify';
import { onlyCharactersAndSpace } from 'utils/value-cleaners';

import { GTM_UNDEFINED } from './constants';

export const getProductShowcaseTitle = (product) => {
  if (product?.showcaseTitle) {
    const showcaseTitle = slugify(onlyCharactersAndSpace(product.showcaseTitle));

    return `sacola:${showcaseTitle}`;
  }

  return GTM_UNDEFINED;
};
