export const getEnableFeatureToggle = () => {
  return !!window.MAGALU_CHANNEL?.channel_configs.enable_ads_collector;
};

const ChannelNameEnum = {
  WEB: 'WEB',
  MOBILE: 'MSITE'
};

function getChannelName(channelName) {
  switch (channelName) {
    case 'm.magazineluiza':
      return ChannelNameEnum.MOBILE;
    case 'magazineluiza':
      return ChannelNameEnum.WEB;
    default:
      return ChannelNameEnum.WEB;
  }
}


export const adsCollector = async (adsEventHandler) => {
  const enableFeatureToggle = getEnableFeatureToggle();

  if (enableFeatureToggle) {
    if (window.magaluAdsCollector) {
      await adsEventHandler();
    } else {
      document.addEventListener('magalu.ads.events.collector', async () => {
        await adsEventHandler();
      });
    }
  }
  return;
};

export const adsCollectorLogin = async (customerId, anonymousId) => {
  try {
    const enableFeatureToggle = getEnableFeatureToggle();
    if (enableFeatureToggle) {
      const context = {
        anonymousId: anonymousId || '',
        userId: customerId || '',
        publisherId: 'magazineluiza',
        channel: getChannelName(window.MAGALU_CHANNEL.channel_name),
      };
      
      await adsCollector(() => window.magaluAdsCollector.onLogin(context));
     
    }
  } catch {
    return;
  }
};

export const adsCollectorPlaceOrder = (placeOrderResponse = {}, customerId, zipcode) => {
  const enableFeatureToggle = getEnableFeatureToggle();

  if (enableFeatureToggle) {
    const {
      created_at: createdAt,
      order_id: orderId,
      products = [],
      total_amount: totalAmount,
    } = placeOrderResponse;
    
    const detail = {
      saleDate: createdAt?.slice(0, -5),
      orderAmountInCurrency: Number(totalAmount),
      products: products?.map((product) => ({
        sellerId: product.seller,
        sku: product.sku,
        quantity: product.quantity,
        salePriceInCurrency: Number(product.payment_subtotal),
      })),
    };

    const user = {
      id: customerId,
      trackId: customerId,
      zipcode,
    };
    
    const context = {
      orderId,
      publisherId: 'magazineluiza',
      user,
      detail,
    };

    adsCollector(() => window.magaluAdsCollector.onPlaceOrder(context));
  }
};
