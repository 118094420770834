import * as CustomerApi from 'api/customer-api';
import * as customerMessages from 'constants/customer-messages';
import * as eventLabels from 'constants/GA';
import { otpCodeInvalid, otpMaxTries, otpExpired } from 'constants/errors';
import { failOtp } from './fail-otp';

export const REQUEST = 'customer-area/REQUEST';
export const RECEIVED = 'customer-area/RECEIVED';
export const FINISHED = 'customer-area/FINISHED';
export const TRACK_START_CHANGE = 'customer-area/TRACK_START_CHANGE';
export const TRACK_SUCCESS_CHANGE = 'customer-area/TRACK_SUCCESS_CHANGE';
export const TRACK_DATA_CHANGE = 'customer-area/TRACK_DATA_CHANGE';
export const TRACK_DATA_CHANGE_ERROR = 'customer-area/TRACK_DATA_CHANGE_ERROR';
export const TRACK_SCREENVIEW_APP = 'customer-area/TRACK_SCREENVIEW_APP';

export const initialState = {
  isRequesting: false,
  formData: {},
  responseInfo: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      const { formData } = action;
      return Object.assign({}, state, { isRequesting: true, formData });
    case RECEIVED:
      const { responseInfo } = action;
      return Object.assign({}, state, { isRequesting: false, responseInfo });
    case FINISHED:
      return Object.assign({}, state, { isRequesting: false });
    default:
      return state;
  }
}

export function request(formData) {
  return { type: REQUEST, formData };
}

export function received(responseInfo) {
  return { type: RECEIVED, responseInfo };
}

export function finish() {
  return { type: FINISHED };
}

export function trackStartChange(mode) {
  return {
    type: TRACK_START_CHANGE,
    mode,
  };
}

export function trackSuccessChange(mode) {
  return {
    type: TRACK_SUCCESS_CHANGE,
    mode,
  };
}

export function trackDataChange(mode) {
  return {
    type: TRACK_DATA_CHANGE,
    mode,
  };
}
export function trackVirtualPageviewApp(customer, step, mode) {
  return {
    type: TRACK_SCREENVIEW_APP,
    customer,
    step,
    mode,
  };
}

export function trackDataChangeError(mode, description) {
  return {
    type: TRACK_DATA_CHANGE_ERROR,
    mode,
    description,
  };
}

export const otpExceptions = [
  {
    key: otpCodeInvalid,
    message: customerMessages.invalidOtpCode,
    eventLabel: eventLabels.invalidOtpCodeEventLabel,
  },
  {
    key: otpMaxTries,
    message: customerMessages.maxTries,
    eventLabel: eventLabels.otpOtherErrorsEventLabel,
  },
  {
    key: otpExpired,
    message: customerMessages.otpExpired,
    eventLabel: eventLabels.otpExpiredEventLabel,
  },
];

export function postEditCustomer(formData, callback) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { customerArea } = getState();

      if (customerArea?.isRequesting) {
        return reject();
      }

      dispatch(request(formData));

      CustomerApi.patchEditEmailOrPhone(formData)
        .then(
          (info) => {
            dispatch(received(info));
            callback();
            return resolve();
          },
          (err) => {
            const { message, status } = err.response.body;
            const otpMappedError = otpExceptions.find(
              (otpExpection) => otpExpection.key === message
            );

            dispatch(failOtp(otpMappedError ? otpMappedError?.message : message, status));

            return reject(err);
          }
        )
        .finally(() => dispatch(finish()));
    });
  };
}
