import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import * as routeNames from 'constants/route-names';
import * as externalUrls from 'constants/external-urls';

import { isLogged } from 'utils/session';
import { redirectToNextUrl, redirectToLogin } from 'utils/redirect';

import * as customerActions from 'reducers/customer';
import * as loadingActions from 'reducers/loading';
import { hideLoading } from 'reducers/promocode';

import Captcha from 'components/Form/Captcha/Captcha';

import {
  EditRegistrationFormPJContainer
} from 'containers/Registration/EditRegistrationFormPJContainer';
import {
  EditRegistrationFormPFContainer
} from 'containers/Registration/EditRegistrationFormPFContainer';

import './EditRegistrationPage.scss';

const { func, object } = PropTypes;

const customerLoadingID = 'customerLoadingID';
const addressLoadingID = 'addressLoadingID';
const signupLoadingID = 'signupLoadingID';

const mapStateToProps = ({ signup, customer, address, channel }) => ({
  customerInfo: customer.customerInfo,
  customerRequesting: customer.isRequesting,
  addressRequesting: address.isRequesting,
  signupRequesting: signup.isRequesting,
  useCaptcha: channel.configs.use_captcha,
});
const mapDispatchToActions = {
  fetchCustomer: customerActions.fetchCustomer,
  toggleLoading: loadingActions.toggleLoading,
  showLoading: loadingActions.showLoading,
  hideLoading: loadingActions.hideLoading,
};

@connect(mapStateToProps, mapDispatchToActions)
export default class EditRegistrationPage extends Component {
  static propTypes = {
    customerInfo: object.isRequired,
    location: object.isRequired,
    fetchCustomer: func.isRequired,
    toggleLoading: func.isRequired,
    showLoading: func.isRequired,
    hideLoading: func.isRequired,
  };

  static contextTypes = {
    router: object.isRequired
  };

  componentWillMount() {
    const {
      location,
      fetchCustomer
    } = this.props;
    const { router } = this.context;
    const { pathname } = location;

    if (!isLogged()) {
      return redirectToLogin(router, pathname, location);
    }

    return fetchCustomer();
  }

  componentWillReceiveProps(nextProps) {
    const {
      customerRequesting,
      addressRequesting,
      signupRequesting,
      toggleLoading
    } = nextProps;

    toggleLoading(customerLoadingID, customerRequesting);
    hideLoading(addressLoadingID, addressRequesting);
    toggleLoading(addressLoadingID, addressRequesting);
    toggleLoading(signupLoadingID, signupRequesting);
  }

  componentWillUnmount() {
    const { toggleLoading, showLoading } = this.props;

    showLoading(addressLoadingID, false);
    toggleLoading(addressLoadingID, false);
    toggleLoading(signupLoadingID, false);
  }

  redirectOnSuccess = () => {
    const {
      location: { query: { origin, next } }
    } = this.props;
    const { router } = this.context;
    const nextUrl = next && decodeURIComponent(next) || routeNames.address;
    const nextOrigin = origin || nextUrl === externalUrls.catalog && DEFAULT_CHANNEL || '';

    return redirectToNextUrl(router.replace, { next: nextUrl, origin: nextOrigin });
  };

  redirectToChangeCustomerAreaPage = () => {
    const { router } = this.context;
    return router.push({
      pathname: `/${routeNames.channelCustomerArea}`,
    });
  }

  render() {
    const { customerInfo, useCaptcha } = this.props;
    const redirects = {
      redirectOnSuccess: this.redirectOnSuccess,
      redirectToChangeCustomerAreaPage: this.redirectToChangeCustomerAreaPage
    };
    return !isEmpty(customerInfo) && (
      <div className="EditRegistrationPage">
        <div className="EditRegistrationPage-title">Altere seu cadastro</div>
        {customerInfo.accountType === 'pf' && (
          <EditRegistrationFormPFContainer
            {...redirects}
          />
        ) || (
          <EditRegistrationFormPJContainer
            redirectOnSuccess={this.redirectOnSuccess}
          />
        )}
        {useCaptcha ? <Captcha /> : null}
      </div>
    );
  }
}
