import requestWebzord from '../../utils/requestWebzord';
import verifyIfMiniapp from 'utils/verifyIfMiniapp';

function addToDataLayer(layer) {
  const { event } = layer || {};

  const argsFirebase = {
    callback: (result) => {
      console.debug('MagaluService Callback', result);
      console.debug('payload', { event, layer });
    },
    callbackId: `Firebase - Event: ${event} - ${new Date().getTime()}`,
    serviceArgs: {
      eventName: event,
      eventParams: layer,
    },
    serviceName: 'analytics.trackEvent',
  };

  const eventMiniApp = 'mini_app_name' in layer;
  const isMiniApp = verifyIfMiniapp();
  if (isMiniApp && eventMiniApp) {
    return requestWebzord(argsFirebase);
  } else if (!eventMiniApp) {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push(layer);
  }
}

export default addToDataLayer;