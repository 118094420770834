import React from 'react';
import PropTypes from 'prop-types';
import './SimpleLoader.scss';

const SimpleLoader = ({text = 'Carregando'}) => {
  return(
    <div className="SimpleLoader-wrapper">
      <div className="SimpleLoader-circle"></div>
      <span>{text}</span>
    </div>
  );
};

export default SimpleLoader;

SimpleLoader.prototype = {
  text: PropTypes.string,
};
