import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './ExpirationTimerPix.scss';

class ExpirationTimerPix extends PureComponent {
  formattedDate = moment(this.props.expiration).format('HH:mm');

  render() {
    return (
      <div className="ExpirationTimerPix">
        <div className="ExpirationTimerPix-description">
          O código é válido até às {this.formattedDate}.
        </div>
      </div>
    );
  }
}

ExpirationTimerPix.propTypes = {
  expiration: PropTypes.string.isRequired,
};

export default ExpirationTimerPix;
