import { catalog as catalogURL } from 'constants/external-urls';

export const stwProduct = ({
  id,
  stewieProductId,
  baseUrl,
  variations,
  review_score,
}) => {
  const variation = variations[0];
  const seller = variation.sellers[0];

  const link = baseUrl
    ? `${baseUrl}?url=${catalogURL}/${variation.url}`
    : `${catalogURL}/${variation.url}`;

  return {
    id,
    stewieProductId,
    reviewScore: review_score,
    link,
    title: variation.title,
    reference: variation.reference,
    rawPictureUrl: variation.media.images[0],
    storeId: seller.id,
    prices: {
      from: seller.list_price,
      to: seller.price,
      cash: seller.best_price.total_amount
    },
    variation,
    variationId: variation.id,
    installment: {
      quantity: seller.best_installment_plan.installment_quantity,
      price: seller.best_installment_plan.installment_amount,
      description: seller.best_installment_plan.payment_method_description,
    }
  };
};

export const hasSeller = (item) => (
  !!item.variations[0].sellers &&
  !!item.variations[0].sellers[0] &&
  !!item.variations[0].sellers[0].id
);

export const stwProductList = (payload = []) =>
  payload.filter(hasSeller).map(stwProduct);

export const trends = ({ purchases, views, carts }) => {
  const purchasesProducts = purchases && Object.assign([],
    purchases.products.filter(hasSeller).map(stwProduct)
  );
  const viewsProducts = views && Object.assign([],
    views.products.filter(hasSeller).map(stwProduct)
  );
  const cartsProducts = carts && Object.assign([],
    carts.products.filter(hasSeller).map(stwProduct)
  );

  let trendsObj = {
    purchases: {},
    views: {},
    carts: {}
  };

  if (purchasesProducts) {
    trendsObj = Object.assign(
      {},
      trendsObj,
      { purchases: Object.assign({}, purchases, { products: purchasesProducts }) }
    );
  }

  if (viewsProducts) {
    trendsObj = Object.assign(
      {},
      trendsObj,
      { views: Object.assign({}, views, { products: viewsProducts }) }
    );
  }

  if (cartsProducts) {
    trendsObj = Object.assign(
      {},
      trendsObj,
      { carts: Object.assign({}, carts, { products: cartsProducts }) }
    );
  }

  return trendsObj;
};
