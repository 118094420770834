import React from 'react';
import PropTypes from 'prop-types';

import {
  bobbyImgWidth,
  bobbyImgHeight,
} from 'constants/bobby';

import { resizeProductPicture } from 'utils/pictures';

import BobbyProductTitle from 'components/Bobby/BobbyProductTitle';
import BobbyProductPrice from 'components/Bobby/BobbyProductPrice';
import BobbyStars from 'components/Bobby/BobbyStars';

import './BobbyProduct.scss';

const BobbyProduct = ({
  priceDescription,
  carouselName,
  product,
  handleLinkClick
}) => {
  const {
    installment,
    link,
    prices,
    rawPictureUrl,
    reference,
    reviewScore,
    stewieProductId,
    stockStatus,
    title,
    variationId,
  } = product;

  const productId = stewieProductId || variationId;

  return (
    <div
      className="BobbyProduct"
    >
      <a
        className="BobbyProduct-link"
        href={link}
        onClick={() => handleLinkClick(product)}
        data-ga_bob={JSON.stringify({ title, productId, carouselName })}
      >
        <img
          className="BobbyProduct-image"
          src={resizeProductPicture(
            rawPictureUrl,
            bobbyImgWidth,
            bobbyImgHeight
          )}
        />
        <div className="BobbyProduct-content">
          <div className="BobbyProduct-title">
            <BobbyProductTitle
              title={title}
              reference={reference}
            />
          </div>
          <BobbyStars rating={reviewScore} />
          <BobbyProductPrice
            prices={prices}
            priceDescription={priceDescription}
            installment={installment}
            stockStatus={stockStatus}
          />
        </div>
      </a>
    </div>
  );
};

BobbyProduct.propTypes = {
  carouselName: PropTypes.string.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
  priceDescription: PropTypes.string.isRequired,
  product: PropTypes.shape({
    installment: PropTypes.object.isRequired,
    link: PropTypes.string.isRequired,
    prices: PropTypes.object.isRequired,
    rawPictureUrl: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    reviewScore: PropTypes.number.isRequired,
    stewieProductId: PropTypes.string,
    stockStatus: PropTypes.number,
    title: PropTypes.string.isRequired,
    variationId: PropTypes.string.isRequired,
  }).isRequired,
};


export default BobbyProduct;
