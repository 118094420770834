import React from 'react';

import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';

const collectorExporter = new OTLPTraceExporter({
  url: SETTINGS.OTEL_EXPORTER_OTLP_ENDPOINT,
  headers: {
    Authorization: SETTINGS.OTEL_EXPORTER_OTLP_HEADERS,
  }
});

const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: SETTINGS.OTEL_SERVICE_NAME,
    [SemanticResourceAttributes.SERVICE_VERSION]: SETTINGS.version,
    'service.tier': SETTINGS.OTEL_SERVICE_TIER,
  }),
});

provider.addSpanProcessor(new BatchSpanProcessor(collectorExporter));
provider.register({
  contextManager: new ZoneContextManager(),
});

registerInstrumentations({
  instrumentations: [
    new DocumentLoadInstrumentation(),
    new XMLHttpRequestInstrumentation({
      ignoreUrls: []
    }),
  ],
});

export default function TraceProvider ({children}) {
  return (
    <div>
      {children}
    </div>
  );
}
