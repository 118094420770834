import React from 'react';

import NoopArrow from 'components/Bobby/Arrow/NoopArrow';
import PrevArrow from 'components/Bobby/Arrow/PrevArrow';
import NextArrow from 'components/Bobby/Arrow/NextArrow';

import './BobbyArrow.scss';

const arrow = (type, showArrow) => {
  if (!showArrow) {
    return <NoopArrow />;
  }

  if (type === 'prev') {
    return <PrevArrow />;
  }

  if (type === 'next') {
    return <NextArrow />;
  }
};

export default arrow;
