export function stringListToObjectList(list) {
  return list.map(item => {
    const obj = item.split(':');

    if (!obj[1]) return {};

    const sku = obj[0];
    const qty = obj[1];
    return { sku, qty };
  });
}

export function maxProductQuantityBySku(id, maxQuantity, restrictedSkus = []) {
  let max = maxQuantity;
  stringListToObjectList(restrictedSkus).map(restrictedSku => {
    if (restrictedSku.sku === id) {
      max = parseInt(restrictedSku.qty, 10);
    }
  });
  return max;
}

export function setSoldOut(products = [], packages = [], itemsOutOfStock = []) {
  if (!itemsOutOfStock?.length) return products; 

  return products.map((product) => ({
    ...product,
    ...(
      itemsOutOfStock.some(
        ({ sku }) => packages?.length ? packages.some(
          ({ items }) => items.some(
            ({ seller_sku: sellerSku, sku: itemSku }) =>
              itemSku === product.id && ( sku === itemSku || sku === sellerSku)
          )
        ) : sku === product.id
      )
      && {
        isSoldOut: true,
      }
    )
  }));
}

export const isOutOfStockProduct = (
  productId = '',
  itemsOutOfStock = []
) => itemsOutOfStock.some(({ sku, bundle_sku }) => {
  return (sku === productId || bundle_sku === productId);
});

export const orderOutOfStockProducts = (
  products = [],
  itemsOutOfStock = []
) => products.filter(product =>
  isOutOfStockProduct(product.id, itemsOutOfStock)
);

export const isItemNotAllowedForCompaniesProduct = (
  productId = '',
  itemsNotAllowedForCompanies = []
) => itemsNotAllowedForCompanies.some(({ sku }) => {
  return sku === productId;
});

export const orderItemsNotAllowedForCompaniesProducts = (
  products = [],
  itemsNotAllowedForCompanies = []
) => products.filter(product =>
  isItemNotAllowedForCompaniesProduct(product.id, itemsNotAllowedForCompanies)
);

export const basketSoldOutProducts = (products = []) =>
  products.filter(product => product.isSoldOut);

export const filterSoldOutProducts = (products = []) =>
  products.filter((product) => product.isSoldOut);

