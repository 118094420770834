import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import QrcodePix from 'components/ConfirmOrder/Pix/QrcodePix/QrcodePix';
import CopyPix from 'components/ConfirmOrder/Pix/CopyPix/CopyPix';
import ExpirationTimerPix from 'components/ConfirmOrder/Pix/ExpirationTimerPix/ExpirationTimerPix';
import Responsive from 'components/Responsive';

import IconPix from 'icons/payment/icon-pix.svg';

import './OrderBoxPix.scss';

export class OrderBoxPix extends PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool,
    pix: PropTypes.shape({
      emv: PropTypes.string.isRequired,
      expiration_date: PropTypes.string.isRequired,
      image_base64: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { isMobile, pix } = this.props;

    return (
      <div className="OrderBoxPix">
        <div className="OrderBoxPix-title">
          <IconPix className="OrderBoxPix-titleIcon" />
          <div className="OrderBoxPix-titleDescription">Pagamento via Pix</div>
        </div>
        <div className="OrderBoxPix-content">
          <div className="OrderBoxPix-help">
            Escaneie o código QR com a câmera do seu celular ou copie e cole o código, no
            aplicativo do seu banco.
          </div>
          {isMobile && (
            <div>
              <CopyPix emv={pix.emv} />
              <ExpirationTimerPix expiration={pix.expiration_date} />
            </div>
          )}
          <QrcodePix base64={pix.image_base64} />
          {!isMobile && (
            <div>
              <CopyPix emv={pix.emv} />
              <ExpirationTimerPix expiration={pix.expiration_date} />
            </div>
          )}
        </div>
        <span className="OrderBoxPix-information">
          Se o pagamento não for confirmado, não se preocupe. O pedido será cancelado
          automaticamente.
        </span>
      </div>
    );
  }
}

export default Responsive(OrderBoxPix);
