import React, { Component } from 'react';
import { connect } from 'react-redux';

import { failNewsletterOption } from 'constants/customer-messages';

import {
  fetchCustomer,
  setEmailNewsletter,
} from 'reducers/customer';

import { fail } from 'reducers/fail';

import NewsletterOption from 'components/OrderReview/NewsletterOption';
import Responsive from 'components/Responsive';

export class NewsletterOptionContainer extends Component {
  state = {
    success: false
  };

  componentWillMount = () => {
    return this.props.fetchCustomer();
  }

  shouldRender() {
    return this.props.customerInfo
      && this.props.customerInfo.notifications?.emailPromotional !== undefined
      && !this.props.customerInfo.notifications?.emailPromotional;
  }

  onClick = () => {
    this.props.setEmailNewsletter(true).then(res => {
      if (res === true) {
        return this.setState({
          success: true
        });
      } else {
        this.props.fail(failNewsletterOption, res.status);
      }
    });
  }

  render() {
    if (!this.shouldRender()) {
      return null;
    }

    return <NewsletterOption
      success={this.state.success}
      handleClick={this.onClick}
      isMobile={this.props.isMobile}
    />;
  }
}

const mapDispatchToProps = {
  fetchCustomer,
  setEmailNewsletter,
  fail,
};

const mapStateToProps = ({ customer }) => ({
  customerInfo: customer.customerInfo,
});

export default Responsive(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterOptionContainer));
