import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, initialize, reduxForm, reset } from 'redux-form';
import isEqual from 'lodash/isEqual';
import classname from 'classname';

import * as routeNames from 'constants/route-names';

import { redirectToLogin, redirectToNextUrl } from 'utils/redirect';
import { isLogged, getChannelConfig } from 'utils/session';
import { scrollToTomInputError } from 'utils/scroll';
import { addressSelectContent, addressViewContent } from 'utils/data-layer/helpers';

import addressFormContainerFactory, {
  formFields,
  errorsAddressForm,
} from 'containers/AddressFormContainer';
import AddressList from 'containers/AddressList';

import AddressModalInfo from 'components/Address/AddressModalInfo/AddressModalInfo';

import * as unauthorizedActions from 'reducers/unauthorized';
import * as addressActions from 'reducers/address';
import * as shipmentActions from 'reducers/shipment';
import * as addressListActions from 'reducers/address-list';
import * as basketActions from 'reducers/basket';
import * as loadingActions from 'reducers/loading';
import * as customerActions from 'reducers/customer';
import * as signupActions from 'reducers/signup';

import './DeliveryAddress.scss';
import ErrorModal from './ErrorModal';

const FETCH_LIST_ERROR = 'FETCH_LIST';
const SET_DEFAULT_ERROR = 'SET_DEFAULT';
const addressListLoadingID = 'addressListLoading';
const addressLoadingID = 'addressLoading';
const basketLoadingID = 'basketLoading';
const customerLoadingID = 'customerLoading';
const deleteLoadingID = 'deleteLoading';

const SHOW_DELETE_ADDRESS = getChannelConfig('enable_delete_address') ?? true;

const formName = 'newAddress';
const editFormName = 'editAddress';

const pushViewContent = (errors) => {
  if (errors?.status) {
    return;
  }

  const contentId = Object.values(errors)
    .map((value) => `erro:${value}`)
    .join('|');
  return addressViewContent({ contentId });
};

const NewAddressFormContainer = reduxForm({
  form: formName,
  fields: formFields,
  validate: errorsAddressForm,
  onSubmitFail: (errors) => {
    pushViewContent(errors);
    setTimeout(scrollToTomInputError, 100);
  },
})(addressFormContainerFactory(formName));

const EditAddressFormContainer = reduxForm({
  form: editFormName,
  fields: formFields,
  validate: errorsAddressForm,
  onSubmitFail: (errors) => {
    pushViewContent(errors);
    setTimeout(scrollToTomInputError, 100);
  },
})(addressFormContainerFactory(editFormName));

const redirectToBasket = (router) => router.push(routeNames.root);

const getErrorModalProps = (modalType) => {
  const options = {
    SET_DEFAULT: {
      title: 'Este endereço está desatualizado ou inconsistente',
      retryText: 'Atualizar endereço',
      message: 'Para garantir que a entregas sejam feitas corretamente atualize os dados.',
      retryBtnClassName: 'btn-primary',
    }
  };
  return options[modalType] || {};
};

@connect(
  ({ form, basket, addressList, unauthorized, shipment, address, customer }) => ({
    ...basket,
    ...addressList,
    ...unauthorized,
    ...shipment,
    addressListRequesting: addressList.isRequesting,
    addressListDeleting: addressList.isDeleting,
    addressRequesting: address.isRequesting,
    basketRequesting: basket.isRequesting,
    customerRequesting: customer.isRequesting,
    customerInfo: customer.customerInfo,
    form,
  }),
  {
    ...basketActions,
    ...addressActions,
    ...addressListActions,
    ...unauthorizedActions,
    ...shipmentActions,
    ...loadingActions,
    ...customerActions,
    ...signupActions,
    initialize,
    resetForm: reset,
    changeField: change,
  }
)
class DeliveryAddress extends Component {
  static propTypes = {
    err: PropTypes.object,
    reset: PropTypes.func.isRequired,
    patchAddress: PropTypes.func.isRequired,
    setBasketAddress: PropTypes.func.isRequired,
    deleteAddress: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    addressList: PropTypes.array.isRequired,
    shipment: PropTypes.object.isRequired,
    addressListRequesting: PropTypes.bool.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    dismissEditAddress: PropTypes.func.isRequired,
    fetchBasketForGTM: PropTypes.func.isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    addressRequesting: PropTypes.bool.isRequired,
    basketRequesting: PropTypes.bool.isRequired,
    addressListDeleting: PropTypes.bool.isRequired,
    redirectToLoginNext: PropTypes.string,
    isCheckoutPage: PropTypes.bool,
    onFetchAddresses: PropTypes.func,
    onAddressSubmit: PropTypes.func,
    putDeliveryAddress: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    willRedirectOnDefaultAddress: false,
    openErrorModal: null,
    openNewAddress: false,
    editAddressUuid: null,
    selectedAddress: null,
  };

  componentWillMount() {
    const {
      location,
      fetchCustomer,
      fetchBasketForGTM,
      redirectToLoginNext,
    } = this.props;

    const { router } = this.context;

    fetchBasketForGTM();

    if (!isLogged()) {
      return redirectToLogin(router, redirectToLoginNext, location);
    }

    fetchCustomer().then(this.handleFetchAddresses);
  }

  componentWillReceiveProps(nextProps) {
    const {
      err,
      reset: resetUnauthorized,
      location,
      addressList,
      addressListRequesting,
      addressRequesting,
      basketRequesting,
      customerRequesting,
      toggleLoading,
      addressListDeleting,
      redirectToLoginNext,
      isCheckoutPage,
    } = nextProps;
    const { router } = this.context;
    if (err) {
      redirectToLogin(router, redirectToLoginNext, location);
      return resetUnauthorized();
    }

    if (
      isCheckoutPage &&
      !this.state.willRedirectOnDefaultAddress &&
      location.query.showAll !== 'true' &&
      addressList.length === 1 &&
      addressList[0].redirect === true
    ) {
      this.setState({ willRedirectOnDefaultAddress: true });
      return this.customRedirectToDelivery();
    }

    if (!isEqual(this.props, nextProps)) {
      toggleLoading(addressListLoadingID, addressListRequesting);
      toggleLoading(addressLoadingID, addressRequesting);
      toggleLoading(basketLoadingID, basketRequesting);
      toggleLoading(customerLoadingID, customerRequesting);
      toggleLoading(deleteLoadingID, addressListDeleting);
    }
  }

  componentWillUnmount() {
    const { toggleLoading, dismissEditAddress } = this.props;

    dismissEditAddress();

    toggleLoading(addressListLoadingID, false);
    toggleLoading(addressLoadingID, false);
    toggleLoading(basketLoadingID, false);
    toggleLoading(customerLoadingID, false);
    toggleLoading(deleteLoadingID, false);
  }

  initializeAddressForm = () => {
    const { initialize: initializeForm, customerInfo } = this.props;

    const formValues = {
      receiver: customerInfo.fullName,
      telephone: customerInfo.telephone,
    };

    initializeForm(formName, formValues, formFields);
    return this.setState({ openNewAddress: true });
  };

  handleFetchAddresses = () => {
    const { shipment, onFetchAddresses } = this.props;
    const { router } = this.context;

    return onFetchAddresses({          
      handleFetchError: () => this.setState({ openErrorModal: FETCH_LIST_ERROR }),
      initializeAddressForm: this.initializeAddressForm,
      router,
      redirectToBasket,
      shipment,
    });
  };

  handleAddressSubmit = (values, editAddressUuid) => {
    const { patchAddress, postSignup, customerInfo, onAddressSubmit } = this.props;

    return onAddressSubmit({
      patchAddress,
      postSignup,
      customerInfo,
      editAddressUuid,
      values,
      handleSubmitError: () => this.setState({ openErrorModal: 'NEW_ADDRESS' }),
      handleReFetchAddresses: () => {
        this.setState({ openNewAddress: false, editAddressUuid: null });
        this.handleFetchAddresses();
      },
      handleCustomRedirectToDelivery: this.customRedirectToDelivery
    });
  };

  handleDeleteAddress = ({ addressUuid, isDefault }) => {
    const { deleteAddress } = this.props;
    const contentIdLabel = isDefault ? 'padrao' : 'outros-enderecos';

    addressSelectContent({
      contentId: `excluir: ${contentIdLabel}`,
      contentType: 'lista-de-enderecos',
    });

    return deleteAddress(addressUuid)
      .then(() => {
        this.handleFetchAddresses();
      })
      .catch(() => {
        this.setState({ openErrorModal: 'DELETE' });
      });
  };

  handleSetDefault = (address) => {
    const { putDeliveryAddress } = this.props;

    addressSelectContent({
      contentId: 'tornar-padrao',
      contentType: 'lista-de-enderecos',
    });

    return putDeliveryAddress({ ...address, isDefault: true }, true)
      .then(() => {
        this.handleFetchAddresses();
      })
      .catch(() => {
        this.setState({ selectedAddress: address, openErrorModal: SET_DEFAULT_ERROR });
      });
  };

  customRedirectToDelivery = () => {
    const { location } = this.props;
    const { router } = this.context;
    const redirectParams = {
      tipo: location.query ? location.query.tipo : undefined,
      changedAddress: location.query.showAll === 'true' ? true : undefined,
    };

    return redirectToNextUrl(router.replace, {
      next: `/${routeNames.delivery}`,
      nextQuery: redirectParams,
    });
  };

  redirectOnError = () => {
    const { router } = this.context;

    redirectToBasket(router);
  };

  redirectToEditRegistration = () => {
    const { router } = this.context;

    router.push({
      pathname: routeNames.editRegistration,
      query: { next: routeNames.address },
    });
  };

  handleSelectConfirm = (address) => {
    addressSelectContent({
      contentId: 'continuar',
      contentType: 'lista-de-enderecos',
    });

    const { patchAddress, setBasketAddress, setAddress } = this.props;

    return patchAddress(address.addressUuid)
      .then(() => {
        setBasketAddress(address);
        setAddress(address);
        return address;
      })
      .then(this.customRedirectToDelivery)
      .catch((err) => {
        if (err.status === 400) {
          return window.location.reload();
        }
        if (err.status === 409) {
          return this.redirectOnError();
        }
        if (err.status === 406) {
          return this.redirectToEditRegistration();
        }
        throw err;
      });
  };

  handleCancelNewFormClick = (e) => {
    e.preventDefault();
    const { resetAddress, resetForm } = this.props;

    resetAddress();
    resetForm(formName);
    this.setState({ openNewAddress: false, editAddressUuid: null });
  };

  handleCancelEditFormClick = (e) => {
    e.preventDefault();
    const { resetAddress, resetForm } = this.props;

    resetAddress();
    resetForm(editFormName);
    this.setState({ editAddressUuid: null });
  };

  handleEditAddress = (address) => {
    const { initialize: initializeForm, editAddress } = this.props;
    const contentIdLabel = address.isDefault ? 'padrao' : 'outros-enderecos';

    const formValues = {
      ...address,
      noNumber: !address.number || address.number === 'S/N',
    };

    addressSelectContent({
      contentType: 'lista-de-enderecos',
      contentId: `editar:${contentIdLabel}`,
    });

    initializeForm(editFormName, formValues, formFields);
    editAddress(address.addressUuid);
    this.setState({ editAddressUuid: address.addressUuid });
  };

  handleOpenNewAddress = () => {
    addressSelectContent({
      contentId: 'adicionar-novo-endereco',
      contentType: 'lista-de-enderecos',
    });

    this.initializeAddressForm();
  };

  handleRetryErrorModal = () => {
    const { openErrorModal, selectedAddress } = this.state;

    if (openErrorModal === FETCH_LIST_ERROR) {
      this.setState({ openErrorModal: null });
      return this.handleFetchAddresses();
    }

    return this.handleEditAddress(selectedAddress);
  }

  renderNewAddressCancelButton = () => {
    const { addressList } = this.props;

    if (!addressList || !addressList.length) {
      return null;
    }

    return (
      <button className="btn btn-invisible" onClick={this.handleCancelNewFormClick}>
        Cancelar
      </button>
    );
  };

  render() {
    const { addressListRequesting, location, addressList, customerInfo, addressUuid, isCheckoutPage } = this.props;
    const {
      willRedirectOnDefaultAddress,
      openErrorModal,
      openNewAddress,
      editAddressUuid,
    } = this.state;

    const submitButtonLabel = isCheckoutPage ? 'Salvar e continuar' : 'Salvar';
    const errorModalProps = getErrorModalProps(openErrorModal);

    const loadingList =
      addressListRequesting || !addressList.length || addressList[0]?.redirect;

    if (openNewAddress) {
      return (
        <NewAddressFormContainer
          title="Novo endereço"
          openCancelComponent={this.renderNewAddressCancelButton()}
          submit={this.handleAddressSubmit}
          hasCustomerAddress={!!customerInfo?.address}
          customerInfo={customerInfo}
          submitButtonLabel={submitButtonLabel}
        />
      );
    }

    if (editAddressUuid) {
      return (
        <EditAddressFormContainer
          title="Alterar endereço"
          openCancelComponent={
            <button
              className="btn btn-invisible"
              onClick={this.handleCancelEditFormClick}>
              Cancelar
            </button>
          }
          submit={(values) => this.handleAddressSubmit(values, editAddressUuid)}
          hasCustomerAddress={!!customerInfo?.address}
          submitButtonLabel={submitButtonLabel}
        />
      );
    }

    return (
      <div className="DeliveryAddress">
        {!loadingList && !willRedirectOnDefaultAddress && (
          <h2
            className={classname('DeliveryAddress-title', { 'mt-[0]': !isCheckoutPage })}>
            Endereço de entrega
          </h2>
        )}
        <AddressModalInfo location={location} />
        {!loadingList && (
          <AddressList
            addressUuid={addressUuid}
            onConfirm={isCheckoutPage ? this.handleSelectConfirm : undefined}
            addressList={addressList}
            onNewAddress={this.handleOpenNewAddress}
            onEdit={this.handleEditAddress}
            onDelete={SHOW_DELETE_ADDRESS && this.handleDeleteAddress}
            onSetDefault={this.handleSetDefault}
            showEditAddress
          />
        )}
        <ErrorModal
          open={!!openErrorModal}
          title={errorModalProps?.title}
          message={errorModalProps?.message}
          retryText={errorModalProps?.retryText}
          retryBtnClassName={errorModalProps?.retryBtnClassName}
          onClose={
            openErrorModal !== FETCH_LIST_ERROR
              ? () => this.setState({ openErrorModal: null })
              : null
          }
          onRetry={
            openErrorModal === FETCH_LIST_ERROR || openErrorModal === SET_DEFAULT_ERROR
              ? this.handleRetryErrorModal
              : null
          }
        />
      </div>
    );
  }
}

DeliveryAddress.defaultProps = {
  redirectToLoginNext: routeNames.address,
  isCheckoutPage: true,
};

export default DeliveryAddress;
