import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './QrcodePix.scss';

class QrcodePix extends PureComponent {
  startsWithData = /^(data:image\/png;base64,)+/gim;
  cleanBase64 = this.props.base64.replace(this.startsWithData, '');

  render() {
    return (
      <div className="QrcodePix">
        <img
          className="QrcodePix-img"
          src={`data:image/png;base64,${this.cleanBase64}`}
        />
      </div>
    );
  }
}

QrcodePix.propTypes = {
  base64: PropTypes.string.isRequired,
};

export default QrcodePix;
