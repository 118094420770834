export const otpErrorPopUpTitleSubTitle = {
  error500: {
    title: 'Código não validado',
    subtext: 'Não conseguimos validar o código de verificação. Por favor tente novamente',
  },
  error422: {
    title: 'Falha ao enviar o código',
    subtext: 'Não foi possível enviar o código de verificação. Por favor, tente novamente clicando em "reenviar código"',
  }
};
