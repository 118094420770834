import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroup from 'components/Form/FormGroup/FormGroup';
import CheckboxGroup from 'components/Form/CheckboxGroup/CheckboxGroup';
import ButtonShowPassword from 'components/Form/ButtonShowPassword/ButtonShowPassword';

import './SignupFormPJ.scss';

export default class SignupFormPJ extends Component {
  static propTypes = {
    cnpj: PropTypes.object.isRequired,
    companyName: PropTypes.object.isRequired,
    fantasyName: PropTypes.object.isRequired,
    stateRegistration: PropTypes.object.isRequired,
    isExemptFromStateRegistration: PropTypes.object.isRequired,
    fullName: PropTypes.object.isRequired,
    oldPassword: PropTypes.object,
    password: PropTypes.object.isRequired,
    asyncValidating: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired,
    showOldPassword: PropTypes.bool,
    showPassword: PropTypes.bool.isRequired,
    handleShowPasswordClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      cnpj,
      companyName,
      fantasyName,
      stateRegistration,
      isExemptFromStateRegistration,
      fullName,
      oldPassword,
      password,
      asyncValidating,
      showOldPassword,
      showPassword,
      handleShowPasswordClick,
    } = this.props;
    const newStateRegistrationField = Object.assign({}, stateRegistration, {
      disabled: !!isExemptFromStateRegistration.value
    });
    const shouldShowSuccess = field => !!field.value && !field.error && !field.active;

    return (
      <div className="SignupFormPJ">
        <div className="form-title">Dados da empresa</div>
        <FormGroup
          inputSize="small"
          labelText="CNPJ"
          field={Object.assign({}, cnpj, { disabled: !!cnpj.initialValue })}
          asyncValidating={asyncValidating}
        />
        <FormGroup
          inputSize="long"
          labelText="Razão social"
          field={companyName}
        />
        <FormGroup
          inputSize="long"
          labelText="Nome fantasia"
          field={fantasyName}
        />
        <FormGroup
          labelText="Inscrição estadual"
          inputSize="small"
          field={newStateRegistrationField}
          forceShowSuccess={shouldShowSuccess(newStateRegistrationField) || !!isExemptFromStateRegistration.value}
        >
          <span className="FormGroup-state-registration">
            <CheckboxGroup
              labelText="Isento"
              field={isExemptFromStateRegistration}
            />
          </span>
        </FormGroup>
        <div className="form-title">Dados pessoais</div>
        <FormGroup
          inputSize="long"
          labelText="Nome completo"
          field={fullName}
        />
        {oldPassword && <FormGroup
          labelText="Senha atual"
          inputSize="small"
          hideSuccess
          field={Object.assign({}, oldPassword, {
            type: showOldPassword && 'text' || 'password'
          })}
        >
          <ButtonShowPassword
            field={showOldPassword}
            handleClick={() => handleShowPasswordClick('showOldPassword')}
          />
        </FormGroup>}
        <FormGroup
          labelText={`${oldPassword && 'Nova senha ' || 'Senha '}(mínimo 6 caracteres)`}
          inputSize="small"
          field={Object.assign({}, password, {
            type: showPassword && 'text' || 'password'
          })}
        >
          <ButtonShowPassword
            field={showPassword}
            handleClick={() => handleShowPasswordClick('showPassword')}
          />
        </FormGroup>
      </div>
    );
  }
}
