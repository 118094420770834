import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import Responsive from 'components/Responsive';

import './Policies.scss';

const Policies = (props) => {
  const { policy, product } = props;
  return (
    <div className="Policies-wrapper">
      <div className="Policies-description">
        <span>ATENÇÃO</span>
        <span>
            Esse pedido viola a política de compra por funcionário para o produto
          <span className="Policies-info-description"> [{product.id}] - {product.title}</span>
        </span>
      </div>

      <div className="Policies-info">
        <span>
          <span className="Policies-info-description">Política violada: </span>
          {policy.description} - {policy.rule}
        </span>
        <div className="Policies-rules-content">
          <span>
            <span className="Policies-info-description"> Você ainda pode comprar {policy.available} unidades do produto.</span> <br />
              Altere para a quantidade máxima permitida para prosseguir com a compra.
          </span>
        </div>
      </div>
    </div>
  );
};

Policies.proptypes = {
  policy: shape({
    description: string,
    available: number,
    sku: string,
  }).isRequired,
  product: shape({
    id: string,
    title: string
  }).isRequired
};

export default Responsive(Policies);
