import React from 'react';

function ColorStrip(props) {
  return (
    <svg
      width="1004"
      height="6"
      viewBox="0 0 1004 6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient x1="1.741%" y1="0%" x2="100%" y2="0%" id="a">
          <stop stopColor="#FFFF4A" offset=".092%" />
          <stop stopColor="#FCD000" offset="6.753%" />
          <stop stopColor="#FFC112" offset="10.656%" />
          <stop stopColor="#FF8A00" offset="16.858%" />
          <stop stopColor="#FF5F5F" offset="23.198%" />
          <stop stopColor="#FF5156" offset="28.816%" />
          <stop stopColor="#FF7AE7" offset="34.908%" />
          <stop stopColor="#FF5193" offset="41.032%" />
          <stop stopColor="#C739FF" offset="46.154%" />
          <stop stopColor="#A400E1" offset="62.954%" />
          <stop stopColor="#2ECEFF" offset="76.15%" />
          <stop stopColor="#0086FF" offset="86.938%" />
          <stop stopColor="#72F772" offset="97.013%" />
          <stop stopColor="#00D604" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M10 69h1004v3.97a2 2 0 01-2 2H12a2 2 0 01-2-2V69z"
        transform="matrix(1 0 0 -1 -10 74.97)"
        fill="url(#a)"
      />
    </svg>
  );
}

export default ColorStrip;
