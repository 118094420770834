import { checkoutUrl } from 'constants/api-urls';

import * as ShipmentModels from './models/shipment-models';
import jsonFetch from './json-fetch';

export const getShipment = (zipcode) => {
  return jsonFetch(`${checkoutUrl}/api/shipping/calculate/${zipcode}/`)
    .then((json) => {
      return ShipmentModels.shipment(json);
    });
};
