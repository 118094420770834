import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { LoadingWithBackground } from 'components/shared/Loading/Loading';

const { array } = PropTypes;

@connect(({ loading }) => ({ ...loading }))
export default class LoadingContainer extends Component {
  static propTypes = {
    ids: array.isRequired
  };

  render() {
    const { ids } = this.props;

    return ! isEmpty(ids) && <LoadingWithBackground />;
  }
}
