import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Responsive from 'components/Responsive';

import { handleLockBackgroundScroll } from '../../utils/maskers';

import CloseX from 'icons/icon-close';

const { bool, array, func, string, object, oneOfType } = PropTypes;

class ModalAdjustable extends Component {
  static propTypes = {
    children: oneOfType([array, object, string]),
    isOpen: bool,
    onRequestClose: func,
    textAlign: string,
    isMobile: bool,
    height: string,
    heightValue: string,
  };

  onRequestClose = (e) => {
    const { onRequestClose } = this.props;
    e.preventDefault();
    handleLockBackgroundScroll('inherit');

    if (onRequestClose) onRequestClose(e);
  }

  render() {

    const {
      children,
      textAlign = 'center',
      isOpen = false,
      onRequestClose,
      heightValue,
      isMobile,
      ...otherProps
    } = this.props;
    const sidePadding = 61;
    const contentWidth = 956 - 2 * sidePadding;
    const marginTopAdjustable = isMobile ? '0vh' : '10vh';
    const heightAdjustable = isMobile ? `${window.innerHeight * 0.9}px` : `${window.innerHeight * 0.7}px`;
    const height = heightValue !== '' ? heightValue : heightAdjustable;
    Modal.setAppElement('body');

    let style = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 21,
      },
      content: {
        position: 'relative',
        top: null,
        left: null,
        right: null,
        bottom: null,
        border: 0,
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: 4,
        outline: 'none',
        padding: 0,
        paddingTop: 50,
        paddingBottom: 50,
        paddingLeft: sidePadding,
        paddingRight: sidePadding,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: contentWidth,
        textAlign,
        boxShadow: '0px 18px 40px 10px rgba(0, 0, 0, 0.12)',
        marginTop: marginTopAdjustable,
        height,
        transition: 'height .233s ease',
        maxHeight: '600px',
        zIndex: null,
      }
    };

    if (isMobile) {
      style.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)';
      style.content.boxShadow = '0px 0px 6px 4px rgba(0, 0, 0, 0.12)';
      style.content.maxHeight = 'auto';
    }

    return (
      <Modal
        {...otherProps}
        isOpen={isOpen}
        onRequestClose={this.onRequestClose}
        style={style}
      >
        <div className="Modal-content">
          {children}
        </div>
        {onRequestClose &&
          <CloseX
            className="CloseIcon"
            onClick={(e) => {
              this.onRequestClose(e);
            }}
          />}
      </Modal>
    );
  }
}

export default Responsive(ModalAdjustable);
