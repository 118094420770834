import { getChannelConfig, getCustomer } from './session';

const validAllowList = (allowList) => !allowList || allowList[0] === '' || typeof allowList !== 'object';

export default function redirectToWallet() {
  const allowList = getChannelConfig('wallet_analysis_cpf_allowlist');

  if(!getChannelConfig('enable_wallet') || validAllowList(allowList)) {
    return null;
  }

  const cpfCnpj = getCustomer()?.cpf_cnpj;
  const lastDigit = cpfCnpj.slice(cpfCnpj.length - 1);

  const allowedDigit = allowList.some((digit) => digit === lastDigit);

  if (allowedDigit) {
    return window.location.assign(getChannelConfig('ravational_url'));
  }

  return null;
}
