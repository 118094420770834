import React from 'react';

import InternationalTaxContingencyModal from 'components/InternationalTaxContingencyModal/InternationalTaxContingencyModal';
import withModal from 'hocs/withModal/withModal';

import './InternationalTaxContingency.scss';

const InternationalTaxContingency = ({ isOpen, handleToggle }) => {
  return (
    <div className="InternationalTaxContingency-wrapper">
      <InternationalTaxContingencyModal isOpen={isOpen} toggle={handleToggle} />
      <span className="InternationalTaxContingency-text">
        Impostos de importação serão adicionados ao seguir com a compra.{' '}
        <span className="InternationalTaxContingency-link" onClick={handleToggle}>
          Saiba mais
        </span>
      </span>
    </div>
  );
};

export default withModal(InternationalTaxContingency);
