import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './BasketContinue.scss';

export const BasketContinue = ({
  handleClick,
  handleAddClick,
  urlCatalog,
  disabled = false,
  fullShowcaseName = '',
  isPromocodeEnabled = false,
}) => {
  const buyMoreProductsLink = (fullShowcaseName ?
    `${urlCatalog}/${fullShowcaseName}` :
    `${urlCatalog}`
  );

  const clickHandler = (eventName) => {
    handleClick();
    handleAddClick(eventName);
  };

  return (
    <div className="BasketContinue">
      <div className="BasketContinue-actions">
        <button className="BasketContinue-button-buyMore" onClick={() => handleAddClick('comprar-mais-produtos')}>
          <a href={buyMoreProductsLink} className="BasketContinue-buyMore" data-ga='{"category": "Carrinho", "action": "Comprar mais produtos", "label": ""}'>
            Comprar mais produtos
          </a>
        </button>
        <button disabled={disabled} className="BasketContinue-button" onClick={() => clickHandler('continuar')} data-ga='{"category": "Carrinho", "action": "Continuar", "label": ""}'>
          Continuar
        </button>
      </div>
      {isPromocodeEnabled &&
        <div className="BasketContinue-information-promocode-vale">
          Tem cupom ou vale compra? Você poderá<br /> utilizá-lo na etapa de pagamento, se aplicável
        </div>
      }
    </div>
  );
};

BasketContinue.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  urlCatalog: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullShowcaseName: PropTypes.string,
  isPromocodeEnabled: PropTypes.bool,
};

const mapStateToProps = ({ channel }) => ({
  urlCatalog: channel.configs.origin_url,
  fullShowcaseName: channel.configs.full_showcase_name,
  isPromocodeEnabled: channel.configs.enable_promocode,
});

export default connect(mapStateToProps)(BasketContinue);
