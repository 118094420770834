import { toBRL } from './currency';

/**
 * Generate label by credits of the user
 */
export const getLabelValeCompraOption = (props) => {
  const {
    credits: { totalAmount, totalAvailableAmount },
    totals: { creditAmountInUse },
    useCredit,
    shippingPackages: { hasMarketplaceItems }
  } = props;

  const label = {
    description: `Crédito: ${toBRL(totalAmount)}`,
    className: '',
  };

  if (hasMarketplaceItems && parseFloat(totalAvailableAmount) <= 0) {
    label.description = `Crédito disponível: ${toBRL(totalAmount)}`;
  } else if (!useCredit) {
    label.description = `Crédito disponível: ${toBRL(totalAmount)}`;
    label.className = 'PaymentBox-line-info-label--success';
  } else {
    label.description = `Crédito utilizado neste pedido: ${toBRL(creditAmountInUse)}`;
    label.className = 'PaymentBox-line-info-label--success';
  }

  return label;
};

export const isOnlyValeCompra = (remainingAmount, hasMarketplaceItems = false) => {
  const hasRemainingAmount = parseFloat(remainingAmount) > 0;
  return (!hasRemainingAmount && !hasMarketplaceItems);
};

export const getTypeInputValeCompra = (remainingAmount, hasMarketplaceItems = false) => {
  return isOnlyValeCompra(remainingAmount, hasMarketplaceItems) ? 'radio' : 'checkbox';
};
