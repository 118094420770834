import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroup from 'components/Form/FormGroup/FormGroup';
import * as ExternalUrls from 'constants/external-urls';

const { object, func } = PropTypes;

export default class SignupBox extends Component {
  static propTypes = {
    loginField: object.isRequired,
    handleSubmit: func.isRequired
  };

  handleChange = (event) => {
    this.props.loginField.onChange(event.target.value.toLowerCase());
  }

  render() {
    const { loginField, handleSubmit } = this.props;
    return (
      <div className="SignupBox">
        <div className="LoginBox-title">Quero criar uma conta</div>
        <form className="LoginBox-form" onSubmit={handleSubmit} method="post" >
          <FormGroup
            labelText="E-mail"
            field={loginField}
            handleChange={this.handleChange}
            type="email"
            inputSize="long"
          />
          <button className="LoginBox-form-continue" data-ga='{"category": "Login", "action": "Criar cadastro", "label": "Selecionou"}'>
            <span className="mobile-hidden">
              Continuar
            </span>
            <span className="mobile-only">
              Criar minha conta
            </span>
          </button>
        </form>
        <div className="LoginBox-help mobile-hidden">
          Dúvidas?
          {' '}
          <a
            href={ExternalUrls.sac}
            target="_blank"
            rel="noopener"
            data-ga='{"category": "Login", "action": "Fale conosco", "label": ""}'
          >
            fale conosco
          </a>
        </div>
      </div>
    );
  }
}
