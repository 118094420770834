import { GTM_FALSE, GTM_TRUE } from './constants';

export const getProductAvailabilityStatus = (
  isSoldOut,
  notAvailableForDelivery
) => {
  if (!isSoldOut && !notAvailableForDelivery) {
    return GTM_TRUE;
  }

  if (!!isSoldOut && !notAvailableForDelivery) {
    return `${GTM_FALSE}:sem-estoque`;
  }

  return `${GTM_FALSE}:indisponivel-cep`;
};
