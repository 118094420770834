import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';
import { fullAddress as fullAddressMask, zipcode as zipcodeMask } from 'utils/maskers';
import { detectMobile } from 'utils/responsive';

import ZipcodeForm from 'components/Basket/ZipcodeForm/ZipcodeForm';
import ButtonLink from 'components/Form/ButtonLink/ButtonLink';

import './BasketAddress.scss';

const BasketAddress = ({
  address,
  state,
  city,
  neighbourhood,
  zipcode,
  packages,
  zipcodeField,
  showForm,
  focus,
  shipmentCost,
  isRequesting,
  handleSubmit,
  handleAddClick,
  handleModifyClick
}) => {
  const isMobile = detectMobile();
  const fullAddressText = fullAddressMask(address, neighbourhood, city, state);
  const dataGA = '{"category":"Endereco","action":"CEP","label":"Alterar"}';

  return (
    <div className="BasketAddress">
      <div className="BasketAddress-address">
        <div className="BasketAddress-address-firstLine">
          <span className="BasketAddress-firstLabel">
            Outras opções de <b>Entrega</b> na próxima etapa.
          </span>
          <div className="BasketAddress-labelContainer">
            <span className="BasketAddress-secondLabel">Frete para o CEP</span>
            {!showForm && isMobile && (
              <ButtonLink
                handleClick={handleModifyClick}
                dataGA={dataGA}
                buttonClassName="BasketAddress-address-change"
              />
            )}
          </div>
          {(showForm && (
            <ZipcodeForm field={zipcodeField} onSubmit={handleSubmit} focus={focus} handleAddClick={handleAddClick} />
          )) || (
            <span className="BasketAddress-alterZipcode">
              <span className="BasketAddress-zipcode">{zipcodeMask(zipcode)}</span>
              {!isMobile && (<ButtonLink
                handleClick={handleModifyClick}
                dataGA={dataGA}
                buttonClassName="BasketAddress-address-change"
              />)}
            </span>
          )}
          {!showForm && (
            <div className="BasketAddress-address-secondLine">{fullAddressText}</div>
          )}
        </div>
      </div>
    </div>
  );
};

BasketAddress.propTypes = {
  address: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  neighbourhood: PropTypes.string,
  zipcode: PropTypes.string,
  packages: PropTypes.array.isRequired,
  zipcodeField: PropTypes.object.isRequired,
  showForm: PropTypes.bool.isRequired,
  shipmentCost: PropTypes.string,
  isRequesting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleModifyClick: PropTypes.func.isRequired,
};

export default BasketAddress;
