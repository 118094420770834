import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FacebookLoginButton from 'components/Login/FacebookLoginButton';
import GoogleLoginButton from 'components/Login/GoogleLoginButton';
import Responsive from 'components/Responsive';

import './SocialLoginBox.scss';

export class SocialLoginBox extends Component {
  static propTypes = {
    FBAppId: PropTypes.string.isRequired,
    googleClientId: PropTypes.string.isRequired,
    FBCallback: PropTypes.func.isRequired,
    GoogleCallback: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    isMobile: PropTypes.bool,
    showLoading: PropTypes.func.isRequired,
    hideLoading: PropTypes.func.isRequired,
    isSignup: PropTypes.bool.isRequired
  };

  handleClick = (source) => {
    this.props.showLoading('SocialLogin');
    this.props.onClick(source);
  }

  handleError = () => {
    this.props.hideLoading('SocialLogin');
  }

  render() {
    const {
      FBAppId,
      FBCallback,
      GoogleCallback,
      isMobile,
      googleClientId,
      isSignup
    } = this.props;

    return (
      <div className="SocialLoginBox">
        <span className="SocialLoginBox--InformationLogin">
          Use sua rede social para se conectar*
        </span>
        <div className="SocialLoginBox-group-buttons">
          <FacebookLoginButton
            appId={FBAppId}
            scope={'public_profile, email'}
            callback={FBCallback}
            onClick={this.handleClick}
          />
          <GoogleLoginButton
            googleClientId={googleClientId}
            callback={GoogleCallback}
            onClick={this.handleClick}
            onError={this.handleError}
            isSignup={isSignup}
          />
        </div>
        { isMobile &&
          <span className="SocialLoginBox--Divider"> ou </span>
        }
      </div>
    );
  }
}

export default Responsive(SocialLoginBox);
