import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { copyToClipboard } from 'utils/copyToClipboard';

import * as orderActions from 'reducers/order';

import './CopyPix.scss';

const mapDispatchToActions = {
  ...orderActions,
};

@connect(null, mapDispatchToActions)
class CopyPix extends Component {
  static propTypes = {
    copyPixClick: PropTypes.func.isRequired,
  };

  input = null;
  timer = null;
  state = {
    showSuccessCopyMessage: false,
  };

  componentDidUpdate() {
    if (this.state.showSuccessCopyMessage) {
      clearTimeout(this.timer);
      this.props.copyPixClick();

      this.timer = setTimeout(() => {
        this.setState({ showSuccessCopyMessage: false });
      }, 1500);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleCopyToClipboard = () => {
    const { emv } = this.props;
    copyToClipboard({
      fallbackMessage: 'Copie o codigo do pix',
      fallbackValue: emv,
      textarea: this.input,
      updateState: () => this.setState({ showSuccessCopyMessage: true }),
    });
  };

  render() {
    const { emv } = this.props;
    const { showSuccessCopyMessage } = this.state;

    return (
      <div className="CopyPix">
        <div>
          <input
            className="CopyPix-input"
            value={emv}
            ref={(component) => {
              this.input = component;
            }}
            readOnly
            onClick={this.handleCopyToClipboard}
          />
        </div>
        <button className="CopyPix-button" onClick={this.handleCopyToClipboard}>
          {showSuccessCopyMessage ? 'Código copiado com sucesso!' : 'Copiar código Pix'}
        </button>
      </div>
    );
  }
}

CopyPix.propTypes = {
  emv: PropTypes.string.isRequired,
};

export default CopyPix;
