import React from 'react';
import PropTypes from 'prop-types';

import Responsive from 'components/Responsive';

const BobbyProductTitle = ({
  title,
  reference,
  isMobile,
}) => {
  const ref = isMobile ? '' : ` - ${reference}`;

  return (
    <div className="BobbyProductTitle">
      {title}{ref}
    </div>
  );
};

BobbyProductTitle.propTypes = {
  title: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

export default Responsive(BobbyProductTitle);
