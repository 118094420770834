import React from 'react';

function Copy(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" {...props}><path fill="#9B9B9B" fillRule="evenodd" d="M12.727 0H1.818A1.818 1.818 0 000 1.818v12.727h1.818V1.818h10.91V0zm2.728 3.636h-10a1.818 1.818 0 00-1.819 1.819v12.727c0 1.003.814 1.818 1.819 1.818h10a1.819 1.819 0 001.818-1.818V5.455a1.819 1.819 0 00-1.818-1.819zm-10 1.819h10v12.727h-10V5.455z"/></svg>
  );
}

export default Copy;


