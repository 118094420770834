import * as CustomerApi from 'api/customer-api';
import * as customerMessages from 'constants/customer-messages';
import * as eventLabels from 'constants/GA';
import { otpCodeInvalid, otpMaxTries, otpExpired } from 'constants/errors';
import { failOtp } from './fail-otp';
import { 
  trackError as trackOtpError, 
  trackSuccess as trackOtpSuccess
} from './send-otp';

export const REQUESTING = 'validate-otp/REQUEST';
export const RECEIVED = 'validate-otp/RECEIVED';
export const FINISHED = 'validate-otp/FINISHED';

export const initialState = {
  isRequesting: false,
  id: null,
  code: null,
  response: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUESTING:
      const { id, code } = action;
      return Object.assign({}, state, {
        isRequesting: true,
        id,
        code,
      });
    case RECEIVED:
      const { response } = action;
      return Object.assign({}, state, {
        response,
      });
    case FINISHED:
      return Object.assign({}, state, {
        isRequesting: false,
      });
    default:
      return state;
  }
}

export function requestOtpValidate(id, code) {
  return { type: REQUESTING, id, code };
}

export function receivedResponseValidate(response) {
  return { type: RECEIVED, response };
}

export function finishedOtpValidate() {
  return { type: FINISHED };
}

export function trackError(errorMessage, page, mode) {
  return { type: TRACK_ERROR, errorMessage, page, mode };
}

export function trackSuccess(description, page, mode) {
  return { type: TRACK_SUCCESS, description, page, mode };
}

export const otpExceptions = [
  {
    key: otpCodeInvalid,
    message: customerMessages.invalidOtpCode,
    eventLabel: eventLabels.invalidOtpCodeEventLabel,
  },
  {
    key: otpMaxTries,
    message: customerMessages.maxTries,
    eventLabel: eventLabels.otpOtherErrorsEventLabel,
  },
  {
    key: otpExpired,
    message: customerMessages.otpExpired,
    eventLabel: eventLabels.otpExpiredEventLabel,
  },
];

export function validateOtp(formData, page, deliveryMode) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestOtpValidate(formData.id, formData.code));
      CustomerApi.postValidateOtp(formData)
        .then(
          (response) => {
            dispatch(trackOtpSuccess('sucesso', page, deliveryMode));
            dispatch(receivedResponseValidate(response));
            return resolve(response);
          },
          (err) => {
            if (err?.response?.body) {
              const { message, status } = err.response.body;
              const otpMappedError = otpExceptions.find(
                (otpExpection) => otpExpection.key === message
              );
              dispatch(failOtp(otpMappedError ? otpMappedError?.message : message, status));
              dispatch(
                trackOtpError(
                  otpMappedError?.eventLabel || eventLabels.otpOtherErrorsEventLabel,
                  page,
                  deliveryMode
                )
              );
              return reject(err);
            }
          }
        )
        .finally(() => dispatch(finishedOtpValidate()));
    });
  };
}
