import addToDataLayer from '../addToDataLayer';


const getContentType = (contentType) => {
  if (window.location.href.includes('/#/seu-espaco/')) {
    return `seu-espaco:${contentType}`;
  }
  
  return contentType;
};

export const addressSelectContent = ({ contentId, contentType } = {}) => {
  const layer = {
    event: 'select_content',
    content_type: getContentType(contentType),
    content_id: contentId,
  };

  return addToDataLayer(layer);
};

export const addressViewContent = ({ contentId } = {}) => {
  const layer = {
    event: 'view_content',
    content_type: getContentType('formulario-enderecos'),
    content_id: contentId,
  };

  return addToDataLayer(layer);
};
