import moment from 'moment';

import { scheduledDelivery as scheduledDeliveryChoice } from 'constants/delivery-choices';
import * as paymentTypes from 'constants/payment-types';

import { addLeadingZero } from 'utils/maskers';
import { onlyNumbers } from 'utils/value-cleaners';

import {
  scheduledParameters,
  additionalProduct,
  basketWarranty,
  shippingAddress,
  variationProduct,
  serviceDescription,
} from 'api/models/basket-models';
import { customerAddress } from 'api/models/address-models';

moment.locale('pt-br');

export const orderService = (service) => {
  return (
    service && {
      name: serviceDescription(service.category, service.duration_value),
      price: service.price,
      subtotal: service.subtotal,
      id: service.service_id,
    }
  );
};

export const card = ({
  ownerName: fullName,
  cardNumber: number,
  expirationMonth,
  expirationYear,
  id,
  cardIssuer: flag,
  isDefault,
  installments = [],
  enabled,
}) => ({
  fullName,
  number,
  expirationMonth,
  expirationYear,
  id,
  flag,
  isDefault,
  installments,
  enabled,
});

export const savedCardData = ({
  id,
  CVC,
  installmentsQuantity,
  fullName,
  expirationMonth,
  expirationYear,
  flag,
  cryptedPaymentData,
}) => ({
  card_token: id,
  card_security_code: CVC,
  installments: installmentsQuantity,
  card_holder_name: fullName,
  card_exp_month: addLeadingZero(expirationMonth),
  card_exp_year: expirationYear.toString(),
  payment_method_id: flag,
  has_marketplace_items: false,
});

export const encryptedSavedCardData = ({
  id,
  installmentsQuantity,
  encryptedCreditCard,
  flag,
}) => ({
  card_token: id,
  installments: installmentsQuantity,
  encrypted_credit_card: encryptedCreditCard,
  payment_method_id: flag,
  has_marketplace_items: false,
});

export const virtualDebitEloData = ({
  fullName,
  number,
  expirationMonth,
  expirationYear,
  CVC,
  saveCard = false,
  hasMarketplaceItems,
}) => ({
  card_holder_name: fullName,
  card_security_code: CVC,
  card_exp_month: addLeadingZero(expirationMonth),
  installments: '1',
  card_number: onlyNumbers(number),
  card_exp_year: expirationYear,
  payment_method_id: 'virtual_debit_elo',
  save_card: saveCard,
  is_default: false,
  has_marketplace_items: hasMarketplaceItems,
});

export const encryptedVirtualDebitEloData = ({
  saveCard = false,
  hasMarketplaceItems,
  encryptedCreditCard,
}) => ({
  installments: '1',
  payment_method_id: 'virtual_debit_elo',
  is_default: false,
  save_card: saveCard,
  has_marketplace_items: hasMarketplaceItems,
  encrypted_credit_card: encryptedCreditCard,
});

export const newCardData = ({
  fullName,
  number,
  expirationMonth,
  expirationYear,
  CVC,
  installmentsQuantity,
  flag,
  saveCard,
  setAsDefault,
  hasMarketplaceItems,
}) => ({
  card_holder_name: fullName,
  card_security_code: CVC,
  card_exp_month: addLeadingZero(expirationMonth),
  installments: installmentsQuantity,
  card_number: onlyNumbers(number),
  card_exp_year: expirationYear,
  payment_method_id: flag,
  save_card: saveCard,
  is_default: setAsDefault,
  has_marketplace_items: hasMarketplaceItems,
});

export const encryptedNewCardData = ({
  installmentsQuantity,
  flag,
  saveCard,
  setAsDefault,
  hasMarketplaceItems,
  encryptedCreditCard,
}) => ({
  installments: installmentsQuantity,
  payment_method_id: flag,
  save_card: saveCard,
  is_default: setAsDefault,
  has_marketplace_items: hasMarketplaceItems,
  encrypted_credit_card: encryptedCreditCard,
});

export const bankSlipData = ({ hasMarketplaceItems }) => ({
  payment_method_id: 'bank_slip',
  installments: '1',
  has_marketplace_items: hasMarketplaceItems,
});

export const pixData = ({ hasMarketplaceItems }) => ({
  payment_method_id: paymentTypes.pix,
  installments: '1',
  has_marketplace_items: hasMarketplaceItems,
});

export const valeCompraData = () => ({
  installments: '1',
  payment_method_id: null,
});

export const paymentItem = ({ storeId, categoryId, subcategoryId, quantity, sku }) => ({
  seller: storeId,
  category: categoryId,
  subcategory: subcategoryId,
  quantity,
  sku,
});

export const payment = ({
  amount,
  payment_method_name: name,
  installment_plan_description: installmentText,
  number_of_installments: installments,
  amount_per_installment: amountPerInstallment,
  payment_method_id: type,
  payment_url: url,
  items = [],
}) => ({
  amount,
  name,
  installmentText,
  installments,
  amountPerInstallment,
  type,
  url,
  items: items.map(paymentItem),
});

export const rulesDeatil = ({ type, available, sku }) => ({
  type,
  available,
  sku,
});

export const rules = ({
  rule_id: id,
  rule_description: ruleDescription,
  detail = [],
}) => ({
  id,
  ruleDescription,
  detail: detail.map(rulesDeatil),
});

export const policy = ({
  policy_id: policyId,
  policy_name: policyName,
  policy_description: policyDescription,
  status: policystatus,
  violated_rules: violatedRules = [],
}) => ({
  policyId,
  policyName,
  policyDescription,
  policystatus,
  violatedRules: violatedRules.map(rules),
});

export const bankSlip = ({ expiration, barcode_number: number }) => ({
  expiration: moment(expiration).format('DD/MM/YYYY'),
  number,
});

export const orderProduct = ({ sku: id }) => ({
  id,
});

export const placeOrder = ({ order_id: id, payments }) => ({
  id,
  payment: payment(payments[0]),
});

export const orderShippingProduct = ({
  sku: id,
  name: title,
  reference,
  quantity,
  warranty,
  gifts,
  bundles,
  subtotal,
  config,
  services,
}) => {
  return {
    id,
    quantity,
    title,
    reference,
    gifts: (gifts && gifts.map(additionalProduct)) || [],
    bundles: (bundles && bundles.map(additionalProduct)) || [],
    warranty: (warranty && basketWarranty(warranty)) || {},
    subtotal,
    variations:
      (config &&
        config
          .split(',')
          .map((c) => ({ value: c.trim() }))
          .map(variationProduct)) ||
      [],
    services: (services.length > 0 && services.map(orderService)) || [],
  };
};

export const orderShippingPackage = ({
  delivery_time: time,
  delivery_type_id: deliveryTypeId,
  delivery_parameters: parameters,
  items,
  price: shippingPrice,
  seller_description: sellerDescription,
  delivery_type: deliveryType,
}) => ({
  time,
  deliveryTypeId,
  deliveryType,
  scheduledParameters:
    (deliveryType === scheduledDeliveryChoice && scheduledParameters(parameters)) || {},
  store: sellerDescription || items[0].seller,
  products: items.map(orderShippingProduct),
  shippingPrice,
  description: parameters?.delivery_time_description || '',
});

export const orderStoreAddress = ({
  store_address: address,
  store_number: number,
  store_state: state,
  store_city: city,
  recipient_identification_document: receiverDocument,
  recipient_name: receiver,
  recipient_document: recipientDocument,
  pickup_address: pickupAddress,
}) => {
  if (pickupAddress) {
    return {
      address: pickupAddress.address,
      number: pickupAddress.number,
      state: pickupAddress.state,
      city: pickupAddress.city,
      receiverDocument: recipientDocument,
      receiver,
    };
  }

  return {
    address,
    number,
    state,
    city,
    receiverDocument,
    receiver,
  };
};
export const orderShippingStore = (store) => {
  const storeId = Object.keys(store)[0];
  const storeById = Object.values(store)[0];

  return {
    [storeId]: {
      packages: storeById.packages.map(orderShippingPackage),
      address: orderStoreAddress(storeById.address),
    },
  };
};

export const orderShippingDeliveries = ({ address = {}, packages = [] }) => {
  return {
    address: shippingAddress(address),
    packages: packages.map(orderShippingPackage),
  };
};

export const order = ({
  display_order_id: id,
  total_amount: totalAmount,
  shipping_total: shippingAmount,
  created_at,
  customer_name: customerName,
  customer_email: customerEmail,
  customer_id: customerId,
  customer_notification: customerNotification,
  billing,
  payments,
  barcode_svg: barCode,
  bank_slip: bankSlip_,
  total_products: itemQuantity,
  total_packages: packagesCount,
  shipping,
  raffles = [],
  items,
  is_new_buyer: isNewBuyer,
  policies = [],
}) => {
  return {
    id,
    totalAmount,
    shippingAmount,
    itemQuantity,
    createdAt: moment(created_at),
    customerName,
    customerEmail,
    customerId,
    customerNotification,
    customerAddress: billing && billing.address ? customerAddress(billing.address) : null,
    payment: payments[0],
    payments: payments.map(payment),
    barCode,
    bankSlip: bankSlip_ && bankSlip(bankSlip_),
    shippingTypes: {
      deliveries: shipping ? orderShippingDeliveries(shipping.deliveries || {}) : null,
      stores: shipping ? shipping.stores.map(orderShippingStore) : [],
    },
    packagesCount,
    raffles,
    items,
    isNewBuyer,
    policies: policies.map(policy),
  };
};

export const installment = ({
  total_amount: totalAmount,
  amount_per_installment: installmentAmount,
  number_of_installments: numberOfInstallments,
  interest_rate: interestRate,
  description,
  expiration_date: expirationDate,
} = {}) => ({
  totalAmount,
  installmentAmount,
  numberOfInstallments,
  interestRate,
  description,
  expirationDate,
});

export const allowedBins = ({ allowed_bins }) => {
  return {
    allowed_bins,
  };
};
