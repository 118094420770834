import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import DeliveryAddress from 'containers/DeliveryAddress';

import * as routeNames from 'constants/route-names';

import * as addressListActions from 'reducers/address-list';
import * as addressActions from 'reducers/address';

import { redirectToLogin } from 'utils/redirect';
import { addressSelectContent } from 'utils/data-layer/helpers';

@connect(null, {
  ...addressListActions,
  ...addressActions,
})
export default class CustomerAreaAddressPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    fetchCustomerAreaAddressList: PropTypes.func.isRequired,
    putCustomerAreaAddress: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  onFetchAddresses = (fetchProps) => {
    const { initializeAddressForm, router, handleFetchError } = fetchProps;
    const { location, fetchCustomerAreaAddressList } = this.props;

    return fetchCustomerAreaAddressList()
      .then((res) => {
        if (isEmpty(res)) {
          return initializeAddressForm();
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          return redirectToLogin(
            router,
            routeNames.channelCustomerAreaAddress,
            location
          );
        }

        handleFetchError();
      });
  };

  onAddressSubmit = (submitProps) => {
    const {
      postSignup,
      customerInfo,
      editAddressUuid,
      values,
      handleSubmitError,
      handleReFetchAddresses
    } = submitProps;

    const { putCustomerAreaAddress } = this.props;

    const address = Object.assign({}, values, { addressUuid: editAddressUuid });

    return new Promise((resolve, reject) => {
      return putCustomerAreaAddress(address, true)
        .then(({ addressUuid }) => addressUuid)
        .then(() => {
          addressSelectContent({
            contentId: 'sucesso',
            contentType: 'formulario-enderecos',
          });

          if (!values.setCustomerAddress) {
            return;
          }

          const dataCustomer = Object.assign({}, customerInfo, {
            address: values.address,
            city: values.city,
            complement: values.complement,
            neighbourhood: values.neighbourhood,
            number: values.number || 'S/N',
            reference: values.reference,
            state: values.state,
            zipcode: values.zipcode,
          });

          postSignup('pf', dataCustomer);
        })
        .then(handleReFetchAddresses)
        .then(resolve)
        .catch((error) => {
          addressSelectContent({
            contentId: `erro: Não foi possível salvar o endereço | status:${error.status}`,
            contentType: 'formulario-enderecos',
          });
          
          handleSubmitError();

          return reject(error);
        });
    });
  }

  render() {
    return (
      <DeliveryAddress
        location={this.props.location}
        redirectToLoginNext={routeNames.channelCustomerAreaAddress}
        onFetchAddresses={this.onFetchAddresses}
        onAddressSubmit={this.onAddressSubmit}
        putDeliveryAddress={this.props.putCustomerAreaAddress}
        isCheckoutPage={false}
      />
    );
  }
}
