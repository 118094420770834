export const serviceCategoryName = (category) => {
  if (category === 'GE') {
    return 'garantia-estendida';
  }
  if (category === 'RF') {
    return 'seguro';
  }
  if (category === 'TF') {
    return 'troca-certa';
  }
  return '';
};
