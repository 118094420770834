import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

import { addLeadingZero } from 'utils/maskers';

import Dropdown from 'components/Form/Dropdown/Dropdown';
import InputError from 'components/Form/InputError/InputError';
import InputSuccess from 'components/Form/InputSuccess/InputSuccess';

export const PaymentExpirationItem = ({
  month,
  year,
  labelText = '',
}) => {
  const currentYear = (new Date()).getFullYear();
  return (
    <div className="FormGroup--multiple">
      <Dropdown
        className={`FormGroup-input FormGroup-input${!year.active && year.touched && !!year.error && '--withError' || ''}`}
        labelText={labelText}
        hideSuccess
        field={month}
        customError={<InputError
          className="FormGroup-errorMessage"
          field={year}
          errorMessage={!year.active && year.touched && year.error || ''}
        />}
        inputSize="extraSmall"
      >
        <option key="month-null" value="-1">Mês</option>
        {range(1, 13).map(i =>
          <option
            key={`month-${i}`}
            value={i}>
            {addLeadingZero(i)}
          </option>
        )}
      </Dropdown>

      <Dropdown
        hideError
        hideSuccess
        hideLabel
        field={year}
        inputSize="extraSmall"
      >
        <option key="year-null" value="-1">Ano</option>
        {range(currentYear, currentYear + 30).map(i =>
          <option key={`year-${i}`} value={i}>
            {i}
          </option>
        )}
      </Dropdown>

      {month.value !== '-1' && year.value !== '-1'
        ? <InputSuccess field={year} />
        : null
      }
    </div>
  );
};

PaymentExpirationItem.propTypes = {
  month: PropTypes.object.isRequired,
  year: PropTypes.object.isRequired,
  labelText: PropTypes.string,
};

export default PaymentExpirationItem;
