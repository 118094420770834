import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as customLabes from 'constants/signin-labels';
import * as validators from 'utils/validators';
import { REDIRECT_COMPANY_URL } from 'constants/channels';
import { BUTTON_LOGIN_ID } from 'constants/ids';
import { getChannelConfig } from 'utils/session';
import ButtonShowPassword from 'components/Form/ButtonShowPassword/ButtonShowPassword';
import FormGroup from 'components/Form/FormGroup/FormGroup';
import './LoginBox.scss';

const enablePJRedirect =
  getChannelConfig('enable_pj_customer_redirect_to_magalu_empresas') ?? false;

class LoginBox extends Component {
  static propTypes = {
    configs: PropTypes.object,
    onForgotPassword: PropTypes.func.isRequired,
    loginField: PropTypes.object.isRequired,
    passwordField: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showPassword: PropTypes.bool.isRequired,
    handleShowPasswordClick: PropTypes.func.isRequired,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isRequesting: PropTypes.bool.isRequired,
    captcha: PropTypes.node,
  };

  constructor(props) {
    super(props);

    if (
      !!props.configs.type_authentication &&
      validators.isAuthenticationByCpf(props.configs.type_authentication)
    ) {
      this.state = { authentication_label: customLabes.labelCpf };
    } else if (
      !!props.configs.type_authentication &&
      validators.isAuthenticationByEmail(props.configs.type_authentication)
    ) {
      this.state = { authentication_label: customLabes.labelEmail };
    } else if (!enablePJRedirect) {
      this.state = { authentication_label: customLabes.labelEmailCpfCnpj };
    } else {
      this.state = { authentication_label: customLabes.labelEmailCpfEnabledRedirect };
    }
  }

  handleChange = (event) => {
    this.props.loginField.onChange(event.target.value.toLowerCase());
  };

  render() {
    const {
      loginField,
      passwordField,
      handleSubmit,
      handleShowPasswordClick,
      showPassword,
      errorMessage: loginErrorMessage,
      isRequesting,
      captcha,
      onForgotPassword,
    } = this.props;
    const magaluEmpresasUrl = getChannelConfig(REDIRECT_COMPANY_URL);
    const formId = 'LoginBox-form';

    return (
      <div className="LoginBox">
        <div className="LoginBox-title">Já sou cliente</div>
        <form id={formId} className="LoginBox-form" onSubmit={handleSubmit} method="post">
          <FormGroup
            autocomplete="username"
            labelText={this.state.authentication_label}
            field={loginField}
            hideError={!!loginErrorMessage || isRequesting}
            handleChange={this.handleChange}
            inputSize="long"
          />
          <FormGroup
            autocomplete="current-password"
            labelText="Senha"
            field={Object.assign({}, passwordField, {
              type: (showPassword && 'text') || 'password',
            })}
            hideError={!!loginErrorMessage || isRequesting}
            hideSuccess
            inputSize="long">
            <ButtonShowPassword
              field={showPassword}
              handleClick={() => handleShowPasswordClick('showPassword')}
            />
          </FormGroup>
          {loginErrorMessage && (
            <p className="LoginBox-form-error--unique">
              {loginErrorMessage.title && (
                <span className="font-2xsm-bold mb-2xsm">{loginErrorMessage.title}</span>
              )}
              <span>{loginErrorMessage?.description || loginErrorMessage}</span>
            </p>
          )}
          {captcha}
          <button className="LoginBox-form-continue" id={BUTTON_LOGIN_ID}>
            Continuar
          </button>
        </form>
        <div className="LoginBox-help">
          <a
            href="#"
            onClick={onForgotPassword}
            data-ga='{"category": "Login", "action": "Esqueci minha senha", "label": "Selecionou"}'>
            Esqueci minha senha
          </a>
        </div>
        {enablePJRedirect && (
          <div className="LoginBox-help">
            <strong className="LoginBox-strong">Possui CNPJ? </strong>Faça seu login pelo&nbsp;
            <a href={magaluEmpresasUrl}>Portal Magalu Empresas</a>
          </div>
        )}
      </div>
    );
  }
}

export default LoginBox;
