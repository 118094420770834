import { GTM_FALSE, GTM_TRUE } from './constants';

export const getProductAvailability = (product) => {
  const reason = product?.isSoldOut ? 'sem-estoque' : 'cep-indisponivel';
  if (product?.isSoldOut || product?.notAvailableForDelivery) {
    return `${GTM_FALSE}:${reason}`;
  }

  return GTM_TRUE;
};
