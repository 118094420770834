import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WhatsAppRegisteredIcon from 'icons/icon-whatsapp-registered.svg';

import './WhatsAppFeedBack.scss';

class WhatsAppFeedBack extends Component {
  render() {
    return (
      <section className={`WhatsAppFeedBack WhatsAppOption__${this.props.handlerShowFeedBack}`}>
        <div className="WhatsAppOption__lateral-borders">
          <div className="WhatsAppFeedBack__icon-whatsapp-registered">
            <WhatsAppRegisteredIcon />
          </div>
          <div className="WhatsAppFeedBack__content">
            <p className="WhatsAppFeedBack__description">Seu WhatsApp foi registrado com sucesso! :)</p>
          </div>
        </div>
      </section>
    );
  }
}

export default WhatsAppFeedBack;

WhatsAppFeedBack.propTypes = {
  handlerShowFeedBack: PropTypes.string
};
