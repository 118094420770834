import React from 'react';

import Modal from 'components/Modal/Modal';
import ModalUserDocumentIcon from 'icons/payment/modal/icon-user-doc.svg';
import { addToDatalayer } from 'utils/gtm';
import './InternationalPurchaseValidationModal.scss';

const ModalContent = (props) => {

  const handleClick = () => {
    const layer = {
      event: 'clickEvent',
      eventCategory: 'revisao-pedido',
      eventAction: 'click',
      eventLabel: 'voltar-para-sacola',
      eventNonInteraction: '0',
      hitTimestamp: `${new Date().getTime()}`,
    };

    addToDatalayer(layer, window.dataLayer || []);
    return props.toggle();
  };

  return (
    <div className="InternationalPurchaseValidationModal">
      <div className="InternationalPurchaseValidationModal-header">
        <ModalUserDocumentIcon className="InternationalPurchaseValidationModal-icon" />

        <div className="InternationalPurchaseValidation-header">
          <span className="font-lg-bold">{props?.title}</span>
        </div>
      </div>

      <div className="InternationalPurchaseValidationModal-content">
        <p className="font-sm-regular">
            O CPF dessa conta está em situação irregular na Receita Federal, o que impede
            compras internacionais.
        </p>
        <p className="font-sm-regular">
          Para continuar com as compras nacionais remova os itens internacionais da
          sacola.
        </p>
        <div className="InternationalPurchaseValidationModal-footer">
          <button
            className="InternationalPurchaseValidationModal-btn BtnLarge"
            onClick={handleClick}>
          Voltar para sacola
          </button>
        </div>
      </div>
    </div>
  );
};

const InternationalPurchaseValidationModal = ({ isOpen, toggle }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Não foi possível concluir seu pedido."
      content={ModalContent}
      withoutFooter
      withoutHeader
      toggle={toggle}
      mobileIsDrawer
    />
  );
};

export default InternationalPurchaseValidationModal;