import React from 'react';
import PropTypes from 'prop-types';

const BasketItemAdditionalProduct = ({
  title,
  productUrl,
}) => (
  <a className="BasketItemProduct-info-extra" href={productUrl}>
    {title}
  </a>
);

BasketItemAdditionalProduct.propTypes = {
  title: PropTypes.string.isRequired,
  productUrl: PropTypes.string.isRequired,
};

export default BasketItemAdditionalProduct;
