import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';
import { moveCursorToLastChar } from 'utils/moveCursorToLastChar';

import FormGroup from 'components/Form/FormGroup/FormGroup';
import InputCvvForm from 'components/Form/InputCvvForm';
import PaymentFormButton from 'components/Payment/Forms/PaymentFormButton/PaymentFormButton';
import PaymentExpirationItem from 'components/Payment/Forms/PaymentExpirationItem/PaymentExpirationItem';
import CardIcon from 'icons/payment/icon-credit-card.svg';
import UserIcon from 'icons/icon-user.svg';

export const VirtualDebitEloForm = ({
  price,
  number: numberField,
  fullName,
  expirationMonth,
  expirationYear,
  CVC,
  handleSubmit
}) => (
  <form
    className="VirtualDebitEloForm"
    onSubmit={handleSubmit}
    method="post"
  >
    <FormGroup
      labelText="Número do cartão"
      inputSize="long"
      field={numberField}
      hideSuccess
      handleKeyUp={moveCursorToLastChar}
      type="tel"
      icon={<CardIcon className="FormGroup-icon-card"/>}
    >
    </FormGroup>
    <FormGroup
      labelText="Nome impresso no cartão"
      inputSize="long"
      field={fullName}
      icon={<UserIcon className="FormGroup-icon-user" />}
    >
    </FormGroup>
    <PaymentExpirationItem
      labelText="Validade"
      month={expirationMonth}
      year={expirationYear}
    />
    <InputCvvForm CVC={CVC} flag={{ value: 'virtual_debit_elo' }} />

    <div className="BankSlipForm-price">
      <span className="BankSlipForm-price--highlighted">
        {toBRL(price)} à vista
      </span>
    </div>

    <PaymentFormButton
      paymentType="Cartão de débito virtual Caixa"
      flag={'virtual_debit_elo'}
    />
    <p className="VirtualDebitEloForm-limit-warning">
      Limite diário para uso desse cartão R$1.000,00
    </p>
  </form>
);

VirtualDebitEloForm.propTypes = {
  price: PropTypes.string.isRequired,
  number: PropTypes.object.isRequired,
  fullName: PropTypes.object.isRequired,
  expirationMonth: PropTypes.object.isRequired,
  expirationYear: PropTypes.object.isRequired,
  CVC: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default VirtualDebitEloForm;
