export function copyToClipboard({
  fallbackMessage,
  fallbackValue,
  textarea,
  updateState,
}) {
  let haveCopy = false;

  try {
    textarea.select();
    haveCopy = document.execCommand('copy');
    window.getSelection().removeAllRanges();
    updateState();
  } catch (ex) {
    window.prompt(fallbackMessage, fallbackValue); // eslint-disable-line no-alert
  }

  if (!haveCopy) {
    window.prompt(fallbackMessage, fallbackValue); // eslint-disable-line no-alert
  }
}
