import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CheckoutHeader from 'components/Header/CheckoutHeader/CheckoutHeader';
import ExternalLoader from 'components/ExternalLoader';
import Navigation from 'components/CheckoutNavigation/Navigation';

const mapStateToProps = ({ channel }) => ({
  siteScripts: channel.configs.site_scripts,
  siteStyles: channel.configs.site_styles,
  headerURL: channel.configs.site_header_url,
});

@connect(mapStateToProps, undefined)
export default class ChannelHeaderContainer extends Component {
  static propTypes = {
    siteScripts: PropTypes.arrayOf(PropTypes.string),
    siteStyles: PropTypes.arrayOf(PropTypes.string),
    headerURL: PropTypes.string,
  };

  render() {
    return (
      <div>
        <ExternalLoader
          externalURL={this.props.headerURL}
          externalScripts={this.props.siteScripts}
          externalStyles={this.props.siteStyles}
          fallback={CheckoutHeader}
        />
        <Navigation />
      </div>
    );
  }
}
