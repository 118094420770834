import React from 'react';
import PropTypes from 'prop-types';

import Responsive from 'components/Responsive';
import InputPinCode from 'components/InputPinCode/InputPinCode';
import Timer from 'components/Timer/Timer';
import * as maskers from 'utils/maskers';
import * as otpActions from 'reducers/send-otp';
import * as failOtpActions from 'reducers/fail-otp';
import { connect } from 'react-redux';

import OtpErrorNotification from '../OtpErrorNotification/OtpErrorNotification';
import './OtpCode.scss';

const DEFAULT_OTP_POPUP_ERROR_500 = 'error500';
const DEFAULT_OTP_POPUP_ERROR_422 = 'error422';
const EMPTY_OBJECT_ERROR = {
  statusCode: '',
  message: '',
};

const mapDispatchToActions = {
  resetOtp: otpActions.resetOtp,
  resetFail: failOtpActions.resetFailOtp,
  trackChangeDataScreenOTP: otpActions.trackChangeDataScreenOTP,
};

@connect(null, mapDispatchToActions)
class OtpCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pinValue: '',
      renderTimeout: null,
    };

    this.handleResendCode = this.handleResendCode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectDeliveryMode = this.handleSelectDeliveryMode.bind(this);
    this.renderExpiresErrorComponent = this.renderExpiresErrorComponent.bind(this);
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  handleResendCode() {
    const { onSelectDeliveryMode, channel } = this.props;
    this.setState({ pinValue: '' });
    return onSelectDeliveryMode(
      {
        to: this.userContactInfo,
        delivery_mode: channel.delivery_mode.toLowerCase(),
      },
      true
    );
  }

  async handleSelectDeliveryMode(deliveryMode) {
    const { onSelectDeliveryMode } = this.props;

    await onSelectDeliveryMode(deliveryMode);
    this.setState({ pinValue: '' });
  }

  handleChange(pinValue) {
    this.setState({ pinValue });
  }

  componentWillMount() {
    const { contacts, channel } = this.props;
    this.userContactInfo = contacts[channel.delivery_mode.toLowerCase()];
  }

  componentWillUnmount() {
    const { onRender } = this.props;

    if (onRender) {
      clearTimeout(this.state.renderTimeout);
    }
  }

  renderExpiresErrorComponent(contacts, expiresAt, error) {
    return (
      <div className="OtpCode-timer">
        <Timer expiresAt={expiresAt} onResendSubmit={this.handleResendCode} />
      </div>
    );
  }

  handleSelectOtherConfirmationOtp = () => {
    const { resetOtp, channel } = this.props;
    return resetOtp(channel.delivery_mode);
  };

  handleResetOtpError = () => {
    const { resetFail } = this.props;
    return resetFail();
  };

  handleLinkClick = () => {
    const { trackChangeDataScreenOTP, channel } = this.props;
    const { router } = this.context;
    const currentDeliveryModeLink = channel.delivery_mode === 'email' ? 'email' : 'telefone';
    trackChangeDataScreenOTP(currentDeliveryModeLink);
    router.goBack();
  };

  componentDidMount() {
    const { onRender, channel } = this.props;

    if (onRender) {
      const renderTimeout = setTimeout(() => {
        if (this.state.renderTimeout) {
          onRender(channel?.delivery_mode);
        }
      }, 1000);

      this.setState({ renderTimeout });
    }
  }

  render() {
    const {
      contacts,
      expiresAt,
      error,
      channel,
      onComplete,
      onCloseOtpModal,
      bothMethodsAvailable,
      isModal,
      otpCodeOnly,
      isMobile,
    } = this.props;
    const isChannelEmail = channel.delivery_mode === 'email';
    const userContactInfo = isChannelEmail ? this.userContactInfo : maskers.mobilePhoneApp(this.userContactInfo);
    const currentDeliveryMode =
      isChannelEmail ? channel.description : 'telefone';
    const popUpError = error?.statusCode === 500 || error?.statusCode === 422;

    return (
      <div className={`OtpCode-wrapper${isModal ? '-modal' : ''}`}>
        {popUpError ? (
          <OtpErrorNotification
            onResetAction={onComplete}
            onResetError={this.handleResetOtpError}
            isMobile={isMobile}
            errorOtp={
              error?.statusCode === 500
                ? DEFAULT_OTP_POPUP_ERROR_500
                : DEFAULT_OTP_POPUP_ERROR_422
            }
          />
        ) : (
          ''
        )}
        <div className="OtpCode-content">
          <div className="OtpCode-info">
            <h2 className="OtpCode-title">Informe o código de verificação</h2>
            <span className="OtpCode-text-info">
              Enviamos um código de 6 dígitos para o {channel.description}{' '}
              <span className="OtpCode-info-user">{userContactInfo}</span>. {channel?.extraDescription}
              <br />
              {otpCodeOnly && (
                <div className="OtpCode-text-info__link">
                  <a onClick={this.handleLinkClick}>Alterar {currentDeliveryMode}</a>
                </div >
              )
              }
            </span>
          </div >

          <InputPinCode
            error={popUpError ? EMPTY_OBJECT_ERROR : error}
            value={this.state.pinValue}
            onChange={this.handleChange}
            success={false}
            onComplete={onComplete}
          />

          {this.renderExpiresErrorComponent(contacts, expiresAt, error)}

          {
            isModal && (
              <button className="OtpCode-outlined-btn" onClick={() => onCloseOtpModal()}>
                Editar meus dados
              </button>
            )
          }

          {
            !isModal && bothMethodsAvailable && !otpCodeOnly && (
              <button
                className="OtpCode-outlined-btn"
                onClick={() => this.handleSelectOtherConfirmationOtp()}>
                Usar outro meio de confirmação
              </button>
            )
          }
        </div >
      </div >
    );
  }
}

OtpCode.propTypes = {
  onRender: PropTypes.func,
  onSelectDeliveryMode: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  expiresAt: PropTypes.number.isRequired,
  updateCustomTo: PropTypes.func,
  onCloseOtpModal: PropTypes.func,
  channel: PropTypes.shape({
    delivery_mode: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  contacts: PropTypes.shape({
    email: PropTypes.string,
    sms: PropTypes.string,
  }).isRequired,
  error: PropTypes.shape({
    statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    message: PropTypes.string,
  }),
  isModal: PropTypes.bool.isRequired,
  otpCodeOnly: PropTypes.bool.isRequired,
};

export default Responsive(OtpCode);
