import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as routeNames from 'constants/route-names';
import { SCREEN_NAME_OPTIONS } from 'utils/data-layer/customerArea';
import { resetOtpId } from 'utils/manageOtpIds';
import { getChannelConfig } from 'utils/session';
import { getSuccessDataChangeText } from 'utils/customerDataChangeSuccess';
import verifyIfMiniapp from 'utils/verifyIfMiniapp';

import * as customerActions from 'reducers/customer';
import * as customerAreaActions from 'reducers/customer-area';
import * as loadingActions from 'reducers/loading';
import * as maskers from 'utils/maskers';

import './CustomerAreaPage.scss';
import miniappValidateSession from 'utils/miniappValidateSession';

const mapStateToProps = ({ signup, customer, address, channel }) => ({
  customerInfo: customer.customerInfo,
  customerRequesting: customer.isRequesting,
  addressRequesting: address.isRequesting,
  signupRequesting: signup.isRequesting,
  useCaptcha: channel.configs.use_captcha,
});

const mapDispatchToActions = {
  fetchCustomer: customerActions.fetchCustomer,
  toggleLoading: loadingActions.toggleLoading,
  showLoading: loadingActions.showLoading,
  hideLoading: loadingActions.hideLoading,
  trackStartChange: customerAreaActions.trackStartChange,
  trackSuccessChange: customerAreaActions.trackSuccessChange,
  trackVirtualPageviewApp: customerAreaActions.trackVirtualPageviewApp,
};

const customerLoadingID = 'customerLoadingID';
const ENABLE_EMAIL_OTP_EDIT_DEFAULT = getChannelConfig('enable_email_otp_edit') ?? true;
const ENABLE_SMS_OTP_EDIT_DEFAULT = getChannelConfig('enable_sms_otp_edit') ?? true;

@connect(mapStateToProps, mapDispatchToActions)
export default class CustomerAreaPage extends Component {
  static propTypes = {
    customerInfo: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    fetchCustomer: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    showLoading: PropTypes.func.isRequired,
    hideLoading: PropTypes.func.isRequired,
    trackStartChange: PropTypes.func.isRequired,
    trackSuccessChange: PropTypes.func.isRequired,
    trackVirtualPageviewApp: PropTypes.func.isRequired,
  };

  state = {
    otpSuccess: false,
    opcao: null,
  };

  showSuccess = (opcao) => {
    this.setState({
      otpSuccess: true,
      opcao,
    });
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const { location, fetchCustomer, trackVirtualPageviewApp } = this.props;
    const { router } = this.context;
    const { pathname } = location;

    if (location.query?.otpSuccess === 'true') {
      this.showSuccess(location.query.opcao);
    }

    resetOtpId();
    miniappValidateSession(router, pathname, location).then(async () => {
      const customer = await fetchCustomer();
      trackVirtualPageviewApp(customer, SCREEN_NAME_OPTIONS);
    });
  }

  componentWillReceiveProps(nextProps) {
    const { customerRequesting, toggleLoading } = nextProps;

    toggleLoading(customerLoadingID, customerRequesting);
  }

  componentDidMount() {
    const { trackSuccessChange, history, location } = this.props;
    const { otpSuccess, opcao } = this.state;

    if (otpSuccess) {
      trackSuccessChange(opcao);
      this.goBackApp(location, history);
    }
  }

  goBackApp = (location, history) => {
    if (location.query.deeplink) {
      const goBackAppSteps = window.history.length - 1;
      history.go(-goBackAppSteps);
    }
  };

  handleNavigate = (query) => {
    const { trackStartChange, location, customerInfo } = this.props;
    const { router } = this.context;
    const isPJ = customerInfo.accountType === 'pj';

    if (query === 'sms' && isPJ) {
      router.push(`${routeNames.editRegistration}`);
    } else {
      router.push(
        `${routeNames.channelCustomerAreaConfirm}?opcao=${query}${
          location.query.deeplink ? '&deeplink=app' : ''
        }`
      );

      trackStartChange(query);
    }
  };

  render() {
    const {
      ENABLE_EMAIL_OTP_EDIT = ENABLE_EMAIL_OTP_EDIT_DEFAULT,
      ENABLE_SMS_OTP_EDIT = ENABLE_SMS_OTP_EDIT_DEFAULT,
      customerRequesting,
      customerInfo,
    } = this.props;
    const { otpSuccess } = this.state;
    const isPJ = customerInfo?.accountType === 'pj';
    const pendingEmailUpdate = !!customerInfo?.pendingDataChange?.email;
    const pendingTelephoneUpdate = !!customerInfo?.pendingDataChange?.phone;
    const hidePhoneChange = isPJ && verifyIfMiniapp();
    const successText = getSuccessDataChangeText(
      pendingEmailUpdate,
      pendingTelephoneUpdate
    );

    if (!customerRequesting) {
      return (
        <section className="CustomerAreaPage">
          <div className="CustomerAreaPage-container">
            <div className="CustomerAreaPage-content">
              {(otpSuccess || pendingEmailUpdate || pendingTelephoneUpdate) && (
                <div className="CustomerAreaPage-success">
                  <span className="ti ti-check-circle" />
                  <span className="CustomerAreaPage-success-text">{successText}</span>
                </div>
              )}
              <h2>E-mail e telefone</h2>
              <div className="CustomerAreaPage-content-option">
                <div className="CustomerAreaPage-content-description">
                  <span>E-mail</span>
                  <p>{customerInfo?.email}</p>
                </div>
                {ENABLE_EMAIL_OTP_EDIT && !pendingEmailUpdate && (
                  <a onClick={() => this.handleNavigate('email')}>Alterar</a>
                )}
              </div>
              <div className="CustomerAreaPage-content-option">
                <div className="CustomerAreaPage-content-description">
                  <span>Telefone de contato</span>
                  <p>
                    {maskers.mobilePhoneApp(
                      customerInfo?.telephone || customerInfo?.mobilePhone
                    )}
                  </p>
                </div>
                {ENABLE_SMS_OTP_EDIT && !hidePhoneChange && !pendingTelephoneUpdate && (
                  <a onClick={() => this.handleNavigate('sms')}>Alterar</a>
                )}
              </div>
              {hidePhoneChange && (
                <div className="CustomerAreaPage-warn">
                  <span className="ti ti-warning-amber" />
                  <span className="CustomerAreaPage-warn-text">
                    Para realizar alteração de telefone em sua conta PJ acesse o Site
                    Magalu.
                  </span>
                </div>
              )}
            </div>
          </div>
        </section>
      );
    }

    return null;
  }
}
