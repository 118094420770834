import request from 'utils/request';

import { recommendation as recommendationModel } from 'api/models/recommendation-models';

export const getShowcasesRecommendation = ({ params, items }) => {
  const { pageId, placementId } = params;
  const itemsPayload = items.length ? { items } : {};

  return request('POST', `api/recommendation/${pageId}/${placementId}/`)
    .send(itemsPayload)
    .then((res) => res.body)
    .then((json) => recommendationModel(json));
};
