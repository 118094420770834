import React from 'react';


const BasketCustomerCompanyServicesWarning = () => (
  <div className="BasketItem-customerCompanyWarning">
    Este serviço não pode ser adquirido por empresas.
  </div>
);

export default BasketCustomerCompanyServicesWarning;
