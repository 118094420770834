/* eslint-disable camelcase */
import { GTM_UNDEFINED } from './constants';

export function categoryFromId({ category_id, subcategory_id, categoryId, subcategoryId } = {}) {
  if (!category_id && !subcategory_id && !categoryId && !subcategoryId) {
    return GTM_UNDEFINED;
  }

  return category_id
    ? `${category_id}/${subcategory_id}`.toLowerCase()
    : `${categoryId}/${subcategoryId}`.toLowerCase();
}
