import React from 'react';
import PropTypes from 'prop-types';

import {
  inputClassName,
  inputGroupClassName,
} from 'utils/classnames';

import InputError from 'components/Form/InputError/InputError';
import DropdownSuccess from 'components/Form/DropdownSuccess/DropdownSuccess';

const Dropdown = ({
  children,
  field = {},
  labelText = '',
  inputSize,
  hideError = false,
  hideSuccess = false,
  hideLabel = false,
  customError = null,
  className = 'FormGroup-input',
  containerClassName = 'FormGroup',
  modifiedcontainerClassName = 'FormGroup-inputGroup',
  labelClassName = 'FormGroup-label',
  errorClassName = 'FormGroup-errorMessage',
  autocomplete,
}) => {
  const id = field.name ? { id: field.name } : undefined;

  const hasError = !field.active && field.touched && field.error;
  const selectClassName = inputClassName(className, hasError);
  const inputGroupClass = inputGroupClassName(modifiedcontainerClassName, inputSize);

  return (
    <div className={containerClassName}>
      {!hideLabel &&
        <label
          className={`${labelClassName} active--input`}
          htmlFor={field.name}
        >
          {labelText}
        </label>
      }

      <div className={inputGroupClass}>
        <select
          {...id}
          className={selectClassName}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          onFocus={field.onFocus}
          autoComplete={autocomplete}
        >
          {children}
        </select>

        {!hideError && (
          customError ? customError : (
            <InputError
              className={`${errorClassName}`}
              field={field}
            />
          )
        )}
      </div>

      {!hideSuccess && (
        <div
          className="FormGroup-feedback"
        >
          <DropdownSuccess field={field} />
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string.isRequired,
    active: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  labelText: PropTypes.string,
  inputSize: PropTypes.string,
  hideError: PropTypes.bool,
  hideSuccess: PropTypes.bool,
  hideLabel: PropTypes.bool,
  customError: PropTypes.element,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  autocomplete: PropTypes.string,
};

export default Dropdown;
