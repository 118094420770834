import { getCustomer } from 'utils/session';
import verifyIfMiniapp from 'utils/verifyIfMiniapp';
import { getCityUf } from './helpers';
import { removeFields } from './helpers/removeAppFields';

const miniAppNameKey = 'mini_app_name';
const pageType = 'alteracao-dados-cadastrais';
const miniAppField = { [miniAppNameKey]: pageType };
export const getDeliveryMode = (mode) => (mode === 'email' ? 'email' : 'telefone');

export const SCREEN_NAME_OPTIONS = 1;
export const SCREEN_NAME_OTP = 2;
export const SCREEN_NAME_CHANGE = 3;
export const SCREEN_NAME_CODE = 4;

const getScreenviewSteps = (mode, step) => {
  const steps = [
    `${pageType}-opcoes`,
    `${pageType}-otp`,
    `${pageType}:alterar:${getDeliveryMode(mode)}`,
    `${pageType}:${getDeliveryMode(mode)}-codigo`,
  ];
  return steps[step];
};

export const startChangeCustomer = ({ mode }) => {
  const layer = {
    event: 'clickEvent',
    eventCategory: 'email-telefone',
    eventAction: 'click',
    eventLabel: `alterar:${getDeliveryMode(mode)}`,
    screenName: 'email-telefone',
    ...miniAppField,
  };

  return removeFields(layer, [miniAppNameKey]);
};

export const successChangeCustomer = ({ mode }) => {
  const layer = {
    event: 'clickEvent',
    eventCategory: 'email-telefone',
    eventAction: 'impression',
    eventLabel: `${getDeliveryMode(mode)}-alterado-com-sucesso`,
    screenName: 'email-telefone',
    ...miniAppField
  };

  return removeFields(layer, [miniAppNameKey]);
};

export const dataChangeCustomer = ({ mode }) => {
  const layer = {
    event: 'clickEvent',
    eventCategory: `alteracao-dados-cadastrais:alterar:${getDeliveryMode(mode)}`,
    eventAction: 'click',
    eventLabel: `alterar-${getDeliveryMode(mode)}`,
    screenName: `alteracao-dados-cadastrais:alterar:${getDeliveryMode(mode)}`,
    ...miniAppField
  };

  return removeFields(layer, [miniAppNameKey]);
};

export const dataChangeResponse = ({ mode, status, description } = {}) => {
  const layer = {
    event: 'clickEvent',
    eventCategory: `alteracao-dados-cadastrais:alterar:${getDeliveryMode(mode)}`,
    eventAction: status,
    eventLabel: description,
    screenName: `alteracao-dados-cadastrais:alterar:${getDeliveryMode(mode)}`,
    ...miniAppField,
  };

  return removeFields(layer, [miniAppNameKey]);
};

export const customVirtualPageview = ({ customer: customerFromApi, step, mode }) => {
  const customerFromSession = getCustomer();
  const deliveryMode = getDeliveryMode(mode);
  const screenName = getScreenviewSteps(deliveryMode, --step);

  const layer = {
    cidadeuf: getCityUf(customerFromApi?.city, customerFromApi?.state),
    loginAtivo: 'logado',
    idUsuario: customerFromApi?.customerUuid || customerFromSession?.id,
    mundos: 'magalu',
    screenName,
    experimentId: undefined,
    pageType,
    hitTimestamp: `${Date.now()}`,
    ...miniAppField,
  };

  const isMiniapp = verifyIfMiniapp();

  layer.event = isMiniapp ? 'customScreenview' : 'virtualPageview';

  return isMiniapp ? layer : removeFields(layer, [miniAppNameKey]);
};
