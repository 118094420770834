import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';

import { pixDescription, cardDescription } from 'constants/price-descriptions';
import InternationalTax from 'components/InternationalTax/InternationalTax';

const BasketItemPrice = ({
  unavailable,
  prices: { to, cash, checkoutPriceDescription, unitPriceDescription },
  services,
  isInternational,
  tax,
}) => {
  const className = !unavailable
    ? 'BasketItemProduct-price'
    : 'BasketItemProduct-price--unavailable';

  return (
    <div className={className}>
      {cash && parseFloat(cash) && (
        <span>
          <span className="BasketItemProduct-to">
            {toBRL(cash)} {checkoutPriceDescription || pixDescription}
          </span>
          <br /> ou
        </span>
      )}{' '}
      {services?.length === undefined && (
        <span>
          {to && toBRL(to)} {unitPriceDescription || cardDescription}
        </span>
      )}
      {services?.length > 0 && <span>{to && toBRL(to)}</span>}
      {isInternational && (
        <InternationalTax
          tax={{
            ...tax,
            total: to,
          }}
        />
      )}
    </div>
  );
};

BasketItemPrice.propTypes = {
  unavailable: PropTypes.bool.isRequired,
  prices: PropTypes.shape({
    to: PropTypes.string.isRequired,
    cash: PropTypes.string,
    checkoutPriceDescription: PropTypes.string,
    unitPriceDescription: PropTypes.string,
  }).isRequired,
  services: PropTypes.array,
  isInternational: PropTypes.bool,
  tax: PropTypes.shape({
    icms: PropTypes.string,
    importValue: PropTypes.string,
    iof: PropTypes.string,
  }),
};

export default BasketItemPrice;
