import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';
import DialogBottomSheet from 'components/DialogBottomSheet';

class ErrorModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    closeText: PropTypes.string,
    retryText: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onRetry: PropTypes.func,
    retryBtnClassName: PropTypes.string
  };

  static defaultProps = {
    title: 'Ocorreu uma falha',
    message: 'Por favor, aguarde alguns instantes e tente novamente.',
    closeText: 'Fechar',
    retryText: 'Tentar novamente',
    retryBtnClassName: 'btn-outline',
  };

  handleRetry = () => {
    const { onClose, onRetry } = this.props;
    onRetry?.();
    onClose?.();
  };

  handleClose = (e) => {
    const { onClose } = this.props;
    e?.preventDefault?.();
    onClose?.();
  };

  render() {
    const { closeText, message, title, open, onClose, onRetry, retryText, retryBtnClassName } = this.props;

    return (
      <DialogBottomSheet
        title={<span className="text-center pt-lg font-lg-bold">{title}</span>}
        open={open}
        onClose={onClose}
        footerContent={
          <div className="flex flex-col items-center">
            {!!onRetry && (
              <button
                className={cls('btn flex justify-center w-[170px] mb-xsm', retryBtnClassName)}
                onClick={this.handleRetry}>
                {retryText}
              </button>
            )}
            {!!onClose && !onRetry && (
              <button className="btn flex justify-center w-[170px] mb-sm" onClick={this.handleClose}>
                {closeText}
              </button>
            )}
          </div>
        }>
        <p className="text-center">
          {message}
        </p>
      </DialogBottomSheet>
    );
  }
}

export default ErrorModal;
