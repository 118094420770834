import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import BobbyProduct from 'components/Bobby/BobbyProduct/BobbyProduct';
import arrow from 'components/Bobby/Arrow';

import Responsive from 'components/Responsive';


class BobbyCarousel extends Component {
  render() {
    const {
      titleComponent,
      products = [],
      carouselName,
      handleLinkClick,
      className,
      isMobile,
    } = this.props;
    const CAROUSELSIZE_DEFAULT = 4;
    const CAROUSELSIZE_MOBILE = 2;

    const carouselSize = isMobile ? CAROUSELSIZE_MOBILE : CAROUSELSIZE_DEFAULT;
    const showArrow = products.length > carouselSize;

    const containerClassName = isMobile
      ? 'BobbyContainer-title'
      : 'BobbyContainer-title--bigger';

    const productsShowing = (current) => {
      const last = (products.length > current + carouselSize)
        ? current + carouselSize
        : products.length;
      this.props.onProductImpression(products, current, last);
    };
  
    productsShowing(0);

    return (
      <div className={`${className || 'BobbyCarousel'}`}>
        <div className={containerClassName}>{titleComponent}</div>
        <div className="BobbyContainer">
          <Slider
            dots={isMobile}
            infinite={false}
            speed={500}
            slidesToShow={carouselSize}
            slidesToScroll={carouselSize}
            prevArrow={arrow('prev', showArrow)}
            nextArrow={arrow('next', showArrow)}
            afterChange={current => productsShowing(current)}
          >
            {products.map(product => (
              <BobbyProduct
                key={product.id}
                carouselName={carouselName}
                handleLinkClick={handleLinkClick}
                priceDescription={product.best_price?.payment_method_description || 'à vista'}
                product={product}
              />
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

BobbyCarousel.propTypes = {
  products: PropTypes.array,
  titleComponent: PropTypes.node.isRequired,
  carouselName: PropTypes.string.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
  onProductImpression: PropTypes.func.isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

export {
  BobbyCarousel
};

export default Responsive(BobbyCarousel);
