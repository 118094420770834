import React from 'react';

function Conventional(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#535353" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g mask="url(#mask0_3026_757)">
        <path d="M20.7443 16.5884C20.2998 16.5884 19.9383 16.2718 19.9383 15.8825C19.9383 15.4932 20.2998 15.1766 20.7443 15.1766C21.1888 15.1766 21.5503 15.4932 21.5503 15.8825C21.5503 16.2718 21.1888 16.5884 20.7443 16.5884V16.5884ZM18.3584 9.88391H20.5194L21.5559 12.0016H18.3584V9.88391ZM12.4818 16.5884C12.0373 16.5884 11.6758 16.2718 11.6758 15.8825C11.6758 15.4932 12.0373 15.1766 12.4818 15.1766C12.9259 15.1766 13.2878 15.4932 13.2878 15.8825C13.2878 16.2718 12.9259 16.5884 12.4818 16.5884V16.5884ZM22.0011 9.32612C21.7472 8.80729 21.164 8.47059 20.5192 8.47059H18.3582V7.41176C18.3582 6.63212 17.6364 6 16.7462 6H9.49194C8.60168 6 7.87988 6.63212 7.87988 7.41176V14.4706C7.87988 15.2502 8.60168 15.8824 9.49194 15.8824H10.063C10.063 17.052 11.1455 18 12.4811 18C13.8163 18 14.8992 17.052 14.8992 15.8824H17.5522H18.3256C18.3256 17.052 19.4081 18 20.7437 18C22.0793 18 23.1617 17.052 23.1617 15.8824H23.1944C23.6393 15.8824 24.0004 15.5665 24.0004 15.1765V13.4118L22.0011 9.32612Z" fill="#535353"/>
      </g>
      <path d="M5.42857 14H0.571429C0.257143 14 0 13.775 0 13.5V13.5C0 13.225 0.257143 13 0.571429 13H5.42857C5.74286 13 6 13.225 6 13.5V13.5C6 13.775 5.74286 14 5.42857 14" fill="#535353"/>
      <path d="M5.375 12H1.625C1.28125 12 1 11.775 1 11.5V11.5C1 11.225 1.28125 11 1.625 11H5.375C5.71875 11 6 11.225 6 11.5V11.5C6 11.775 5.71875 12 5.375 12" fill="#535353"/>
      <path d="M5.33333 10H3.66667C3.3 10 3 9.775 3 9.5V9.5C3 9.225 3.3 9 3.66667 9H5.33333C5.7 9 6 9.225 6 9.5V9.5C6 9.775 5.7 10 5.33333 10" fill="#535353"/>
    </svg>
  );
}

export default Conventional;
