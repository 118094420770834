import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';

import PaymentFormButton from 'components/Payment/Forms/PaymentFormButton/PaymentFormButton';

export const BankSlipForm = ({
  price,
  expiration,
  handleSubmit,
  hasSameDayPickup,
  hasSameDayDelivery,
}) => (
  <form className="BankSlipForm" onSubmit={handleSubmit} method="post" >
    {hasSameDayPickup &&
      <p className="BankSlipForm-samedaymessage">
        Se deseja retirar seus produtos hoje, escolha uma outra forma de pagamento, pois a aprovação do Boleto não costuma ocorrer no mesmo dia da compra.
      </p>
    }

    {hasSameDayDelivery &&
      <p className="BankSlipForm-samedaymessage BankSlipForm-one-hour">
        Se deseja receber seus produtos hoje, escolha outra forma de pagamento, pois a aprovação do Boleto não costuma ocorrer no mesmo dia da compra.
      </p>
    }

    <div className="BankSlipForm-price">
      <span className="BankSlipForm-price--highlighted">{toBRL(price)} à vista</span>
    </div>
    <div className="BankSlipForm-description">
      O prazo para pagamento do boleto é {expiration}
    </div>
    <PaymentFormButton paymentType="Boleto Bancário" />
  </form>
);

BankSlipForm.propTypes = {
  price: PropTypes.string.isRequired,
  expiration: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasSameDayPickup: PropTypes.bool,
  hasSameDayDelivery: PropTypes.bool,
};

export default BankSlipForm;
