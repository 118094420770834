import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, initialize, getValues, change } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { Element } from 'react-scroll';
import store from 'store';

import * as routeNames from 'constants/route-names';
import * as externalUrls from 'constants/external-urls';
import * as customerMessages from 'constants/customer-messages';
import { DEFAULT_CHANNEL } from 'constants/channels';
import { GUEST_DOCUMENT } from 'constants/cookies';

import { redirectToLogin, redirectToNextUrl } from 'utils/redirect';
import * as cleaners from 'utils/value-cleaners';
import * as validators from 'utils/validators';
import { getChannelConfig, logoutCustomer, storageSaveCustomerData } from 'utils/session';
import { detectMobile } from 'utils/responsive';
import { scrollToElement, scrollToError, scrollToTop } from 'utils/scroll';

import * as addressActions from 'reducers/address';
import * as signupActions from 'reducers/signup';
import * as socialLoginActions from 'reducers/social-login';
import * as otpActions from 'reducers/send-otp';
import { fetchLoginAvailable } from 'reducers/signup-available';
import { fetchCpfOrCnpjAvailable } from 'reducers/signup-available';

import SignupForm, { SignupPolicy } from 'components/Signup/SignupForm/SignupForm';
import { SignupFormPF } from 'components/Signup/SignupFormPF/SignupFormPF';
import SignupFormPJ from 'components/Signup/SignupFormPJ/SignupFormPJ';
import AddressForm from 'components/Address/AddressForm/AddressForm';
import { validateCaptcha } from 'components/Form/Captcha/Captcha';
import CheckboxGroup from 'components/Form/CheckboxGroup/CheckboxGroup';
import SignupNotificationsPF from 'components/Signup/SignupNotificationsPF/SignupNotificationsPF';
import SignupNotificationsPJ from 'components/Signup/SignupNotificationsPJ/SignupNotificationsPJ';
import SignupOptInMagaluPay from 'components/Signup/SignupOptInMagaluPay/SignupOptInMagaluPay';
import ValidateAccountOtp from 'components/ValidateAccountOtp/ValidateAccountOtp';
import SuccessNotification from 'components/SuccessNotificationModal/SuccessNotificationModal';
import Modal from 'components/ModalAdjustable';

import { handleZipcodeSubmit, handleZipcodeChange } from './AddressFormContainer';

const signupFormName = 'signup';
const signupFormPFName = 'signupPF';
const signupFormPJName = 'signupPJ';

const ENABLE_SIGNUP_SESSION = getChannelConfig('enable_signup_session') ?? true;
const ENABLE_OPT_MAGALUPAY = getChannelConfig('enable_magalupay_optin') ?? true;
const ENABLE_SMS_OTP_SIGNUP = getChannelConfig('enable_sms_otp_signup') ?? true;
const ENABLE_EMAIL_OTP_SIGNUP = getChannelConfig('enable_email_otp_signup') ?? true;
const ENABLE_OTP_SIGNUP = ENABLE_SMS_OTP_SIGNUP || ENABLE_EMAIL_OTP_SIGNUP;

const PJ_TYPE = 'pj';
const PF_TYPE = 'pf';

const commonFields = [
  'fullName',
  'password',
  'zipcode',
  'address',
  'number',
  'complement',
  'neighbourhood',
  'city',
  'state',
  'reference',
  'privacyCheck',
  'selectAll',
  'pushPromotional',
  'smsPromotional',
  'emailPromotional',
  'mailingPromotional',
  'whatsappPromotional',
  'whatsappTransactional',
  'whatsappTelemarketingPromotional',
  'telemarketingPromotional',
];

export const commonFieldsPJ = [
  'cnpj',
  'companyName',
  'fantasyName',
  'stateRegistration',
  'isExemptFromStateRegistration',
  'homePhone',
  'comercialPhone',
  'mobilePhone',
  ...commonFields,
];

export const commonFieldsPF = [
  'cpf',
  'birthDate',
  'telephone',
  'magalupay',
  ...commonFields,
];

export const onSubmitFail = () => {
  setTimeout(scrollToError, 100);
};

export const errorsAddress = ({
  zipcode,
  address,
  number,
  city,
  state,
  neighbourhood,
  reference,
}) => {
  const errors = {};

  if (!zipcode || !validators.zipcode(cleaners.zipcode(zipcode))) {
    errors.zipcode = customerMessages.invalidZipcode;
  }

  if (!address) {
    errors.address = customerMessages.invalidAddress;
  }

  if (!number) {
    errors.number = customerMessages.invalidNumber;
  }

  if (!city) {
    errors.city = customerMessages.invalidCity;
  }

  if (!state) {
    errors.state = customerMessages.invalidState;
  }

  if (!neighbourhood) {
    errors.neighbourhood = customerMessages.invalidNeighbourhood;
  }

  if (!reference || reference.trim().length < 5) {
    errors.reference = customerMessages.invalidReference;
  }

  return errors;
};

export const errorsFormPF = ({ cpf, fullName, telephone, birthDate }) => {
  const errors = {};

  if (!cpf || !validators.isValidCpf(cpf)) {
    errors.cpf = customerMessages.invalidCPF;
  }

  if (!fullName || !validators.fullName(fullName)) {
    errors.fullName = customerMessages.invalidFullName;
  }

  if (!telephone || !validators.mobilePhone(telephone)) {
    errors.telephone = customerMessages.invalidTelephone;
  }

  if (!birthDate) {
    errors.birthDate = customerMessages.invalidBirthDate;
  } else {
    if (!validators.date(birthDate)) {
      errors.birthDate = customerMessages.invalidBirthDate;
    } else if (!validators.birthDate(birthDate)) {
      errors.birthDate = customerMessages.invalidBirthDateOfAge;
    }
  }

  return errors;
};

export const errorsFormPJ = ({
  cnpj,
  companyName,
  fantasyName,
  stateRegistration,
  isExemptFromStateRegistration,
  fullName,
  homePhone,
  comercialPhone,
  mobilePhone,
}) => {
  const errors = {};

  if (!cnpj || !validators.isValidCnpj(cnpj)) {
    errors.cnpj = customerMessages.invalidCNPJ;
  }

  if (!companyName) {
    errors.companyName = customerMessages.invalidCompanyName;
  }

  if (!fantasyName) {
    errors.fantasyName = customerMessages.invalidFantasyName;
  }

  if (!isExemptFromStateRegistration && !stateRegistration) {
    errors.stateRegistration = customerMessages.invalidStateRegistration;
  }

  if (!fullName || !validators.fullName(fullName)) {
    errors.fullName = customerMessages.invalidFullName;
  }

  if (!homePhone || !validators.telephone(homePhone)) {
    errors.homePhone = customerMessages.invalidTelephone;
  }

  if (!comercialPhone || !validators.telephone(comercialPhone)) {
    errors.comercialPhone = customerMessages.invalidTelephone;
  }

  if (!mobilePhone || !validators.mobilePhone(mobilePhone)) {
    errors.mobilePhone = customerMessages.invalidTelephone;
  }

  return errors;
};

export const setNotificationAsTrue = (notifications) => {
  const optinSetDefaultToTrue =
    window.MAGALU_CHANNEL?.channel_configs?.notifications_optin_set_default_as_true || [];
  const currentNotifications = notifications;
  optinSetDefaultToTrue.forEach((optinKey) => (currentNotifications[optinKey] = true));
  return currentNotifications;
};

export const errorsPassword = ({ password }) => {
  const errors = {};

  if (!password) {
    errors.password = customerMessages.invalidPassword;
  }
  if (password && !validators.password(password)) {
    errors.password = customerMessages.wrongPasswordLength;
  }

  return errors;
};

const shouldSkipAsyncValidate = (document) => {
  const state = store.getState();
  const signupAvailableState = state?.signupAvailable;
  const isSameValue = signupAvailableState?.cpfOrCnpj === document;

  return isSameValue && signupAvailableState?.loginAvailable;
};

const asyncValidateCpf = (values, dispatch) =>
  new Promise((resolve, reject) => {
    const state = store.getState();
    const signupAvailableState = state?.signupAvailable;
    const cleanCpf = cleaners.onlyNumbers(values.cpf);

    if (shouldSkipAsyncValidate(cleanCpf)) {
      return signupAvailableState?.cpfOrCnpjAvailable
        ? resolve()
        : reject({ cpf: customerMessages.CPFAlreadyRegistered });
    }

    return validateCaptcha('document').then((captchaToken) => {
      return dispatch(fetchCpfOrCnpjAvailable(cleanCpf, captchaToken)).then(
        resolve,
        (err) => {
          if (err && err.status && !err.isAvailable) {
            scrollToElement(signupFormPFName);
            return reject({
              cpf: customerMessages.CPFAlreadyRegistered,
            });
          }

          return resolve();
        }
      );
    });
  });

const syncValidatePF = (values) => {
  const cleanValues = Object.assign({}, values, {
    telephone: cleaners.onlyNumbers(values.telephone),
    cpf: cleaners.cpfOrCnpj(values.cpf),
    zipcode: cleaners.zipcode(values.zipcode),
  });

  return Object.assign(
    {},
    errorsFormPF(cleanValues),
    errorsAddress(cleanValues),
    errorsPassword(cleanValues)
  );
};

const asyncValidateCnpj = (values, dispatch) =>
  new Promise((resolve, reject) => {
    const state = store.getState();
    const signupAvailableState = state?.signupAvailable;
    const cleanCnpj = cleaners.onlyNumbers(values.cnpj);

    if (shouldSkipAsyncValidate(cleanCnpj)) {
      return signupAvailableState?.cpfOrCnpjAvailable
        ? resolve()
        : reject({ cnpj: customerMessages.CNPJAlreadyRegistered });
    }

    return validateCaptcha('document').then((captchaToken) => {
      return dispatch(fetchCpfOrCnpjAvailable(cleanCnpj, captchaToken)).then(
        resolve,
        (err) => {
          if (err && err.status && !err.isAvailable) {
            scrollToElement(signupFormPJName);
            return reject({
              cnpj: customerMessages.CNPJAlreadyRegistered,
            });
          }

          return resolve();
        }
      );
    });
  });

const syncValidatePJ = (values) => {
  const cleanValues = Object.assign({}, values, {
    homePhone: cleaners.onlyNumbers(values.homePhone),
    mobilePhone: cleaners.onlyNumbers(values.mobilePhone),
    comercialPhone: cleaners.onlyNumbers(values.comercialPhone),
    cnpj: cleaners.cpfOrCnpj(values.cnpj),
    zipcode: cleaners.zipcode(values.zipcode),
  });

  return Object.assign(
    {},
    errorsFormPJ(cleanValues),
    errorsAddress(cleanValues),
    errorsPassword(cleanValues)
  );
};

const makeSubmitCallback = (accountType = PF_TYPE, handleOpenSuccesModalCreateAccount) =>
  function cb(values) {
    const isPF = accountType === PF_TYPE;
    const formName = isPF ? signupFormPFName : signupFormPJName;
    const {
      channelName,
      form,
      postSignup,
      sendOtp: { otpId, deliveryMode },
      profileInfo,
      postSocialLogin,
      trackSubmit,
      trackError,
    } = this.props;

    const { email } = getValues(form[signupFormName]);

    trackSubmit();

    return new Promise((_, reject) => {
      // Parse values
      const defaultValues = {
        zipcode: cleaners.zipcode(values.zipcode),
        channelName,
      };
      const cleanValues = isPF
        ? {
          telephone: cleaners.onlyNumbers(values.telephone),
          cpf: cleaners.cpfOrCnpj(values.cpf),
        }
        : {
          homePhone: cleaners.onlyNumbers(values.homePhone),
          mobilePhone: cleaners.onlyNumbers(values.mobilePhone),
          comercialPhone: cleaners.onlyNumbers(values.comercialPhone),
          cnpj: cleaners.cpfOrCnpj(values.cnpj),
        };
      const finalValues = {
        ...values,
        ...defaultValues,
        ...cleanValues,
        notifications: this.state.notifications,
        opt: this.state.opt,
        otpCode: values.providedCode,
        otpId,
        otpDeliveryMode: deliveryMode,
      };

      // Form Errors
      const defaultErrors = {
        ...errorsAddress(finalValues),
        ...errorsPassword(finalValues),
      };
      const errors = isPF
        ? {
          ...errorsFormPF(finalValues),
          ...defaultErrors,
        }
        : {
          ...errorsFormPJ(finalValues),
          ...defaultErrors,
        };

      const success = () => handleOpenSuccesModalCreateAccount();

      if (!isEmpty(errors)) {
        scrollToElement(formName);
        trackError(JSON.stringify(errors));
        return reject(errors);
      }

      setTimeout(() => {
        document
          .querySelectorAll('iframe[title*="reCAPTCHA"]')
          .forEach((iframeCaptcha) => {
            const parentDiv =
              iframeCaptcha &&
              iframeCaptcha.parentElement &&
              iframeCaptcha.parentElement.parentElement;

            if (parentDiv && parseInt(parentDiv.style.opacity, 10) === 1) {
              scrollToTop();
            }
          });
      }, 2000);

      return validateCaptcha('signup').then((captchaToken) => {
        return postSignup(
          accountType,
          Object.assign({}, finalValues, { email }),
          captchaToken,
          'signup'
        ).then(() => {
          if (isPF && profileInfo && profileInfo.socialClientId) {
            return (
              postSocialLogin()
                .then(success)
                // If fails, success anyway :/
                .catch(success)
            );
          }
          return success();
        });
      });
    });
  };

const handleRedirectUserOnSuccess = () =>
  function cb() {
    const {
      location: {
        query: { origin, next },
      },
    } = this.props;

    const state = store.getState();
    const { router } = this.context;

    const productCount = state?.basket?.basket?.productCount;
    const nextUrl =
      next ||
      (this.props.location.pathname.indexOf('/cliente/') !== -1 &&
        externalUrls.catalog) ||
      (productCount > 0 ? routeNames.address : routeNames.root);

    sessionStorage.removeItem(GUEST_DOCUMENT);
    if (ENABLE_SIGNUP_SESSION && state?.signup?.signupInfo) {
      storageSaveCustomerData(state.signup.signupInfo);
      const nextOrigin =
        origin || (nextUrl === externalUrls.catalog && DEFAULT_CHANNEL) || '';

      return redirectToNextUrl(router.replace, {
        next: nextUrl,
        origin: nextOrigin,
      });
    }

    logoutCustomer();
    return redirectToLogin(router, nextUrl, this.props.location);
  };

const makePinCodeGenerateSubmit = (optionsData, retry = false) =>
  async function cb() {
    const { postOtpCode, otpTrackClick, otpTrackPageView } = this.props;
    const clickLabel = retry ? 'reenviar-codigo' : `login:${optionsData.delivery_mode}`;
    otpTrackClick(clickLabel, 'signup');
    otpTrackPageView({
      device: detectMobile() ? 'site-mobile' : 'site-desktop',
      screenName: `validacao-por-${optionsData.delivery_mode}`,
      pageType: `cadastro-otp-${optionsData.delivery_mode}`,
    });

    const { id, expires_at: expiresAt } = await postOtpCode(optionsData, 'signup');
    return { id, expiresAt };
  };

@reduxForm({
  form: signupFormPFName,
  fields: [...commonFieldsPF],
  asyncValidate: asyncValidateCpf,
  asyncBlurFields: ['cpf'],
  validate: syncValidatePF,
  onSubmitFail,
})
@connect(
  (globalState) => {
    const {
      form,
      address,
      fail,
      failOtp,
      signup,
      socialLogin,
      signupAvailable,
      channel,
      sendOtp,
      validateOtp
    } = globalState;

    return {
      form,
      signup,
      address,
      sendOtp,
      fail,
      failOtp,
      channelName: channel.name,
      ...socialLogin,
      ...signupAvailable,
      isRequestingValidateOtp: validateOtp.isRequesting,
    };
  },
  {
    initialize,
    ...addressActions,
    ...signupActions,
    ...socialLoginActions,
    ...otpActions,
    changeField: change,
  }
)
export class SignupFormPFContainer extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    login: PropTypes.object,
    channelName: PropTypes.string.isRequired,
    profileInfo: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    resetAddress: PropTypes.func.isRequired,
    postSignup: PropTypes.func.isRequired,
    postOtpCode: PropTypes.func.isRequired,
    otpTrackClick: PropTypes.func.isRequired,
    otpTrackPageView: PropTypes.func.isRequired,
    sendOtp: PropTypes.object.isRequired,
    signup: PropTypes.object.isRequired,
    fail: PropTypes.object,
    failOtp: PropTypes.object,
    optDidUpdate: PropTypes.func.isRequired,
    optKnowMoreClick: PropTypes.func.isRequired,
    optTermsClick: PropTypes.func.isRequired,
    postSocialLogin: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    asyncValidating: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    changeField: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    showPassword: false,
    notifications: {
      selectAll: false,
      pushPromotional: false,
      smsPromotional: false,
      emailPromotional: false,
      mailingPromotional: false,
      whatsappPromotional: false,
      whatsappTransactional: false,
      whatsappTelemarketingPromotional: false,
      telemarketingPromotional: false,
    },
    opt: {
      magalupay: false,
    },
    showOtpValidate: false,
    showSuccessModal: false,
    formValues: {},
  };

  onNotificationsHandlerPF = this.onNotificationsHandlerPF.bind(this);

  componentDidMount() {
    const { fields, initialize: initializeForm } = this.props;

    initializeForm(
      signupFormPFName,
      {
        privacyCheck: false,
      },
      Object.keys(fields)
    );
    const { notifications } = this.state;
    const notificationOptin = setNotificationAsTrue(notifications);
    this.setState({ notifications: notificationOptin });
  }

  componentWillReceiveProps(nextProps) {
    const {
      form,
      profileInfo: { socialClientId },
      fields,
      initialize: initializeForm,
    } = this.props;

    if (socialClientId !== nextProps.profileInfo.socialClientId) {
      const formValues = getValues(form[signupFormPFName]);
      initializeForm(
        signupFormPFName,
        Object.assign({}, formValues, {
          fullName: nextProps.profileInfo.fullName,
        }),
        Object.keys(fields)
      );
    }
  }

  submitPfForm = makeSubmitCallback(
    PF_TYPE,
    this.handleOpenSuccesModalCreateAccount.bind(this)
  );
  submitGenerateOtpCode = (contactOptions, retry) =>
    makePinCodeGenerateSubmit(contactOptions, retry).bind(this);

  onNotificationsHandlerPF(type, value) {
    if (type === 'selectAll') {
      this.setState({
        ...this.state,
        notifications: {
          selectAll: value,
          pushPromotional: value,
          smsPromotional: value,
          emailPromotional: value,
          mailingPromotional: value,
          whatsappPromotional: value,
          whatsappTransactional: value,
          whatsappTelemarketingPromotional: value,
          telemarketingPromotional: value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        notifications: {
          ...this.state.notifications,
          [type]: value,
        },
      });
    }
  }

  handleOptClick = this.handleOptClick.bind(this);
  handleOptClick(type, value) {
    const state = { ...this.state };
    this.setState({
      ...state,
      otpCode: undefined,
      opt: {
        ...state.opt,
        [type]: value,
      },
    });
    this.props.optDidUpdate(value);
  }

  handleCloseOtpModal() {
    this.props.otpTrackClick('editar-meus-dados', 'signup');
    this.setState({ showOtpValidate: false });
  }

  handleOpenSuccessModal() {
    this.setState({ showSuccessModal: true });
  }

  handleUpdateOtpCode(providedCode) {
    this.submitPfForm({ ...this.state.formValues, providedCode });
  }

  handleSubmitFormValues(formValues) {
    if (ENABLE_OTP_SIGNUP) {
      const { otpTrackPageView } = this.props;

      otpTrackPageView({
        ...formValues,
        device: detectMobile() ? 'site-mobile' : 'site-desktop',
        screenName: 'validacao-de-cadastro',
        pageType: 'cadastro-otp',
      });

      return this.setState({ formValues, showOtpValidate: true });
    }

    return this.submitPfForm(formValues);
  }

  handleOpenSuccesModalCreateAccount() {
    this.setState({ showOtpValidate: false, showSuccessModal: true });
  }

  redirectUser = handleRedirectUserOnSuccess().bind(this);

  render() {
    const { showSuccessModal, showOtpValidate } = this.state;
    const {
      address: { address, neighbourhood, city, state },
      fields,
      fetchAddress,
      resetAddress,
      handleSubmit,
      asyncValidating,
      changeField,
      login,
      signup: { isRequesting: isRequestingSignup },
      failOtp: { code: statusCode, failMessage: message },
      sendOtp: { expiresAt, isRequesting, otpId, isError },
      isRequestingValidateOtp,
    } = this.props;

    const finalFields = Object.assign({}, fields, {
      address: Object.assign({}, fields.address, { disabled: !!address }),
      neighbourhood: Object.assign({}, fields.neighbourhood, {
        disabled: !!neighbourhood,
      }),
      city: Object.assign({}, fields.city, { disabled: !!city }),
      state: Object.assign({}, fields.state, { disabled: !!state }),
    });

    return (
      <Element name={signupFormPFName}>
        <form
          onSubmit={handleSubmit(this.handleSubmitFormValues.bind(this))}
          className="SignupFormPF">
          <input
            disabled
            type="email"
            value={login.email}
            className="hide"
            name="username-email"
            autoComplete="username"
          />
          <SignupFormPF
            {...finalFields}
            asyncValidating={asyncValidating}
            showPassword={this.state.showPassword}
            handleShowPasswordClick={(statePassword) => {
              this.setState({ [statePassword]: !this.state[statePassword] });
            }}
          />
          <AddressForm
            {...finalFields}
            handleZipcodeSubmit={handleZipcodeSubmit(
              fields.zipcode.value,
              signupFormPFName,
              fetchAddress,
              changeField
            )}
            canChangeZipcode
            handleZipcodeChange={handleZipcodeChange(resetAddress, fields)}
          />
          <SignupNotificationsPF
            {...finalFields}
            notifications={this.state.notifications}
            onNotificationsHandlerPF={this.onNotificationsHandlerPF}
          />
          <hr className="SignupFormPF-divider-line" />
          {ENABLE_OPT_MAGALUPAY && (
            <div>
              <SignupOptInMagaluPay
                opt={this.state.opt}
                onOptClick={this.handleOptClick}
                onOptTermsClick={this.props.optTermsClick}
                onOptKnowMoreClick={this.props.optKnowMoreClick}
              />
              <hr className="SignupFormPF-divider-line" />
            </div>
          )}
          <CheckboxGroup field={finalFields.privacyCheck}>
            <SignupPolicy />
          </CheckboxGroup>
          <button disabled={!finalFields.privacyCheck.checked} className="continueButton">
            Continuar
          </button>
          <Modal isOpen={showSuccessModal}>
            <SuccessNotification onClick={this.redirectUser} />
          </Modal>
        </form>
        {ENABLE_OTP_SIGNUP && (
          <ValidateAccountOtp
            enableEmail={ENABLE_EMAIL_OTP_SIGNUP}
            enableSms={ENABLE_SMS_OTP_SIGNUP}
            error={{ statusCode, message }}
            hasOtpId={!!otpId || isError}
            expiresAt={expiresAt}
            loading={isRequesting || isRequestingSignup || isRequestingValidateOtp}
            isOpen={showOtpValidate}
            onComplete={this.handleUpdateOtpCode.bind(this)}
            postOtpGenerate={this.submitGenerateOtpCode.bind(this)}
            handleCloseOtpModal={this.handleCloseOtpModal.bind(this)}
            contacts={{
              email: login.email,
              sms: fields.telephone.value,
              city: fields.city.value,
              state: fields.state.value,
            }}
          />
        )}
      </Element>
    );
  }
}

@reduxForm({
  form: signupFormPJName,
  fields: [...commonFieldsPJ],
  asyncValidate: asyncValidateCnpj,
  asyncBlurFields: ['cnpj'],
  validate: syncValidatePJ,
  onSubmitFail,
})
@connect(
  ({ form, fail, failOtp, signup, address, signupAvailable, channel, sendOtp, validateOtp }) => ({
    fail,
    failOtp,
    form,
    signup,
    address,
    sendOtp,
    channelName: channel.name,
    ...signupAvailable,
    isRequestingValidateOtp: validateOtp.isRequesting,
  }),
  {
    initialize,
    ...addressActions,
    ...signupActions,
    ...otpActions,
    changeField: change,
  }
)
export class SignupFormPJContainer extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    channelName: PropTypes.string.isRequired,
    address: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    resetAddress: PropTypes.func.isRequired,
    postSignup: PropTypes.func.isRequired,
    postOtpCode: PropTypes.func.isRequired,
    otpTrackClick: PropTypes.func.isRequired,
    otpTrackPage: PropTypes.func.isRequired,
    sendOtp: PropTypes.object.isRequired,
    signup: PropTypes.object.isRequired,
    fail: PropTypes.object,
    failOtp: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    asyncValidating: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    changeField: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    showPassword: false,
    notifications: {
      selectAll: false,
      pushPromotional: false,
      smsPromotional: false,
      emailPromotional: false,
      mailingPromotional: false,
      whatsappPromotional: false,
      whatsappTransactional: false,
      whatsappTelemarketingPromotional: false,
      telemarketingPromotional: false,
    },
    showOtpValidate: false,
    showSuccessModal: false,
    formValues: {},
  };

  onNotificationsHandlerPJ = this.onNotificationsHandlerPJ.bind(this);

  componentDidMount() {
    const cnpj = sessionStorage.getItem(GUEST_DOCUMENT) || '';

    this.props.initialize(
      signupFormPJName,
      {
        isExemptFromStateRegistration: true,
        privacyCheck: false,
        cnpj,
      },
      Object.keys(this.props.fields)
    );

    const { notifications } = this.state;
    const notificationOptin = setNotificationAsTrue(notifications);
    this.setState({ notifications: notificationOptin });
  }

  submitGenerateOtpCode = (contactOptions, retry) =>
    makePinCodeGenerateSubmit(contactOptions, retry).bind(this);
  submitPjForm = makeSubmitCallback(
    PJ_TYPE,
    this.handleOpenSuccesModalCreateAccount.bind(this)
  );

  onNotificationsHandlerPJ(type, value) {
    if (type === 'selectAll') {
      this.setState({
        ...this.state,
        notifications: {
          selectAll: value,
          pushPromotional: value,
          smsPromotional: value,
          emailPromotional: value,
          mailingPromotional: value,
          whatsappPromotional: value,
          whatsappTransactional: value,
          whatsappTelemarketingPromotional: value,
          telemarketingPromotional: value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        notifications: {
          ...this.state.notifications,
          [type]: value,
        },
      });
    }
  }

  handleCloseOtpModal() {
    this.props.otpTrackClick('editar-meus-dados', 'signup');
    this.setState({ showOtpValidate: false });
  }

  handleOpenSuccessModal() {
    this.setState({ showSuccessModal: true });
  }

  handleUpdateOtpCode(providedCode) {
    this.submitPjForm({ ...this.state.formValues, providedCode });
  }

  handleSubmitFormValues(formValues) {
    if (ENABLE_OTP_SIGNUP) {
      const { otpTrackPageView } = this.props;

      otpTrackPageView({
        ...formValues,
        device: detectMobile() ? 'site-mobile' : 'site-desktop',
        screenName: 'validacao-de-cadastro',
        pageType: 'cadastro-otp',
      });

      return this.setState({ formValues, showOtpValidate: true });
    }

    return this.submitPjForm(formValues);
  }

  handleOpenSuccesModalCreateAccount() {
    this.setState({ showOtpValidate: false, showSuccessModal: true });
  }

  redirectUser = handleRedirectUserOnSuccess().bind(this);

  render() {
    const { showSuccessModal, showOtpValidate } = this.state;

    const {
      fields,
      address: { address, neighbourhood, city, state },
      fetchAddress,
      resetAddress,
      handleSubmit,
      asyncValidating,
      changeField,
      login,
      signup: { isRequesting: isRequestingSignup },
      failOtp: { code: statusCode, failMessage: message },
      sendOtp: { expiresAt, isRequesting, otpId, isError },
      isRequestingValidateOtp,
    } = this.props;

    const finalFields = Object.assign({}, fields, {
      address: Object.assign({}, fields.address, { disabled: !!address }),
      neighbourhood: Object.assign({}, fields.neighbourhood, {
        disabled: !!neighbourhood,
      }),
      city: Object.assign({}, fields.city, { disabled: !!city }),
      state: Object.assign({}, fields.state, { disabled: !!state }),
    });

    return (
      <Element name={signupFormPJName}>
        <form
          className="SignupFormPJ"
          onSubmit={handleSubmit(this.handleSubmitFormValues.bind(this))}>
          <input
            autoComplete="username"
            className="hide"
            name="username-email"
            type="email"
            value={login.email}
            disabled
          />
          <SignupFormPJ
            {...finalFields}
            asyncValidating={asyncValidating}
            showPassword={this.state.showPassword}
            handleShowPasswordClick={(statePassword) => {
              this.setState({ [statePassword]: !this.state[statePassword] });
            }}
          />
          <AddressForm
            {...finalFields}
            handleZipcodeSubmit={handleZipcodeSubmit(
              fields.zipcode.value,
              signupFormPJName,
              fetchAddress,
              changeField
            )}
            handleZipcodeChange={handleZipcodeChange(resetAddress, fields)}
            canChangeZipcode
            multipleTelephones
          />
          <SignupNotificationsPJ
            {...finalFields}
            notifications={this.state.notifications}
            onNotificationsHandlerPJ={this.onNotificationsHandlerPJ}
          />
          <hr className="SignupFormPJ-divider-line" />
          <CheckboxGroup field={finalFields.privacyCheck}>
            <SignupPolicy />
          </CheckboxGroup>
          <button className="continueButton" disabled={!finalFields.privacyCheck.checked}>
            Continuar
          </button>
          {ENABLE_OTP_SIGNUP && (
            <ValidateAccountOtp
              enableEmail={ENABLE_EMAIL_OTP_SIGNUP}
              enableSms={ENABLE_SMS_OTP_SIGNUP}
              error={{ statusCode, message }}
              hasOtpId={!!otpId || isError}
              expiresAt={expiresAt}
              loading={isRequesting || isRequestingSignup || isRequestingValidateOtp}
              isOpen={showOtpValidate}
              onComplete={this.handleUpdateOtpCode.bind(this)}
              postOtpGenerate={this.submitGenerateOtpCode.bind(this)}
              handleCloseOtpModal={this.handleCloseOtpModal.bind(this)}
              contacts={{
                email: login.email,
                sms: fields.mobilePhone.value,
              }}
            />
          )}
          <Modal isOpen={showSuccessModal}>
            <SuccessNotification onClick={this.redirectUser} />
          </Modal>
        </form>
      </Element>
    );
  }
}

const syncValidateSignup = ({ email }) => {
  const errors = {};

  if (!email || !validators.email(email)) {
    errors.email = customerMessages.blankSignupEmail;
  }

  return errors;
};

const asyncValidateSignup = (values, dispatch) => {
  return new Promise((resolve, reject) => {
    const { email } = values;
    const errors = syncValidateSignup(values);

    if (!isEmpty(errors)) {
      return reject(errors);
    }

    return validateCaptcha('signup_email').then((captchaToken) => {
      return dispatch(fetchLoginAvailable(captchaToken, undefined, email)).then(
        resolve,
        (err) => {
          if (err && err.status && !err.isAvailable) {
            return reject({
              email: err.status === 403 ?
                customerMessages.blockEmail :
                customerMessages.blankSignupEmail,
            });
          }

          return resolve();
        }
      );
    });
  });
};

export class SignupFormContainer extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    login: PropTypes.object,
    profileInfo: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    asyncValidating: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { login, profileInfo } = this.props;

    const accountType = sessionStorage.getItem(GUEST_DOCUMENT) ? PJ_TYPE : PF_TYPE;

    this.props.initialize(
      signupFormName,
      {
        accountType,
        email: login?.email || profileInfo?.email || '',
      },
      Object.keys(this.props.fields)
    );
  }

  render() {
    const {
      fields: { accountType, email },
      profileInfo,
      location,
      asyncValidating,
    } = this.props;
    const { router } = this.context;

    return (
      <SignupForm
        accountTypeField={accountType}
        email={Object.assign({}, email, { disabled: !!email.initialValue })}
        showPJ={!profileInfo.socialClientId}
        forceShowSuccess={!!email.initialValue}
        asyncValidating={asyncValidating}>
        {(accountType.value === PJ_TYPE && (
          <SignupFormPJContainer location={location} router={router} />
        )) || <SignupFormPFContainer router={router} location={location} />}
      </SignupForm>
    );
  }
}

export default reduxForm({
  form: signupFormName,
  fields: ['accountType', 'email'],
  asyncValidate: asyncValidateSignup,
  asyncBlurFields: ['email'],
})(
  connect(
    (global) => {
      return {
        ...global.sendOtp,
        ...global.socialLogin,
        ...global.signupAvailable,
      };
    },
    { initialize }
  )(SignupFormContainer)
);
