import { checkoutProgress, DEFAULT_PAGE_TYPE_CATEGORY } from './checkoutProgress';
import { pageviewLayer } from 'utils/data-layer/pageviewLayer';

export const loginPageview = (_, __, stateAfter) =>
  pageviewLayer(stateAfter, '2');

export const loginCheckoutProgress = (_, __, stateAfter) =>
  checkoutProgress({
    pageType: DEFAULT_PAGE_TYPE_CATEGORY.login,
    stateAfter
  });

export function loginResponse({ status, description } = {}) {
  const layer = {};

  layer.event = 'clickEvent';
  layer.eventCategory = 'identificacao:login';
  layer.eventAction = status;
  layer.eventLabel = description;
  layer.eventNonInteraction = '1';
  layer.hitTimestamp = `${Date.now()}`;

  return layer;
}
