import React from 'react';
import PropTypes from 'prop-types';

import { toBRL } from 'utils/currency';
import * as maskers from 'utils/maskers';

import Responsive from 'components/Responsive';

const BobbyProductPrice = ({
  prices,
  priceDescription,
  installment,
  stockStatus,
  isMobile
}) => (
  <div>
    {!isMobile ?
      (
        <div className="BobbyProduct-priceContainer">
          {prices.from && <div className="BobbyProduct-priceFrom">de {toBRL(prices.from)}</div> || null}
          <div className="BobbyProduct-price">
            {prices.to && 'por' || ''} {toBRL(prices.to || prices.from)}
          </div>
          {installment.quantity && (
            <div className="BobbyProduct-installment">
              {installment.quantity}x de {toBRL(installment.price)} {maskers.installmentDescription(installment.description)}<br />
              {prices.cash && prices.cash < prices.to && <span>ou <b>{toBRL(prices.cash)} {priceDescription}</b></span> || null}
            </div>
          ) || null}
          {stockStatus === 2 && (
            <div className="BobbyProduct-stockStatus">
              Disponível sob consulta
            </div>
          ) || null}
        </div>
      ) : (
        <div className="BobbyProduct-price">
          <div className="BobbyProduct-price-link">
            {toBRL(prices.cash || prices.to || prices.from)}
          </div>
          {installment.quantity && (
            <div className="BobbyProduct-installment">
              {installment.quantity}x de {toBRL(installment.price)}
            </div>
          )}
        </div>
      )
    }
  </div>
);

BobbyProductPrice.propTypes = {
  prices: PropTypes.object.isRequired,
  priceDescription: PropTypes.string.isRequired,
  installment: PropTypes.object.isRequired,
  stockStatus: PropTypes.number,
  isMobile: PropTypes.bool,
};


export default Responsive(BobbyProductPrice);
