import ButtonShowPassword from 'components/Form/ButtonShowPassword/ButtonShowPassword';
import FormGroup from 'components/Form/FormGroup/FormGroup';
import Responsive from 'components/Responsive';
import { REDIRECT_COMPANY_URL } from 'constants/channels';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { moveCursorToLastChar } from 'utils/moveCursorToLastChar';
import { getChannelConfig } from 'utils/session';
import './SignupFormPF.scss';


const enablePJRedirect = getChannelConfig(
  'enable_pj_customer_redirect_to_magalu_empresas'
) ?? false;

export class SignupFormPF extends Component {
  static propTypes = {
    cpf: PropTypes.object.isRequired,
    fullName: PropTypes.object.isRequired,
    birthDate: PropTypes.object.isRequired,
    oldPassword: PropTypes.object,
    password: PropTypes.object.isRequired,
    asyncValidating: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired,
    isMobile: PropTypes.bool,
    showOldPassword: PropTypes.bool,
    showPassword: PropTypes.bool.isRequired,
    handleShowPasswordClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      cpf,
      fullName,
      birthDate,
      oldPassword,
      password,
      asyncValidating,
      isMobile,
      showOldPassword,
      showPassword,
      handleShowPasswordClick,
    } = this.props;

    const magaluEmpresasUrl = getChannelConfig(REDIRECT_COMPANY_URL);

    return (
      <div className="SignupFormPF">
        <div className="form-title">Dados pessoais</div>
        <FormGroup
          inputSize="small"
          labelText="CPF"
          field={cpf}
          asyncValidating={asyncValidating}
          handleKeyUp={moveCursorToLastChar}
          type="tel"
        />
        {enablePJRedirect && ( 
          <div className="SignupFormPF-redirect-me">
            <span className="SignupFormPF-strong">Possui CNPJ? </span>Faça seu cadastro pelo {''}
            <a
              className="SignupFormPF-link"
              href={magaluEmpresasUrl}
            >
                Portal Magalu Empresas
            </a>
          </div>
        )}
        <FormGroup
          labelText="Nome completo"
          field={fullName}
        />
        {!isMobile &&
        <FormGroup
          inputSize="extraSmall"
          labelText="Data de nascimento"
          placeholder="DD/MM/AAAA"
          field={birthDate}
          type="tel"
        />
        }
        {isMobile &&
        <FormGroup
          inputSize="extraSmall"
          labelText="Data de nascimento (DD/MM/AAAA)"
          field={birthDate}
          type="tel"
        />
        }
        {oldPassword && <FormGroup
          labelText="Senha atual"
          inputSize="small"
          hideSuccess
          field={Object.assign({}, oldPassword, {
            type: showOldPassword && 'text' || 'password'
          })}
        >
          <ButtonShowPassword
            field={showOldPassword}
            handleClick={() => handleShowPasswordClick('showOldPassword')}
          />
        </FormGroup>}
        <FormGroup
          inputSize="small"
          labelText={`${oldPassword && 'Nova senha ' || 'Senha '}(mínimo 6 caracteres)`}
          field={Object.assign({}, password, {
            type: showPassword && 'text' || 'password'
          })}
        >
          <ButtonShowPassword
            field={showPassword}
            handleClick={() => handleShowPasswordClick('showPassword')}
          />
        </FormGroup>
      </div>
    );
  }
}

export default Responsive(SignupFormPF);
