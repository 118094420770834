import cleanHtml from 'utils/cleanHtml';
import { formatShipmentData } from './formatShipmentData';
import { GTM_UNDEFINED, GTM_INTERNATIONAL_PURCHASE } from './constants';
import { sellerType } from './sellerType';
import { productName } from './productName';
import { getProductAvailability } from './getProductAvailability';
import { lowerDash } from './lowerDash';
import { onlyCharactersAndSpace } from 'utils/value-cleaners';
import isInternationalPrice from 'utils/isInternationalPrice';
import getBasketTaxes from './getBasketTaxes';

export const generateDimensionsDataGA4 = (
  stateAfter,
  product,
  productId,
  index,
  eventName = ''
) => {
  const isAvailable = getProductAvailability(product);
  const deliveries = stateAfter?.basket?.basket?.packages || null;
  const { dimension9 } = getBasketTaxes(product?.tax);

  const findProduct = stateAfter?.basket?.basket?.products?.find(
    (item) => item.sku === product?.sku
  );

  const reviewCount = product?.review_count || product?.reviewCount || 'null';
  const reviewScore = product?.review_score || product?.reviewScore || 'null';
  const showcaseTitle = product?.showcase?.title || product?.showcaseTitle;
  const isRecommendation = !!showcaseTitle;
  const itemListName = isRecommendation
    ? `carrinho:${productName(cleanHtml(showcaseTitle))}`
    : GTM_UNDEFINED;
  const nominal = product?.prices
    ? (parseFloat(product?.prices?.to) - parseFloat(product?.prices?.cash)).toFixed(2)
    : product?.payment_discount_amount;

  const paymentSubTotal = product?.prices?.to ?? product?.subtotal;
  const percentage = ((nominal / paymentSubTotal) * 100).toFixed(2);
  const shipmentData = deliveries ? formatShipmentData(deliveries, productId) : null;
  const badge = lowerDash(onlyCharactersAndSpace(product?.badges?.[0]?.text));

  const dimensions = {
    dimension5: lowerDash(sellerType(product?.storeId || product?.seller)),
    dimension7: `preco-de:${product?.prices?.from || null}|pix:${
      product?.prices?.cash || null
    }|mpay:null|boleto:null`,
    dimension8: `cartao-luiza:null:null:null|cartao-credito:${
      product?.prices?.to ?? null
    }:${product?.installment?.quantity || null}:${product?.installment?.price || null}`,
    dimension9,
    dimension11: isAvailable,
    dimension23:
      product?.storeId === 'magazineluiza' || product?.seller === 'magazineluiza'
        ? '0'
        : null,
    dimension24: `qtd-av:${reviewCount}|av:${reviewScore}`,
    dimension41: 'etiqueta:null',
    dimension61: product?.seller || product?.storeId || null,
    dimension62: product?.provider || findProduct?.provider || null,
    dimension77: `nominal:${
      nominal ?? product?.payment_discount_amount
    }|percentual:${percentage}`,
    dimension86: badge || null,
    dimension87: isInternationalPrice(product) ? GTM_INTERNATIONAL_PURCHASE : null,
    dimension112:
      shipmentData && shipmentData !== 'undefined'
        ? `${shipmentData?.substring(0, shipmentData.lastIndexOf(':'))}`
        : 'entrega-padrao:null:null|retira-loja:null:null',
    dimension114: 'expressa:null:null|agendada:null:null',
    dimension115: null,
  };
  const eventsMap = ['remove_from_cart', 'add_to_cart'];
  if (!isRecommendation && eventName.includes(eventsMap)) {
    dimensions.item_list_name = 'carrinho:carrinho';
    dimensions.dimension114 = `expressa:${null}:${null}|agendada:${null}:${null}`;
    dimensions.dimension120 = product?.medias?.length || null;
  }


  if (!isRecommendation && eventName === 'add_to_cart') {
    dimensions.dimension120 = product?.medias?.length || null;
    dimensions.item_list_name = 'carrinho:carrinho';
  }

  if (!isRecommendation && eventName === 'purchase') {
    dimensions.dimension120 = null;
  }

  if (isRecommendation) {
    dimensions.dimension103 = 'bob';
    dimensions.dimension106 = `alg:${product?.screenName || 'null'}-null-${
      product?.showcaseId || 'null'
    }|type:${product?.showcaseType || 'null'}`;
    dimensions.item_list_name = itemListName;
    dimensions.index = index || null;
  }

  return dimensions;
};
