import * as routeNames from '../app/constants/route-names';
import * as externalUrls from '../app/constants/external-urls';
import { DEFAULT_CHANNEL } from 'constants/channels';
import store from 'store';

const isInternalLogin = (pathname) => {
  return /identificacao/i.test(pathname);
};

export const getNextUrl = (pathname) => {
  const state = store.getState();
  const productCount = state?.basket?.basket?.productCount;

  if (!isInternalLogin(pathname)) {
    return externalUrls.catalog;
  }

  return productCount > 0 ? routeNames.address : routeNames.root;
};

export const getOriginUrl = (next) => {
  return (next === externalUrls.catalog)
    ? DEFAULT_CHANNEL
    : '';
};
