import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, initialize, change } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

import * as customerMessages from 'constants/customer-messages';

import * as cleaners from 'utils/value-cleaners';
import * as validators from 'utils/validators';

import * as addressActions from 'reducers/address';
import * as signupActions from 'reducers/signup';

import { validateCaptcha } from 'components/Form/Captcha/Captcha';
import FormGroup from 'components/Form/FormGroup/FormGroup';
import SignupFormPJ from 'components/Signup/SignupFormPJ/SignupFormPJ';
import AddressForm from 'components/Address/AddressForm/AddressForm';
import SignupNotificationsPJ from 'components/Signup/SignupNotificationsPJ/SignupNotificationsPJ';

import {
  errorsAddress,
  errorsFormPJ,
  onSubmitFail,
  commonFieldsPJ,
} from 'containers/SignupFormContainer';
import {
  handleZipcodeSubmit,
  handleZipcodeChange,
} from 'containers/AddressFormContainer';

const editFormPJName = 'editPJ';

const errorsPasswordEdit = ({ oldPassword, password }) => {
  const errors = {};

  if (!oldPassword) {
    errors.oldPassword = customerMessages.oldPasswordRequired;
  }
  if (!password) {
    errors.password = customerMessages.invalidPassword;
  }
  if (password && !validators.password(password)) {
    errors.password = customerMessages.wrongPasswordLength;
  }

  return errors;
};

const getAddress = async (fetchAddress, customerInfo) => {
  try {
    return await fetchAddress(customerInfo.zipcode);
  } catch {
    return customerInfo;
  }
};

const mapStateToProps = ({ customer, address, form, channel }) => ({
  customerInfo: customer.customerInfo,
  channelName: channel.name,
  address,
  form,
});

const mapDispatchToActions = {
  initializeForm: initialize,
  fetchAddress: addressActions.fetchAddress,
  resetAddress: addressActions.resetAddress,
  postSignup: signupActions.postSignup,
};

@reduxForm({
  form: editFormPJName,
  fields: ['email', 'oldPassword', ...commonFieldsPJ],
  onSubmitFail,
})
@connect(mapStateToProps, {
  ...mapDispatchToActions,
  changeField: change,
})
export class EditRegistrationFormPJContainer extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    channelName: PropTypes.string.isRequired,
    customerInfo: PropTypes.object.isRequired,
    initializeForm: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
    fetchAddress: PropTypes.func.isRequired,
    resetAddress: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    postSignup: PropTypes.func.isRequired,
    changeField: PropTypes.func.isRequired,
    asyncValidating: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
      .isRequired,
    redirectOnSuccess: PropTypes.func.isRequired,
  };

  state = {
    showOldPassword: false,
    showPassword: false,
    notifications: {
      selectAll: false,
      pushPromotional: false,
      smsPromotional: false,
      emailPromotional: false,
      mailingPromotional: false,
      whatsappPromotional: false,
      whatsappTransactional: false,
      whatsappTelemarketingPromotional: false,
      telemarketingPromotional: false,
    },
  };

  constructor(props) {
    super(props);
    this.onNotificationsHandlerPJ = this.onNotificationsHandlerPJ.bind(this);
    this.state = {
      showOldPassword: false,
      showPassword: false,
      notifications: props.customerInfo.notifications,
    };
  }

  componentWillMount() {
    const { customerInfo, fetchAddress, initializeForm, fields } = this.props;

    if (customerInfo.zipcode) {
      return getAddress(fetchAddress, customerInfo).then((res) => {
        const { address, neighbourhood, city, state } = res;

        initializeForm(
          editFormPJName,
          Object.assign({}, customerInfo, {
            address,
            neighbourhood,
            city,
            state,
          }),
          Object.keys(fields)
        );
      });
    }
  }

  onNotificationsHandlerPJ(type, value) {
    if (type === 'selectAll') {
      this.setState({
        ...this.state,
        notifications: {
          selectAll: value,
          pushPromotional: value,
          smsPromotional: value,
          emailPromotional: value,
          mailingPromotional: value,
          whatsappPromotional: value,
          whatsappTransactional: value,
          whatsappTelemarketingPromotional: value,
          telemarketingPromotional: value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        notifications: {
          ...this.state.notifications,
          [type]: value,
        },
      });
    }
  }

  render() {
    const {
      customerInfo,
      channelName,
      address: { address, neighbourhood, city, state },
      fields,
      fetchAddress,
      resetAddress,
      handleSubmit,
      changeField,
      postSignup,
      asyncValidating,
      redirectOnSuccess,
    } = this.props;
    const finalFields = Object.assign({}, fields, {
      email: Object.assign({}, fields.email, { disabled: true }),
      cnpj: Object.assign({}, fields.cnpj, { disabled: true }),
      address: Object.assign({}, fields.address, { disabled: !!address }),
      neighbourhood: Object.assign({}, fields.neighbourhood, {
        disabled: !!neighbourhood,
      }),
      city: Object.assign({}, fields.city, { disabled: !!city }),
      state: Object.assign({}, fields.state, { disabled: !!state }),
    });
    return (
      <form
        method="post"
        className="SignupFormPJ"
        onSubmit={handleSubmit((values) => {
          return new Promise((resolve, reject) => {
            const cleanValues = Object.assign({}, values, {
              homePhone: cleaners.onlyNumbers(values.homePhone),
              comercialPhone: cleaners.onlyNumbers(values.comercialPhone),
              mobilePhone: cleaners.onlyNumbers(values.mobilePhone),
              cnpj: cleaners.cpfOrCnpj(values.cnpj),
              zipcode: cleaners.zipcode(values.zipcode),
              channelName,
              notifications: this.state.notifications,
            });
            let errors = Object.assign(
              {},
              errorsFormPJ(cleanValues),
              errorsAddress(cleanValues)
            );

            if (values.oldPassword || values.password) {
              errors = Object.assign(
                {},
                errors,
                errorsPasswordEdit(cleanValues)
              );
            }

            if (!isEmpty(errors)) {
              return reject(errors);
            }

            return validateCaptcha('signup').then((captchaToken) => {
              return postSignup(
                'pj',
                Object.assign({}, cleanValues, {
                  customerUuid: customerInfo.customerUuid,
                }),
                captchaToken
              ).then(redirectOnSuccess);
            });
          });
        })}
      >
        <FormGroup labelText="E-mail" field={finalFields.email} />
        <SignupFormPJ
          {...finalFields}
          asyncValidating={asyncValidating}
          showOldPassword={this.state.showOldPassword}
          showPassword={this.state.showPassword}
          handleShowPasswordClick={(statePassword) => {
            this.setState({ [statePassword]: !this.state[statePassword] });
          }}
        />
        <AddressForm
          {...finalFields}
          multipleTelephones
          handleZipcodeSubmit={handleZipcodeSubmit(
            fields.zipcode.value,
            editFormPJName,
            fetchAddress,
            changeField
          )}
          canChangeZipcode
          handleZipcodeChange={handleZipcodeChange(resetAddress, fields)}
        />
        <SignupNotificationsPJ
          {...finalFields}
          notifications={this.state.notifications}
          onNotificationsHandlerPJ={this.onNotificationsHandlerPJ}
        />
        <button className="continueButton">Continuar</button>
      </form>
    );
  }
}
