export function loadJsAsync(src, callback = null, id) {
  const script = document.createElement('script');

  script.id = id;
  script.src = src;
  script.type = 'text/javascript';
  script.async = true;

  if (callback) {
    script.addEventListener(
      'load',
      e => callback(null, e),
      false
    );
  }

  document.head.appendChild(script);
}

