export const formatPoliciesByProduct = (policies, sku) => {
  const policiesByProduct = [];
  policies.forEach(policy => {
    for (const violations of policy.violations) {
      if (violations.skus?.[sku]) {
        policiesByProduct.push({
          ...violations.skus[sku],
          rule: violations.ruleDescription,
          description: policy.description
        });
      }
    }
  });

  return policiesByProduct;
};
