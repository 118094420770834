import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

import IconDelete from 'icons/icon-delete.svg';
import Responsive from 'components/Responsive';

const BasketItemQuantity = ({
  disabled,
  handleItemQuantityUpdate,
  handleItemRemove,
  quantity,
  maxProductQuantity,
  isMobile,
  maxQuantity,
  minQuantity,
  unavailable,
}) => {
  const showMaxQuantity = maxQuantity ? maxQuantity : maxProductQuantity;
  const showMinQuantity = minQuantity || 1;

  const options = range(showMinQuantity, showMaxQuantity + 1).map((i) => {
    return <option value={i} key={i}>{i}</option>;
  });

  return (
    <div className="BasketItemProduct-quantity">
      {isMobile &&
    <span className="BasketItemProduct-quantity-title">Quantidade:</span>
      }
      {!unavailable && (
        <select
          className="BasketItemProduct-quantity-dropdown"
          value={quantity}
          disabled={disabled}
          onChange={handleItemQuantityUpdate}
        >
          {options}
        </select>

      )}
      {unavailable && (
        <select
          className="BasketItemProduct-quantity-dropdown--unavailable"
          value={quantity}
          disabled
          onChange={handleItemQuantityUpdate}
        >
          {options}
        </select>
      )}
      <button
        className="BasketItemProduct-quantity-remove"
        onClick={handleItemRemove}
        data-ga='{"category": "Carrinho", "action": "Produto", "label": "Remover"}'
      >
        <IconDelete className="BasketItem-delete-icon" />
        <span className="BasketItem-delete-label">Excluir</span>
      </button>
    </div>
  );
};

BasketItemQuantity.propTypes = {
  handleItemQuantityUpdate: PropTypes.func,
  handleItemRemove: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  maxProductQuantity: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  maxQuantity: PropTypes.number,
  minQuantity: PropTypes.number,
  unavailable: PropTypes.bool.isRequired,
};

export {
  BasketItemQuantity
};
export default Responsive(BasketItemQuantity);
