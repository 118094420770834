import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as routeNames from 'constants/route-names';
import { GUEST_DOCUMENT } from 'constants/cookies';

import { redirectToNextUrl } from 'utils/redirect';
import { isLogged } from 'utils/session';

import * as loadingActions from 'reducers/loading';

import { GenericFooter } from 'components/Footer/GenericFooter/GenericFooter';
import LoginFooterInfo from 'components/Login/LoginFooterInfo';
import Captcha from 'components/Form/Captcha/Captcha';

import SocialLoginBoxContainer from 'containers/SocialLoginBoxContainer';
import SignupFormContainer from 'containers/SignupFormContainer';

import './SignupPage.scss';

const { func, object, bool } = PropTypes;

const signupLoadingID = 'signupLoadingID';
const addressLoadingID = 'addressLoadingID';

const mapStateToProps = ({
  signup,
  address,
  signupAvailable,
  socialLogin,
  channel,
}) => ({
  ...signupAvailable,
  ...socialLogin,
  signupRequesting: signup.isRequesting,
  addressRequesting: address.isRequesting,
  useCaptcha: channel.configs.use_captcha,
  useSocialLogin: channel.configs.use_social_login ?? true,
});
const mapDispatchToActions = { ...loadingActions };

@connect(mapStateToProps, mapDispatchToActions)
export default class SignupPage extends Component {
  static propTypes = {
    loginAvailable: bool.isRequired,
    profileInfo: object.isRequired,
    location: object.isRequired,
    addressRequesting: bool.isRequired,
    signupRequesting: bool.isRequired,
    toggleLoading: func.isRequired
  };

  static contextTypes = {
    router: object.isRequired
  };

  componentWillMount() {
    const {
      loginAvailable,
      location: { query }
    } = this.props;
    const { router } = this.context;

    if (isLogged()) {
      return router.replace(routeNames.address);
    }

    if (sessionStorage.getItem(GUEST_DOCUMENT)) {
      return;
    }

    if (!loginAvailable) {
      return redirectToNextUrl(router.replace, {
        next: routeNames.login,
        nextQuery: query
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      addressRequesting,
      signupRequesting,
      toggleLoading
    } = nextProps;

    toggleLoading(addressLoadingID, addressRequesting);
    toggleLoading(signupLoadingID, signupRequesting);
  }

  componentWillUnmount() {
    const { toggleLoading } = this.props;

    toggleLoading(addressLoadingID, false);
    toggleLoading(signupLoadingID, false);
  }

  render() {
    const {
      profileInfo: { accessToken, socialClientId },
      location,
      useCaptcha,
      useSocialLogin,
    } = this.props;

    return (
      <div className="SignupPage">
        {useCaptcha ? <Captcha /> : null}

        <div className="SignupPage-title">
          Quero criar uma conta
        </div>

        {!(accessToken || socialClientId) && useSocialLogin &&
          <SocialLoginBoxContainer
            location={location}
            isSignup
          />
        }

        <SignupFormContainer location={location} />

        {useSocialLogin &&
          <GenericFooter>
            <LoginFooterInfo />
          </GenericFooter>
        }
      </div>
    );
  }
}
