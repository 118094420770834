import React from 'react';

import { MIN_WIDTH } from 'constants/responsive-properties';

function ColorStripMobile(props) {
  const { width = MIN_WIDTH } = props;

  return (
    <svg
      height="6"
      viewBox={`0 0 ${width} 6`}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient x1=".92%" y1="0%" x2="100%" y2="0%" id="a">
          <stop stopColor="#FFFF4A" offset=".092%" />
          <stop stopColor="#FCD000" offset="3.911%" />
          <stop stopColor="#FFC112" offset="7.776%" />
          <stop stopColor="#FFC112" offset="10.896%" />
          <stop stopColor="#FF8A00" offset="15.69%" />
          <stop stopColor="#FF5F5F" offset="22.388%" />
          <stop stopColor="#FF253A" offset="27.517%" />
          <stop stopColor="#FF37A8" offset="36.913%" />
          <stop stopColor="#C739FF" offset="49.38%" />
          <stop stopColor="#A400E1" offset="55.694%" />
          <stop stopColor="#2ECEFF" offset="71.63%" />
          <stop stopColor="#0086FF" offset="80.307%" />
          <stop stopColor="#72F772" offset="93.689%" />
          <stop stopColor="#00D604" offset="100%" />
        </linearGradient>
      </defs>

      <path
        transform="matrix(1 0 0 -1 0 74.97)"
        d={`M0 69h${width}v5.97H0z`}
        fill="url(#a)"
      />
    </svg>
  );
}

export default ColorStripMobile;
